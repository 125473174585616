import { createSlice } from "@reduxjs/toolkit";
import { EnumPageMode } from "../../../common/enums/common.enums";
import {
  InspectionDTO,
  InspectionMemberDTO,
  InspectionQuestionDTO,
  InspectionStandardDTO,
} from "../../../common/types/common.dto.types";
import {
  PageModeType,
  RedirectObject,
} from "../../../common/types/common.page.type";
import {
  QuestionMainDataType,
  ReopenInspectionStandardData,
  SiteInspectionStartDataType,
} from "./SiteInspectionStartPage.types";

// ==============================|| VIEWS - PAGES - STANDARDSubject PAGE - REDUCER ||============================== //

const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: true,
    IsShowAddBtn: false,
    IsShowEditBtn: true,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: false,
    IsShowUpdateBtn: false,
    RedirctOption: {
      NavigateURl: "",
      NavigateObject: {} as any,
    } as RedirectObject,
  } as PageModeType,
  Inspection: {} as InspectionDTO,
  SiteTeamMemberName: "",
  InspectionName: "",
  InspectonDescription: "",
  QuestionMainData: [] as Array<QuestionMainDataType>,
  InspectionMembers: [] as Array<InspectionMemberDTO>,
  InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
  AllowResumption: true,
  InspectionStandardIdToResume: [],
  ReopenUpdateData: [] as Array<ReopenInspectionStandardData>,
  UpdateOnlySMEData: false,
  ShowGlobalSMEAssignmentPopup: false,
  TempGlobalSMEAssignmentObject: {},
  CloseCopyPopup: false,
} as SiteInspectionStartDataType;

export const siteInspectionStartSlice = createSlice({
  name: "siteInspectionStart",
  initialState: initialState,
  reducers: {
    setLoading: (
      state: SiteInspectionStartDataType,
      action: { payload: boolean; type: string }
    ) => {
      return { ...state, IsLoading: action.payload };
    },
    setSiteInspectionStartList: (
      state: SiteInspectionStartDataType,
      action: { payload: SiteInspectionStartDataType; type: string }
    ) => {
      return {
        ...state,
        Inspection: action.payload.Inspection,
        SiteTeamMemberName: action.payload.SiteTeamMemberName,
        InspectionName: action.payload.InspectionName,
        InspectonDescription: action.payload.InspectonDescription,
        QuestionMainData: action.payload.QuestionMainData,
        InspectionMembers: action.payload.InspectionMembers,
        AllowResumption: false,
        InspectionStandardIdToResume: [],
        ReopenUpdateData: [],
        UpdateOnlySMEData: false,
        ShowGlobalSMEAssignmentPopup: false,
      };
    },
    setSiteTeamMember: (
      state: SiteInspectionStartDataType,
      action: { payload: Array<InspectionMemberDTO>; type: string }
    ) => {
      return {
        ...state,
        InspectionMembers: action.payload,
        SiteTeamMemberName: action.payload[0].MemberName,
        UpdateOnlySMEData: false,
        ShowGlobalSMEAssignmentPopup: false,
      };
    },
    changePageMode: (
      state: SiteInspectionStartDataType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            IsDisable: true,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: false,
          },
          UpdateOnlySMEData: false,
          ShowGlobalSMEAssignmentPopup: false,
        };
      }
    },
    setPageModeRedirectMode: (
      state: SiteInspectionStartDataType,
      action: { payload: RedirectObject; type: string }
    ) => {
      return {
        ...state,
        PageMode: {
          ...state.PageMode,
          RedirctOption: action.payload,
        },
        UpdateOnlySMEData: false,
        ShowGlobalSMEAssignmentPopup: false,
      };
    },
    clearPageModeRedirectMode: (state) => {
      return {
        ...state,
        PageMode: {
          ...state.PageMode,
          RedirctOption: initialState.PageMode.RedirctOption,
        },
        UpdateOnlySMEData: false,
        ShowGlobalSMEAssignmentPopup: false,
      };
    },
    clearPageState: () => {
      return initialState;
    },
    setPageMode: (
      state: SiteInspectionStartDataType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.Default === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.Default,
            IsDisable: true,
            IsShowAddBtn: false,
            IsShowEditBtn: true,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
          },
          UpdateOnlySMEData: false,
          ShowGlobalSMEAssignmentPopup: false,
        };
      } else if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowAddBtn: false,
            IsShowEditBtn: true,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
          },
          UpdateOnlySMEData: false,
          ShowGlobalSMEAssignmentPopup: false,
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: true,
            IsShowSaveBtn: true,
            IsShowUpdateBtn: false,
          },
          UpdateOnlySMEData: false,
          ShowGlobalSMEAssignmentPopup: false,
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowAddBtn: false,
            IsShowEditBtn: false,
            IsShowDeleteBtn: false,
            IsShowCancelBtn: true,
            IsShowSaveBtn: true,
            IsShowUpdateBtn: false,
          },
          UpdateOnlySMEData: false,
          ShowGlobalSMEAssignmentPopup: false,
        };
      }
    },
    updateInspectionStandardStatus: (
      state: SiteInspectionStartDataType,
      action: {
        payload: {
          allowResumption: boolean;
          idToUpdate: number;
          reopenedData: ReopenInspectionStandardData;
        };
        type: string;
      }
    ) => {
      return {
        ...state,
        AllowResumption: action.payload.allowResumption,
        InspectionStandardIdToResume: [
          ...state.InspectionStandardIdToResume,
          action.payload.idToUpdate,
        ],
        ReopenUpdateData: [
          ...state.ReopenUpdateData,
          action.payload.reopenedData,
        ],
        UpdateOnlySMEData: false,
        ShowGlobalSMEAssignmentPopup: false,
      };
    },

    setUpdatedSMEList: (
      state: SiteInspectionStartDataType,
      action: {
        payload: {
          MainData: SiteInspectionStartDataType;
          TempInspectionStandard: InspectionStandardDTO;
          ShowGlobalSMEAssignmentPopup: boolean;
        };
        type: string;
      }
    ) => {
      return {
        ...state,
        Inspection: action.payload.MainData.Inspection,
        QuestionMainData: action.payload.MainData.QuestionMainData,
        UpdateOnlySMEData: true,
        ShowGlobalSMEAssignmentPopup:
          action.payload.ShowGlobalSMEAssignmentPopup,
        TempGlobalSMEAssignmentObject: action.payload.TempInspectionStandard,
      };
    },

    closeCopyPopup: (
      state: SiteInspectionStartDataType,
      action: { payload: boolean; type: string }
    ) => {
      return {
        ...state,
        CloseCopyPopup: action.payload,
      };
    },
  },
});

export const {
  setSiteInspectionStartList,
  setSiteTeamMember,
  changePageMode,
  setLoading,
  setPageMode,
  setPageModeRedirectMode,
  clearPageModeRedirectMode,
  clearPageState,
  updateInspectionStandardStatus,
  setUpdatedSMEList,
  closeCopyPopup,
} = siteInspectionStartSlice.actions;
export const siteInspectionStartReducer = siteInspectionStartSlice.reducer;
