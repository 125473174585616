// project imports
import { AxiosError } from "axios";
import { QuestionDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

// ==============================|| VIEWS - PAGES - QUESTION - API ||============================== //

// client object for axios calls to backend
const client = new ApiClient();

export const getLanguageList = async (): Promise<ActionResponseType> => {
  try {
    const URL: string = `Language/GetLanguageList`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response: ActionResponseType) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

// Get standard data from backend
export const getQuestionById = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Question/GetQuestionById/${id}`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

// save question
export const SaveQuestion = async (
  question: QuestionDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "";
    if (question.Id > 0) {
      URL = `Question/UpdateQuestion`;
      question.Standard = null;
    } else {
      URL = `Question/SaveQuestion`;
    }
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, question)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        //return error;
      });
    return data;
  } catch (error) {
    throw error;
  }
};

// Get standard data from backend
export const getStandardById = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Standard/GetStandardById/${id}`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const getQuestionPageInitialData = async (
  question: QuestionDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Question/GetPageInitialData`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, question)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

// save question
export const deactivateQuestionApi = async (
  question: QuestionDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "";
    if (question.Id > 0) {
      URL = `Question/ChangeQuestionStatus`;
      question.Standard = null;
    } else {
      URL = `Question/ChangeQuestionStatus`;
    }
    let data = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, question)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        //return error;
      });
    return data;
  } catch (error) {
    throw error;
  }
};

// check available inspection sets before update question - publish only
// export const checkAvailableInspectionSetsBeforeUpdateQuestion = async (
//   question: QuestionDTO
// ): Promise<ActionResponseType> => {
//   try {
//     let URL: string =
//       "Question/CheckAvailableInspectionSetsBeforeUpdateQuestion";

//     let data = {} as ActionResponseType;
//     await client
//       .post<ActionResponseType>(URL, question)
//       .then((response: ActionResponseType) => {
//         data = response;
//       })
//       .catch((error) => {
//         //return error;
//       });
//     return data;
//   } catch (error) {
//     throw error;
//   }
// };

export const getNavigateQuestion = async (
  Id: number,
  StandardId: number,
  IsPrev: boolean
): Promise<ActionResponseType> => {
  try {
    let URL: string = `Question/GetNavigateQuestion?Id=${Id}&StandardId=${StandardId}&IsPrev=${IsPrev}`;
    let data = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response: ActionResponseType) => {
        data = response;
      })
      .catch((error) => {
        //return error;
      });
    return data;
  } catch (e) {}
};

// Check question status before deactivate
// Check question status before Update
// check question used for inspections
export const CheckBeforeUpdateOrDeactivateQuestion = async (
  question: QuestionDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "Question/CheckBeforeUpdateOrDeactivateQuestion";

    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, question)
      .then((response) => {
        result = response;
      })
      .catch((error: AxiosError) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const getQuestionDropdownCategoriesApi = async (
  questionTypeId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Question/GetQuestionDropdownCategories/${questionTypeId}`;
    let result: ActionResponseType = {} as ActionResponseType;

    await client
      .get<ActionResponseType>(URL)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const getQuestionCategoryChoicesApi = async (
  question: QuestionDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Question/GetQuestionCategoryChoices`;
    let result: ActionResponseType = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, question)
      .then((response) => {
        result = response;
      })
      .catch((error: AxiosError) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};
