import { MRT_ColumnDef } from "material-react-table";
import HPRMaterialReactTable from "../../../components/common/HPRMaterialReactTable/HPRMaterialReactTable";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
} from "@mui/material";
import { SearchNormal1, AddCircle } from "iconsax-react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  EmployeeDTO,
  MutipleChoiceListDTO,
  PaginationDTO,
  QuestionCategoryDTO,
  SortDropDownDTO,
  StatusDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";
import {
  EnumListShowRowCountList,
  EnumPageType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { HPRMaterialReactTableStatusSummaryType } from "../../../components/common/HPRMaterialReactTable/HPRMaterialReactTable.type";
import {
  CheckFunctionRights,
  formatDateTimeToString,
} from "../../../common/functions/common.functions";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import {
  MultipleChoicesPageParametersType,
  MultipleChoicesPageType,
} from "./MultipleChoicesPage.types";
import { multipleChoicesReducerActions } from "./MultipleChoicesPage.reducer";
import { getMultipleChoiceList } from "./MultipleChoicesPage.action";
import { multipleChoiceReducerActions } from "../MultipleChoicePage/MultipleChoicePage.reducer";

// ==============================|| VIEWS - PAGES - MULTIPLE CHOICES ||============================== //

const initialState = {
  Data: {
    Data: [] as Array<MutipleChoiceListDTO>,
    PageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    TotalRecordsCount: 0,
    StatusSummaryCount: {} as StatusSummaryCountDTO,
  } as PaginationDTO,
  MultipleChoicesSearchParameter: {
    Name: "",
    StatusNavigation: {
      Status1: "",
    } as StatusDTO,
    CreatedBy: { DisplayName: "" } as EmployeeDTO,
    CreatedDateFrom: null,
    CreatedDateTo: null,
    ModifiedByNavigation: { DisplayName: "" } as EmployeeDTO,
    ModifiedDateFrom: null,
    ModifiedDateTo: null,
    Status: EnumStatus.Published,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    BasicSearchValue: "",
    SortByNavigation: {
      Label: "Name",
      SortType: 1,
      Value: "1",
    },
  } as QuestionCategoryDTO,
  DataLoading: false,
} as MultipleChoicesPageType;

const MultipleChoicesPage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);

  const dataFetchedRef = useRef(false);
  const dispatch = useAppDispatch();

  // use for page navigation
  const navigate = useNavigate();

  // create state for AssessmentTypeWizardsPage with initialState values
  const [multipleChoicesPageState, setMultipleChoicesPageState] =
    useState<MultipleChoicesPageType>(initialState);

  const multipleChoicesListState = useAppSelector(
    (state) => state.multipleChoiceListReducer
  );

  const authState = useAppSelector((state) => state.authReducer);

  // pass parameters from outside to page
  const location = useLocation();
  const parameter: MultipleChoicesPageParametersType = location.state;

  //-----standard list heigh ------
  const mainHeading = useRef(null);
  const wrapperCard = useRef(null);

  //Set Hight
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    let tableHeight =
      wrapperCard?.current?.offsetHeight -
      mainHeading?.current?.offsetHeight -
      45;
    setTableHeight(tableHeight);

    function handleResize() {
      let tableHeight =
        wrapperCard?.current?.offsetHeight -
        mainHeading?.current?.offsetHeight -
        45;
      setTableHeight(tableHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [wrapperCard?.current?.offsetHeight, mainHeading?.current?.offsetHeight]);

  // Set Search Clear Icon
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.MultipleChoices,
      functionId,
      functionTypeId
    );
  };

  const defaultSort = {
    Label: "Name",
    SortType: 1,
    Value: "1",
  } as SortDropDownDTO;

  const TableCellComponent = (props: {
    value: any;
    showToolTip?: boolean;
    className?: string;
  }) => {
    return props.showToolTip ? (
      <Tooltip title={!props.value || props.value === "-" ? "" : props.value}>
        <span className={`${props.className}`}>{props.value}</span>
      </Tooltip>
    ) : (
      <span className={props.className}>{props.value}</span>
    );
  };

  const columns: Array<MRT_ColumnDef<any>> = [
    {
      id: "Name-1",
      accessorKey: "Name",
      header: "Name",
      Cell: ({ cell }) =>
        TableCellComponent({
          value: cell.row.original?.Name || "-",
          showToolTip: true,
          className: "limit-3 truncate block",
        }),
    },
    {
      id: "Type-2",
      accessorKey: "Type",
      header: "Type",
      Cell: ({ cell }) =>
        TableCellComponent({
          value: cell.row.original?.Type || "-",
          showToolTip: true,
          className: "limit-3 truncate",
        }),
    },
    {
      id: "Status-3",
      accessorKey: "Status",
      header: "Status",
      Cell: ({ cell }) =>
        TableCellComponent({
          value: cell.row.original?.Status || "-",
          className: `status-color ${getMultipleChoiceStatusColor(
            cell.row.original?.StatusId
          )}`,
        }),
    },
    {
      id: "CreatedBy-4",
      accessorKey: "CreatedBy",
      header: "Created By",
      size: 270,
      Cell: ({ cell }) =>
        TableCellComponent({
          value: cell.row.original?.CreatedBy || "-",
        }),
    },
    {
      id: "CreatedDateAndTime-5",
      accessorKey: "CreatedDateAndTime",
      header: "Created Date And Time",
      size: 200,
      Cell: ({ cell }) =>
        TableCellComponent({
          value: formatDateTimeToString(cell.row.original?.CreatedDate) || "-",
        }),
    },
    {
      id: "ModifiedBy-6",
      accessorKey: "ModifiedBy",
      header: "Modified By ",
      size: 270,
      Cell: ({ cell }) =>
        TableCellComponent({
          value:
            formatDateTimeToString(cell.row.original?.CreatedDate) ===
            formatDateTimeToString(cell.row.original?.ModifiedDate)
              ? "-"
              : cell.row.original?.ModifiedBy || "-",
        }),
    },
    {
      id: "ModifiedDateAndTime-7",
      accessorKey: "ModifiedDateAndTime",
      header: "Modified Date And Time",
      size: 200,
      Cell: ({ cell }) =>
        TableCellComponent({
          value:
            formatDateTimeToString(cell.row.original?.CreatedDate) ===
            formatDateTimeToString(cell.row.original?.ModifiedDate)
              ? "-"
              : formatDateTimeToString(cell.row.original?.ModifiedDate) || "-",
        }),
    },
  ];

  const getMultipleChoiceStatusColor = (status: EnumStatus) => {
    if (status === EnumStatus.Published) {
      return "published";
    } else if (status === EnumStatus.Draft) {
      return "draft";
    } else if (status === EnumStatus.Deactivated) {
      return "deactivated";
    } else if (status === EnumStatus.All) {
      return "all";
    } else {
      return "";
    }
  };

  const multipleChoiceStatusSummary =
    multipleChoicesPageState?.MultipleChoiceStatusSummary?.map(
      (status: StatusDTO) => {
        return {
          Value: status.Id,
          Label: status.Status1,
          StatusSummaryCount: status.NoOfStatusCount,
          ClassName: getMultipleChoiceStatusColor(status.Id),
        } as HPRMaterialReactTableStatusSummaryType;
      }
    );

  // Page onload
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    setMultipleChoicesPageState(initialState);
    dispatch(multipleChoicesReducerActions.resetMultipleChoiceList());
    dispatch(multipleChoiceReducerActions.resetMultipleChoice());

    const multipleChoicesSearchParameter = JSON.parse(
      localStorage.getItem("MultipleChoicesSearchParameter")
    );

    if (!parameter?.IsNavigateFromInside) {
      const assessmentTypeSearchParamter = {
        ...multipleChoicesSearchParameter,
        Status: initialState.MultipleChoicesSearchParameter.Status,
        SelectedPage: initialState.MultipleChoicesSearchParameter.SelectedPage,
      } as QuestionCategoryDTO;

      localStorage.setItem(
        "MultipleChoicesSearchParameter",
        JSON.stringify(assessmentTypeSearchParamter)
      );
    }

    const initialStateWithSearchParameter = {
      ...initialState,
      MultipleChoicesSearchParameter: {
        ...initialState.MultipleChoicesSearchParameter,
        BasicSearchValue:
          multipleChoicesSearchParameter?.BasicSearchValue ??
          initialState.MultipleChoicesSearchParameter.BasicSearchValue,
        ListPageSize:
          multipleChoicesSearchParameter?.ListPageSize ??
          initialState.MultipleChoicesSearchParameter.ListPageSize,
        SelectedPage: parameter?.IsNavigateFromInside
          ? multipleChoicesSearchParameter?.SelectedPage ??
            initialState.MultipleChoicesSearchParameter.SelectedPage
          : initialState.MultipleChoicesSearchParameter.SelectedPage,
        SortByNavigation:
          multipleChoicesSearchParameter?.SortByNavigation ??
          initialState.MultipleChoicesSearchParameter.SortByNavigation,
        Status: parameter?.IsNavigateFromInside
          ? multipleChoicesSearchParameter?.Status ??
            initialState.MultipleChoicesSearchParameter.Status
          : initialState.MultipleChoicesSearchParameter.Status,
      },
    } as MultipleChoicesPageType;

    setMultipleChoicesPageState(initialStateWithSearchParameter);

    dispatch(
      getMultipleChoiceList({
        ...initialStateWithSearchParameter?.MultipleChoicesSearchParameter,
      })
    );

    //clear parameter
    navigate(location.pathname, { replace: true });
  }, []);

  // add to local storage if search parameter change
  useEffect(() => {
    localStorage.setItem(
      "MultipleChoicesSearchParameter",
      JSON.stringify(multipleChoicesPageState.MultipleChoicesSearchParameter)
    );
  }, [multipleChoicesPageState.MultipleChoicesSearchParameter]);

  //when reducer state change
  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setMultipleChoicesPageState((values) => {
      return {
        ...values,
        Data: {
          ...values.Data,
          Data: multipleChoicesListState?.Data?.Data,
          TotalRecordsCount: multipleChoicesListState?.Data?.TotalRecordsCount,
          SelectedPage: multipleChoicesListState?.Data?.SelectedPage,
          PageSize: multipleChoicesListState?.Data?.PageSize,
        } as PaginationDTO,
        MultipleChoicesSearchParameter: {
          ...values?.MultipleChoicesSearchParameter,
          SelectedPage: multipleChoicesListState?.Data?.SelectedPage,
          ListPageSize: multipleChoicesListState?.Data?.PageSize,
        } as QuestionCategoryDTO,
        MultipleChoiceStatusSummary:
          multipleChoicesListState?.MultipleChoiceStatusSummary,
      } as MultipleChoicesPageType;
    });
  }, [multipleChoicesListState]);

  //--------------------- Page Navigate <Start> ------------------------------//
  const onClickBackButton = () => {
    navigate("/");
  };

  const onClickAddBtn = () => {
    navigate("/DefineAnswerChoice", {
      state: { MultipleChoiceId: 0 },
    });
  };

  const onRowClickedCallBack = (multipleChoice: QuestionCategoryDTO) => {
    navigate("/DefineAnswerChoice", {
      state: { MultipleChoiceId: multipleChoice.Id },
    });
  };
  //--------------------- Page Navigate <End> ------------------------------//

  //--------------------- Page Function Search <Start> ------------------------------//

  const searchMultipleChoiceWizard = (
    multipleChoicesSearchParameter: QuestionCategoryDTO
  ) => {
    setMultipleChoicesPageState((values) => {
      return {
        ...values,
        MultipleChoicesSearchParameter: multipleChoicesSearchParameter,
      };
    });

    dispatch(getMultipleChoiceList(multipleChoicesSearchParameter));
  };

  const onClickBasicSerachBtn = () => {
    if (
      multipleChoicesPageState.MultipleChoicesSearchParameter
        .BasicSearchValue !==
      multipleChoicesListState.MultipleChoicesSearchParameter.BasicSearchValue
    ) {
      const assessmentTypeSearchParamter = {
        ...multipleChoicesPageState.MultipleChoicesSearchParameter,
        BasicSearchValue:
          multipleChoicesPageState.MultipleChoicesSearchParameter
            .BasicSearchValue,
        SelectedPage: 1,
      } as QuestionCategoryDTO;

      searchMultipleChoiceWizard(assessmentTypeSearchParamter);
    }
  };

  const onChangeSearchName = (searchValue: string) => {
    setMultipleChoicesPageState((values) => {
      return {
        ...values,
        MultipleChoicesSearchParameter: {
          ...values.MultipleChoicesSearchParameter,
          BasicSearchValue: searchValue,
        },
      };
    });

    if (!searchValue || searchValue === "") {
      const assessmentTypeSearchParamter = {
        ...multipleChoicesPageState.MultipleChoicesSearchParameter,
        BasicSearchValue: "",
        SelectedPage: 1,
      } as QuestionCategoryDTO;

      searchMultipleChoiceWizard(assessmentTypeSearchParamter);
    }
  };

  const onKeyPressSearchName = (event: any) => {
    if (event.charCode === 13) {
      event.preventDefault();
      onClickBasicSerachBtn();
    }
  };

  const onClickStatusSummaryCallBack = (status: number) => {
    const assessmentTypeSearchParamter = {
      ...multipleChoicesPageState.MultipleChoicesSearchParameter,
      Status: status,
      SelectedPage: 1,
    } as QuestionCategoryDTO;

    localStorage.setItem(
      "MultipleChoicesSearchParameter",
      JSON.stringify(assessmentTypeSearchParamter)
    );
    searchMultipleChoiceWizard(assessmentTypeSearchParamter);
  };

  const onPaginationChangeCallBack = (newPage: number, pageSize: number) => {
    let assessmentTypeSearchParamter = {
      ...multipleChoicesPageState.MultipleChoicesSearchParameter,
    } as QuestionCategoryDTO;

    if (
      multipleChoicesPageState.MultipleChoicesSearchParameter
        ?.FilterColumnWise &&
      (multipleChoicesPageState.MultipleChoicesSearchParameter.SelectedPage !==
        newPage ||
        multipleChoicesPageState.MultipleChoicesSearchParameter.ListPageSize !==
          pageSize)
    ) {
      assessmentTypeSearchParamter = {
        ...assessmentTypeSearchParamter,
        SelectedPage: newPage,
        ListPageSize: pageSize,
        FilterColumnWise: true,
      };
    } else if (
      multipleChoicesPageState.MultipleChoicesSearchParameter.ListPageSize !==
      pageSize
    ) {
      assessmentTypeSearchParamter = {
        ...multipleChoicesPageState.MultipleChoicesSearchParameter,
        SelectedPage: 1,
        ListPageSize: pageSize,
        FilterColumnWise: false,
      };
    } else if (
      multipleChoicesPageState.MultipleChoicesSearchParameter.SelectedPage !==
      newPage
    ) {
      assessmentTypeSearchParamter = {
        ...assessmentTypeSearchParamter,
        SelectedPage: newPage,
        ListPageSize: pageSize,
        FilterColumnWise: false,
      };
    }

    searchMultipleChoiceWizard(assessmentTypeSearchParamter);
  };

  const onChangeAssessmentTypeWizardSortByCallBack = (
    selectedSortState: SortDropDownDTO
  ) => {
    if (
      Object.keys(selectedSortState)?.length === 0 ||
      JSON.stringify(
        multipleChoicesPageState?.MultipleChoicesSearchParameter
          ?.SortByNavigation
      ) === JSON.stringify(selectedSortState)
    )
      return;

    const assessmentTypeSearchParamter = {
      ...multipleChoicesPageState.MultipleChoicesSearchParameter,
      SortByNavigation: selectedSortState,
      SelectedPage: 1,
      FilterColumnWise: false,
    } as QuestionCategoryDTO;

    searchMultipleChoiceWizard(assessmentTypeSearchParamter);
  };

  //--------------------- Page Function Search <End> ------------------------------//

  return (
    <div
      className="content-section-card rem-tracker top-main-padding"
      ref={wrapperCard}
    >
      <Grid container>
        <div
          className="flex--container fx-wrap width100-p top-main-border mb-10 rem-btn-wrap"
          ref={mainHeading}
        >
          <div className="flex__item--inherit pr-10 pl-10">
            <h2>
              <Button
                aria-label="back"
                className="sub-head-back-btn"
                onClick={onClickBackButton}
                title="Back to home page"
              >
                <ArrowBackIosNewOutlinedIcon className="" />
                <span className="body-bold secondary-color">Back</span>
              </Button>
            </h2>
          </div>
          <div className="global-header-wrap rem-trak-head-res pr-10">
            <h1>{props.PageTitle}</h1>
          </div>
          <div className="flex__item search-bar--xsmall mr-10 pr-20 fx-around">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <InputBase
                value={
                  multipleChoicesPageState?.MultipleChoicesSearchParameter
                    ?.BasicSearchValue
                }
                onChange={(event) => {
                  onChangeSearchName(event.target?.value);
                }}
                onKeyPress={onKeyPressSearchName}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Name/Type"
                inputProps={{ "aria-label": "name" }}
              />

              {isMouseOver &&
                multipleChoicesPageState.MultipleChoicesSearchParameter
                  ?.BasicSearchValue &&
                multipleChoicesPageState.MultipleChoicesSearchParameter
                  ?.BasicSearchValue?.length > 0 && (
                  <Tooltip title="Clear" placement="top">
                    <IconButton
                      className="mr-0"
                      onClick={() => onChangeSearchName("")}
                    >
                      <CloseIcon
                        style={{ color: "#219ebc", fontSize: "20px" }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              <Tooltip title="Search" placement="top">
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  className="m-0"
                  onClick={onClickBasicSerachBtn}
                >
                  <SearchNormal1
                    variant="Outline"
                    className="ico-secondary"
                    size={18}
                  />
                </IconButton>
              </Tooltip>
            </Paper>
          </div>
          <div className="flex__item--inherit text-right sub-header-btn-wrap pr-10 pl-10">
            {checkSystemFunctionRight(
              EnumSystemFunctions.MultipleChoicesPage_Add_Multiple_Choices,
              EnumSystemFunctionTypes.ButtonAccess
            ) && (
              <Button
                className="primary-btn-small"
                variant="outlined"
                startIcon={<AddCircle variant="Outline" />}
                onClick={onClickAddBtn}
                title="Add"
              >
                Add
              </Button>
            )}
          </div>
        </div>

        <div style={{ maxHeight: tableHeight, width: "100%" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              className="grid-height pl-10 pr-10 hpr-material-table width100-p"
            >
              <HPRMaterialReactTable
                Columns={columns}
                Data={multipleChoicesPageState.Data?.Data}
                PaginationProps={{
                  TotalRecordsCount:
                    multipleChoicesPageState.Data?.TotalRecordsCount,
                  PageSize: multipleChoicesPageState.Data?.PageSize,
                  SelectedPage: multipleChoicesPageState.Data?.SelectedPage,
                  onPaginationChangeCallBack: onPaginationChangeCallBack,
                }}
                EnableSorting
                EnableRowClick={checkSystemFunctionRight(
                  EnumSystemFunctions.MultipleChoicesPage_View_Multiple_Choices,
                  EnumSystemFunctionTypes.ViewPage
                )}
                CallbackFunctionProps={{
                  onRowClickedCallBack: onRowClickedCallBack,
                  onChangeSortByCallBack:
                    onChangeAssessmentTypeWizardSortByCallBack,
                }}
                InitialSortByState={
                  JSON.parse(
                    localStorage.getItem("MultipleChoicesSearchParameter")
                  )?.SortByNavigation ??
                  multipleChoicesPageState.MultipleChoicesSearchParameter
                    .SortByNavigation
                }
                DefaultSort={defaultSort}
                ToolBarProps={{
                  StatusSummaryProps: {
                    SelectedStatus:
                      multipleChoicesPageState.MultipleChoicesSearchParameter
                        ?.Status,
                    StatusSummary: multipleChoiceStatusSummary,
                    onClickStatusSummaryCallBack: onClickStatusSummaryCallBack,
                  },
                }}
                FilterColumnWise={
                  multipleChoicesPageState.MultipleChoicesSearchParameter
                    ?.FilterColumnWise ?? false
                }
                ShowDataLoading={multipleChoicesListState.DataLoading}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
export default MultipleChoicesPage;
