import { QuestionCategoryDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

const client = new ApiClient();

export const getMultipleChoiceByIdApi = async (
  multipleChoiceId: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `MultipleChoices/GetMultipleChoiceById/${multipleChoiceId}`;
    let result: ActionResponseType = {} as ActionResponseType;

    await client
      .get<ActionResponseType>(URL)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

export const saveMultipleChoiceApi = async (
  multipleChoice: QuestionCategoryDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "";
    if (multipleChoice.Id > 0) {
      URL = "MultipleChoices/UpdateMultipleChoice";
    } else {
      URL = "MultipleChoices/AddMultipleChoice";
    }
    let result: ActionResponseType = {} as ActionResponseType;

    await client
      .post<ActionResponseType>(URL, multipleChoice)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};
