import { createSlice } from "@reduxjs/toolkit";
import {
  EnumListShowRowCountList,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  EmployeeDTO,
  MutipleChoiceListDTO,
  PaginationDTO,
  QuestionCategoryDTO,
  StatusDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";
import { MultipleChoicesPageType } from "./MultipleChoicesPage.types";

// ==============================|| VIEWS - PAGES - MULTIPLE CHOICES - REDUCER ||============================== //

const initialState = {
  Data: {
    Data: [] as Array<MutipleChoiceListDTO>,
    PageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    TotalRecordsCount: 0,
    StatusSummaryCount: {} as StatusSummaryCountDTO,
  } as PaginationDTO,
  MultipleChoicesSearchParameter: {
    Name: "",
    StatusNavigation: {
      Status1: "",
    } as StatusDTO,
    CreatedBy: { DisplayName: "" } as EmployeeDTO,
    CreatedDateFrom: null,
    CreatedDateTo: null,
    ModifiedByNavigation: { DisplayName: "" } as EmployeeDTO,
    ModifiedDateFrom: null,
    ModifiedDateTo: null,
    Status: EnumStatus.Published,
    ListPageSize: EnumListShowRowCountList.ListShowRowCountPerPage_3,
    SelectedPage: 1,
    BasicSearchValue: "",
    SortByNavigation: {
      Label: "Name",
      SortType: 1,
      Value: "1",
    },
  } as QuestionCategoryDTO,
  DataLoading: false,
} as MultipleChoicesPageType;

export const multipleChoiceSlice = createSlice({
  name: "MultipleChoicesList",
  initialState: initialState,
  reducers: {
    setMultipleChoiceList: (
      state: MultipleChoicesPageType,
      action: { payload: MultipleChoicesPageType; type: string }
    ) => {
      return {
        ...state,
        Data: {
          ...state.Data,
          Data: action.payload.Data?.Data,
          TotalRecordsCount: action.payload.Data?.TotalRecordsCount,
          SelectedPage: action.payload.Data?.SelectedPage,
          PageSize: action.payload.Data?.PageSize,
        } as PaginationDTO,
        MultipleChoiceStatusSummary:
          action.payload?.MultipleChoiceStatusSummary,
      };
    },
    setMultipleChoiceSearchParameter: (
      state: MultipleChoicesPageType,
      action: { payload: QuestionCategoryDTO; type: string }
    ) => {
      return {
        ...state,
        MultipleChoicesSearchParameter: action.payload,
      };
    },
    resetMultipleChoiceList: () => {
      return initialState;
    },
  },
});

export const multipleChoicesReducerActions = multipleChoiceSlice.actions;
export const multipleChoiceListReducer = multipleChoiceSlice.reducer;
