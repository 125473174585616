import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { ActionResponseType } from "../../../common/types/common.types";
import { ReportViewerType } from "../../../components/ReportViewer/ReportViewer.types";
import { GET_EXCEL_REPORT_DATA } from "./StandardReport.actions";
import StandardReportApi from "./StandardReport.api";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { DownloadStatusType, StandardReportType } from "./StandardReport.types";
import { standarReportReducerActions } from "./StandardReport.reducer";
import dayjs from "dayjs";
import store from "../../../core/store/index";

const excelWorkerStandardsReport: Worker = new Worker(
  new URL("./ExcelWorkerStandardReport.ts", import.meta.url)
);

excelWorkerStandardsReport.onmessage = (event) => {
  const excelData = event.data.buffer;

  //Handle the Excel data here, for example, you can save it as a file
  const blob = new Blob([excelData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  // let downloadFileName = `StandardSummaryReport - ${event.data.name} - ${
  //   event.data.rf
  // } - ${dayjs(new Date()).format("DD-MMM-YYYY HH_mm")}`;
  let baseFileName = `StandardSummaryReport - `;
  let timestamp = dayjs(new Date()).format("DD-MMM-YYYY HH_mm");

  let reservedLength = baseFileName.length + timestamp.length + 12; // 6 accounts for " -  - "
  let remainingLength = 207 - reservedLength;

  let truncatedName = event.data.name;

  let maxRfLength = remainingLength - truncatedName.length;
  let truncatedRf =
    event.data.rf.length > maxRfLength
      ? event.data.rf.substring(0, maxRfLength)
      : event.data.rf;

  if (truncatedName.length + truncatedRf.length > remainingLength) {
    let maxNameLength = remainingLength - truncatedRf.length;
    truncatedName = truncatedName.substring(0, maxNameLength);
  }

  let downloadFileName =
    truncatedRf.length > 0
      ? `${baseFileName}${truncatedName} - ${truncatedRf} - ${timestamp}`
      : `${baseFileName}${truncatedName} - ${timestamp}`;
  const extension = getFileExtensionFromMimeType(blob.type);
  if (extension != null && extension !== "") {
    if (!downloadFileName.endsWith(extension)) {
      downloadFileName += extension;
    }
  }
  // Create a download link for the Excel file and trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = downloadFileName;
  a.click();

  window.URL.revokeObjectURL(url);
  store.dispatch(
    standarReportReducerActions.setExcelReportStatus({
      InspectionId: event.data.inspectionId,
      StandardId: event.data.standardId,
      StandardName: event.data.rf,
      IsDownloading: false,
      InspectionStandardId: event.data.inspectionStandardId,
    } as DownloadStatusType)
  );
  store.dispatch(
    openPopup({
      Open: true,
      BodyText: "Report downloaded successfully!",
      HeaderText: "Success", //"Success",
      PopupType: EnumPopupType.SuccessPopup,
    } as PopupProps)
  );

  return () => {
    excelWorkerStandardsReport.terminate();
  };
};

const standardExcelReportWatcher = function* () {
  yield takeEvery(GET_EXCEL_REPORT_DATA, function* (action: AnyAction): any {
    const data = action.payload as ReportViewerType;
    const inspectionId = data?.Inspection?.Id;
    const standardId = data?.Standard?.Id;
    const standardName = data?.Standard?.Name;
    const inspectionStandardId = data?.InspectionStandardId;

    //yield put(setLoading(true));
    try {
      const result: ActionResponseType = yield call(() =>
        StandardReportApi.getReport(data)
      );

      if (result.IsSuccess) {
        const data = {
          StandardReport: { Inspection: result.Data },
        } as StandardReportType;
        excelWorkerStandardsReport.postMessage(data);

        // yield put(standarReportReducerActions.setStandardReportData(data));
        // yield put(standarReportReducerActions.setIsReportReady(true));
      } else {
        yield put(
          standarReportReducerActions.setExcelReportStatus({
            InspectionId: inspectionId,
            StandardId: standardId,
            StandardName: standardName,
            IsDownloading: false,
            InspectionStandardId: inspectionStandardId,
          } as DownloadStatusType)
        );

        yield put(
          openPopup({
            Open: true,
            BodyText:
              "Sorry, An error occured while generating the excel report!",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            OkBtnClick() {},
          } as PopupProps)
        );

        excelWorkerStandardsReport.terminate();
      }
    } catch {
      yield put(
        standarReportReducerActions.setExcelReportStatus({
          InspectionId: inspectionId,
          StandardId: standardId,
          StandardName: standardName,
          IsDownloading: false,
          InspectionStandardId: inspectionStandardId,
        } as DownloadStatusType)
      );

      yield put(
        openPopup({
          Open: true,
          BodyText:
            "Sorry, An error occured while generating the excel report!",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          OkBtnClick() {},
        } as PopupProps)
      );

      excelWorkerStandardsReport.terminate();
    }

    //yield put(setLoading(false));
  });
};
const getFileExtensionFromMimeType = (mimeType) => {
  const mimeTypesToExtensions = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      ".xlsx",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      ".docx",
    "application/pdf": ".pdf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
  };
  return mimeTypesToExtensions[mimeType] || "";
};
const standardReportSaga = function* () {
  yield all([standardExcelReportWatcher()]);
};

export default standardReportSaga;
