import { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Grid, Tooltip } from "@mui/material";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import SimpleReactValidator from "simple-react-validator";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import {
  CardSend,
  CloseCircle,
  Edit,
  ClipboardText,
  CardSlash,
  InfoCircle,
} from "iconsax-react";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import {
  EnumPageMode,
  EnumWorkflowRules,
  EnumStages,
  EnumStatus,
  EnumPageType,
} from "../../../common/enums/common.enums";
import { PageModeType } from "../../../common/types/common.page.type";
import {
  AssessmentTypeWizardPageParametersType,
  AssessmentTypeWizardPageType,
} from "./AssessmentTypeWizardPage.types";
import {
  AssessmentTypeDTO,
  AssessmentTypeRuleDTO,
  StatusDTO,
} from "../../../common/types/common.dto.types";
import {
  checkBeforeDeactivateAssessmentTypeWizard,
  getAssessmentTypeWizardById,
  saveAssessmentTypeWizard,
} from "./AssessmentTypeWizardPage.action";
import { assessmentTypeWizardReducerActions } from "./AssessmentTypeWizardPage.reducer";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import {
  EnumSystemFunctions,
  EnumSystemFunctionTypes,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";

const AssessmentTypeWizardPage = (props: PageRegistrationProperties) => {
  // registering page properties
  usePageRegistration(props);

  // initial page state object
  const initialState = {
    PageMode: {
      PageMode: EnumPageMode.Default,
      IsShowSaveBtn: false,
      IsDisable: false,
      IsShowCloseBtn: false,
      IsShowUpdateBtn: false,
      IsShowCancelBtn: false,
      IsShowDraftBtn: false,
      IsShowEditBtn: false,
      IsShowPublishedBtn: false,
      IsShowDeactivateBtn: false,
    } as PageModeType,
    AssessmentType: {
      Id: 0,
      AssessmentTypeName: "",
      Description: "",
      Code: "",
      AssessmentTypeRules: [] as Array<AssessmentTypeRuleDTO>,
      StatusNavigation: { Status1: "Draft" } as StatusDTO,
      Version: 0.1,
      GroupId: "",
    } as AssessmentTypeDTO,
    IsDisable: false,
    ConfirmationPopupOpen: false,
    ErrorPopupOpen: false,
    HeaderText: "",
    Validator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
      },
    }),
    ShowErrorMsg: false,
    InspectionReviewByGlobalSME: false,
    TrackTasksRequired: false,
    GlobalSMEApprovalRequired: false,
    SiteLeadershipApprovalRequired: false,
    ClosureStageApprovalRequired: false,
    CloseTaskIfERNumberEnteredRequired: false,
    AssessmentTypeSMEApprovalsRequired: [] as Array<number>,
    AssessmentTypeSiteLeadershipApprovalsRequired: [] as Array<number>,
    AssessmentTypeSMERoles: [] as Array<number>,
    AssessmentTypeSiteLeadershipRoles: [] as Array<number>,
    ShowSMEReviewApprovalsRequiredError: false,
    ShowSMEReviewRolesRequiredError: false,
    ShowSiteLeadershipReviewApprovalsRequiredError: false,
    ShowSiteLeadershipReviewRolesRequiredError: false,
    ShowClosureStageApprovalsRequiredError: false,
    ShowClosureStageRolesRequiredError: false,
  } as AssessmentTypeWizardPageType;

  // create state for AssessmentTypeWizardPageType with initialState values
  const [assessmentTypeWizardPageState, setAssessmentTypeWizardPageState] =
    useState(initialState);

  // use for page navigation
  const navigate = useNavigate();

  // pass parameters from outside to page
  const location = useLocation();
  const parameter: AssessmentTypeWizardPageParametersType = location.state;

  // reducer manage fucntions
  const assessmentTypeWizardState = useAppSelector(
    (state) => state.assessmentTypeWizardReducer
  );

  const authState = useAppSelector((state) => state.authReducer);

  const dispatch = useAppDispatch();

  // check when one time call to get standard data from backend
  const dataFetchedRef = useRef(false);

  // load page with parameter and with out
  useEffect(() => {
    if (parameter?.AssessmentTypeId > 0) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      dispatch(getAssessmentTypeWizardById(parameter.AssessmentTypeId));
    } else {
      dispatch(
        assessmentTypeWizardReducerActions.changePageMode(EnumPageMode.AddMode)
      );
    }
  }, []);

  // every time when reducer state change set values to page state
  useEffect(() => {
    setAssessmentTypeWizardPageState((prevState) => {
      return {
        ...prevState,
        AssessmentType: assessmentTypeWizardState.AssessmentType,
        PageMode: assessmentTypeWizardState.PageMode,
        AssessmentTypeSMEApprovalsRequired: [
          EnumWorkflowRules.GlobalSMEApprovalByAllRoles,
          EnumWorkflowRules.GlobalSMEApprovalByOneRole,
        ],
        AssessmentTypeSiteLeadershipApprovalsRequired: [
          EnumWorkflowRules.SiteLeadershipApprovalByAllRoles,
          EnumWorkflowRules.SiteLeadershipApprovalByOneRole,
        ],
        AssessmentTypeSMERoles: [
          EnumWorkflowRules.GlobalPrimarySMEApprovalRequired,
          EnumWorkflowRules.GlobalSecondarySMEApprovalRequired,
          EnumWorkflowRules.InspectionAssignmentModuleSMEApprovalRequired,
        ],
        AssessmentTypeSiteLeadershipRoles: [
          EnumWorkflowRules.SiteLeadershipDirectorApprovalRequired,
          EnumWorkflowRules.SiteLeadershipEHSDirectorApprovalRequired,
          EnumWorkflowRules.SiteLeadershipQADirectorApprovalRequired,
          EnumWorkflowRules.SiteLeadershipQCManagerApprovalRequired,
          EnumWorkflowRules.SiteLeadershipEngineeringDirectorApprovalRequired,
          EnumWorkflowRules.SiteLeadershipMaintenanceManagerApprovalRequired,
        ],
        ShowErrorMsg: false,
      };
    });
  }, [assessmentTypeWizardState]);

   //-----standard list heigh ------
   const mainHeading = useRef(null);
   const wrapperCard = useRef(null);
   
   //Set Hight
 
   const [assesmentWizHeight, setAssesmentWizHeight] = useState(0);
 
   useEffect(() => {
     let assesmentWizHeight =
       wrapperCard?.current?.offsetHeight -
       mainHeading?.current?.offsetHeight -
       14;
     setAssesmentWizHeight(assesmentWizHeight);
     
 
     function handleResize() {
      let assesmentWizHeight =
      wrapperCard?.current?.offsetHeight -
      mainHeading?.current?.offsetHeight -
      14;
    setAssesmentWizHeight(assesmentWizHeight);
     }
 
     window.addEventListener("resize", handleResize);
     return () => window.removeEventListener("resize", handleResize);
   }, [
     wrapperCard?.current?.offsetHeight, mainHeading?.current?.offsetHeight
   ]);

  useEffect(() => {
    if (
      parameter?.AssessmentTypeId !==
      assessmentTypeWizardPageState.AssessmentType.Id
    ) {
      navigate(location.pathname, {
        state: {
          AssessmentTypeId: assessmentTypeWizardPageState.AssessmentType.Id,
        },
      });
    }
  }, [assessmentTypeWizardPageState.AssessmentType.Id]);

  useEffect(() => {
    AssessmentTypeWizardPagevalidation();
  }, [assessmentTypeWizardPageState]);

  useEffect(() => {
    let InspectionReviewByGlobalSME = false;
    let trackTasksRequired = false;
    let globalSMEApprovalRequired = false;
    let siteLeadershipApprovalRequired = false;
    let closureStageApprovalRequired = false;
    let closeTaskIfERNumberEntered = false;

    // Check if inspections review by Global SME is required
    if (
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) =>
          rule.WorkflowRuleId ===
            EnumWorkflowRules.SiteInspectionGlobalSMEReviewRequired &&
          rule.StageId === EnumStages.SiteInspectionReviewByGlobalSME
      )
    ) {
      InspectionReviewByGlobalSME = true;
    }

    // Check if tasks need to be tracked
    if (
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) =>
          rule.WorkflowRuleId === EnumWorkflowRules.TrackTasksRequired &&
          rule.StageId === EnumStages.TrackTasks
      )
    ) {
      trackTasksRequired = true;
    }

    // Check if Global SME Review is required
    if (
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) =>
          rule.WorkflowRuleId === EnumWorkflowRules.GlobalSMEReviewRequired &&
          rule.StageId === EnumStages.ReviewByGlobalSME
      )
    ) {
      globalSMEApprovalRequired = true;
    }

    // Check if Site Leadership Review is required
    if (
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) =>
          rule.WorkflowRuleId ===
            EnumWorkflowRules.SiteLeadershipReviewRequired &&
          rule.StageId === EnumStages.ReviewBySiteLeadership
      )
    ) {
      siteLeadershipApprovalRequired = true;
    }

    // Check if Closure Approval is required
    if (
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) =>
          rule.WorkflowRuleId === EnumWorkflowRules.ClosureApprovalRequired &&
          rule.StageId === EnumStages.ClosureApproval
      )
    ) {
      closureStageApprovalRequired = true;
    }

    // Check if tasks need to close if ER Number entered
    if (
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) =>
          rule.WorkflowRuleId ===
            EnumWorkflowRules.CloseTaskIfERNumberEnteredRequired &&
          rule.StageId === EnumStages.CloseTaskIfERNumberEntered
      )
    ) {
      closeTaskIfERNumberEntered = true;
    }
    checkRequiredSelectionValidations(
      assessmentTypeWizardPageState?.ShowErrorMsg
    );
    setAssessmentTypeWizardPageState((prevState) => {
      return {
        ...prevState,
        InspectionReviewByGlobalSME: InspectionReviewByGlobalSME,
        TrackTasksRequired: trackTasksRequired,
        GlobalSMEApprovalRequired: globalSMEApprovalRequired,
        SiteLeadershipApprovalRequired: siteLeadershipApprovalRequired,
        ClosureStageApprovalRequired: closureStageApprovalRequired,
        CloseTaskIfERNumberEnteredRequired: closeTaskIfERNumberEntered,
      };
    });
  }, [assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules]);

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.AssessmentTypeWizard,
      functionId,
      functionTypeId
    );
  };

  const IsPageValid = (): boolean => {
    let isValid = assessmentTypeWizardPageState.Validator.allValid();
    let isCheckboxSelectionValid = checkRequiredSelectionValidations(true);

    if (!isCheckboxSelectionValid) {
      setAssessmentTypeWizardPageState((prevState) => {
        return {
          ...prevState,
          ShowErrorMsg: true,
        };
      });
    }

    if (!isValid) {
      // show validation message
      assessmentTypeWizardPageState.Validator.showMessages();

      return isValid;
    } else {
      // hide validation message
      assessmentTypeWizardPageState.Validator.hideMessages();
    }

    return isValid && isCheckboxSelectionValid;
  };

  // page unload validations
  const [isDirty, setIsDirty] = useState(false);
  const prompt = {
    Message: "Do you want to discard the changes?",
    When:
      isDirty &&
      parameter?.AssessmentTypeId ===
        assessmentTypeWizardPageState.AssessmentType.Id,
    YesBtnClick() {
      //dispatch(assessmentTypeWizardReducerActions.resetAssessmentTypeWizard());
      setAssessmentTypeWizardPageState((prevState) => {
        return {
          ...prevState,
          AssessmentType: assessmentTypeWizardState.AssessmentType,
        };
      });
    },
  } as UsePromptTypes;
  usePrompt(prompt);

  //Validation checks
  const AssessmentTypeWizardPagevalidation = () => {
    if (
      assessmentTypeWizardPageState.PageMode.PageMode === EnumPageMode.AddMode
    ) {
      if (
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeName ===
          "" &&
        assessmentTypeWizardPageState?.AssessmentType?.Description === "" &&
        assessmentTypeWizardPageState?.AssessmentType?.Code === "" &&
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules
          ?.length === 0
      ) {
        setIsDirty(false);
      } else {
        setIsDirty(true);
      }
    } else {
      const isSameRules =
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.filter(
          (obj) => {
            return !assessmentTypeWizardState?.AssessmentType?.AssessmentTypeRules?.some(
              (obj1) => {
                return (
                  obj1.WorkflowRuleId === obj.WorkflowRuleId &&
                  obj1.StageId === obj.StageId
                );
              }
            );
          }
        );
      if (
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeName !==
          assessmentTypeWizardState?.AssessmentType?.AssessmentTypeName ||
        assessmentTypeWizardPageState?.AssessmentType?.Description !==
          assessmentTypeWizardState?.AssessmentType?.Description ||
        assessmentTypeWizardPageState?.AssessmentType?.Code !==
          assessmentTypeWizardState?.AssessmentType?.Code ||
        isSameRules.length > 0 ||
        assessmentTypeWizardState?.AssessmentType?.AssessmentTypeRules?.length >
          assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules
            ?.length
      ) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  };

  // Checkbox selection validations
  const checkRequiredSelectionValidations = (
    showErrorMessages: boolean
  ): boolean => {
    let showSMEReviewApprovalsRequiredError = false;
    let showSMEReviewRolesRequiredError = false;
    let showSiteLeadershipReviewApprovalsRequiredError = false;
    let showSiteLeadershipReviewRolesRequiredError = false;
    let showClosureStageApprovalsRequiredError = false;
    let showClosureStageRolesRequiredError = false;
    let isValid = true;

    // Check if SME Review Required and not approval type or roles selected
    if (
      showErrorMessages &&
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) =>
          rule.WorkflowRuleId === EnumWorkflowRules.GlobalSMEReviewRequired &&
          rule.StageId === EnumStages.ReviewByGlobalSME
      )
    ) {
      // Check if SME Review approval type is not selected
      if (
        !assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
          (rule) =>
            assessmentTypeWizardPageState?.AssessmentTypeSMEApprovalsRequired?.includes(
              rule.WorkflowRuleId
            ) && rule.StageId === EnumStages.ReviewByGlobalSME
        )
      ) {
        isValid = false;
        showSMEReviewApprovalsRequiredError = true;
      } else {
        showSMEReviewApprovalsRequiredError = false;
      }

      // Check if SME Review role is not selected
      if (
        !assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
          (rule) =>
            assessmentTypeWizardPageState?.AssessmentTypeSMERoles?.includes(
              rule.WorkflowRuleId
            ) && rule.StageId === EnumStages.ReviewByGlobalSME
        )
      ) {
        isValid = false;
        showSMEReviewRolesRequiredError = true;
      } else {
        showSMEReviewRolesRequiredError = false;
      }
    } else {
      showSMEReviewApprovalsRequiredError = false;
      showSMEReviewRolesRequiredError = false;
    }

    // Check if Site Leadership Review Required and not approval type or roles selected
    if (
      showErrorMessages &&
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) =>
          rule.WorkflowRuleId ===
            EnumWorkflowRules.SiteLeadershipReviewRequired &&
          rule.StageId === EnumStages.ReviewBySiteLeadership
      )
    ) {
      // Check if Site Leadership Review approval type is not selected
      if (
        !assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
          (rule) =>
            assessmentTypeWizardPageState?.AssessmentTypeSiteLeadershipApprovalsRequired?.includes(
              rule.WorkflowRuleId
            ) && rule.StageId === EnumStages.ReviewBySiteLeadership
        )
      ) {
        isValid = false;
        showSiteLeadershipReviewApprovalsRequiredError = true;
      } else {
        showSiteLeadershipReviewApprovalsRequiredError = false;
      }

      // Check if Site Leadership Review role is not selected
      if (
        !assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
          (rule) =>
            assessmentTypeWizardPageState?.AssessmentTypeSiteLeadershipRoles?.includes(
              rule.WorkflowRuleId
            ) && rule.StageId === EnumStages.ReviewBySiteLeadership
        )
      ) {
        isValid = false;
        showSiteLeadershipReviewRolesRequiredError = true;
      } else {
        showSiteLeadershipReviewRolesRequiredError = false;
      }
    } else {
      showSiteLeadershipReviewApprovalsRequiredError = false;
      showSiteLeadershipReviewRolesRequiredError = false;
    }

    // Check if Closure Stage approval Required and not approval type or roles selected
    if (
      showErrorMessages &&
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) =>
          rule.WorkflowRuleId === EnumWorkflowRules.ClosureApprovalRequired &&
          rule.StageId === EnumStages.ClosureApproval
      )
    ) {
      // Check if Closure Stage approval type is not selected
      if (
        !assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
          (rule) =>
            assessmentTypeWizardPageState?.AssessmentTypeSiteLeadershipApprovalsRequired?.includes(
              rule.WorkflowRuleId
            ) && rule.StageId === EnumStages.ClosureApproval
        )
      ) {
        isValid = false;
        showClosureStageApprovalsRequiredError = true;
      } else {
        showClosureStageApprovalsRequiredError = false;
      }

      // Check if Closure Stage approval role is not selected
      if (
        !assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
          (rule) =>
            assessmentTypeWizardPageState?.AssessmentTypeSiteLeadershipRoles?.includes(
              rule.WorkflowRuleId
            ) && rule.StageId === EnumStages.ClosureApproval
        )
      ) {
        isValid = false;
        showClosureStageRolesRequiredError = true;
      } else {
        showClosureStageRolesRequiredError = false;
      }
    } else {
      showClosureStageApprovalsRequiredError = false;
      showClosureStageRolesRequiredError = false;
    }

    setAssessmentTypeWizardPageState((prevState) => {
      return {
        ...prevState,
        ShowSMEReviewApprovalsRequiredError:
          showSMEReviewApprovalsRequiredError,
        ShowSMEReviewRolesRequiredError: showSMEReviewRolesRequiredError,
        ShowSiteLeadershipReviewApprovalsRequiredError:
          showSiteLeadershipReviewApprovalsRequiredError,
        ShowSiteLeadershipReviewRolesRequiredError:
          showSiteLeadershipReviewRolesRequiredError,
        ShowClosureStageApprovalsRequiredError:
          showClosureStageApprovalsRequiredError,
        ShowClosureStageRolesRequiredError: showClosureStageRolesRequiredError,
      };
    });

    return isValid;
  };

  //-----Button functions-----//

  const publishButtonClick = () => {
    saveAssessmentType(EnumStatus.Published);
  };

  const draftButtonClick = () => {
    saveAssessmentType(EnumStatus.Draft);
  };

  const deactivateButtonClick = () => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to deactivate?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          saveAssessmentType(EnumStatus.Deactivated);
        },
      } as PopupProps)
    );
  };

  const editButtonClick = () => {
    dispatch(
      assessmentTypeWizardReducerActions.changePageMode(EnumPageMode.EditMode)
    );
  };

  const saveAssessmentType = (saveStatus: EnumStatus) => {
    setIsDirty(false);

    if (IsPageValid()) {
      let assessmentType: AssessmentTypeDTO = {} as AssessmentTypeDTO;

      if (saveStatus === EnumStatus.Deactivated) {
        assessmentType = Object.assign(
          {},
          assessmentTypeWizardState.AssessmentType
        );
      } else {
        assessmentType = Object.assign(
          {},
          assessmentTypeWizardPageState.AssessmentType
        );
      }

      assessmentType.Status = saveStatus;

      if (
        assessmentTypeWizardPageState.PageMode.PageMode ===
        EnumPageMode.EditMode
      ) {
        assessmentType.CreateNewRecordWithGroupId = true;
      } else {
        assessmentType.CreateNewRecordWithGroupId = false;
      }
      // const isSameRules =
      //   assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.filter(
      //     (obj) => {
      //       return !assessmentTypeWizardState?.AssessmentType?.AssessmentTypeRules?.some(
      //         (obj1) => {
      //           return (
      //             obj1.WorkflowRuleId === obj.WorkflowRuleId &&
      //             obj1.StageId === obj.StageId
      //           );
      //         }
      //       );
      //     }
      //   );
      // if (
      //   assessmentTypeWizardPageState.PageMode.PageMode ===
      //     EnumPageMode.EditMode &&
      //   (assessmentType.AssessmentTypeName.trim() !==
      //     assessmentTypeWizardState?.AssessmentType?.AssessmentTypeName ||
      //     assessmentType.Description.trim() !==
      //       assessmentTypeWizardState?.AssessmentType?.Description ||
      //     assessmentType.Code.trim() !==
      //       assessmentTypeWizardState?.AssessmentType?.Code ||
      //     isSameRules.length > 0 ||
      //     assessmentTypeWizardState?.AssessmentType?.AssessmentTypeRules
      //       ?.length >
      //       assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules
      //         ?.length)
      // ) {
      //   assessmentType.CreateNewRecordWithGroupId = true;
      // } else {
      //   assessmentType.CreateNewRecordWithGroupId = false;
      // }
      if (saveStatus === EnumStatus.Deactivated) {
        dispatch(checkBeforeDeactivateAssessmentTypeWizard(assessmentType));
      } else {
        dispatch(saveAssessmentTypeWizard(assessmentType));
      }
    }
  };
  const checkRuleAvailability = (
    stageId: EnumStages,
    ruleId: EnumWorkflowRules
  ) => {
    if (
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) => rule.WorkflowRuleId === ruleId && rule.StageId === stageId
      )
    ) {
      return true;
    }
    return false;
  };
  // Add/Remove rules based on selection
  const onChangeRuleSelection = (
    stageId: EnumStages,
    ruleId: EnumWorkflowRules,
    value: any
  ) => {
    if (value) {
      // Add the rules for the stage if selected and not already available
      if (
        !assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
          (rule) => rule.WorkflowRuleId === ruleId && rule.StageId === stageId
        )
      ) {
        let assessmentTypeRuleList = JSON.parse(
          JSON.stringify([
            ...assessmentTypeWizardPageState.AssessmentType.AssessmentTypeRules,
          ])
        ) as AssessmentTypeRuleDTO[];

        //If Global SME Approval By All Roles Selected, then deselect Global SME Approval By One Role
        if (
          ruleId === EnumWorkflowRules.GlobalSMEApprovalByAllRoles &&
          stageId === EnumStages.ReviewByGlobalSME
        ) {
          assessmentTypeRuleList = assessmentTypeRuleList.filter(
            (rule) =>
              !(
                (rule.WorkflowRuleId ===
                  EnumWorkflowRules.GlobalSMEApprovalByOneRole ||
                  rule.WorkflowRuleId ===
                    EnumWorkflowRules.InspectionAssignmentModuleSMEApprovalRequired) &&
                rule.StageId === EnumStages.ReviewByGlobalSME
              )
          );
        }

        //If Global SME Approval By One Role Selected, then deselect Global SME Approval By All Roles
        else if (
          ruleId === EnumWorkflowRules.GlobalSMEApprovalByOneRole &&
          stageId === EnumStages.ReviewByGlobalSME
        ) {
          assessmentTypeRuleList = assessmentTypeRuleList.filter(
            (rule) =>
              !(
                rule.WorkflowRuleId ===
                  EnumWorkflowRules.GlobalSMEApprovalByAllRoles &&
                rule.StageId === EnumStages.ReviewByGlobalSME
              )
          );
        }
        //If Site Leadership Approval By All Roles Selected, then deselect Site Leadership Approval By One Role
        else if (
          ruleId === EnumWorkflowRules.SiteLeadershipApprovalByAllRoles &&
          stageId === EnumStages.ReviewBySiteLeadership
        ) {
          assessmentTypeRuleList = assessmentTypeRuleList.filter(
            (rule) =>
              !(
                rule.WorkflowRuleId ===
                  EnumWorkflowRules.SiteLeadershipApprovalByOneRole &&
                rule.StageId === EnumStages.ReviewBySiteLeadership
              )
          );
        }
        //If Site Leadership Approval By One Role Selected, then deselect Site Leadership Approval By All Roles
        else if (
          ruleId === EnumWorkflowRules.SiteLeadershipApprovalByOneRole &&
          stageId === EnumStages.ReviewBySiteLeadership
        ) {
          assessmentTypeRuleList = assessmentTypeRuleList.filter(
            (rule) =>
              !(
                rule.WorkflowRuleId ===
                  EnumWorkflowRules.SiteLeadershipApprovalByAllRoles &&
                rule.StageId === EnumStages.ReviewBySiteLeadership
              )
          );
        }
        //If Site Leadership Approval By All Roles Selected, then deselect Site Leadership Approval By One Role (for Closure Stage)
        else if (
          ruleId === EnumWorkflowRules.SiteLeadershipApprovalByAllRoles &&
          stageId === EnumStages.ClosureApproval
        ) {
          assessmentTypeRuleList = assessmentTypeRuleList.filter(
            (rule) =>
              !(
                rule.WorkflowRuleId ===
                  EnumWorkflowRules.SiteLeadershipApprovalByOneRole &&
                rule.StageId === EnumStages.ClosureApproval
              )
          );
        }
        //If Site Leadership Approval By One Role Selected, then deselect Site Leadership Approval By All Roles (for Closure Stage)
        else if (
          ruleId === EnumWorkflowRules.SiteLeadershipApprovalByOneRole &&
          stageId === EnumStages.ClosureApproval
        ) {
          assessmentTypeRuleList = assessmentTypeRuleList.filter(
            (rule) =>
              !(
                rule.WorkflowRuleId ===
                  EnumWorkflowRules.SiteLeadershipApprovalByAllRoles &&
                rule.StageId === EnumStages.ClosureApproval
              )
          );
        }

        //If SME(s) defined in Site Inspection Selected, then deselect Global SME selections, All selected approver(s)
        else if (
          ruleId ===
            EnumWorkflowRules.InspectionAssignmentModuleSMEApprovalRequired &&
          stageId === EnumStages.ReviewByGlobalSME
        ) {
          assessmentTypeRuleList = assessmentTypeRuleList.filter(
            (rule) =>
              !(
                (rule.WorkflowRuleId ===
                  EnumWorkflowRules.GlobalPrimarySMEApprovalRequired ||
                  rule.WorkflowRuleId ===
                    EnumWorkflowRules.GlobalSecondarySMEApprovalRequired ||
                  rule.WorkflowRuleId ===
                    EnumWorkflowRules.GlobalSMEApprovalByAllRoles) &&
                rule.StageId === EnumStages.ReviewByGlobalSME
              )
          );

          if (
            !assessmentTypeRuleList?.some(
              (rule) =>
                rule.WorkflowRuleId ===
                  EnumWorkflowRules.GlobalSMEApprovalByOneRole &&
                rule.StageId === EnumStages.ReviewByGlobalSME
            )
          ) {
            assessmentTypeRuleList = [
              ...assessmentTypeRuleList,
              {
                AssessmentTypeId:
                  assessmentTypeWizardPageState.AssessmentType.Id,
                WorkflowRuleId: EnumWorkflowRules.GlobalSMEApprovalByOneRole,
                StageId: EnumStages.ReviewByGlobalSME,
              } as AssessmentTypeRuleDTO,
            ];
          }
        }

        //If Global SME Selected, then deselect SME(s) defined in Site Inspection
        else if (
          (ruleId === EnumWorkflowRules.GlobalPrimarySMEApprovalRequired ||
            ruleId === EnumWorkflowRules.GlobalSecondarySMEApprovalRequired) &&
          stageId === EnumStages.ReviewByGlobalSME
        ) {
          assessmentTypeRuleList = assessmentTypeRuleList.filter(
            (rule) =>
              !(
                rule.WorkflowRuleId ===
                  EnumWorkflowRules.InspectionAssignmentModuleSMEApprovalRequired &&
                rule.StageId === EnumStages.ReviewByGlobalSME
              )
          );
        }

        setAssessmentTypeWizardPageState((prevState) => {
          return {
            ...prevState,
            AssessmentType: {
              ...prevState.AssessmentType,
              AssessmentTypeRules: [
                ...(assessmentTypeRuleList || []),
                {
                  AssessmentTypeId:
                    assessmentTypeWizardPageState.AssessmentType.Id,
                  WorkflowRuleId: ruleId,
                  StageId: stageId,
                } as AssessmentTypeRuleDTO,
              ],
            },
          };
        });
      }
    } else {
      // Remove the rule for particular stage if available when unselected
      if (
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
          (rule) => rule.WorkflowRuleId === ruleId && rule.StageId === stageId
        )
      ) {
        setAssessmentTypeWizardPageState((prevState) => {
          return {
            ...prevState,
            AssessmentType: {
              ...prevState.AssessmentType,
              AssessmentTypeRules:
                prevState.AssessmentType.AssessmentTypeRules.filter(
                  (rule) =>
                    !(
                      rule.WorkflowRuleId === ruleId && rule.StageId === stageId
                    )
                ),
            },
          };
        });
      }
    }
  };

  //Handle changes when toggling stage required or not
  const onStageRequiredChange = (
    stageId: EnumStages,
    ruleId: EnumWorkflowRules,
    required: boolean
  ) => {
    if (required) {
      if (
        !assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
          (rule) => rule.WorkflowRuleId === ruleId && rule.StageId === stageId
        )
      ) {
        // Add the rule for particular stage if not already available

        setAssessmentTypeWizardPageState((prevState) => {
          return {
            ...prevState,
            AssessmentType: {
              ...prevState.AssessmentType,
              AssessmentTypeRules: [
                ...(prevState.AssessmentType?.AssessmentTypeRules || []),
                {
                  AssessmentTypeId:
                    assessmentTypeWizardPageState.AssessmentType.Id,
                  WorkflowRuleId: ruleId,
                  StageId: stageId,
                } as AssessmentTypeRuleDTO,
              ],
            },
          };
        });
      }
    } else {
      if (
        ruleId === EnumWorkflowRules.TrackTasksRequired &&
        stageId === EnumStages.TrackTasks
      ) {
        // If tasks need not be tracked, then remove all rules

        setAssessmentTypeWizardPageState((prevState) => {
          return {
            ...prevState,
            AssessmentType: {
              ...prevState.AssessmentType,
              AssessmentTypeRules:
                prevState.AssessmentType.AssessmentTypeRules.filter(
                  (rule) =>
                    rule.WorkflowRuleId ===
                      EnumWorkflowRules.SiteInspectionGlobalSMEReviewRequired &&
                    rule.StageId === EnumStages.SiteInspectionReviewByGlobalSME
                ),
            },
          };
        });
      } else {
        // Remove the rule by respective stage if the particular stage is not required
        if (
          assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
            (rule) => rule.StageId === stageId
          )
        ) {
          setAssessmentTypeWizardPageState((prevState) => {
            return {
              ...prevState,
              AssessmentType: {
                ...prevState.AssessmentType,
                AssessmentTypeRules:
                  prevState.AssessmentType.AssessmentTypeRules.filter(
                    (rule) => rule.StageId !== stageId
                  ),
              },
            };
          });
        }
      }
    }
  };

  const cancelButtonClick = () => {
    const tempisDirty = isDirty;
    if (
      assessmentTypeWizardPageState.PageMode.PageMode === EnumPageMode.AddMode
    ) {
      if (
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeName ===
          "" &&
        assessmentTypeWizardPageState?.AssessmentType?.Description === "" &&
        assessmentTypeWizardPageState?.AssessmentType?.Code === "" &&
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules
          ?.length === 0
      ) {
        navigate("/AssessmentTypeWizards");
      } else {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setAssessmentTypeWizardPageState((prevState) => {
                return {
                  ...prevState,
                  AssessmentType: assessmentTypeWizardState?.AssessmentType,
                };
              });

              navigate("/AssessmentTypeWizards");
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      }
    } else {
      const isSameRules =
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.filter(
          (obj) => {
            return !assessmentTypeWizardState?.AssessmentType?.AssessmentTypeRules?.some(
              (obj1) => {
                return (
                  obj1.WorkflowRuleId === obj.WorkflowRuleId &&
                  obj1.StageId === obj.StageId
                );
              }
            );
          }
        );
      if (
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeName !==
          assessmentTypeWizardState?.AssessmentType?.AssessmentTypeName ||
        assessmentTypeWizardPageState?.AssessmentType?.Description !==
          assessmentTypeWizardState?.AssessmentType?.Description ||
        assessmentTypeWizardPageState?.AssessmentType?.Code !==
          assessmentTypeWizardState?.AssessmentType?.Code ||
        isSameRules.length > 0 ||
        assessmentTypeWizardState?.AssessmentType?.AssessmentTypeRules?.length >
          assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules
            ?.length
      ) {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setAssessmentTypeWizardPageState((values) => {
                return {
                  ...values,
                  AssessmentType: assessmentTypeWizardState?.AssessmentType,
                };
              });
              dispatch(
                assessmentTypeWizardReducerActions.changePageMode(
                  EnumPageMode.ViewMode
                )
              );
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      } else {
        dispatch(
          assessmentTypeWizardReducerActions.changePageMode(
            EnumPageMode.ViewMode
          )
        );
      }
    }
  };

  const closeButtonClick = () => {
    dispatch(assessmentTypeWizardReducerActions.resetAssessmentTypeWizard());

    navigate("/AssessmentTypeWizards");
  };

  const backBtnClick = () => {
    const tempisDirty = isDirty;

    if (
      assessmentTypeWizardPageState.PageMode.PageMode === EnumPageMode.AddMode
    ) {
      if (
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeName ===
          "" &&
        assessmentTypeWizardPageState?.AssessmentType?.Description === "" &&
        assessmentTypeWizardPageState?.AssessmentType?.Code === "" &&
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules
          ?.length === 0
      ) {
        navigate("/AssessmentTypeWizards");
      } else {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setAssessmentTypeWizardPageState((values) => {
                return {
                  ...values,
                  AssessmentType: assessmentTypeWizardState.AssessmentType,
                };
              });

              navigate("/AssessmentTypeWizards");
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      }
    } else {
      const isSameRules =
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.filter(
          (obj) => {
            return !assessmentTypeWizardState?.AssessmentType?.AssessmentTypeRules?.some(
              (obj1) => {
                return (
                  obj1.WorkflowRuleId === obj.WorkflowRuleId &&
                  obj1.StageId === obj.StageId
                );
              }
            );
          }
        );
      if (
        assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeName !==
          assessmentTypeWizardState?.AssessmentType?.AssessmentTypeName ||
        assessmentTypeWizardPageState?.AssessmentType?.Description !==
          assessmentTypeWizardState?.AssessmentType?.Description ||
        assessmentTypeWizardPageState?.AssessmentType?.Code !==
          assessmentTypeWizardState?.AssessmentType?.Code ||
        isSameRules.length > 0 ||
        assessmentTypeWizardState?.AssessmentType?.AssessmentTypeRules?.length >
          assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules
            ?.length
      ) {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setAssessmentTypeWizardPageState((values) => {
                return {
                  ...values,
                  AssessmentType: assessmentTypeWizardState.AssessmentType,
                };
              });
              dispatch(
                assessmentTypeWizardReducerActions.changePageMode(
                  EnumPageMode.ViewMode
                )
              );
              dispatch(
                assessmentTypeWizardReducerActions.resetAssessmentTypeWizard()
              );
              navigate("/AssessmentTypeWizards");
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      } else {
        dispatch(
          assessmentTypeWizardReducerActions.resetAssessmentTypeWizard()
        );
        navigate("/AssessmentTypeWizards");
      }
    }
  };

  //-----End Button functions-----//

  const onChangeAssessmentTypeName = (name: string, value: any) => {
    setAssessmentTypeWizardPageState((prevState) => {
      return {
        ...prevState,
        AssessmentType: {
          ...prevState.AssessmentType,
          AssessmentTypeName: value?.replace(/^\s+/g, ""),
        },
      };
    });
  };

  const onChangeAssessmentTypeCode = (name: string, value: any) => {
    setAssessmentTypeWizardPageState((prevState) => {
      return {
        ...prevState,
        AssessmentType: {
          ...prevState.AssessmentType,
          Code: value?.replace(/[\s]/g, "")?.toUpperCase(),
        },
      };
    });
  };

  const onChangeAssessmentTypeDescription = (name: string, value: any) => {
    setAssessmentTypeWizardPageState((prevState) => {
      return {
        ...prevState,
        AssessmentType: {
          ...prevState.AssessmentType,
          Description: value?.replace(/^\s+/g, ""),
        },
      };
    });
  };

  // Check whether a particular rule is selected
  const isRuleSelected = (stageId: EnumStages, ruleId: EnumWorkflowRules) => {
    const isChecked =
      assessmentTypeWizardPageState?.AssessmentType?.AssessmentTypeRules?.some(
        (rule) => rule.WorkflowRuleId === ruleId && rule.StageId === stageId
      );
    return isChecked;
  };

  const getButtonClassName = (isActive: boolean): string => {
    if (assessmentTypeWizardPageState.PageMode.IsDisable && isActive) {
      return "primary-btn-active--disabled";
    } else if (assessmentTypeWizardPageState.PageMode.IsDisable) {
      return "primary-btn--disabled";
    } else if (isActive) {
      return "primary-btn-active";
    } else {
      return "primary-btn";
    }
  };

  return (
    <div className="content-section-card top-main-padding" ref={wrapperCard}>
      {/* Global Sub Header with  Buttons*/}
      <Grid container className="top-header-wrap" ref={mainHeading}>
        <Grid
          container
          columns={12}
          className="global-subhead-btn-wrap top-main-border-inner"
        >
          <Grid item className="global-subhead-wrap">
            {/* <IconButton
              aria-label="back"
              className="sub-head-back-btn"
              onClick={backBtnClick}
              title="Back to Remediation Tracker"
            >
              <ArrowLeft2 size="24" variant="Outline" />
            </IconButton> */}
            <Button
              aria-label="back"
              className="sub-head-back-btn back"
              title="Back to home page"
              onClick={backBtnClick}
            >
              {/* <ArrowLeft2 size="24" variant="Outline" /> */}
              <ArrowBackIosNewOutlinedIcon className="" />
              <span className="body-bold secondary-color">Back</span>
            </Button>
            <h1>
              Assessment Type Wizard
              {assessmentTypeWizardState?.AssessmentType?.Code
                ? `: ${assessmentTypeWizardState.AssessmentType.Code}`
                : ""}
            </h1>
            <div className="vl"></div>
            <Grid
              item
              // lg={6}
              // md={6}
              // sm={12}
              // xs={12}
              className="vlign-middle status-version-wrap"
            >
              <div className="standart-status-change d-flex">
                {" "}
                {/* removeed mb-5 class */}
                <div className="standards-status">
                  {/* <span className="status-color yellow">Draft</span> */}
                  <span
                    className={
                      assessmentTypeWizardPageState.PageMode.PageMode ===
                      EnumPageMode.EditMode
                        ? "status-color edit"
                        : assessmentTypeWizardPageState.AssessmentType
                            .Status === EnumStatus.Published
                        ? "status-color green"
                        : assessmentTypeWizardPageState.AssessmentType
                            .Status === EnumStatus.Draft &&
                          assessmentTypeWizardPageState.PageMode.PageMode !==
                            EnumPageMode.AddMode
                        ? "status-color yellow"
                        : assessmentTypeWizardPageState.AssessmentType
                            .Status === EnumStatus.Deactivated
                        ? "status-color gray"
                        : "status-color yellow"
                    }
                  >
                    {assessmentTypeWizardPageState.PageMode.PageMode ===
                    EnumPageMode.EditMode
                      ? "Edit"
                      : assessmentTypeWizardPageState.AssessmentType
                          .StatusNavigation?.Status1}
                  </span>
                </div>
              </div>
              <div className="global-version-top">
                {" "}
                {/* removeed mb-5 class */}
                <span>Version:</span>
                {assessmentTypeWizardPageState?.AssessmentType?.Version != null
                  ? assessmentTypeWizardPageState?.AssessmentType.Version.toFixed(
                      1
                    )
                  : "0.1"}
              </div>
            </Grid>
          </Grid>

          <Grid item className="sub-header-btn-wrap">
            {assessmentTypeWizardPageState.PageMode.IsShowCancelBtn && (
              <Button
                variant="contained"
                disableElevation
                className="secondary-btn-small"
                size="small"
                startIcon={<CloseCircle variant="Outline" />}
                onClick={cancelButtonClick}
              >
                Cancel
              </Button>
            )}
            {assessmentTypeWizardPageState.PageMode.IsShowCloseBtn && (
              <Button
                variant="contained"
                disableElevation
                startIcon={<CloseCircle variant="Outline" />}
                className="secondary-btn-small"
                size="small"
                onClick={closeButtonClick}
              >
                Close
              </Button>
            )}

            {checkSystemFunctionRight(
              EnumSystemFunctions.AssessmentTypeWizardPage_Deactivate_Assessment_Type,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              assessmentTypeWizardPageState.PageMode.IsShowDeactivateBtn &&
              assessmentTypeWizardPageState.AssessmentType.Status !==
                EnumStatus.Deactivated && (
                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<CardSlash variant="Outline" />}
                  className="primary-btn-small"
                  size="small"
                  onClick={deactivateButtonClick}
                >
                  Deactivate
                </Button>
              )}
            {checkSystemFunctionRight(
              EnumSystemFunctions.AssessmentTypeWizardPage_Edit_Assessment_Type,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              assessmentTypeWizardPageState.PageMode.IsShowEditBtn &&
              assessmentTypeWizardPageState.AssessmentType.Status !==
                EnumStatus.Deactivated && (
                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<Edit variant="Outline" />}
                  className="primary-btn-small"
                  size="small"
                  onClick={editButtonClick}
                >
                  Edit
                </Button>
              )}
            {checkSystemFunctionRight(
              EnumSystemFunctions.AssessmentTypeWizardPage_Edit_Assessment_Type,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              assessmentTypeWizardPageState.PageMode.IsShowDraftBtn &&
              assessmentTypeWizardPageState.AssessmentType.Status !==
                EnumStatus.Published && (
                <Button
                  variant="contained"
                  disableElevation
                  className="primary-btn-small"
                  size="small"
                  startIcon={<ClipboardText variant="Outline" />}
                  onClick={draftButtonClick}
                >
                  Draft
                </Button>
              )}
            {checkSystemFunctionRight(
              EnumSystemFunctions.AssessmentTypeWizardPage_Edit_Assessment_Type,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              assessmentTypeWizardPageState.PageMode.IsShowPublishedBtn && (
                <Button
                  variant="contained"
                  disableElevation
                  className="primary-btn-small"
                  size="small"
                  startIcon={<CardSend variant="Outline" />}
                  onClick={publishButtonClick}
                >
                  Publish
                </Button>
              )}
            {/* {checkSystemFunctionRight(
              EnumSystemFunctions.AssessmentTypeWizardPage_Reactivate_Assessment_Type,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              assessmentTypeWizardPageState.AssessmentType.Status ===
                EnumStatus.Deactivated && (
                <Button
                  variant="contained"
                  disableElevation
                  className="primary-btn-small"
                  size="small"
                  startIcon={<CardSend variant="Outline" />}
                  // onClick={() => PublishButtonClick()}
                >
                  Reactivate
                </Button>
              )} */}
            {/* <Button
              variant="contained"
              disableElevation
              className="primary-btn-small"
              size="small"
              startIcon={<CardTick variant="Outline" />}
            >
              Reactivate
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
      {/* Global Sub Header with  Buttons*/}
      <Grid container className="bottom-content-wrap" height={assesmentWizHeight}>
        <Grid container className="global-subhead-wrap">
          {/* Button bar */}
          <Grid container spacing={3} className="pl-10 pr-10 wizard-wrapper">
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="pt-0 mt-10"
            >
              <fieldset className="mb-20 fieldset-main">
                <legend>Assessment Type</legend>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12}>
                    <div className="form-group mb-0">
                      <HPRTextBox
                        Id={""}
                        Label="Name"
                        Name="Name"
                        Type={EnumTextBoxType.Text}
                        ClassName={""}
                        Value={
                          assessmentTypeWizardPageState?.AssessmentType
                            ?.AssessmentTypeName
                        }
                        onTextBoxChange={onChangeAssessmentTypeName}
                        Validator={assessmentTypeWizardPageState.Validator}
                        Rules={"required"}
                        IsEnableValidator={true}
                        Required
                        Disabled={
                          assessmentTypeWizardPageState.PageMode.IsDisable
                        }
                        InputProps={{ maxLength: 25 }}
                        TextLength={25}
                        ShowTextCounter
                      />
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12}>
                    <div className="form-group mb-0">
                      <HPRTextBox
                        Id={""}
                        Label="Code"
                        Name="Code"
                        Type={EnumTextBoxType.Text}
                        ClassName={""}
                        Value={
                          assessmentTypeWizardPageState?.AssessmentType?.Code
                        }
                        onTextBoxChange={onChangeAssessmentTypeCode}
                        Validator={assessmentTypeWizardPageState.Validator}
                        Rules={"required"}
                        IsEnableValidator={true}
                        Required
                        Disabled={
                          assessmentTypeWizardPageState.PageMode.IsDisable
                        }
                        InputProps={{ maxLength: 4 }}
                        TextLength={4}
                        ShowTextCounter
                      />
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} className="pt-0">
                    <div className="form-group">
                      <HPRTextBox
                        Id={""}
                        Label="Description"
                        Name="Description"
                        Type={EnumTextBoxType.Text}
                        ClassName={""}
                        Value={
                          assessmentTypeWizardPageState?.AssessmentType
                            ?.Description
                        }
                        onTextBoxChange={onChangeAssessmentTypeDescription}
                        Disabled={
                          assessmentTypeWizardPageState.PageMode.IsDisable
                        }
                        InputProps={{ maxLength: 50 }}
                        TextLength={50}
                        ShowTextCounter
                      />
                    </div>
                  </Grid>
                </Grid>
              </fieldset>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="pt-0">
              <fieldset className="fieldset-main">
                <legend>Workflow Definition</legend>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} className="pt-0 mt-5">
                    <fieldset className="fieldset-inner">
                      <legend>Site Inspection</legend>
                      <Grid container spacing={2} className="v-align">
                        <Grid item lg={4} md={4} sm={6}>
                          <p className="body1 fx">
                            Review by Global SME
                            <Tooltip
                              title={
                                "Enables global standard SME to review and approve/reject the answers entered for the questions in the site inspection module."
                              }
                            >
                              <InfoCircle
                                className="icon-btn ml-10"
                                color={"#0288d1"}
                                variant="Bold"
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                              />
                            </Tooltip>
                          </p>
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          md={4}
                          sm={6}
                          className="field-sub-header-btn-wrap fx fx-left"
                        >
                          <Button
                            variant="contained"
                            disableElevation
                            className={getButtonClassName(
                              assessmentTypeWizardPageState.InspectionReviewByGlobalSME
                            )}
                            size="small"
                            onClick={() => {
                              onStageRequiredChange(
                                EnumStages.SiteInspectionReviewByGlobalSME,
                                EnumWorkflowRules.SiteInspectionGlobalSMEReviewRequired,
                                true
                              );
                            }}
                            disabled={
                              assessmentTypeWizardPageState.PageMode.IsDisable
                            }
                          >
                            Yes
                          </Button>
                          <Button
                            variant="contained"
                            disableElevation
                            className={getButtonClassName(
                              !assessmentTypeWizardPageState.InspectionReviewByGlobalSME
                            )}
                            size="small"
                            onClick={() => {
                              onStageRequiredChange(
                                EnumStages.SiteInspectionReviewByGlobalSME,
                                EnumWorkflowRules.SiteInspectionGlobalSMEReviewRequired,
                                false
                              );
                            }}
                            disabled={
                              assessmentTypeWizardPageState.PageMode.IsDisable
                            }
                          >
                            No
                          </Button>
                        </Grid>
                      </Grid>
                    </fieldset>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} className="pt-0">
                    <fieldset className="fieldset-inner">
                      <legend>Remediation Task Workflow</legend>
                      <Grid container className="review-stage-wrapper">
                        <Grid container spacing={2} className="v-align">
                          <Grid item lg={4} md={4} sm={4}>
                            <p className="body1 fx">
                              Tasks need to be tracked
                              <Tooltip
                                title={
                                  "Enables creation of remediation tasks automatically for the gaps identified during site inspection."
                                }
                              >
                                <InfoCircle
                                  className="icon-btn ml-10"
                                  color={"#0288d1"}
                                  variant="Bold"
                                  style={{
                                    transform: "rotate(180deg)",
                                  }}
                                />
                              </Tooltip>
                            </p>
                          </Grid>
                          <Grid
                            item
                            lg={3}
                            md={3}
                            sm={3}
                            className="field-sub-header-btn-wrap fx fx-left"
                          >
                            <Button
                              variant="contained"
                              disableElevation
                              className={getButtonClassName(
                                assessmentTypeWizardPageState.TrackTasksRequired
                              )}
                              size="small"
                              onClick={() => {
                                onStageRequiredChange(
                                  EnumStages.TrackTasks,
                                  EnumWorkflowRules.TrackTasksRequired,
                                  true
                                );
                              }}
                              disabled={
                                assessmentTypeWizardPageState.PageMode.IsDisable
                              }
                            >
                              Yes
                            </Button>
                            <Button
                              variant="contained"
                              disableElevation
                              className={getButtonClassName(
                                !assessmentTypeWizardPageState.TrackTasksRequired
                              )}
                              size="small"
                              onClick={() => {
                                onStageRequiredChange(
                                  EnumStages.TrackTasks,
                                  EnumWorkflowRules.TrackTasksRequired,
                                  false
                                );
                              }}
                              disabled={
                                assessmentTypeWizardPageState.PageMode.IsDisable
                              }
                            >
                              No
                            </Button>
                          </Grid>
                          {assessmentTypeWizardPageState?.TrackTasksRequired && (
                            <>
                              <Grid item lg={3} md={3} sm={6}>
                                <p className="body1 fx">
                                  Close task if ER Number entered
                                  <Tooltip
                                    title={
                                      "Allows the task to be closed without review/approval process if ER number is entered."
                                    }
                                  >
                                    <InfoCircle
                                      className="icon-btn ml-10"
                                      color={"#0288d1"}
                                      variant="Bold"
                                      style={{
                                        transform: "rotate(180deg)",
                                      }}
                                    />
                                  </Tooltip>
                                </p>
                              </Grid>
                              <Grid
                                item
                                lg={2}
                                md={2}
                                sm={6}
                                className="field-sub-header-btn-wrap fx fx-middle"
                              >
                                <Button
                                  variant="contained"
                                  disableElevation
                                  className={getButtonClassName(
                                    assessmentTypeWizardPageState.CloseTaskIfERNumberEnteredRequired
                                  )}
                                  size="small"
                                  onClick={() => {
                                    onStageRequiredChange(
                                      EnumStages.CloseTaskIfERNumberEntered,
                                      EnumWorkflowRules.CloseTaskIfERNumberEnteredRequired,
                                      true
                                    );
                                  }}
                                  disabled={
                                    assessmentTypeWizardPageState.PageMode
                                      .IsDisable
                                  }
                                >
                                  Yes
                                </Button>
                                <Button
                                  variant="contained"
                                  disableElevation
                                  className={getButtonClassName(
                                    !assessmentTypeWizardPageState.CloseTaskIfERNumberEnteredRequired
                                  )}
                                  size="small"
                                  onClick={() => {
                                    onStageRequiredChange(
                                      EnumStages.CloseTaskIfERNumberEntered,
                                      EnumWorkflowRules.CloseTaskIfERNumberEnteredRequired,
                                      false
                                    );
                                  }}
                                  disabled={
                                    assessmentTypeWizardPageState.PageMode
                                      .IsDisable
                                  }
                                >
                                  No
                                </Button>
                              </Grid>
                            </>
                          )}
                        </Grid>
                        {assessmentTypeWizardPageState?.TrackTasksRequired && (
                          <>
                            <Grid
                              container
                              className="review-stage-inner-wrapper b-bottom-dashed"
                            >
                              <Grid
                                container
                                spacing={2}
                                className={
                                  !assessmentTypeWizardPageState?.GlobalSMEApprovalRequired
                                    ? "v-align pt-10 pb-20 mt-0"
                                    : "v-align pt-10 mt-0"
                                }
                              >
                                <Grid item lg={12} md={12} sm={12}>
                                  <p className="body1 bold pl-40">
                                    Review Stage
                                  </p>
                                </Grid>
                                <Grid item lg={4} md={4} sm={6}>
                                  <p className="body1 pl-80 fx v-align">
                                    Review by Global SME
                                    <Tooltip
                                      title={
                                        "Enables global standard SME review stage for the remediation task."
                                      }
                                    >
                                      <InfoCircle
                                        className="icon-btn ml-10"
                                        color={"#0288d1"}
                                        variant="Bold"
                                        style={{
                                          transform: "rotate(180deg)",
                                        }}
                                      />
                                    </Tooltip>
                                  </p>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={4}
                                  sm={6}
                                  className="field-sub-header-btn-wrap fx fx-left"
                                >
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    className={getButtonClassName(
                                      assessmentTypeWizardPageState.GlobalSMEApprovalRequired
                                    )}
                                    size="small"
                                    onClick={() => {
                                      onStageRequiredChange(
                                        EnumStages.ReviewByGlobalSME,
                                        EnumWorkflowRules.GlobalSMEReviewRequired,
                                        true
                                      );
                                    }}
                                    disabled={
                                      assessmentTypeWizardPageState.PageMode
                                        .IsDisable
                                    }
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    className={getButtonClassName(
                                      !assessmentTypeWizardPageState.GlobalSMEApprovalRequired
                                    )}
                                    size="small"
                                    onClick={() => {
                                      onStageRequiredChange(
                                        EnumStages.ReviewByGlobalSME,
                                        EnumWorkflowRules.GlobalSMEReviewRequired,
                                        false
                                      );
                                    }}
                                    disabled={
                                      assessmentTypeWizardPageState.PageMode
                                        .IsDisable
                                    }
                                  >
                                    No
                                  </Button>
                                </Grid>
                              </Grid>
                              {assessmentTypeWizardPageState?.GlobalSMEApprovalRequired && (
                                <>
                                  <Grid container spacing={2} className="mt-10">
                                    <Grid item lg={4} md={4} sm={6}>
                                      <p className="body1 pl-100 pt-5 fx">
                                        Select the approvals required
                                        <Tooltip
                                          title={
                                            <>
                                              Select 1 option based on the
                                              requirement:
                                              <ul>
                                                <li>
                                                  • “All selected approver(s)”
                                                  means all approver(s) in the
                                                  selected applicable role
                                                  should approve to move the
                                                  task to the next stage.
                                                </li>
                                                <li>
                                                  • “One of the selected
                                                  approver(s)” means one of the
                                                  approvers in the selected
                                                  applicable role should approve
                                                  to move the task to the next
                                                  stage.
                                                </li>
                                              </ul>
                                            </>
                                          }
                                        >
                                          <InfoCircle
                                            className="icon-btn ml-10"
                                            color={"#0288d1"}
                                            variant="Bold"
                                            style={{
                                              transform: "rotate(180deg)",
                                            }}
                                          />
                                        </Tooltip>
                                      </p>
                                    </Grid>
                                    <Grid
                                      item
                                      lg={8}
                                      md={4}
                                      sm={6}
                                      className="field-sub-header-btn-wrap fx-left"
                                    >
                                      <FormGroup>
                                        <HPRCheckBox
                                          Id={""}
                                          Label={"All selected approver(s)"}
                                          Name={"All selected approver(s)"}
                                          checked={isRuleSelected(
                                            EnumStages.ReviewByGlobalSME,
                                            EnumWorkflowRules.GlobalSMEApprovalByAllRoles
                                          )}
                                          onCheckBoxChange={(
                                            name: string,
                                            value: any
                                          ) => {
                                            onChangeRuleSelection(
                                              EnumStages.ReviewByGlobalSME,
                                              EnumWorkflowRules.GlobalSMEApprovalByAllRoles,
                                              value
                                            );
                                          }}
                                          Disabled={
                                            assessmentTypeWizardPageState
                                              .PageMode.IsDisable ||
                                            checkRuleAvailability(
                                              EnumStages.ReviewByGlobalSME,
                                              EnumWorkflowRules.InspectionAssignmentModuleSMEApprovalRequired
                                            )
                                          }
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <HPRCheckBox
                                          Id={""}
                                          Label={
                                            "One of the selected approver(s)"
                                          }
                                          Name={
                                            "One of the selected approver(s)"
                                          }
                                          checked={isRuleSelected(
                                            EnumStages.ReviewByGlobalSME,
                                            EnumWorkflowRules.GlobalSMEApprovalByOneRole
                                          )}
                                          onCheckBoxChange={(
                                            name: string,
                                            value: any
                                          ) => {
                                            onChangeRuleSelection(
                                              EnumStages.ReviewByGlobalSME,
                                              EnumWorkflowRules.GlobalSMEApprovalByOneRole,
                                              value
                                            );
                                          }}
                                          Disabled={
                                            assessmentTypeWizardPageState
                                              .PageMode.IsDisable
                                          }
                                        />
                                      </FormGroup>
                                      {assessmentTypeWizardPageState?.ShowSMEReviewApprovalsRequiredError && (
                                        <Grid
                                          container
                                          className="validation-wrapper"
                                        >
                                          <p className="body1 fx v-align">
                                            <InfoCircle
                                              className="icon-btn"
                                              variant="Bold"
                                            />
                                            <span className="ml-5 message">
                                              Select approvals required
                                            </span>
                                          </p>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    spacing={2}
                                    className="mt-0 mb-10 pt-10"
                                  >
                                    <Grid item lg={4} md={4} sm={6}>
                                      <p className="body1 pl-100 pt-5 fx">
                                        Select the applicable roles
                                        <Tooltip
                                          title={
                                            "Select the approver role(s) that needs to perform the global standard SME review."
                                          }
                                        >
                                          <InfoCircle
                                            className="icon-btn ml-10"
                                            color={"#0288d1"}
                                            variant="Bold"
                                            style={{
                                              transform: "rotate(180deg)",
                                            }}
                                          />
                                        </Tooltip>
                                      </p>
                                    </Grid>
                                    <Grid
                                      item
                                      lg={8}
                                      md={4}
                                      sm={6}
                                      className="field-sub-header-btn-wrap fx-wrap fx-left"
                                    >
                                      <Grid className="multi-checkbox-list">
                                        <FormGroup>
                                          <HPRCheckBox
                                            Id={""}
                                            Label={"Global Primary SME"}
                                            Name={"Global Primary SME"}
                                            checked={isRuleSelected(
                                              EnumStages.ReviewByGlobalSME,
                                              EnumWorkflowRules.GlobalPrimarySMEApprovalRequired
                                            )}
                                            onCheckBoxChange={(
                                              name: string,
                                              value: any
                                            ) => {
                                              onChangeRuleSelection(
                                                EnumStages.ReviewByGlobalSME,
                                                EnumWorkflowRules.GlobalPrimarySMEApprovalRequired,
                                                value
                                              );
                                            }}
                                            Disabled={
                                              assessmentTypeWizardPageState
                                                .PageMode.IsDisable ||
                                              checkRuleAvailability(
                                                EnumStages.ReviewByGlobalSME,
                                                EnumWorkflowRules.InspectionAssignmentModuleSMEApprovalRequired
                                              )
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <HPRCheckBox
                                            Id={""}
                                            Label={
                                              "SME(s) defined in Site Inspection"
                                            }
                                            Name={
                                              "SME(s) defined in Site Inspection"
                                            }
                                            checked={isRuleSelected(
                                              EnumStages.ReviewByGlobalSME,
                                              EnumWorkflowRules.InspectionAssignmentModuleSMEApprovalRequired
                                            )}
                                            onCheckBoxChange={(
                                              name: string,
                                              value: any
                                            ) => {
                                              onChangeRuleSelection(
                                                EnumStages.ReviewByGlobalSME,
                                                EnumWorkflowRules.InspectionAssignmentModuleSMEApprovalRequired,
                                                value
                                              );
                                            }}
                                            Disabled={
                                              assessmentTypeWizardPageState
                                                .PageMode.IsDisable ||
                                              checkRuleAvailability(
                                                EnumStages.ReviewByGlobalSME,
                                                EnumWorkflowRules.GlobalPrimarySMEApprovalRequired
                                              ) ||
                                              checkRuleAvailability(
                                                EnumStages.ReviewByGlobalSME,
                                                EnumWorkflowRules.GlobalSecondarySMEApprovalRequired
                                              )
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <HPRCheckBox
                                            Id={""}
                                            Label={"Global Secondary SME(s)"}
                                            Name={"Global Secondary SME(s)"}
                                            checked={isRuleSelected(
                                              EnumStages.ReviewByGlobalSME,
                                              EnumWorkflowRules.GlobalSecondarySMEApprovalRequired
                                            )}
                                            onCheckBoxChange={(
                                              name: string,
                                              value: any
                                            ) => {
                                              onChangeRuleSelection(
                                                EnumStages.ReviewByGlobalSME,
                                                EnumWorkflowRules.GlobalSecondarySMEApprovalRequired,
                                                value
                                              );
                                            }}
                                            Disabled={
                                              assessmentTypeWizardPageState
                                                .PageMode.IsDisable ||
                                              checkRuleAvailability(
                                                EnumStages.ReviewByGlobalSME,
                                                EnumWorkflowRules.InspectionAssignmentModuleSMEApprovalRequired
                                              )
                                            }
                                          />
                                        </FormGroup>
                                      </Grid>
                                      {assessmentTypeWizardPageState?.ShowSMEReviewRolesRequiredError && (
                                        <Grid
                                          container
                                          className="validation-wrapper"
                                        >
                                          <p className="body1 fx v-align">
                                            <InfoCircle
                                              className="icon-btn"
                                              variant="Bold"
                                            />
                                            <span className="ml-5 message">
                                              Select at least one applicable
                                              role
                                            </span>
                                          </p>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>

                            <Grid
                              container
                              className="review-stage-inner-wrapper b-bottom-solid"
                            >
                              <Grid
                                container
                                spacing={2}
                                className={
                                  !assessmentTypeWizardPageState?.SiteLeadershipApprovalRequired
                                    ? "v-align pb-20 mt-5"
                                    : "v-align mt-5"
                                }
                              >
                                <Grid item lg={4} md={4} sm={6}>
                                  <p className="body1 pl-80 fx v-align">
                                    Review by Site Leadership
                                    <Tooltip
                                      title={
                                        "Enables site leadership review stage for the remediation task."
                                      }
                                    >
                                      <InfoCircle
                                        className="icon-btn ml-10"
                                        color={"#0288d1"}
                                        variant="Bold"
                                        style={{
                                          transform: "rotate(180deg)",
                                        }}
                                      />
                                    </Tooltip>
                                  </p>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={4}
                                  sm={6}
                                  className="field-sub-header-btn-wrap fx fx-left"
                                >
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    className={getButtonClassName(
                                      assessmentTypeWizardPageState.SiteLeadershipApprovalRequired
                                    )}
                                    size="small"
                                    onClick={() => {
                                      onStageRequiredChange(
                                        EnumStages.ReviewBySiteLeadership,
                                        EnumWorkflowRules.SiteLeadershipReviewRequired,
                                        true
                                      );
                                    }}
                                    disabled={
                                      assessmentTypeWizardPageState.PageMode
                                        .IsDisable
                                    }
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    variant="contained"
                                    disableElevation
                                    className={getButtonClassName(
                                      !assessmentTypeWizardPageState.SiteLeadershipApprovalRequired
                                    )}
                                    size="small"
                                    onClick={() => {
                                      onStageRequiredChange(
                                        EnumStages.ReviewBySiteLeadership,
                                        EnumWorkflowRules.SiteLeadershipReviewRequired,
                                        false
                                      );
                                    }}
                                    disabled={
                                      assessmentTypeWizardPageState.PageMode
                                        .IsDisable
                                    }
                                  >
                                    No
                                  </Button>
                                </Grid>
                              </Grid>
                              {assessmentTypeWizardPageState?.SiteLeadershipApprovalRequired && (
                                <>
                                  <Grid container spacing={2} className="mt-10">
                                    <Grid item lg={4} md={4} sm={6}>
                                      <p className="body1 pl-100 pt-5 fx">
                                        Select the approvals required
                                        <Tooltip
                                          title={
                                            <>
                                              Select 1 option based on the
                                              requirement:
                                              <ul>
                                                <li>
                                                  • “All selected approver(s)”
                                                  means all approver(s) in the
                                                  selected applicable role
                                                  should approve to move the
                                                  task to the next stage.
                                                </li>
                                                <li>
                                                  • “One of the selected
                                                  approver(s)” means one of the
                                                  approvers in the selected
                                                  applicable role should approve
                                                  to move the task to the next
                                                  stage.
                                                </li>
                                              </ul>
                                            </>
                                          }
                                        >
                                          <InfoCircle
                                            className="icon-btn ml-10"
                                            color={"#0288d1"}
                                            variant="Bold"
                                            style={{
                                              transform: "rotate(180deg)",
                                            }}
                                          />
                                        </Tooltip>
                                      </p>
                                    </Grid>
                                    <Grid
                                      item
                                      lg={8}
                                      md={4}
                                      sm={6}
                                      className="field-sub-header-btn-wrap fx-left"
                                    >
                                      <FormGroup>
                                        <HPRCheckBox
                                          Id={""}
                                          Label={"All selected approver(s)"}
                                          Name={"All selected approver(s)"}
                                          checked={isRuleSelected(
                                            EnumStages.ReviewBySiteLeadership,
                                            EnumWorkflowRules.SiteLeadershipApprovalByAllRoles
                                          )}
                                          onCheckBoxChange={(
                                            name: string,
                                            value: any
                                          ) => {
                                            onChangeRuleSelection(
                                              EnumStages.ReviewBySiteLeadership,
                                              EnumWorkflowRules.SiteLeadershipApprovalByAllRoles,
                                              value
                                            );
                                          }}
                                          Disabled={
                                            assessmentTypeWizardPageState
                                              .PageMode.IsDisable
                                          }
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <HPRCheckBox
                                          Id={""}
                                          Label={
                                            "One of the selected approver(s)"
                                          }
                                          Name={
                                            "One of the selected approver(s)"
                                          }
                                          checked={isRuleSelected(
                                            EnumStages.ReviewBySiteLeadership,
                                            EnumWorkflowRules.SiteLeadershipApprovalByOneRole
                                          )}
                                          onCheckBoxChange={(
                                            name: string,
                                            value: any
                                          ) => {
                                            onChangeRuleSelection(
                                              EnumStages.ReviewBySiteLeadership,
                                              EnumWorkflowRules.SiteLeadershipApprovalByOneRole,
                                              value
                                            );
                                          }}
                                          Disabled={
                                            assessmentTypeWizardPageState
                                              .PageMode.IsDisable
                                          }
                                        />
                                      </FormGroup>
                                      {assessmentTypeWizardPageState?.ShowSiteLeadershipReviewApprovalsRequiredError && (
                                        <Grid
                                          container
                                          className="validation-wrapper"
                                        >
                                          <p className="body1 fx v-align">
                                            <InfoCircle
                                              className="icon-btn"
                                              variant="Bold"
                                            />
                                            <span className="ml-10 message">
                                              Select approvals required
                                            </span>
                                          </p>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    spacing={2}
                                    className="mt-0 mb-10 pt-10"
                                  >
                                    <Grid item lg={4} md={4} sm={6}>
                                      <p className="body1 pl-100 pt-5 fx">
                                        Select the applicable roles
                                        <Tooltip
                                          title={
                                            "Select the approver role(s) that needs to perform the site leadership review."
                                          }
                                        >
                                          <InfoCircle
                                            className="icon-btn ml-10"
                                            color={"#0288d1"}
                                            variant="Bold"
                                            style={{
                                              transform: "rotate(180deg)",
                                            }}
                                          />
                                        </Tooltip>
                                      </p>
                                    </Grid>
                                    <Grid
                                      item
                                      lg={8}
                                      md={4}
                                      sm={6}
                                      className="field-sub-header-btn-wrap fx-wrap fx-left"
                                    >
                                      <Grid className="multi-checkbox-list">
                                        <FormGroup>
                                          <HPRCheckBox
                                            Id={""}
                                            Label={"Director"}
                                            Name={"Director"}
                                            checked={isRuleSelected(
                                              EnumStages.ReviewBySiteLeadership,
                                              EnumWorkflowRules.SiteLeadershipDirectorApprovalRequired
                                            )}
                                            onCheckBoxChange={(
                                              name: string,
                                              value: any
                                            ) => {
                                              onChangeRuleSelection(
                                                EnumStages.ReviewBySiteLeadership,
                                                EnumWorkflowRules.SiteLeadershipDirectorApprovalRequired,
                                                value
                                              );
                                            }}
                                            Disabled={
                                              assessmentTypeWizardPageState
                                                .PageMode.IsDisable
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <HPRCheckBox
                                            Id={""}
                                            Label={"QA Director"}
                                            Name={"QA Director"}
                                            checked={isRuleSelected(
                                              EnumStages.ReviewBySiteLeadership,
                                              EnumWorkflowRules.SiteLeadershipQADirectorApprovalRequired
                                            )}
                                            onCheckBoxChange={(
                                              name: string,
                                              value: any
                                            ) => {
                                              onChangeRuleSelection(
                                                EnumStages.ReviewBySiteLeadership,
                                                EnumWorkflowRules.SiteLeadershipQADirectorApprovalRequired,
                                                value
                                              );
                                            }}
                                            Disabled={
                                              assessmentTypeWizardPageState
                                                .PageMode.IsDisable
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <HPRCheckBox
                                            Id={""}
                                            Label={"Engineering Head"}
                                            Name={"Engineering Head"}
                                            checked={isRuleSelected(
                                              EnumStages.ReviewBySiteLeadership,
                                              EnumWorkflowRules.SiteLeadershipEngineeringDirectorApprovalRequired
                                            )}
                                            onCheckBoxChange={(
                                              name: string,
                                              value: any
                                            ) => {
                                              onChangeRuleSelection(
                                                EnumStages.ReviewBySiteLeadership,
                                                EnumWorkflowRules.SiteLeadershipEngineeringDirectorApprovalRequired,
                                                value
                                              );
                                            }}
                                            Disabled={
                                              assessmentTypeWizardPageState
                                                .PageMode.IsDisable
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <HPRCheckBox
                                            Id={""}
                                            Label={"EHS Head"}
                                            Name={"EHS Head"}
                                            checked={isRuleSelected(
                                              EnumStages.ReviewBySiteLeadership,
                                              EnumWorkflowRules.SiteLeadershipEHSDirectorApprovalRequired
                                            )}
                                            onCheckBoxChange={(
                                              name: string,
                                              value: any
                                            ) => {
                                              onChangeRuleSelection(
                                                EnumStages.ReviewBySiteLeadership,
                                                EnumWorkflowRules.SiteLeadershipEHSDirectorApprovalRequired,
                                                value
                                              );
                                            }}
                                            Disabled={
                                              assessmentTypeWizardPageState
                                                .PageMode.IsDisable
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <HPRCheckBox
                                            Id={""}
                                            Label={"QC Manager"}
                                            Name={"QC Manager"}
                                            checked={isRuleSelected(
                                              EnumStages.ReviewBySiteLeadership,
                                              EnumWorkflowRules.SiteLeadershipQCManagerApprovalRequired
                                            )}
                                            onCheckBoxChange={(
                                              name: string,
                                              value: any
                                            ) => {
                                              onChangeRuleSelection(
                                                EnumStages.ReviewBySiteLeadership,
                                                EnumWorkflowRules.SiteLeadershipQCManagerApprovalRequired,
                                                value
                                              );
                                            }}
                                            Disabled={
                                              assessmentTypeWizardPageState
                                                .PageMode.IsDisable
                                            }
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <HPRCheckBox
                                            Id={""}
                                            Label={"Maintenance Manager"}
                                            Name={"Maintenance Manager"}
                                            checked={isRuleSelected(
                                              EnumStages.ReviewBySiteLeadership,
                                              EnumWorkflowRules.SiteLeadershipMaintenanceManagerApprovalRequired
                                            )}
                                            onCheckBoxChange={(
                                              name: string,
                                              value: any
                                            ) => {
                                              onChangeRuleSelection(
                                                EnumStages.ReviewBySiteLeadership,
                                                EnumWorkflowRules.SiteLeadershipMaintenanceManagerApprovalRequired,
                                                value
                                              );
                                            }}
                                            Disabled={
                                              assessmentTypeWizardPageState
                                                .PageMode.IsDisable
                                            }
                                          />
                                        </FormGroup>
                                      </Grid>
                                      {assessmentTypeWizardPageState?.ShowSiteLeadershipReviewRolesRequiredError && (
                                        <Grid
                                          container
                                          className="validation-wrapper"
                                        >
                                          <p className="body1 fx v-align">
                                            <InfoCircle
                                              className="icon-btn"
                                              variant="Bold"
                                            />
                                            <span className="ml-10 message">
                                              Select at least one applicable
                                              role
                                            </span>
                                          </p>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </>
                        )}
                      </Grid>
                      {assessmentTypeWizardPageState?.TrackTasksRequired && (
                        <Grid container className="review-stage-inner-wrapper">
                          <Grid
                            container
                            spacing={2}
                            className="v-align pt-0 mt-5"
                          >
                            <Grid item lg={12} md={12} sm={12}>
                              <p className="body1 bold pl-40">Closure Stage</p>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6}>
                              <p className="body1 pl-80 fx v-align">
                                Review by Site Leadership
                                <Tooltip
                                  title={
                                    "Enables site leadership closure approval stage for the remediation task."
                                  }
                                >
                                  <InfoCircle
                                    className="icon-btn ml-10"
                                    color={"#0288d1"}
                                    variant="Bold"
                                    style={{
                                      transform: "rotate(180deg)",
                                    }}
                                  />
                                </Tooltip>
                              </p>
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={4}
                              sm={6}
                              className="field-sub-header-btn-wrap fx fx-left"
                            >
                              <Button
                                variant="contained"
                                disableElevation
                                className={getButtonClassName(
                                  assessmentTypeWizardPageState.ClosureStageApprovalRequired
                                )}
                                size="small"
                                onClick={() => {
                                  onStageRequiredChange(
                                    EnumStages.ClosureApproval,
                                    EnumWorkflowRules.ClosureApprovalRequired,
                                    true
                                  );
                                }}
                                disabled={
                                  assessmentTypeWizardPageState.PageMode
                                    .IsDisable
                                }
                              >
                                Yes
                              </Button>
                              <Button
                                variant="contained"
                                disableElevation
                                className={getButtonClassName(
                                  !assessmentTypeWizardPageState.ClosureStageApprovalRequired
                                )}
                                size="small"
                                onClick={() => {
                                  onStageRequiredChange(
                                    EnumStages.ClosureApproval,
                                    EnumWorkflowRules.ClosureApprovalRequired,
                                    false
                                  );
                                }}
                                disabled={
                                  assessmentTypeWizardPageState.PageMode
                                    .IsDisable
                                }
                              >
                                No
                              </Button>
                            </Grid>
                          </Grid>
                          {assessmentTypeWizardPageState.ClosureStageApprovalRequired && (
                            <>
                              <Grid container spacing={2} className="mt-10">
                                <Grid item lg={4} md={4} sm={6}>
                                  <p className="body1 pl-100 fx">
                                    Select the approvals required
                                    <Tooltip
                                      title={
                                        <>
                                          Select 1 option based on the
                                          requirement:
                                          <ul>
                                            <li>
                                              • “All selected approver(s)” means
                                              all approver(s) in the selected
                                              applicable role should approve to
                                              move the task to the next stage.
                                            </li>
                                            <li>
                                              • “One of the selected
                                              approver(s)” means one of the
                                              approvers in the selected
                                              applicable role should approve to
                                              move the task to the next stage.
                                            </li>
                                          </ul>
                                        </>
                                      }
                                    >
                                      <InfoCircle
                                        className="icon-btn ml-10"
                                        color={"#0288d1"}
                                        variant="Bold"
                                        // size="22"
                                        style={{
                                          transform: "rotate(180deg)",
                                        }}
                                      />
                                    </Tooltip>
                                  </p>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={4}
                                  sm={6}
                                  className="field-sub-header-btn-wrap fx-left"
                                >
                                  <FormGroup>
                                    <HPRCheckBox
                                      Id={""}
                                      Label={"All selected approver(s)"}
                                      Name={"All selected approver(s)"}
                                      checked={isRuleSelected(
                                        EnumStages.ClosureApproval,
                                        EnumWorkflowRules.SiteLeadershipApprovalByAllRoles
                                      )}
                                      onCheckBoxChange={(
                                        name: string,
                                        value: any
                                      ) => {
                                        onChangeRuleSelection(
                                          EnumStages.ClosureApproval,
                                          EnumWorkflowRules.SiteLeadershipApprovalByAllRoles,
                                          value
                                        );
                                      }}
                                      Disabled={
                                        assessmentTypeWizardPageState.PageMode
                                          .IsDisable
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <HPRCheckBox
                                      Id={""}
                                      Label={"One of the selected approver(s)"}
                                      Name={"One of the selected approver(s)"}
                                      checked={isRuleSelected(
                                        EnumStages.ClosureApproval,
                                        EnumWorkflowRules.SiteLeadershipApprovalByOneRole
                                      )}
                                      onCheckBoxChange={(
                                        name: string,
                                        value: any
                                      ) => {
                                        onChangeRuleSelection(
                                          EnumStages.ClosureApproval,
                                          EnumWorkflowRules.SiteLeadershipApprovalByOneRole,
                                          value
                                        );
                                      }}
                                      Disabled={
                                        assessmentTypeWizardPageState.PageMode
                                          .IsDisable
                                      }
                                    />
                                  </FormGroup>
                                  {assessmentTypeWizardPageState?.ShowClosureStageApprovalsRequiredError && (
                                    <Grid
                                      container
                                      className="validation-wrapper"
                                    >
                                      <p className="body1 fx v-align">
                                        <InfoCircle
                                          className="icon-btn"
                                          variant="Bold"
                                        />
                                        <span className="ml-10 message">
                                          Select approvals required
                                        </span>
                                      </p>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                className="mt-0 mb-10 pt-10"
                              >
                                <Grid item lg={4} md={4} sm={6}>
                                  <p className="body1 pl-100 fx">
                                    Select the applicable roles
                                    <Tooltip
                                      title={
                                        "Select the approver role(s) that needs to perform the site leadership closure approval."
                                      }
                                    >
                                      <InfoCircle
                                        className="icon-btn ml-10"
                                        color={"#0288d1"}
                                        variant="Bold"
                                        style={{
                                          transform: "rotate(180deg)",
                                        }}
                                      />
                                    </Tooltip>
                                  </p>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={4}
                                  sm={6}
                                  className="field-sub-header-btn-wrap fx-wrap fx-left"
                                >
                                  <Grid className="multi-checkbox-list">
                                    <FormGroup>
                                      <HPRCheckBox
                                        Id={""}
                                        Label={"Director"}
                                        Name={"Director"}
                                        checked={isRuleSelected(
                                          EnumStages.ClosureApproval,
                                          EnumWorkflowRules.SiteLeadershipDirectorApprovalRequired
                                        )}
                                        onCheckBoxChange={(
                                          name: string,
                                          value: any
                                        ) => {
                                          onChangeRuleSelection(
                                            EnumStages.ClosureApproval,
                                            EnumWorkflowRules.SiteLeadershipDirectorApprovalRequired,
                                            value
                                          );
                                        }}
                                        Disabled={
                                          assessmentTypeWizardPageState.PageMode
                                            .IsDisable
                                        }
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <HPRCheckBox
                                        Id={""}
                                        Label={"QA Director"}
                                        Name={"QA Director"}
                                        checked={isRuleSelected(
                                          EnumStages.ClosureApproval,
                                          EnumWorkflowRules.SiteLeadershipQADirectorApprovalRequired
                                        )}
                                        onCheckBoxChange={(
                                          name: string,
                                          value: any
                                        ) => {
                                          onChangeRuleSelection(
                                            EnumStages.ClosureApproval,
                                            EnumWorkflowRules.SiteLeadershipQADirectorApprovalRequired,
                                            value
                                          );
                                        }}
                                        Disabled={
                                          assessmentTypeWizardPageState.PageMode
                                            .IsDisable
                                        }
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <HPRCheckBox
                                        Id={""}
                                        Label={"Engineering Head"}
                                        Name={"Engineering Head"}
                                        checked={isRuleSelected(
                                          EnumStages.ClosureApproval,
                                          EnumWorkflowRules.SiteLeadershipEngineeringDirectorApprovalRequired
                                        )}
                                        onCheckBoxChange={(
                                          name: string,
                                          value: any
                                        ) => {
                                          onChangeRuleSelection(
                                            EnumStages.ClosureApproval,
                                            EnumWorkflowRules.SiteLeadershipEngineeringDirectorApprovalRequired,
                                            value
                                          );
                                        }}
                                        Disabled={
                                          assessmentTypeWizardPageState.PageMode
                                            .IsDisable
                                        }
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <HPRCheckBox
                                        Id={""}
                                        Label={"EHS Head"}
                                        Name={"EHS Head"}
                                        checked={isRuleSelected(
                                          EnumStages.ClosureApproval,
                                          EnumWorkflowRules.SiteLeadershipEHSDirectorApprovalRequired
                                        )}
                                        onCheckBoxChange={(
                                          name: string,
                                          value: any
                                        ) => {
                                          onChangeRuleSelection(
                                            EnumStages.ClosureApproval,
                                            EnumWorkflowRules.SiteLeadershipEHSDirectorApprovalRequired,
                                            value
                                          );
                                        }}
                                        Disabled={
                                          assessmentTypeWizardPageState.PageMode
                                            .IsDisable
                                        }
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <HPRCheckBox
                                        Id={""}
                                        Label={"QC Manager"}
                                        Name={"QC Manager"}
                                        checked={isRuleSelected(
                                          EnumStages.ClosureApproval,
                                          EnumWorkflowRules.SiteLeadershipQCManagerApprovalRequired
                                        )}
                                        onCheckBoxChange={(
                                          name: string,
                                          value: any
                                        ) => {
                                          onChangeRuleSelection(
                                            EnumStages.ClosureApproval,
                                            EnumWorkflowRules.SiteLeadershipQCManagerApprovalRequired,
                                            value
                                          );
                                        }}
                                        Disabled={
                                          assessmentTypeWizardPageState.PageMode
                                            .IsDisable
                                        }
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <HPRCheckBox
                                        Id={""}
                                        Label={"Maintenance Manager"}
                                        Name={"Maintenance Manager"}
                                        checked={isRuleSelected(
                                          EnumStages.ClosureApproval,
                                          EnumWorkflowRules.SiteLeadershipMaintenanceManagerApprovalRequired
                                        )}
                                        onCheckBoxChange={(
                                          name: string,
                                          value: any
                                        ) => {
                                          onChangeRuleSelection(
                                            EnumStages.ClosureApproval,
                                            EnumWorkflowRules.SiteLeadershipMaintenanceManagerApprovalRequired,
                                            value
                                          );
                                        }}
                                        Disabled={
                                          assessmentTypeWizardPageState.PageMode
                                            .IsDisable
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  {assessmentTypeWizardPageState?.ShowClosureStageRolesRequiredError && (
                                    <Grid
                                      container
                                      className="validation-wrapper"
                                    >
                                      <p className="body1 fx v-align">
                                        <InfoCircle
                                          className="icon-btn"
                                          variant="Bold"
                                        />
                                        <span className="ml-10 message">
                                          Select at least one applicable role
                                        </span>
                                      </p>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}
                    </fieldset>
                  </Grid>
                </Grid>
              </fieldset>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default AssessmentTypeWizardPage;
