import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { RootState } from "../../../core/store";
import { QuestionCategoryDTO } from "../../../common/types/common.dto.types";
import { GETMULTIPLECHOICESLIST } from "./MultipleChoicesPage.action";
import { MultipleChoicesPageType } from "./MultipleChoicesPage.types";
import { multipleChoicesReducerActions } from "./MultipleChoicesPage.reducer";
import { getMultipleChoicesList } from "./MultipleChoicesPage.api";

// ==============================|| VIEWS - PAGES - MULTIPLE CHOICES - SAGA ||============================== //

const getMultipleChoiceListWatcher = function* (): any {
  yield takeEvery(GETMULTIPLECHOICESLIST, function* (action: AnyAction): any {
    const state: MultipleChoicesPageType = yield select(
      (s: RootState) => s.multipleChoiceListReducer
    );

    // set page loading
    if (!state.DataLoading) {
      yield put(setLoading(true));
    }

    const searchPara: QuestionCategoryDTO = action.payload?.searchParameter;

    const result = yield call(
      async () => await getMultipleChoicesList(searchPara)
    );

    if (result?.Data?.PageData == null && !result?.Data?.PageData) {
      // TODO document why this block is empty
    } else {
      yield put(
        multipleChoicesReducerActions.setMultipleChoiceList({
          Data: result?.Data?.PageData,
          MultipleChoiceStatusSummary:
            result?.Data?.MultipleChoiceStatusSummary,
        } as MultipleChoicesPageType)
      );

      yield put(
        multipleChoicesReducerActions.setMultipleChoiceSearchParameter(
          searchPara
        )
      );
    }

    yield put(setLoading(false));
  });
};

const multipleChoiceListSaga = function* () {
  yield all([getMultipleChoiceListWatcher()]);
};

export default multipleChoiceListSaga;
