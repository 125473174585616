// Saga & Redux imports
import {
  all,
  takeEvery,
  put,
  call,
  select,
  take,
} from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { channel } from "redux-saga";

// Project imports
import { EnumPageMode, EnumStatus } from "../../../common/enums/common.enums";
import { CallNavigation } from "../../../common/functions/common.functions";
import {
  DropDownDTO,
  StandardDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { RootState } from "../../../core/store";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  CHECKSTANDARDBEFOREDEACTIVATE,
  deactivateStandard,
  DEACTIVATESTANDARD,
  GETSTANDARD,
  SAVESTANDARD,
} from "./StandardPage.action";
import {
  checkBeforeDeactivateStandard,
  deactivateStandardApi,
  getStandardById,
  getStandardSMEDropdownList,
  saveStandard,
} from "./StandardPage.api";
import {
  setStandard,
  changePageMode,
  setStandardSmes,
  setStandardSmeDropDownList,
} from "./StandardPage.reducer";
import { StandardPageType } from "./StandardPage.types";
import { callAllApiRequest } from "../../../core/api/apiClient.service";

// ==============================|| VIEWS - PAGES - STANDARD - SAGA ||============================== //

// Get pop up message when saving standards

const getStatusMessage = (
  statusChange: boolean,
  status: EnumStatus,
  versionChange: boolean
): string => {
  if (!statusChange) {
    if (versionChange) {
      return "Standard updated successfully";
    }
    return "Standard saved successfully";
  } else if (status === EnumStatus.Draft) {
    return "Standard successfully saved as draft";
  } else if (status === EnumStatus.Published) {
    return "Standard successfully published";
  }
  return "Standard saved successfully";
};

// Channel for call deactivted standard from callback function
const deactivateStandardChannel = channel();
export function* deactivateStandardChannelWatcher() {
  while (true) {
    const action = yield take(deactivateStandardChannel);
    yield put(action);
  }
}

// Channel for navigation
const navigationChannel = channel();
export function* navigationChannelWatcher() {
  const action = yield take(navigationChannel);
  yield call(() => CallNavigation("/standards"));
}

// Watcher for Deactivated channel
export function* deactivateStandardWithInspectionsChannelWatcher() {
  const action = yield take(deactivateStandardChannel);
  yield put(action);
}

// Watcher for GETSTANDARD(get standard by id) action
const getStandardWatcher = function* (): any {
  yield takeEvery(GETSTANDARD, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));

    const standardId: number = action.payload;

    // get data from backend
    try {
      const getStandardDataById: Promise<ActionResponseType> =
        getStandardById(standardId);

      const getSMEDropdownList: Promise<ActionResponseType> =
        getStandardSMEDropdownList();

      const result = yield call(
        async () =>
          await callAllApiRequest([getStandardDataById, getSMEDropdownList])
      );
      if (result[0]?.IsSuccess && result[1]?.IsSuccess) {
        if (!result[0]?.Data || !result[1]?.Data) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No standard data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          const standard: StandardDTO = result[0].Data;
          yield put(setStandard(standard));
          yield put(
            setStandardSmeDropDownList(
              result[1].Data ?? ([] as Array<DropDownDTO>)
            )
          );
          yield put(setLoading(false));
        }
      } else {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }

    // change page mode (change disable and button show hide)
    yield put(changePageMode(EnumPageMode.ViewMode));

    // set page loading off
    yield put(setLoading(false));
  });
};

// watcher for SAVESTANDARD(save standard) action
const saveStandardWatcher = function* (): any {
  yield takeEvery(SAVESTANDARD, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));

    let standard: StandardDTO = action.payload;
    const state: StandardPageType = yield select(
      (s: RootState) => s.standardReducer
    );
    let statusChange = true;
    let versionChnage: boolean = false;
    let prevStatus = state.Standard.Status;
    let prevVersion = state.Standard.Version;
    if (prevStatus === standard.Status) {
      statusChange = false;
    }
    yield put(setStandardSmes(standard));
    // get data from backend
    try {
      let result: ActionResponseType = yield call(() =>
        saveStandard({ ...standard, StandardSmeDropDownList: [] })
      );

      if (result.IsSuccess) {
        standard = result.Data;

        if (prevVersion !== standard.Version) {
          versionChnage = true;
        }
        const state: StandardPageType = yield select(
          (s: RootState) => s.standardReducer
        );
        standard.PrimarySME = state.Standard.PrimarySME;
        standard.SecondarySME = state.Standard.SecondarySME;
        standard.QuestionsSummary = state.Standard.QuestionsSummary;
        yield put(
          openPopup({
            Open: true,
            BodyText: getStatusMessage(
              statusChange,
              standard.Status,
              versionChnage
            ),
            HeaderText: "Success",
            PopupType: EnumPopupType.SuccessPopup,
          } as PopupProps)
        );
        yield put(setStandard(standard));

        // change page mode (change disable and button show hide)
        yield put(changePageMode(EnumPageMode.ViewMode));
      } else {
        let errorMessgae: string = "";
        if (result.ErrorMessage && result.ErrorMessage !== "") {
          errorMessgae = result.ErrorMessage;
        } else {
          errorMessgae = "Standard not saved successfully";
        }

        yield put(
          openPopup({
            Open: true,
            BodyText: errorMessgae,
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred saving standard",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading off
    yield put(setLoading(false));
  });
};

// watcher for DEACTIVATESTANDARD(deactivate standard) action
const deactivateStandardWatcher = function* (): any {
  yield takeEvery(DEACTIVATESTANDARD, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));

    let standard: StandardDTO = Object.assign({}, action.payload);
    //standard.Status = EnumStatus.Deactivated;
    // get data from backend
    try {
      let result: ActionResponseType = yield call(() =>
        deactivateStandardApi({ ...standard, StandardSmeDropDownList: [] })
      );
      if (result.IsSuccess) {
        const state: StandardPageType = yield select(
          (s: RootState) => s.standardReducer
        );
        if (result.ErrorMessage !== "" && result.ErrorMessage != null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage,
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              YesBtnClick() {},
            } as PopupProps)
          );
        } else {
          standard = result.Data;
          standard.PrimarySME = state.Standard.PrimarySME;
          standard.SecondarySME = state.Standard.SecondarySME;
          // standard.QuestionsSummary = state.Standard.QuestionsSummary;

          yield put(
            openPopup({
              Open: true,
              BodyText: `Standard successfully ${
                standard.Status === EnumStatus.Draft
                  ? "saved as draft"
                  : "deactivated"
              }`,
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );

          yield put(setStandard(standard));

          // change page mode (change disable and button show hide)
          yield put(changePageMode(EnumPageMode.ViewMode));
        }
      } else {
        if (result.ErrorMessage !== "" && result.ErrorMessage != null) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage,
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred saving standard",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }

    // set page loading off
    yield put(setLoading(false));
  });
};

// watcher for CHECKSTANDARDBEFOREDEACTIVATE(save standard) action
const checkStandardBeforeDeactivateWatcher = function* (): any {
  yield takeEvery(
    CHECKSTANDARDBEFOREDEACTIVATE,
    function* (action: AnyAction): any {
      // set page loading on
      yield put(setLoading(true));

      let standard: StandardDTO = action.payload;
      let standardStatus: number = standard.Status;
      yield put(setStandardSmes(standard));

      // get data from backend
      try {
        let result: ActionResponseType = yield call(() =>
          checkBeforeDeactivateStandard({
            ...standard,
            StandardSmeDropDownList: [],
          })
        );
        standard = result.Data;
        standard.Status = standardStatus;
        if (result.IsSuccess) {
          const state: StandardPageType = yield select(
            (s: RootState) => s.standardReducer
          );
          if (result.ErrorMessage !== "" && result.ErrorMessage != null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: result.ErrorMessage,
                HeaderText: "Warning",
                PopupType: EnumPopupType.YesNoConfirmation,
                YesBtnClick() {
                  deactivateStandardChannel.put(deactivateStandard(standard));
                },
              } as PopupProps)
            );
          } else {
            standard.PrimarySME = state.Standard.PrimarySME;
            standard.SecondarySME = state.Standard.SecondarySME;
            //standard.QuestionsSummary = state.Standard.QuestionsSummary;

            yield put(setStandard(standard));
            yield put(changePageMode(EnumPageMode.ViewMode));
            yield put(
              openPopup({
                Open: true,
                BodyText: `Standard successfully ${
                  standard.Status === EnumStatus.Draft
                    ? "saved as draft"
                    : "deactivated"
                }`,
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
              } as PopupProps)
            );

            // change page mode (change disable and button show hide)
          }
        } else {
          if (result.ErrorMessage !== "" && result.ErrorMessage != null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: result.ErrorMessage,
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred saving standard",
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }

      // set page loading off
      yield put(setLoading(false));
    }
  );
};

const standardSaga = function* () {
  yield all([
    getStandardWatcher(),
    saveStandardWatcher(),
    deactivateStandardWatcher(),
    checkStandardBeforeDeactivateWatcher(),
    deactivateStandardChannelWatcher(),
    navigationChannelWatcher(),
  ]);
};

export default standardSaga;
