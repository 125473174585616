import { QuestionCategoryDTO } from "../../../common/types/common.dto.types";

export const GETMULTIPLECHOICE = "GETMULTIPLECHOICE";
export const SAVEMULTIPLECHOICE = "SAVEMULTIPLECHOICE";

export const getMultipleChoiceById = (multipleChoiceId: number) => {
  return {
    type: GETMULTIPLECHOICE,
    payload: multipleChoiceId,
  };
};

export const saveMultipleChoiceWizard = (
  multipleChoice: QuestionCategoryDTO
) => {
  return {
    type: SAVEMULTIPLECHOICE,
    payload: multipleChoice,
  };
};
