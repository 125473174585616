import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { AppOptions, AuthDetails } from "../types/common.types";
// import configData from "../../Publish.json";
import { EnumPageType } from "../enums/common.enums";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
  FunctionRightDTO,
} from "../../core/reducers/functionRights.types";
import { AuthProperties } from "../../core/hooks/useAuth";
import { AuthType } from "../../core/reducers/auth.types";
import jwt_decode from "jwt-decode";
import dayjs, { Dayjs } from "dayjs";
export const CallNavigation = (url: string, parameters?: any) => {
  // use for page navigation
  const navigate = useNavigate();
  navigate(url, parameters);
};

export const useValidator = (customMessage = {}, customValidator = {}) => {
  const [show, setShow] = useState(false);
  const validator = new SimpleReactValidator({
    messages: customMessage,
    validators: customValidator,
  });

  if (show) {
    validator.showMessages();
  }

  return [validator, setShow];
};

export const GetAppOptions = (): AppOptions => {
  const appOptions = {
    Version: process.env.REACT_APP_VERSION,
    Environment: process.env.REACT_APP_ENV,
    APIURL: process.env.REACT_APP_API_BASE_URL,
    AuthMode: parseInt(process.env.REACT_APP_AUTH_MODE),
    AuthDetails: {
      MicrosoftAzureActiveDirectoryOptions: {
        WindowMode: parseInt(process.env.REACT_APP_AZURE_AUTH_WINDOW_MODE),
        TenantId: process.env.REACT_APP_AZURE_AD_TENANTID,
        ClientId: process.env.REACT_APP_AZURE_AD_CLIENTID,
        Scopes: process.env["REACT_APP_AZURE_AD_SCOPES"]?.split(","),
        RedirectUri: process.env.REACT_APP_AZURE_AD_REDIRECTURI,
        InsightKey: process.env.REACT_APP_AZURE_INSIGHTS_KEY,
      },
    } as AuthDetails,
  } as AppOptions;

  return appOptions;
};

// check function rights from function rights
export const CheckFunctionRights = (
  functionRights: Array<FunctionRightDTO>,
  screenId: EnumPageType,
  functionId?: EnumSystemFunctions,
  functionType?: EnumSystemFunctionTypes
): boolean => {
  // const [auth, loading, logOut] = useAuth();

  // return true;

  const tokenStore = JSON.parse(
    localStorage.getItem("hpr_token_store") || "null"
  ) as AuthType | null;

  const decoded = jwt_decode(tokenStore?.GeneralToken) as AuthProperties;
  if (decoded?.SystemFunctions?.includes(functionId.toString())) {
    return true;
  } else {
    return false;
  }
  // // // console.log(auth?.SystemFunctionsId);
  // // Checked with screen type
  // let isAvailable: FunctionRightDTO = functionRights?.find(
  //   (functionRight) => functionRight?.ScreenId == screenId
  // );

  // // Checked with function type
  // if (functionType != EnumSystemFunctionTypes.None) {
  //   isAvailable = functionRights?.find(
  //     (functionRight) => functionRight?.FunctionTypeId == functionType
  //   );
  // }

  // // Check with function id
  // if (functionId != EnumSystemFunctions.None) {
  //   isAvailable = functionRights?.find(
  //     (functionRight) => functionRight?.FunctionId == functionId
  //   );
  // }

  // return isAvailable ? true : false;
};

// check Screen access rights from Screen access rights
export const CheckSystemScreenAccessRights = (screenId: number): boolean => {
  try {
    const tokenStore = JSON.parse(
      localStorage.getItem("hpr_token_store") || "null"
    ) as AuthType | null;

    // no access if token is empty
    if (!tokenStore) return false;

    const decoded: AuthProperties = jwt_decode(tokenStore?.GeneralToken);

    let systemScreensIds = [];
    if (Array.isArray(decoded?.SystemScreensIds)) {
      systemScreensIds = decoded?.SystemScreensIds;
    } else {
      systemScreensIds = [decoded?.SystemScreensIds];
    }

    if (
      systemScreensIds?.some((some) => some?.toString() === screenId.toString())
    ) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

//check if user is logging for the first time
export const isFirstTimeLogging = (): boolean => {
  try {
    const tokenStore = JSON.parse(
      localStorage.getItem("hpr_token_store") || "null"
    ) as AuthType | null;

    // no access if token is empty
    if (!tokenStore) return false;

    const decoded: AuthProperties = jwt_decode(tokenStore?.GeneralToken ?? "");

    return decoded.IsFirstTimeLogging === "True";
  } catch (error) {
    return false;
  }
};

//format date to string
export const formatDateToString = (_date: any): string => {
  const date = dayjs(_date).format("DD-MMM-YYYY");
  return date !== "Invalid Date" ? date : "-";
};

export const formatDateTimeToString = (_date: any, format?: string): string => {
  const date = dayjs(_date).format(format ?? "DD-MMM-YYYY HH:mm:ss");
  return date !== "Invalid Date" ? date : "-";
};

export const getFormatedDateForBackend = (date: any): string => {
  const _date: Dayjs | null = dayjs(date);

  return _date.isValid() &&
    dayjs(new Date(date)).format("YYYY-MM-DD") !== "Invalid Date"
    ? dayjs(new Date(date)).format("YYYY-MM-DD")
    : null;
};

export const IsDisabledFromDate = (date: dayjs.Dayjs, currentDate: string) => {
  const _currentDate: Dayjs | null = currentDate ? dayjs(currentDate) : null;
  if (_currentDate === null || !_currentDate.isValid()) return false;

  return date.isAfter(_currentDate.subtract(0, "day"));
};

export const IsDisabledToDate = (date: dayjs.Dayjs, currentDate: string) => {
  const _currentDate: Dayjs | null = currentDate ? dayjs(currentDate) : null;
  if (_currentDate === null || !_currentDate.isValid()) return false;

  return date.isBefore(_currentDate.subtract(0, "day"));
};

const deepEqual = (a: any, b: any): boolean => {
  if (a === b) return true; // Same reference or primitive types
  if (
    typeof a !== "object" ||
    typeof b !== "object" ||
    a === null ||
    b === null
  ) {
    return false; // Different types or null values
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false; // Different number of keys
  }

  for (let key of keysA) {
    if (!keysB.includes(key)) {
      return false; // Key exists in 'a' but not in 'b'
    }
    if (!deepEqual(a[key], b[key])) {
      return false; // Recursive deep check on property values
    }
  }

  return true;
};

export const isObjectEqualTo = (obj1: object, obj2: object): boolean => {
  if (obj1 === obj2) return true; // Same reference or primitive types
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false; // Different types or null values
  }

  const keysA = Object.keys(obj1);
  const keysB = Object.keys(obj2);

  if (keysA.length !== keysB.length) {
    return false; // Different number of keys
  }

  for (let key of keysA) {
    if (!keysB.includes(key)) {
      return false; // Key exists in 'a' but not in 'b'
    }
    if (!deepEqual(obj1[key], obj2[key])) {
      return false; // Recursive deep check on property values
    }
  }

  return true;
};

export const CreateHashCode = (input: any): number => {
  let hash = 0;

  const str = String(input);

  if (str.length === 0) return hash;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
};
