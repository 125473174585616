import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import {
  EnumInspectionStandardStatus,
  EnumPageMode,
} from "../../../common/enums/common.enums";
import {
  InspectionDTO,
  InspectionMemberDTO,
  InspectionStandardDTO,
  QuestionnaireDTO,
} from "../../../common/types/common.dto.types";
import { RedirectObject } from "../../../common/types/common.page.type";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { RootState } from "../../../core/store";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import {
  QuestionnaireSummaryPageParametersType,
  QuestionnaireSummaryParameterDTO,
} from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import {
  COMPLETEINSPECTION,
  COMPLETEINSPECTIONSTANDARD,
  COPYINSPECTIONSTANDARD,
  GETGLOBALSMEASSIGNMENTPOPUPDATA,
  GETSITEINSPECTIONQUESTIONLIST,
  REOPENCOMPLETEINSPECTION,
  REOPENINSPECTIONSTANDARD,
  RESUMEINSPECTIONSTANDARD,
  SAVEASSIGNEDGLOBALSME,
  SAVEINSPECTIONSITETEAMMEMBER,
} from "./SiteInspectionStartPage.action";
import {
  completeInspection,
  completeInspectionStandard,
  copyInspectionStandard,
  getGlobalSMEAssignmentPopupDataApi,
  getSiteInspectionQuestionList,
  reOpenCompleteInspection,
  reOpenInspectionStandard,
  resumeInspectionStandard,
  saveAssignedGlobalSMEApi,
  saveInspectionSiteTeamMember,
} from "./SiteInspectionStartPage.api";
import {
  clearPageState,
  closeCopyPopup,
  setPageMode,
  setPageModeRedirectMode,
  setSiteInspectionStartList,
  setSiteTeamMember,
  setUpdatedSMEList,
  updateInspectionStandardStatus,
} from "./SiteInspectionStartPage.reducer";
import {
  CopyInspectionStandardDTO,
  QuestionMainDataType,
  ReopenInspectionStandardData,
  SiteInspectionStartDataType,
} from "./SiteInspectionStartPage.types";

const getSiteInspectionQuestionListWatcher = function* (): any {
  yield takeEvery(
    GETSITEINSPECTIONQUESTIONLIST,
    function* (action: AnyAction): any {
      // set page loading on
      yield put(setLoading(true));
      yield put(clearPageState());
      // get data from backend

      try {
        const InspectionId: number = action.payload;
        const result: ActionResponseType = yield call(() =>
          getSiteInspectionQuestionList(InspectionId)
        );
        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(
              openPopup({
                Open: true,
                BodyText: "No inspection question data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            const inspection: InspectionDTO = result.Data;
            const siteInspectionStartDataType: SiteInspectionStartDataType = {
              Inspection: inspection,
              InspectionName: inspection.Name,
              InspectonDescription: inspection.Description,
              QuestionMainData: createMainDataObject(inspection),
              InspectionMembers: inspection.InspectionMembers,
              SiteTeamMemberName:
                inspection.InspectionMembers.length > 0
                  ? inspection.InspectionMembers[0].MemberName
                  : "",
            } as SiteInspectionStartDataType;
            yield put(setSiteInspectionStartList(siteInspectionStartDataType));
            yield put(setPageMode(EnumPageMode.ViewMode));
            yield put(setLoading(false));
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            // okBtnClick: () => navigate("/standards", {}),
          } as PopupProps)
        );
      }

      // set page loading on
      yield put(setLoading(false));
    }
  );
};

const saveSiteTeamMemberWatcher = function* (): any {
  yield takeEvery(
    SAVEINSPECTIONSITETEAMMEMBER,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      try {
        // save Subject data from backend
        const InspectionMemberParam: Array<InspectionMemberDTO> =
          action.payload;
        const result: ActionResponseType = yield call(() =>
          saveInspectionSiteTeamMember(InspectionMemberParam)
        );

        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "An error occurred when saving data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          const siteTeamMember: Array<InspectionMemberDTO> = result.Data;
          yield put(
            openPopup({
              Open: true,
              BodyText: "Successfully saved",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
              // okBtnClick:  ()=>navigate("/standards", {}),
            } as PopupProps)
          );
          yield put(setPageMode(EnumPageMode.ViewMode));
          yield put(setSiteTeamMember(siteTeamMember));
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            // okBtnClick: () => navigate("/standards", {}),
          } as PopupProps)
        );
      }

      // set page loading
      yield put(setLoading(false));
    }
  );
};

const copyInspectionStandardWatcher = function* (): any {
  yield takeEvery(COPYINSPECTIONSTANDARD, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));

    try {
      // save Subject data from backend
      const inspectionStandardParams: CopyInspectionStandardDTO =
        action.payload;
      const result: ActionResponseType = yield call(() =>
        copyInspectionStandard(inspectionStandardParams)
      );

      if (!result.Data.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.Data.ErrorMessage
              ? result.Data.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );

        yield put(closeCopyPopup(false));
      } else {
        const state: SiteInspectionStartDataType = yield select(
          (s: RootState) => s.siteInspectionStartReducer
        );

        const InspectionId: number = state?.Inspection?.Id;
        const inspectionresult: ActionResponseType = yield call(() =>
          getSiteInspectionQuestionList(InspectionId)
        );

        if (inspectionresult.IsSuccess) {
          const inspection: InspectionDTO = inspectionresult?.Data;
          const siteInspectionStartDataType: SiteInspectionStartDataType = {
            Inspection: inspection,
            InspectionName: inspection.Name,
            InspectonDescription: inspection.Description,
            QuestionMainData: createMainDataObject(inspection),
            InspectionMembers: inspection.InspectionMembers,
            SiteTeamMemberName:
              inspection.InspectionMembers.length > 0
                ? inspection.InspectionMembers[0].MemberName
                : "",
          } as SiteInspectionStartDataType;
          yield put(setSiteInspectionStartList(siteInspectionStartDataType));
          yield put(setPageMode(EnumPageMode.ViewMode));

          yield put(
            openPopup({
              Open: true,
              BodyText: "Successfully copied",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              OkBtnClick: () => window.location.reload(),
            } as PopupProps)
          );
        }
        yield put(closeCopyPopup(true));
        // yield put(setSiteTeamMember(inspectionStandard));
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
          // okBtnClick: () => navigate("/standards", {}),
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};
const resumeInspectionStandardWatcher = function* (): any {
  yield takeEvery(RESUMEINSPECTIONSTANDARD, function* (action: AnyAction): any {
    const inspectionStandardId = action.payload;
    //yield put(setLoading(true));
    const result: ActionResponseType = yield call(() =>
      resumeInspectionStandard(inspectionStandardId)
    );
    //yield put(setLoading(false));
  });
};
const completeSiteInspectionStandardWatcher = function* (): any {
  yield takeEvery(
    COMPLETEINSPECTIONSTANDARD,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));
      // use for page navigation
      try {
        // save Subject data from backend
        const InspectionStandardStatusParam: QuestionnaireDTO = action.payload;
        const result: ActionResponseType = yield call(() =>
          completeInspectionStandard(InspectionStandardStatusParam)
        );

        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "An error occurred when saving data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          yield put(setLoading(false));

          if (
            InspectionStandardStatusParam.InspectionStandardStatus ===
            EnumInspectionStandardStatus.Paused
          ) {
            // TODO document why this block is empty
          } else if (
            InspectionStandardStatusParam.InspectionStandardStatus ===
            EnumInspectionStandardStatus.Completed
          ) {
            let redirectObject = {
              NavigateURl: "/questionnaireSummary",
              NavigateObject: {
                state: {
                  StandardId: InspectionStandardStatusParam.StandardID,
                  SiteInspectionId:
                    InspectionStandardStatusParam.SiteInspectionID,
                  InspectionStandardId:
                    InspectionStandardStatusParam.InspectionStandardId,
                  ReAssess: false,
                } as QuestionnaireSummaryPageParametersType,
              },
            } as RedirectObject;

            yield put(setPageModeRedirectMode(redirectObject));
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      }

      // set page loading
      yield put(setLoading(false));
    }
  );
};

const completeSiteInspectionWatcher = function* (): any {
  yield takeEvery(COMPLETEINSPECTION, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    // use for page navigation
    try {
      // save Subject data from backend
      const InspectionParam: InspectionDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        completeInspection(InspectionParam)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        const result: ActionResponseType = yield call(() =>
          getSiteInspectionQuestionList(InspectionParam.Id)
        );
        if (!result.IsSuccess) {
          yield put(setLoading(false));
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(setLoading(false));
            yield put(
              openPopup({
                Open: true,
                BodyText: "No inspection question data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            const inspection: InspectionDTO = result.Data;
            const siteInspectionStartDataType: SiteInspectionStartDataType = {
              Inspection: inspection,
              InspectionName: inspection.Name,
              InspectonDescription: inspection.Description,
              QuestionMainData: createMainDataObject(inspection),
              InspectionMembers: inspection.InspectionMembers,
              SiteTeamMemberName:
                inspection.InspectionMembers.length > 0
                  ? inspection.InspectionMembers[0].MemberName
                  : "",
            } as SiteInspectionStartDataType;
            yield put(setSiteInspectionStartList(siteInspectionStartDataType));
            yield put(setPageMode(EnumPageMode.ViewMode));
            yield put(setLoading(false));
            yield put(
              setPageModeRedirectMode({
                NavigateURl: "/remediationTrackerTasks",
              } as RedirectObject)
            );
          }
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const reopenCompleteSiteInspectionWatcher = function* (): any {
  yield takeEvery(REOPENCOMPLETEINSPECTION, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    // use for page navigation
    try {
      // save Subject data from backend
      const InspectionParam: QuestionnaireDTO = action.payload;
      const result: ActionResponseType = yield call(() =>
        reOpenCompleteInspection(InspectionParam.SiteInspectionID)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        const result: ActionResponseType = yield call(() =>
          getSiteInspectionQuestionList(InspectionParam.SiteInspectionID)
        );
        if (!result.IsSuccess) {
          yield put(setLoading(false));
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when getting data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(setLoading(false));
            yield put(
              openPopup({
                Open: true,
                BodyText: "No inspection question data to show",
                HeaderText: "Success",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            const inspection: InspectionDTO = result.Data;
            const siteInspectionStartDataType: SiteInspectionStartDataType = {
              Inspection: inspection,
              InspectionName: inspection.Name,
              InspectonDescription: inspection.Description,
              QuestionMainData: createMainDataObject(inspection),
              InspectionMembers: inspection.InspectionMembers,
              SiteTeamMemberName:
                inspection.InspectionMembers.length > 0
                  ? inspection.InspectionMembers[0].MemberName
                  : "",
            } as SiteInspectionStartDataType;
            yield put(setSiteInspectionStartList(siteInspectionStartDataType));
            yield put(setPageMode(EnumPageMode.ViewMode));
            yield put(setLoading(false));
          }
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const reopenSiteInspectionStandardWatcher = function* (): any {
  yield takeEvery(REOPENINSPECTIONSTANDARD, function* (action: AnyAction): any {
    // set page loading
    yield put(setLoading(true));
    // use for page navigation
    try {
      // save Subject data from backend
      const InspectionStandardParam: QuestionnaireSummaryParameterDTO =
        action.payload;
      const result: ActionResponseType = yield call(() =>
        reOpenInspectionStandard(InspectionStandardParam)
      );

      if (!result.IsSuccess) {
        yield put(
          openPopup({
            Open: true,
            BodyText: result.ErrorMessage
              ? result.ErrorMessage
              : "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        let data = result.Data;
        // let redirectObject = {
        //   NavigateURl: "/questionnaireStart",
        //   NavigateObject: {
        //     state: {
        //       InspectionStandardId:
        //         InspectionStandardParam.InspectionStandardId,
        //       StandardId: InspectionStandardParam.StandardId,
        //       InspectionId: InspectionStandardParam.SiteInspectionId,
        //       Title: InspectionStandardParam.InspectionStandardName,
        //       IsContinueButtonVisible: true,
        //     } as QuestionnaireStartPageParametersTypes,
        //   },
        // } as RedirectObject;
        // yield put(setPageModeRedirectMode(redirectObject));
        yield put(
          updateInspectionStandardStatus({
            allowResumption: true,
            idToUpdate: InspectionStandardParam.InspectionStandardId,
            reopenedData: {
              InspectionStandardId: data?.InspectionStandardId,
              ReopenedBy: data?.ReOpenedBy,
              ReopenedDate: data?.ReOpenedDate,
              StatusName: data?.StatusName,
            } as ReopenInspectionStandardData,
          })
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when saving data",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    // set page loading
    yield put(setLoading(false));
  });
};

const createMainDataObject = (inspection: InspectionDTO) => {
  const QuestionMainData = [];
  for (let k = 0; k < inspection.InspectionStandards.length; k++) {
    let MainDataObject = {
      AllQuestionCount: inspection.InspectionStandards[k].TotalQuestionCount,
      AnsweredCount: inspection.InspectionStandards[k].AnswerCount,
      PendingCount: inspection.InspectionStandards[k].PendingCount,
      GapCount: inspection.InspectionStandards[k].GapCount,
      SkippedCount: inspection.InspectionStandards[k].SkipCount,
      InspectionStandardId: inspection.InspectionStandards[k].Id,
      SelectedBuildingId: inspection.InspectionStandards[k].BuildingId,
      Suffix: inspection.InspectionStandards[k].Suffix,
      Location: inspection.InspectionStandards[k].Location,
      RelatedEntity: inspection.InspectionStandards[k].RelatedEntity,
      InspectionStandardName: inspection.InspectionStandards[k].Standard?.Name,
      InspectionStandardStartedDate:
        inspection?.InspectionStandards[k]?.StartedDate,
      InspectionStandardStartedBy:
        inspection?.InspectionStandards[k]?.StartedBy,
      InspectionStandardCompletedDate:
        inspection?.InspectionStandards[k]?.LastCompletedDate,
      InspectionStandardCompletedBy:
        inspection?.InspectionStandards[k]?.ModifiedBy,
      InspectionStandardReOpenedBy:
        inspection?.InspectionStandards[k]?.ReOpenedBy,
      InspectionStandardReOpenedDate:
        inspection?.InspectionStandards[k]?.ReOpenedDate,
      InspectionStandardStatus:
        inspection.InspectionStandards[k]?.InspectionStandardStatusId,
      InspectionStandardStatusName:
        inspection.InspectionStandards[k]?.InspectionStandardStatus?.StatusName,
      GlobalPrimarySME:
        inspection.InspectionStandards[k]?.Standard?.PrimarySME?.Sme
          ?.DisplayName,
      SitePrimarySME:
        inspection.InspectionStandards[k].InspectionStandardSmes?.length > 0
          ? inspection.InspectionStandards[k].InspectionStandardSmes?.map(
              (sme) => sme.Sme.DisplayName
            ).join("; ")
          : inspection.InspectionStandards[k]?.PrimarySme?.DisplayName,
      IsDownloadFile: true,
      //InspectionQuestionStatus: element.InspectionQuestionStatusId,
      StandardId: inspection.InspectionStandards[k].StandardId,
      StandardName: `${inspection.InspectionStandards[k]?.Standard?.Name}${
        inspection.InspectionStandards[k]?.Suffix != null
          ? " - " + inspection.InspectionStandards[k]?.Suffix
          : ""
      }`,
      InspectionId: inspection.InspectionStandards[k].InspectionId,
      ProgressBarValue: inspection.InspectionStandards[k].ProgressValue,
      Buildings: inspection.Site?.Buildings ?? [],
      AnsweredAsNA: inspection.InspectionStandards[k].AnsweredAsNA,
      StandardSMEDropdownList:
        inspection.InspectionStandards[k].StandardSMEDropdownList,
      SelectedSMEList: inspection.InspectionStandards[k].SelectedSMEList,
      IsException: inspection.InspectionStandards[k].IsException,
      AnsweredWithoutApprovalCount:
        inspection.InspectionStandards[k].AnsweredWithoutApprovalCount,
      IsApprovalRequired: inspection.InspectionStandards[k].IsApprovalRequired,
    };

    QuestionMainData.push(MainDataObject);
  }
  return QuestionMainData;
};

const saveAssignedGlobalSMEWatcher = function* (): any {
  yield takeEvery(SAVEASSIGNEDGLOBALSME, function* (action: AnyAction): any {
    yield put(setLoading(true));

    let inspectionStandard: InspectionStandardDTO = action.payload;

    const state: SiteInspectionStartDataType = yield select(
      (s: RootState) => s.siteInspectionStartReducer
    );

    let inspection = JSON.parse(
      JSON.stringify(state.Inspection)
    ) as InspectionDTO;
    let questionMainData = JSON.parse(
      JSON.stringify(state.QuestionMainData)
    ) as Array<QuestionMainDataType>;

    try {
      let result: ActionResponseType = yield call(() =>
        saveAssignedGlobalSMEApi(inspectionStandard)
      );

      if (result.IsSuccess) {
        let inspectionData: InspectionDTO = result.Data;

        let inspectionStandardData: Array<InspectionStandardDTO> =
          inspectionData.InspectionStandards;
        inspection.IsException = inspectionData.IsException;
        inspection.InspectionStandards.forEach((x) => {
          const matchingItem = inspectionStandardData.find(
            (item) => item.Id === x.Id
          );

          if (matchingItem) {
            x.SelectedSMEList = matchingItem.SelectedSMEList;
            x.IsException = matchingItem.IsException;
          }
        });

        questionMainData.forEach((x) => {
          const matchingItem = inspectionStandardData.find(
            (item) => item.Id === x.InspectionStandardId
          );

          if (matchingItem) {
            x.SelectedSMEList = matchingItem.SelectedSMEList;
            x.IsException = matchingItem.IsException;
          }
        });

        yield put(
          openPopup({
            Open: true,
            BodyText: "Global SME Assignment saved successfully",
            HeaderText: "Success",
            PopupType: EnumPopupType.SuccessPopup,
          } as PopupProps)
        );

        yield put(
          setUpdatedSMEList({
            MainData: {
              Inspection: inspection,
              QuestionMainData: questionMainData,
            } as SiteInspectionStartDataType,
            TempInspectionStandard: {} as InspectionStandardDTO,
            ShowGlobalSMEAssignmentPopup: false,
          })
        );
      } else {
        let errorMessage: string = "";
        if (result.ErrorMessage && result.ErrorMessage !== "") {
          errorMessage = result.ErrorMessage;
        } else {
          errorMessage =
            "An error occured when saving the assigned Global SMEs";
        }
        yield put(
          openPopup({
            Open: true,
            BodyText: errorMessage,
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occured when saving the assigned Global SMEs",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }

    yield put(setLoading(false));
  });
};

const getGlobalSMEAssignmentPopupDataWatcher = function* (): any {
  yield takeEvery(
    GETGLOBALSMEASSIGNMENTPOPUPDATA,
    function* (action: AnyAction): any {
      yield put(setLoading(true));

      let inspectionStandard: InspectionStandardDTO = action.payload;

      const state: SiteInspectionStartDataType = yield select(
        (s: RootState) => s.siteInspectionStartReducer
      );

      let inspection = JSON.parse(
        JSON.stringify(state.Inspection)
      ) as InspectionDTO;
      let questionMainData = JSON.parse(
        JSON.stringify(state.QuestionMainData)
      ) as Array<QuestionMainDataType>;

      try {
        let result: ActionResponseType = yield call(() =>
          getGlobalSMEAssignmentPopupDataApi(inspectionStandard)
        );

        if (result.IsSuccess) {
          let inspectionStandardData: InspectionStandardDTO = result.Data;

          inspection.InspectionStandards.forEach((x) => {
            if (x.Id === inspectionStandardData.Id) {
              x.SelectedSMEList = inspectionStandardData.SelectedSMEList;
              x.StandardSMEDropdownList =
                inspectionStandardData.StandardSMEDropdownList;
            }
          });

          questionMainData.forEach((x) => {
            if (x.InspectionStandardId === inspectionStandardData.Id) {
              x.SelectedSMEList = inspectionStandardData.SelectedSMEList;
              x.StandardSMEDropdownList =
                inspectionStandardData.StandardSMEDropdownList;
            }
          });

          let tempinspectionStandard = {
            Id: inspectionStandardData.Id,
            InspectionId: inspectionStandardData.InspectionId,
            InspectionStandardStatusId:
              inspectionStandardData.InspectionStandardStatusId,
            StandardSMEDropdownList:
              inspectionStandardData.StandardSMEDropdownList,
            SelectedSMEList: inspectionStandardData.SelectedSMEList,
          } as InspectionStandardDTO;

          yield put(
            setUpdatedSMEList({
              MainData: {
                Inspection: inspection,
                QuestionMainData: questionMainData,
              } as SiteInspectionStartDataType,
              TempInspectionStandard: tempinspectionStandard,
              ShowGlobalSMEAssignmentPopup: true,
            })
          );
        } else {
          let errorMessage: string = "";
          if (result.ErrorMessage && result.ErrorMessage !== "") {
            errorMessage = result.ErrorMessage;
          } else {
            errorMessage =
              "An error occured when getting the assigned Global SMEs";
          }
          yield put(
            openPopup({
              Open: true,
              BodyText: errorMessage,
              HeaderText: "Error",
              PopupType: EnumPopupType.ErrorPopup,
            } as PopupProps)
          );
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occured when getting the assigned Global SMEs",
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }

      yield put(setLoading(false));
    }
  );
};

const stieInspectionStartPageSaga = function* () {
  yield all([
    getSiteInspectionQuestionListWatcher(),
    saveSiteTeamMemberWatcher(),
    completeSiteInspectionStandardWatcher(),
    copyInspectionStandardWatcher(),
    reopenSiteInspectionStandardWatcher(),
    resumeInspectionStandardWatcher(),
    completeSiteInspectionWatcher(),
    reopenCompleteSiteInspectionWatcher(),
    saveAssignedGlobalSMEWatcher(),
    getGlobalSMEAssignmentPopupDataWatcher(),
  ]);
};

export default stieInspectionStartPageSaga;
