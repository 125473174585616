import { all, takeEvery, put, call, select } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { QuestionCategoryDTO } from "../../../common/types/common.dto.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { RootState } from "../../../core/store";
import { EnumPageMode, EnumStatus } from "../../../common/enums/common.enums";
import {
  GETMULTIPLECHOICE,
  SAVEMULTIPLECHOICE,
} from "./MultipleChoicePage.action";
import {
  getMultipleChoiceByIdApi,
  saveMultipleChoiceApi,
} from "./MultipleChoicePage.api";
import { multipleChoiceReducerActions } from "./MultipleChoicePage.reducer";
import { MultipleChoicePageType } from "./MultipleChoicePage.types";

// Get message for popup when saving multiple choice
const getStatusMessage = (
  statusChange: boolean,
  status: EnumStatus,
  versionChange: boolean
): string => {
  if (!statusChange && versionChange) {
    return "Record updated successfully";
  }

  if (!statusChange) {
    return "Record saved successfully";
  }

  if (status === EnumStatus.Draft) {
    return "Record saved successfully";
  }

  if (status === EnumStatus.Published) {
    return "Record saved successfully";
  }

  if (status === EnumStatus.Deactivated) {
    return "Record deactivated successfully";
  }

  return "Record saved successfully";
};

const getMultipleChoiceWatcher = function* (): any {
  yield takeEvery(GETMULTIPLECHOICE, function* (action: AnyAction): any {
    yield put(setLoading(true));

    let assessmentTypeId: number = action.payload;

    try {
      let result: ActionResponseType = yield call(() =>
        getMultipleChoiceByIdApi(assessmentTypeId)
      );

      if (result.IsSuccess) {
        const assessmentType: QuestionCategoryDTO = result.Data;

        yield put(
          multipleChoiceReducerActions.setMultipleChoice(assessmentType)
        );
        yield put(multipleChoiceReducerActions.setDataRecieved(true));
      } else {
        let errorMessage: string = "";
        if (result.ErrorMessage && result.ErrorMessage !== "") {
          errorMessage = result.ErrorMessage;
        } else {
          errorMessage = "An error occurred when getting data";
        }
        yield put(
          openPopup({
            Open: true,
            BodyText: errorMessage,
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occurred when getting data",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }
    if (assessmentTypeId > 0) {
      yield put(
        multipleChoiceReducerActions.changePageMode(EnumPageMode.ViewMode)
      );
    } else {
      yield put(
        multipleChoiceReducerActions.changePageMode(EnumPageMode.AddMode)
      );
    }
    yield put(setLoading(false));
  });
};

const saveMultipleChoiceWatcher = function* (): any {
  yield takeEvery(SAVEMULTIPLECHOICE, function* (action: AnyAction): any {
    yield put(setLoading(true));

    let assessmentType: QuestionCategoryDTO = action.payload;

    const state: MultipleChoicePageType = yield select(
      (s: RootState) => s.multipleChoiceReducer
    );

    let statusChange = true;
    let versionChnage: boolean = false;
    let prevStatus = state.MultipleChoice.Status;
    let prevVersion = state.MultipleChoice.Version;
    if (prevStatus === assessmentType.Status) {
      statusChange = false;
    }

    try {
      let result: ActionResponseType = yield call(() =>
        saveMultipleChoiceApi(assessmentType)
      );

      if (result.IsSuccess) {
        assessmentType = result.Data;

        if (prevVersion !== assessmentType.Version) {
          versionChnage = true;
        }

        yield put(
          openPopup({
            Open: true,
            BodyText: getStatusMessage(
              statusChange,
              assessmentType.Status,
              versionChnage
            ),
            HeaderText: "Success",
            PopupType: EnumPopupType.SuccessPopup,
          } as PopupProps)
        );

        yield put(
          multipleChoiceReducerActions.setMultipleChoice(assessmentType)
        );
        yield put(
          multipleChoiceReducerActions.changePageMode(EnumPageMode.ViewMode)
        );
      } else {
        let errorMessage: string = "";
        if (result.ErrorMessage && result.ErrorMessage !== "") {
          errorMessage = result.ErrorMessage;
          yield put(
            openPopup({
              Open: true,
              BodyText: errorMessage,
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          errorMessage = "An error occurred when getting data";
          yield put(
            openPopup({
              Open: true,
              BodyText: errorMessage,
              HeaderText: "Error",
              PopupType: EnumPopupType.ErrorPopup,
            } as PopupProps)
          );
        }
      }
    } catch (error) {
      yield put(
        openPopup({
          Open: true,
          BodyText: "An error occured when saving the assessment type",
          HeaderText: "Error",
          PopupType: EnumPopupType.ErrorPopup,
        } as PopupProps)
      );
    }

    yield put(setLoading(false));
  });
};

const multipleChoiceSaga = function* () {
  yield all([getMultipleChoiceWatcher(), saveMultipleChoiceWatcher()]);
};

export default multipleChoiceSaga;
