import { createSlice } from "@reduxjs/toolkit";

import {
  InspectionQuestionChoiceDTO,
  InspectionQuestionDTO,
} from "../../../common/types/common.dto.types";
import {
  ViewInspectionQuestionProps,
  ViewInspectionQuestionType,
} from "./ViewInspectionQuestion.types";
import { EnumQuestionTypesType } from "../../../common/enums/common.enums";

// ==============================|| VIEWS - COMPONENTS - VIEW INSPECTION QUESTION - REDUCER ||============================== //
const initialState = {
  InspectionQuestion: {} as InspectionQuestionDTO,
  QuestionnaireSelectedAnswer: "",
  Open: false,
  SerachInspectionQuestion: { Id: 0 } as InspectionQuestionDTO,
  onCloseBtnClick() {},
} as ViewInspectionQuestionType;

export const viewInspectionQuestionSlice = createSlice({
  name: "viewInspectionQuestion",
  initialState: initialState,
  reducers: {
    setDataFetched: (
      state: ViewInspectionQuestionType,
      action: { payload: ViewInspectionQuestionProps; type: string }
    ) => {
      return {
        ...state,
        InspectionQuestion: {
          ...state.InspectionQuestion,
          Id: action.payload.InspectionQuestionId,
        },
        onCloseBtnClick: action.payload?.onCloseBtnClick,
      };
    },
    setViewInspectionQuestion: (
      state: ViewInspectionQuestionType,
      action: { payload: ViewInspectionQuestionType; type: string }
    ) => {
      let inspectionQuestion: InspectionQuestionDTO =
        action?.payload?.InspectionQuestion;

      let questionType = inspectionQuestion?.QuestionTypeId ?? 0;

      let answerValue = "";
      if (
        questionType === EnumQuestionTypesType.MultipleAnswersSingleChoice ||
        questionType === EnumQuestionTypesType.YesNo
      ) {
        let selectedAnswer =
          inspectionQuestion?.InspectionQuestionChoices?.filter(
            (obj: InspectionQuestionChoiceDTO) => {
              if (obj.IsCorrect) {
                return obj;
              }
            }
          );
        if (selectedAnswer?.length > 0) {
          if (selectedAnswer[0].ChoiceText?.DefaultText) {
            answerValue =
              selectedAnswer[0].ChoiceText.DefaultText?.toLowerCase();
          }
        }
      } else if (questionType === EnumQuestionTypesType.FreeText) {
        answerValue = inspectionQuestion.FreeTextAnswer ?? "";
      }

      return {
        ...state,
        InspectionQuestion: inspectionQuestion,
        QuestionnaireSelectedAnswer: answerValue,
        Open: true,
      };
    },
    viewInspectionQuestionDetails: (
      state: ViewInspectionQuestionType,
      action: { payload: ViewInspectionQuestionProps; type: string }
    ) => {
      return {
        ...state,
        SerachInspectionQuestion: {
          ...state.InspectionQuestion,
          Id: action.payload.InspectionQuestionId,
        },
        onCloseBtnClick: action.payload?.onCloseBtnClick,
      };
    },
    resetViewInspectionQuestion: () => {
      return initialState;
    },
  },
});

export const viewInspectionQuestionSliceReducerActions =
  viewInspectionQuestionSlice.actions;
export const viewInspectionQuestionReducer =
  viewInspectionQuestionSlice.reducer;
