import { SyntheticEvent } from "react";
import { Rating, Tooltip, Typography } from "@mui/material";
import { HPRRatingBarProps } from "./HPRRatingBar.types";
import { Star1 } from "iconsax-react";

const HPRRatingBar = (props: HPRRatingBarProps) => {
  //On rating selection change event
  const onChange = (
    event: SyntheticEvent<Element, Event>,
    newSelection: number
  ) => {
    if (props.onRatingSelectionChange && newSelection > 0) {
      props.onRatingSelectionChange(
        event.currentTarget.getAttribute("name"),
        newSelection
      );
    }
  };

  const getLabelText = (value: number) => {
    const labelObj = props.Tooltips?.find((x) => x.Value === value);

    if (!labelObj) {
      return "";
    }

    return `${value} Star${value !== 1 ? "s" : ""}, ${labelObj.Label}`;
  };

  const iconContainerComponent = (containerProps) => {
    const { value, children } = containerProps;

    const getTooltips = (value: number): string => {
      if (!Array.isArray(props.Tooltips) || props.Tooltips.length === 0) {
        return "";
      }

      const tooltip = props.Tooltips.find((x) => x.Value === value);
      return tooltip ? tooltip.Tooltip : "";
    };

    return (
      <Tooltip
      
        title={`${getTooltips(value)}\u00A0\u00A0`}
        placement="bottom"
        className="rating-component-tooltip"
        slotProps={{
          tooltip: {
            style: {
              position: 'relative',
              top: '25px',
              fontSize: "1rem",
              padding: "4px",
              maxWidth: "100%",
              margin: "0 5%",
              textAlign: "center",
              wordBreak: "normal",
              whiteSpace: "normal",
              overflowWrap: "normal",
              fontFamily: "Assistant-Regular",
            },
          },
        }}
      >
        <span>{children}</span>
      </Tooltip>
    );
  };
  return (
    <div
      className="rating-outer-wrapper text-center"
      // display="flex"
      // flexDirection={props.LabelDirection === "column" ? "column" : "row"}
      // alignItems={props.LabelDirection === "column" ? "flex-start" : "center"}
    >
      <Typography component="legend">{props.Label}</Typography>

      <Rating
        id={props.Id}
        name={props.Name}
        className={props.ClassName}
        value={props.Value}
        precision={props.Precision ?? 1}
        readOnly={props.ReadOnly}
        disabled={props.Disabled}
        max={props.Max}
        getLabelText={getLabelText}
        onChange={onChange}
        // color="red"
        IconContainerComponent={iconContainerComponent}
        icon={<Star1 className="rating-fill" size={props.IconSize ?? 20} />}
        emptyIcon={
          <Star1 className="rating-empty" size={props.IconSize ?? 20} />
        }
      />

      {props.Tooltips?.length > 0 && (
        <div className="block ml-10 body2 text-center indicator-text">
          {
            // Check if hover exists in RatingLabels, otherwise fallback to props.Value
            //props.Tooltips.find((x) => x.Value === hover)?.Label ||
            props.Tooltips.find((x) => x.Value === props.Value)?.Label || ""
          }
        </div>
      )}
    </div>
  );
};

export default HPRRatingBar;
