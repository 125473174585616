import SimpleReactValidator from "simple-react-validator";
import {
  EnumPageMode,
  EnumPageType,
  EnumQuestionTypesType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import {
  DefaultTextDTO,
  DropDownDTO,
  QuestionCategoryChoiceDTO,
  QuestionCategoryDTO,
  StatusDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import {
  MultipleChoicePageParametersType,
  MultipleChoicePageType,
} from "./MultipleChoicePage.types";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  getMultipleChoiceById,
  saveMultipleChoiceWizard,
} from "./MultipleChoicePage.action";
import { multipleChoiceReducerActions } from "./MultipleChoicePage.reducer";
import { Button, Grid, IconButton } from "@mui/material";
import HPRDropDownSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownSingleSelect";
import {
  AddSquare,
  Bag,
  CardSend,
  CardSlash,
  ClipboardText,
  CloseCircle,
  Edit,
  Star1,
} from "iconsax-react";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {
  EnumSystemFunctions,
  EnumSystemFunctionTypes,
} from "../../../core/reducers/functionRights.types";
import { CheckFunctionRights } from "../../../common/functions/common.functions";
import HPRRadioButton from "../../../components/common/HPRRadioButton/HPRRadioButton";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import HPRTextBoxAutoResize from "../../../components/common/HPRTextBox/HPRTextBoxAutoResize";
import React from "react";
import { MultipleChoicesPageParametersType } from "../MultipleChoicesPage/MultipleChoicesPage.types";

const MultipleChoicePage = (props: PageRegistrationProperties) => {
  // registering page properties
  usePageRegistration(props);

  // initial page state object
  const initialState = {
    PageMode: {
      PageMode: EnumPageMode.Default,
      IsDisable: false,
      IsShowSaveBtn: false,
      IsShowCloseBtn: false,
      IsShowUpdateBtn: false,
      IsShowCancelBtn: false,
      IsShowDraftBtn: false,
      IsShowEditBtn: false,
      IsShowPublishedBtn: false,
      IsShowDeactivateBtn: false,
    } as PageModeType,
    MultipleChoice: {
      Id: 0,
      Name: "",
      QuestionTypeId: 0,
      StatusNavigation: { Status1: "Draft" } as StatusDTO,
      Version: 0.1,
      QuestionTypes: [] as DropDownDTO[],
      QuestionCategoryChoices: [] as QuestionCategoryChoiceDTO[],
    } as QuestionCategoryDTO,
    Validator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
      },
    }),
    ChoiceValidator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
      },
    }),
    ShowErrorMsg: false,
    DataRecieved: false,
  } as MultipleChoicePageType;

  // create state for MultipleChoicePageType with initialState values
  const [multipleChoicePageState, setMultipleChoicePageState] =
    useState(initialState);

  // use for page navigation
  const navigate = useNavigate();

  // pass parameters from outside to page
  const location = useLocation();
  const parameter: MultipleChoicePageParametersType = location.state;

  const forceUpdate = React.useReducer((x) => x + 1, 0)[1];

  // reducer manage fucntions
  const multipleChoiceState = useAppSelector(
    (state) => state.multipleChoiceReducer
  );

  const authState = useAppSelector((state) => state.authReducer);

  const dispatch = useAppDispatch();

  // check when one time call to get standard data from backend
  const dataFetchedRef = useRef(false);

  // load page with parameter and with out
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    dispatch(getMultipleChoiceById(parameter.MultipleChoiceId));
  }, []);

  // every time when reducer state change set values to page state
  useEffect(() => {
    setMultipleChoicePageState((prevState) => {
      return {
        ...prevState,
        MultipleChoice: multipleChoiceState.MultipleChoice,
        PageMode: multipleChoiceState.PageMode,
        ShowErrorMsg: false,
      };
    });
  }, [multipleChoiceState]);

  useEffect(() => {
    if (
      parameter?.MultipleChoiceId !== multipleChoicePageState.MultipleChoice.Id
    ) {
      navigate(location.pathname, {
        state: {
          MultipleChoiceId: multipleChoicePageState.MultipleChoice.Id,
        },
      });
      //} else {
      //  setMultipleChoicePageState((prevState) => {
      //    return {
      //      ...prevState,
      //      MultipleChoice: {
      //        ...prevState.MultipleChoice,
      //        StatusNavigation: { Status1: "Draft" } as StatusDTO,
      //        Version: 0.1,
      //      },
      //    };
      //  });
    }
  }, [multipleChoicePageState.MultipleChoice.Id]);

  //-----standard list heigh ------
  const mainHeading = useRef(null);
  // const wrapperCard = useRef(null);

  //Set Hight

  const [multipleChoiceHeight, setMultipleChoiceHeight] = useState(0);

  useEffect(() => {
    let multipleChoiceHeight =
      window.innerHeight - mainHeading?.current?.offsetHeight - 121;
    setMultipleChoiceHeight(multipleChoiceHeight);

    function handleResize() {
      let multipleChoiceHeight =
        window.innerHeight - mainHeading?.current?.offsetHeight - 121;
      setMultipleChoiceHeight(multipleChoiceHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [mainHeading?.current?.offsetHeight]);

  const isMultipleChoiceDirty = () => {
    if (
      multipleChoicePageState?.MultipleChoice?.Name !==
        multipleChoiceState?.MultipleChoice?.Name ||
      multipleChoicePageState?.MultipleChoice?.QuestionTypeId !==
        multipleChoiceState?.MultipleChoice?.QuestionTypeId ||
      multipleChoicePageState?.MultipleChoice?.QuestionCategoryChoices
        ?.length !==
        multipleChoiceState?.MultipleChoice?.QuestionCategoryChoices?.length ||
      multipleChoicePageState?.MultipleChoice?.QuestionCategoryChoices?.some(
        (pageChoice) => {
          const choice =
            multipleChoiceState?.MultipleChoice?.QuestionCategoryChoices.find(
              (choice) => choice.Id === pageChoice.Id
            );
          return (
            pageChoice.DefaultText.DefaultText1 !==
            choice?.DefaultText.DefaultText1
          );
        }
      )
    ) {
      return true;
    }
    return false;
  };

  //determine whether the page is dirty
  useEffect(() => {
    if (multipleChoicePageState?.DataRecieved) {
      if (
        multipleChoicePageState?.PageMode?.PageMode === EnumPageMode.AddMode
      ) {
        if (
          multipleChoicePageState?.MultipleChoice?.Name === "" &&
          multipleChoicePageState?.MultipleChoice?.QuestionTypeId === 0 &&
          multipleChoicePageState?.MultipleChoice?.QuestionCategoryChoices
            ?.length === 1
        ) {
          setIsDirty(false);
        } else {
          setIsDirty(true);
        }
      } else {
        if (isMultipleChoiceDirty()) {
          setIsDirty(true);
        } else {
          setIsDirty(false);
        }
      }
    }
  }, [
    multipleChoicePageState?.MultipleChoice?.Name,
    multipleChoicePageState?.MultipleChoice?.QuestionTypeId,
    multipleChoicePageState?.MultipleChoice?.QuestionCategoryChoices,
    multipleChoicePageState?.DataRecieved,
  ]);

  // useEffect(() => {
  //   multipleChoicePageState?.ChoiceValidator.hideMessageFor(
  //     `choiceText-${
  //       multipleChoicePageState?.MultipleChoice?.QuestionCategoryChoices
  //         ?.length - 1
  //     }`
  //   );
  // }, [multipleChoicePageState?.ChoiceValidator]);

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.MultipleChoices,
      functionId,
      functionTypeId
    );
  };

  const IsPageValid = (): boolean => {
    let isValid = multipleChoicePageState.Validator.allValid();
    let isValidChoices = multipleChoicePageState.ChoiceValidator.allValid();

    if (!isValid || !isValidChoices) {
      // show validation message
      multipleChoicePageState.Validator.showMessages();
      multipleChoicePageState.ChoiceValidator.showMessages();
      forceUpdate();

      return isValid && isValidChoices;
    } else {
      // hide validation message
      multipleChoicePageState.Validator.hideMessages();
      multipleChoicePageState.ChoiceValidator.hideMessages();
    }

    return isValid && isValidChoices;
  };

  const onClickBackButton = () => {
    const tempisDirty = isDirty;

    if (multipleChoicePageState.PageMode.PageMode === EnumPageMode.AddMode) {
      const isDefaultState =
        multipleChoicePageState?.MultipleChoice?.Name === "" &&
        multipleChoicePageState?.MultipleChoice?.QuestionTypeId === 0 &&
        multipleChoicePageState?.MultipleChoice?.QuestionCategoryChoices
          ?.length === 1;

      if (isDefaultState) {
        navigate("/DefineAnswerChoices", {
          state: {
            IsNavigateFromInside: true,
          } as MultipleChoicesPageParametersType,
        });
      } else {
        setIsDirty(false);
        showConfirmationPopup(
          () => {
            setMultipleChoicePageState((prevState) => ({
              ...prevState,
              MultipleChoice: multipleChoiceState?.MultipleChoice,
            }));
            navigate("/DefineAnswerChoices", {
              state: {
                IsNavigateFromInside: true,
              } as MultipleChoicesPageParametersType,
            });
          },
          () => setIsDirty(tempisDirty)
        );
      }
    } else {
      if (isMultipleChoiceDirty()) {
        setIsDirty(false);
        showConfirmationPopup(
          () => {
            setMultipleChoicePageState((values) => ({
              ...values,
              MultipleChoice: multipleChoiceState?.MultipleChoice,
            }));
            dispatch(multipleChoiceReducerActions.resetMultipleChoice());
            navigate("/DefineAnswerChoices", {
              state: {
                IsNavigateFromInside: true,
              } as MultipleChoicesPageParametersType,
            });
          },
          () => setIsDirty(tempisDirty)
        );
      } else {
        dispatch(multipleChoiceReducerActions.resetMultipleChoice());
        navigate("/DefineAnswerChoices", {
          state: {
            IsNavigateFromInside: true,
          } as MultipleChoicesPageParametersType,
        });
      }
    }
  };

  const onChangeChoiceName = (name: string, value: any) => {
    const cleanedValue = value.replace(/[\r\n]+/g, "");
    setMultipleChoicePageState((prevState) => {
      return {
        ...prevState,
        MultipleChoice: {
          ...prevState.MultipleChoice,
          Name: cleanedValue?.replace(/^\s+/g, ""),
        },
      };
    });
  };

  // page unload validations
  const [isDirty, setIsDirty] = useState(false);
  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
    YesBtnClick() {
      dispatch(multipleChoiceReducerActions.resetMultipleChoice());
    },
  } as UsePromptTypes;
  usePrompt(prompt);

  const questionTypeDropDownChange = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string,
    name?: string
  ) => {
    // question types save to state
    const valueCleared =
      selectedValue && selectedValue !== null && selectedValue !== "0";
    setMultipleChoicePageState((prevState) => {
      return {
        ...prevState,
        MultipleChoice: {
          ...prevState.MultipleChoice,
          QuestionTypeId: parseInt(selectedValue),
          QuestionTypes: selectOption,
          QuestionCategoryChoices: !valueCleared
            ? []
            : [
                {
                  Id: 0,
                  DefaultText: { DefaultText1: "" } as DefaultTextDTO,
                  DisplayOrder: 1,
                } as QuestionCategoryChoiceDTO,
              ],
        },
      };
    });
  };

  const ChangeMultipleChoiceText = (name: string, value: any) => {
    let qIndex: number = parseInt(name.split("-")[1]);
    const cleanedValue = value.replace(/[\r\n]+/g, "");

    const editedList: Array<QuestionCategoryChoiceDTO> =
      multipleChoicePageState.MultipleChoice.QuestionCategoryChoices.map(
        (questionChoice: QuestionCategoryChoiceDTO, index: number) => {
          if (index !== qIndex) {
            return questionChoice;
          }

          // Create a shallow copy of `questionChoice`
          const updatedQuestionChoice = { ...questionChoice };

          updatedQuestionChoice.DefaultText = {
            ...questionChoice.DefaultText,
            DefaultText1: cleanedValue,
          };

          return updatedQuestionChoice;
        }
      );

    const isSameChoiceAvailable = editedList.some(
      (choice, index, array) =>
        choice.DefaultText.DefaultText1 !== "" &&
        array.findIndex(
          (item) =>
            item.DefaultText.DefaultText1 === choice.DefaultText.DefaultText1
        ) !== index
    );

    if (!isSameChoiceAvailable) {
      setViewAdd(true);
    }

    //updating state
    setMultipleChoicePageState((values) => {
      return {
        ...values,
        MultipleChoice: {
          ...values.MultipleChoice,
          QuestionCategoryChoices: editedList,
        },
      };
    });

    multipleChoicePageState.ChoiceValidator.hideMessages();
  };

  const [viewAdd, setViewAdd] = useState(true);

  const BlurMultipleChoiceText = (name: string, value: any, event: any) => {
    const deleteClicked = event?.relatedTarget?.name === "deleteButton";
    const isSameChoiceAvailable =
      multipleChoicePageState.MultipleChoice.QuestionCategoryChoices.some(
        (choice, index, array) =>
          choice.DefaultText.DefaultText1 !== "" &&
          array.findIndex(
            (item) =>
              item.DefaultText.DefaultText1 === choice.DefaultText.DefaultText1
          ) !== index
      );

    if (
      isSameChoiceAvailable &&
      deleteClicked !== undefined &&
      !deleteClicked
    ) {
      setViewAdd(false);
      dispatch(
        openPopup({
          Open: true,
          BodyText: "The choice is already available!",
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    } else {
      setViewAdd(true);
    }
  };

  const addMultipleAnswers = (index: number) => {
    const isSameChoiceAvailable =
      multipleChoicePageState.MultipleChoice.QuestionCategoryChoices.some(
        (choice, index, array) =>
          choice.DefaultText.DefaultText1 !== "" &&
          array.findIndex(
            (item) =>
              item.DefaultText.DefaultText1 === choice.DefaultText.DefaultText1
          ) !== index
      );

    if (!isSameChoiceAvailable) {
      const displayOrder =
        multipleChoicePageState.MultipleChoice.QuestionCategoryChoices.length +
        1;
      setMultipleChoicePageState((values) => {
        return {
          ...values,
          MultipleChoice: {
            ...values.MultipleChoice,
            QuestionCategoryChoices: [
              ...values.MultipleChoice.QuestionCategoryChoices,
              {
                Id: 0,
                DefaultText: { DefaultText1: "" } as DefaultTextDTO,
                DisplayOrder: displayOrder,
              } as QuestionCategoryChoiceDTO,
            ],
          },
        };
      });
      multipleChoicePageState.ChoiceValidator.hideMessages();
    }
  };

  const removeMultipleAnswers = (index: number) => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to remove the choice?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          const filteredQuestionChoices: Array<QuestionCategoryChoiceDTO> =
            multipleChoicePageState.MultipleChoice.QuestionCategoryChoices.filter(
              (questionChoices: QuestionCategoryChoiceDTO, qIndex: number) => {
                return qIndex !== index;
              }
            ).map((questionChoice, newIndex) => {
              return {
                ...questionChoice,
                DisplayOrder: newIndex + 1,
              };
            });

          const isSameChoiceAvailable = filteredQuestionChoices.some(
            (choice, index, array) =>
              choice.DefaultText.DefaultText1 !== "" &&
              array.findIndex(
                (item) =>
                  item.DefaultText.DefaultText1 ===
                  choice.DefaultText.DefaultText1
              ) !== index
          );

          if (!isSameChoiceAvailable) {
            setViewAdd(true);
          }

          if (filteredQuestionChoices.length > 0) {
            setMultipleChoicePageState((values) => {
              return {
                ...values,
                MultipleChoice: {
                  ...values.MultipleChoice,
                  QuestionCategoryChoices: filteredQuestionChoices,
                },
              };
            });

            multipleChoicePageState.ChoiceValidator.fields[
              `choiceText-${index}`
            ] = true;
          }
        },
      } as PopupProps)
    );
  };

  const closeButtonClick = () => {
    dispatch(multipleChoiceReducerActions.resetMultipleChoice());

    navigate("/DefineAnswerChoices", {
      state: {
        IsNavigateFromInside: true,
      } as MultipleChoicesPageParametersType,
    });
  };

  const showConfirmationPopup = (
    onConfirm: () => void,
    onCancel: () => void
  ) => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Do you want to discard the changes?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick: onConfirm,
        NoBtnClick: onCancel,
      } as PopupProps)
    );
  };

  const saveAssessmentType = (saveStatus: EnumStatus) => {
    setIsDirty(false);

    if (IsPageValid()) {
      let assessmentType: QuestionCategoryDTO = {} as QuestionCategoryDTO;

      if (saveStatus === EnumStatus.Deactivated) {
        assessmentType = Object.assign({}, multipleChoiceState.MultipleChoice);
      } else {
        assessmentType = Object.assign(
          {},
          multipleChoicePageState.MultipleChoice
        );
      }

      const isSameChoiceAvailable =
        multipleChoicePageState.MultipleChoice.QuestionCategoryChoices.some(
          (choice, index, array) =>
            choice.DefaultText.DefaultText1 !== "" &&
            array.findIndex(
              (item) =>
                item.DefaultText.DefaultText1 ===
                choice.DefaultText.DefaultText1
            ) !== index
        );

      if (isSameChoiceAvailable) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "The choice is already available!",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
      } else {
        assessmentType.Status = saveStatus;
        dispatch(saveMultipleChoiceWizard(assessmentType));
      }
    }
  };

  const cancelButtonClick = () => {
    const tempisDirty = isDirty;

    if (multipleChoicePageState.PageMode.PageMode === EnumPageMode.AddMode) {
      const isDefaultState =
        multipleChoicePageState?.MultipleChoice?.Name === "" &&
        multipleChoicePageState?.MultipleChoice?.QuestionTypeId === 0 &&
        multipleChoicePageState?.MultipleChoice?.QuestionCategoryChoices
          ?.length === 1;

      if (isDefaultState) {
        navigate("/DefineAnswerChoices", {
          state: {
            IsNavigateFromInside: true,
          } as MultipleChoicesPageParametersType,
        });
      } else {
        setIsDirty(false);
        showConfirmationPopup(
          () => {
            setMultipleChoicePageState((prevState) => ({
              ...prevState,
              MultipleChoice: multipleChoiceState?.MultipleChoice,
            }));
            navigate("/DefineAnswerChoices", {
              state: {
                IsNavigateFromInside: true,
              } as MultipleChoicesPageParametersType,
            });
          },
          () => setIsDirty(tempisDirty)
        );
      }
    } else {
      if (isMultipleChoiceDirty()) {
        setIsDirty(false);
        showConfirmationPopup(
          () => {
            setMultipleChoicePageState((values) => ({
              ...values,
              MultipleChoice: multipleChoiceState?.MultipleChoice,
            }));
            dispatch(
              multipleChoiceReducerActions.changePageMode(EnumPageMode.ViewMode)
            );
          },
          () => setIsDirty(tempisDirty)
        );
      } else {
        dispatch(
          multipleChoiceReducerActions.changePageMode(EnumPageMode.ViewMode)
        );
      }
    }
  };

  const editButtonClick = () => {
    dispatch(
      multipleChoiceReducerActions.changePageMode(EnumPageMode.EditMode)
    );
  };

  const deactivateButtonClick = () => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Are you sure you want to deactivate?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          saveAssessmentType(EnumStatus.Deactivated);
        },
      } as PopupProps)
    );
  };

  const prefixIcon = (
    questionTypeId: number,
    questionChoice: QuestionCategoryChoiceDTO
  ) => {
    if (questionTypeId === EnumQuestionTypesType.MultipleAnswersSingleChoice) {
      return (
        <HPRRadioButton
          Id={""}
          Name={""}
          ClassName={""}
          Value={""}
          Disabled={true}
        ></HPRRadioButton>
      );
    } else if (questionTypeId === EnumQuestionTypesType.RatingStar) {
      return (
        <div className="rating-wrapper">
          <div className="star-item d-flex">
            {/* <StarIcon className="large-star" /> */}
            <Star1 size={20} className="rating-fill" />
            <span className="star-amount">{questionChoice.DisplayOrder}</span>
          </div>
        </div>
      );
    } else {
      return (
        <HPRCheckBox
          Id={""}
          Name={""}
          ClassName={"inline-single-element"}
          checked={false}
          Disabled={true}
        ></HPRCheckBox>
      );
    }
  };

  const questionTypeSelectionChange = () => {
    return multipleChoicePageState.MultipleChoice.QuestionCategoryChoices?.map(
      (questionChoice: QuestionCategoryChoiceDTO, index: number) => {
        if (multipleChoicePageState.MultipleChoice.QuestionTypeId !== 0) {
          const isLastChoice =
            (multipleChoicePageState.MultipleChoice.QuestionCategoryChoices
              ?.length || 0) -
              1 ===
            index;

          const isRatingStarQuestion =
            multipleChoicePageState.MultipleChoice.QuestionTypeId ===
            EnumQuestionTypesType.RatingStar;

          const isIndexWithinLimit = index < 9;

          const isViewMode =
            multipleChoicePageState.PageMode.PageMode === EnumPageMode.ViewMode;

          const isChoiceDuplicated =
            multipleChoicePageState.MultipleChoice.QuestionCategoryChoices.some(
              (choice) =>
                choice !== questionChoice &&
                questionChoice?.DefaultText?.DefaultText1 !== "" &&
                choice?.DefaultText?.DefaultText1 ===
                  questionChoice?.DefaultText?.DefaultText1
            );

          const addSpace =
            multipleChoicePageState.MultipleChoice.QuestionCategoryChoices
              .length %
              2 ===
            0;
          return (
            <>
              <div
                className={`d-flex no-wrap sub-question-wrapper two-col multiple-choice ${
                  isChoiceDuplicated ? "duplicate-indicate" : ""
                }`}
                key={`Key${index}`}
              >
                <div className="d-flex flex__item first-set">
                  <div className="multiplechoice-checkbox">
                    {prefixIcon(
                      multipleChoicePageState.MultipleChoice.QuestionTypeId,
                      questionChoice
                    )}
                  </div>

                  <div className="width100-p position-rel">
                    <HPRTextBoxAutoResize
                      Id=""
                      Label=""
                      Name={`choiceText-${index}`}
                      PlaceHolder="Enter Choice here"
                      Value={questionChoice.DefaultText?.DefaultText1}
                      Type={EnumTextBoxType.Text}
                      onTextBoxChange={ChangeMultipleChoiceText}
                      Validator={multipleChoicePageState.ChoiceValidator}
                      onBlurChange={BlurMultipleChoiceText}
                      Rules={"required"}
                      IsEnableValidator={true}
                      AutoFocus
                      //Required={isChoiceDuplicated}
                      Disabled={
                        multipleChoicePageState.PageMode.PageMode ===
                        EnumPageMode.ViewMode
                      }
                      InputProps={{ maxLength: 250 }}
                      TextLength={250}
                      ShowTextCounter
                    ></HPRTextBoxAutoResize>
                  </div>
                </div>
                <div className="gap-separater d-flex flex__item--inherit">
                  {multipleChoicePageState.MultipleChoice
                    .QuestionCategoryChoices?.length !== 1 &&
                    !isViewMode && (
                      <div className="inline-element">
                        <IconButton
                          className="Icon-button-special"
                          name="deleteButton"
                          aria-label="Follow-up Question"
                          onClick={(event) => {
                            event.stopPropagation();
                            removeMultipleAnswers(index);
                          }}
                          disabled={
                            multipleChoicePageState.PageMode.PageMode ===
                            EnumPageMode.ViewMode
                          }
                          title="Delete"
                        >
                          <Bag
                            className="Icon-button-delete"
                            variant="Outline"
                          />
                        </IconButton>
                      </div>
                    )}
                </div>
              </div>
              {isLastChoice &&
              isRatingStarQuestion &&
              isIndexWithinLimit &&
              !isViewMode &&
              viewAdd ? (
                <div className={`add-row ${addSpace ? "ml-15" : ""}`}>
                  <div className="inline-element">
                    <IconButton
                      className="Icon-button-special"
                      aria-label="Follow-up Question"
                      onClick={() => addMultipleAnswers(index)}
                      disabled={
                        multipleChoicePageState.PageMode.PageMode ===
                        EnumPageMode.ViewMode
                      }
                      title="Add"
                    >
                      <AddSquare variant="Outline" />
                    </IconButton>
                  </div>
                </div>
              ) : (
                isLastChoice &&
                !isRatingStarQuestion &&
                !isViewMode &&
                viewAdd && (
                  <div className={`add-row ${addSpace ? "ml-15" : ""}`}>
                    <div className="inline-element">
                      <IconButton
                        className="Icon-button-special"
                        aria-label="Follow-up Question"
                        onClick={() => addMultipleAnswers(index)}
                        disabled={
                          multipleChoicePageState.PageMode.PageMode ===
                          EnumPageMode.ViewMode
                        }
                        title="Add"
                      >
                        <AddSquare variant="Outline" />
                      </IconButton>
                    </div>
                  </div>
                )
              )}
            </>
          );
        } else {
          return (
            <>
              {/* <div className="no-data width100-p flex--container">
                <div className="gray-text body2 flex__item--vcenter nodata-text">No text selected</div>
                </div> */}
            </>
          );
        }
      }
    );
  };

  const publishButtonClick = () => {
    saveAssessmentType(EnumStatus.Published);
  };

  const draftButtonClick = () => {
    saveAssessmentType(EnumStatus.Draft);
  };

  function getChoiceClassName() {
    const isRatingStar =
      multipleChoicePageState.MultipleChoice?.QuestionTypeId ===
      EnumQuestionTypesType.RatingStar;

    const isViewMode =
      multipleChoicePageState.PageMode.PageMode === EnumPageMode.ViewMode;

    if (isRatingStar) {
      return isViewMode
        ? "flex flex-wrap_wrap question-generater-sec rating-com non-editable-sec"
        : "flex flex-wrap_wrap question-generater-sec rating-com editable-sec";
    }

    return isViewMode
      ? "flex flex-wrap_wrap question-generater-sec non-editable-sec"
      : "flex flex-wrap_wrap question-generater-sec editable-sec";
  }

  return (
    <div className="content-section-card top-main-padding">
      {/* Global Sub Header with  Buttons*/}
      <Grid container className="top-header-wrap" ref={mainHeading}>
        <Grid
          container
          columns={12}
          className="global-subhead-btn-wrap top-main-border-inner"
        >
          <Grid item className="global-subhead-wrap">
            <Button
              aria-label="back"
              className="sub-head-back-btn back"
              title="Back to home page"
              onClick={onClickBackButton}
            >
              <ArrowBackIosNewOutlinedIcon className="" />
              <span className="body-bold secondary-color">Back</span>
            </Button>
            <h1>{props.PageTitle}</h1>
            <div className="vl"></div>
            <Grid item className="vlign-middle status-version-wrap">
              <div className="standart-status-change d-flex">
                {" "}
                <div className="standards-status">
                  <span
                    className={
                      multipleChoicePageState.PageMode.PageMode ===
                      EnumPageMode.EditMode
                        ? "status-color edit"
                        : multipleChoicePageState.MultipleChoice.Status ===
                          EnumStatus.Published
                        ? "status-color green"
                        : multipleChoicePageState.MultipleChoice.Status ===
                            EnumStatus.Draft &&
                          multipleChoicePageState.PageMode.PageMode !==
                            EnumPageMode.AddMode
                        ? "status-color yellow"
                        : multipleChoicePageState.MultipleChoice.Status ===
                          EnumStatus.Deactivated
                        ? "status-color gray"
                        : "status-color yellow"
                    }
                  >
                    {multipleChoicePageState.PageMode.PageMode ===
                    EnumPageMode.EditMode
                      ? "Edit"
                      : multipleChoicePageState.MultipleChoice.StatusNavigation
                          ?.Status1}
                  </span>
                </div>
              </div>
              <div className="global-version-top">
                {" "}
                <span>Version:</span>
                {multipleChoicePageState?.MultipleChoice?.Version != null
                  ? multipleChoicePageState?.MultipleChoice.Version.toFixed(1)
                  : "0.1"}
              </div>
            </Grid>
          </Grid>

          <Grid item className="sub-header-btn-wrap">
            {multipleChoicePageState.PageMode.IsShowCancelBtn && (
              <Button
                variant="contained"
                disableElevation
                className="secondary-btn-small"
                size="small"
                startIcon={<CloseCircle variant="Outline" />}
                onClick={cancelButtonClick}
                title="Cancel"
              >
                Cancel
              </Button>
            )}
            {multipleChoicePageState.PageMode.IsShowCloseBtn && (
              <Button
                variant="contained"
                disableElevation
                startIcon={<CloseCircle variant="Outline" />}
                className="secondary-btn-small"
                size="small"
                onClick={closeButtonClick}
                title="Close"
              >
                Close
              </Button>
            )}

            {checkSystemFunctionRight(
              EnumSystemFunctions.MultipleChoicesPage_Deactivate_Multiple_Choices,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              multipleChoicePageState.PageMode.IsShowDeactivateBtn &&
              multipleChoicePageState.MultipleChoice.Status !==
                EnumStatus.Deactivated && (
                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<CardSlash variant="Outline" />}
                  className="primary-btn-small"
                  size="small"
                  onClick={deactivateButtonClick}
                  title="Deactivate"
                >
                  Deactivate
                </Button>
              )}
            {checkSystemFunctionRight(
              EnumSystemFunctions.MultipleChoicesPage_Edit_Multiple_Choices,
              EnumSystemFunctionTypes.ButtonAccess
            ) &&
              multipleChoicePageState.PageMode.IsShowEditBtn &&
              multipleChoicePageState.MultipleChoice.Status !==
                EnumStatus.Deactivated && (
                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<Edit variant="Outline" />}
                  className="primary-btn-small"
                  size="small"
                  onClick={editButtonClick}
                  title="Edit"
                >
                  Edit
                </Button>
              )}
            {(checkSystemFunctionRight(
              EnumSystemFunctions.MultipleChoicesPage_Edit_Multiple_Choices,
              EnumSystemFunctionTypes.ButtonAccess
            ) ||
              checkSystemFunctionRight(
                EnumSystemFunctions.MultipleChoicesPage_Add_Multiple_Choices,
                EnumSystemFunctionTypes.ButtonAccess
              )) &&
              multipleChoicePageState.PageMode.IsShowDraftBtn &&
              multipleChoicePageState.MultipleChoice.Status !==
                EnumStatus.Published && (
                <Button
                  variant="contained"
                  disableElevation
                  className="primary-btn-small"
                  size="small"
                  startIcon={<ClipboardText variant="Outline" />}
                  onClick={draftButtonClick}
                  title="Draft"
                >
                  Draft
                </Button>
              )}
            {(checkSystemFunctionRight(
              EnumSystemFunctions.MultipleChoicesPage_Edit_Multiple_Choices,
              EnumSystemFunctionTypes.ButtonAccess
            ) ||
              checkSystemFunctionRight(
                EnumSystemFunctions.MultipleChoicesPage_Add_Multiple_Choices,
                EnumSystemFunctionTypes.ButtonAccess
              )) &&
              multipleChoicePageState.PageMode.IsShowPublishedBtn && (
                <Button
                  variant="contained"
                  disableElevation
                  className="primary-btn-small"
                  size="small"
                  startIcon={<CardSend variant="Outline" />}
                  onClick={publishButtonClick}
                  title="Publish"
                >
                  Publish
                </Button>
              )}
          </Grid>
        </Grid>
      </Grid>
      {/* Global Sub Header with  Buttons*/}
      <div className="" style={{ height: multipleChoiceHeight }}>
        <Grid container className="bottom-content-wrap height100-p">
          <Grid container className="global-subhead-wrap">
            {/* Button bar */}
            <Grid container spacing={0} className="pl-10 pr-10 wizard-wrapper">
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="pt-0 mt-10"
              >
                {/* Quetion Type */}
                <fieldset>
                  <legend>
                    Choice <span className="red-text"></span>
                  </legend>
                  <Grid container spacing={2}>
                    <Grid item lg={9} md={8} sm={12} xs={12} className="">
                      <div className="form-group mb-0">
                        <HPRTextBoxAutoResize
                          Id={""}
                          Label="Name"
                          Name="Name"
                          PlaceHolder="Type name here"
                          Type={EnumTextBoxType.Text}
                          ClassName={""}
                          Value={multipleChoicePageState?.MultipleChoice?.Name}
                          onTextBoxChange={onChangeChoiceName}
                          Validator={multipleChoicePageState.Validator}
                          Rules={"required"}
                          IsEnableValidator={true}
                          Required
                          Disabled={multipleChoicePageState.PageMode.IsDisable}
                          InputProps={{ maxLength: 250 }}
                          TextLength={250}
                          ShowTextCounter
                        />
                      </div>
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} className="">
                      <div className="form-group">
                        <HPRDropDownSingleSelect
                          Select={
                            multipleChoicePageState.MultipleChoice
                              .QuestionTypeId
                          }
                          Label={"Type"}
                          Name={"QuestionTypeId"}
                          // Url={"Question/GetQuestionTypesForDropDown"}
                          onDropDownChange={questionTypeDropDownChange}
                          Items={
                            multipleChoicePageState.MultipleChoice.QuestionTypes
                          }
                          Validator={multipleChoicePageState.Validator}
                          Rules={"required"}
                          IsEnableValidator={true}
                          IsSearchable={true}
                          Required
                          Disabled={multipleChoicePageState.PageMode.IsDisable}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className={getChoiceClassName()}>
                    {questionTypeSelectionChange()}
                  </div>
                </fieldset>
                {/* Quetion Type End */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MultipleChoicePage;
