/** @format */
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  RadioGroup,
  Tooltip,
} from "@mui/material";

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  ArrowLeft2,
  ArrowRight2,
  Slash,
  TickCircle,
  ShieldTick,
  CloseCircle,
  Note,
  Edit,
  EmptyWallet,
  Bag,
  ArrowCircleDown,
  ArrowCircleUp,
} from "iconsax-react";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import "./QuestionnairePage.scss";
import HPRFileUploader from "../../../components/common/HPRFileUploader/HPRFileUploader";
import { FileUploaderErrorType } from "../../../components/common/HPRFileUploader/HPRFileUploader.types";
import {
  EnumApprovalStatus,
  EnumDataTransferStatus,
  EnumFileType,
  EnumInspectionQuestionStatus,
  EnumInspectionStandardStatus,
  EnumPageMode,
  EnumPageType,
  EnumQuestionTypesType,
} from "../../../common/enums/common.enums";
import {
  InspectionDTO,
  InspectionQuestionApprovalHistoryDetailDTO,
  InspectionQuestionChoiceDTO,
  InspectionQuestionDTO,
  InspectionStandardDTO,
  QuestionnaireDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import {
  QuestionnaireDataType,
  QuestionnaireParametersType,
} from "./QuestionnairePage.types";
import {
  PageModeType,
  RedirectObject,
} from "../../../common/types/common.page.type";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  getSiteInspectionQuestiontData,
  inspectionFileUpload,
  saveInspectionQuestionAnswer,
  saveInspectionStandardStatus,
} from "./QuestionnairePage.action";
import HPRRadioButton from "../../../components/common/HPRRadioButton/HPRRadioButton";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { motion } from "framer-motion";
import {
  setSiteInspectionStandardStatus,
  changeUploadFileListData,
  clearPageModeRedirectMode,
  clearPageState,
  setPageMode,
  setPageReadOnlyMode,
} from "./QuestionnairePage.reducer";
import { QuestionnaireSummaryPageParametersType } from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import { viewInspectionSummarySliceReducerActions } from "../../components/ViewInspectionSummary/ViewInspectionSummary.reducer";
import {
  EnumInspectionSummaryType,
  ViewInspectionSummaryProps,
} from "../../components/ViewInspectionSummary/ViewInspectionSummary.types";
import {
  EnumSystemFunctionTypes,
  EnumSystemFunctions,
} from "../../../core/reducers/functionRights.types";
import {
  CheckFunctionRights,
  isObjectEqualTo,
  formatDateTimeToString,
} from "../../../common/functions/common.functions";
import attachIco3 from "../../../icons/attachment.svg";
import ViewInspectionQuestionHistory from "../../components/ViewInspectionQuestionHistory/ViewInspectionQuestionHistory";
import HPRTextBoxAutoResize from "../../../components/common/HPRTextBox/HPRTextBoxAutoResize";
import { updateQuestionAnswerApprovalStatus } from "./QuestionnairePage.action";
import SimpleReactValidator from "simple-react-validator";
import HPRCheckBox from "../../../components/common/HPRCheckBox/HPRCheckBox";
import HPRRatingBar from "../../../components/common/HPRRatingBar/HPRRatingBar";
import { HPRRatingBarLabelType } from "../../../common/types/common.types";
import { DisabledByDefault, Opacity } from "@mui/icons-material";

const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: false,
    IsShowAddBtn: false,
    IsShowEditBtn: false,
    IsShowDeleteBtn: false,
    IsShowCancelBtn: false,
    IsShowSaveBtn: true,
    IsShowUpdateBtn: false,
    IsPauseBtn: false,
    IsCompleteBtn: false,
    IsShowProgress: false,
    RedirctOption: {
      NavigateURl: "",
      NavigateObject: {} as any,
    } as RedirectObject,
  } as PageModeType,
  Questionnaire: {
    InspectionQuestion: {
      Comment: "",
    },
    StandardID: 0,
    SiteInspectionID: 0,
    CurrentQuestionNumber: 0,
    AllQuestionCount: 0,
    AnsweredCount: 0,
    PendingCount: 0,
    GapCount: 0,
    SkippedCount: 0,
    InspectionStandardName: "",
    QuestionProgressBarValue: 0,
    QuestionText: "",
    InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
    InspectionStandardStatus: 0,
    InspectionStandardStatusId: EnumInspectionStandardStatus.Completed,
    ReadOnlyMode: false,
    FinalQuestionAnswered: false,
    SaveAnswerBeforeClickPrevious: false,
    UploadedFileList: [] as Array<UploadFileDTO>,
    IsFromQuestionnairePge: false,
    ImageFile: {} as any,
    DocumentFile: [] as any,
    RemovedFileIdList: [] as Array<number>,
    IsSiteInspectionViewButtonClick: false,
    ReferenceID: 0,
    IsEnableReviewByGlobalSME: false,
    RejectedNow: false,
  } as QuestionnaireDTO,
  QuestionnaireSaveActionName: "",
  IsQuestionnaireSaveRunning: false,
  QuestionnaireSaveCopy: {} as QuestionnaireDTO,
  ShowPreviousAnswerAndApprovalHistory: false,
} as QuestionnaireDataType;

const QuestionnairePage = (props: PageRegistrationProperties) => {
  usePageRegistration(props);
  // create state for QuestionnairePage with initialState values
  const [questionnairePagePageState, setQuestionnairePageState] =
    React.useState(initialState);
  const [numberList, setNumberList] = useState<InspectionQuestionDTO[] | null>(
    null
  );
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [isScrollableStartPrev, setIsScrollableStartPrev] =
    useState<boolean>(true);
  const [isScrollableEndPrev, setIsScrollableEndPrev] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const authState = useAppSelector((state) => state.authReducer);
  //const [isDirty, setIsDirty] = useState(false);
  // const prompt = {
  //   Message: "Do you want to discard the changes?",
  //   When: isDirty,
  // } as UsePromptTypes;
  // usePrompt(prompt);
  const parameter: QuestionnaireParametersType = location.state;

  const FetchedRef = useRef(false);

  // Horizontal ScrollBar Navigation
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(true);
  const questionContainerRef = useRef<HTMLDivElement>(null);
  const container = questionContainerRef.current
    ? questionContainerRef.current
    : null;
  const ScrollWidth = container ? container.scrollWidth : 0;
  const ClientWidth = container ? container.clientWidth : 0;
  const ScrollLeft = container ? container.scrollLeft : 0;

  const handleScroll = (direction: number) => {
    checkScrollState(direction);
    if (direction === 1) {
      questionContainerRef.current.scrollBy({
        top: 0,
        left: 325,
        behavior: "smooth",
      });
      setIsStart(false);
    } else {
      questionContainerRef.current.scrollBy({
        top: 0,
        left: -325,
        behavior: "smooth",
      });
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  //Reject modal set
  const [openRejectPopup, setRejectPopupOpen] = useState(false);

  const [validator] = useState(
    new SimpleReactValidator({
      messages: {
        default: "Please enter a reason for rejection",
      },
    })
  );

  const handleRejectPopupOpen = () => {
    setRejectReason("");
    setRejectPopupOpen(true);
  };
  const [rejectReason, setRejectReason] = useState("");

  const handleRejectPopupClose = () => {
    setRejectPopupOpen(false);
    setRejectReason("");
    validator.hideMessages();
  };

  const checkScrollState = (direction?: number) => {
    try {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      const isAtStart = scrollLeft - 325 <= 0;
      const isAtEnd = Math.ceil(scrollLeft + clientWidth + 325) >= scrollWidth;
      setIsStart(isAtStart);
      setIsEnd(isAtEnd);
      isAtStart
        ? setIsScrollableStartPrev(isAtStart)
        : setIsScrollableStartPrev(null);
      isAtEnd ? setIsScrollableEndPrev(isAtEnd) : setIsScrollableEndPrev(null);

      if (isAtStart === true && isScrollableStartPrev === true) {
        if (direction === 1) {
          setIsStart(false);
        } else {
          setIsStart(true);
        }
      }
      if (isAtEnd === true && isScrollableEndPrev === true) {
        if (direction === 0) {
          setIsEnd(false);
        } else {
          setIsEnd(true);
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (ScrollWidth > ClientWidth) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
    if (ScrollLeft > 65) {
      setIsStart(false);
      setIsScrollableStartPrev(false);
    } else if (ScrollLeft <= 65) {
      setIsStart(true);
      setIsScrollableStartPrev(true);
    }
    if (ScrollLeft + ClientWidth + 65 <= ScrollWidth) {
      setIsEnd(false);
      setIsScrollableStartPrev(false);
    } else if (ScrollLeft + ClientWidth + 65 > ScrollWidth) {
      setIsEnd(true);
      setIsScrollableStartPrev(true);
    }
  }, [ScrollLeft, ClientWidth, ScrollWidth]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollLeft } = container;

      if (!container) return;

      if (scrollLeft >= 300) {
        setIsStart(false);
        setIsScrollableStartPrev(false);
      } else if (scrollLeft > 0 && scrollLeft <= 150) {
        return;
      } else if (scrollLeft <= 0) {
        setIsStart(true);
        setIsScrollableStartPrev(true);
      }
    };
    setTimeout(() => {
      if (container) {
        container.addEventListener("scroll", handleScroll);
      }
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }, 100);

    // Clear the timeout
    //clearTimeout(timeoutId);
  }, [handleScroll]);

  const dispatch = useAppDispatch();
  const questionnaireState = useAppSelector(
    (state) => state.siteInspectionQuestionnaireReducer
  );

  window.addEventListener("popstate", (ev) => {
    navigate("/questionnaire", {
      state: {
        SiteInspectionID: parameter?.SiteInspectionID,
        StandardID: parameter?.StandardID,
        InspectionStandardId: parameter?.InspectionStandardId,
        ReferenceID: parameter?.ReferenceID,
        InspectionStandardName: parameter?.InspectionStandardName,
      } as QuestionnaireParametersType,
    });
  });

  const backwardTransition = {
    initial: {
      opacity: 0,
      x: "-100%",
    },
    animate: {
      opacity: 1,
      x: "0%",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      x: "100%",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
  };

  const forwardTransition = {
    initial: {
      opacity: 0,
      x: "100%", // swap with '100%'
    },
    animate: {
      opacity: 1,
      x: "0%",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      x: "-100%", // swap with '-100%'
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
  };

  type Transition = typeof backwardTransition | typeof forwardTransition;
  const [questionTransition, setQuestionTransition] =
    useState<Transition>(null);

  const initCountList = {
    All: 0,
    AnsweredWithoutGaps: 0,
    AnsweredWithGaps: 0,
    Skipped: 0,
    Pending: 0,
    AnsweredAsNA: 0,
    Answered: 0,
    InspectionStandardQuestionList: [] as Array<InspectionQuestionDTO>,
  };
  type CountListType = typeof initCountList;
  const [countList, setCountList] = useState<CountListType>(initCountList);

  //-----standard list heigh ------
  const slider = useRef(null);
  const legend = useRef(null);
  const header = useRef(null);
  const footer = useRef(null);

  //Set Hight
  const [qsectionheight, setQsectioHeight] = useState(0);

  //-----Page load-----

  const heightCal = () => {
    let qsectionheight =
      window.innerHeight -
      slider?.current?.offsetHeight -
      legend?.current?.offsetHeight -
      header?.current?.offsetHeight -
      footer?.current?.offsetHeight -
      145;
    setQsectioHeight(qsectionheight);

    function handleResize() {
      let qsectionheight =
        window.innerHeight -
        slider?.current?.offsetHeight -
        legend?.current?.offsetHeight -
        header?.current?.offsetHeight -
        footer?.current?.offsetHeight -
        145;
      setQsectioHeight(qsectionheight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  };
  //------------------------------------

  const handleQuestionRadioChange = (name: string, value: any) => {
    if (parseInt(value) !== -1) {
      let answerId: number = parseInt(name.split("_")[1]);

      let editedList: Array<InspectionQuestionChoiceDTO> =
        questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices.map(
          (questionChoice: InspectionQuestionChoiceDTO) => {
            return {
              ...questionChoice,
              IsCorrect: questionChoice.Id === answerId,
            };
          }
        );

      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire?.InspectionQuestion,
              InspectionQuestionChoices: editedList,
              NotApplicableAnswer: null,
            },
            SaveAnswerBeforeClickPrevious: true,
          },
        };
      });
    } else {
      let editedList: Array<InspectionQuestionChoiceDTO> =
        questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.map(
          (questionChoice: InspectionQuestionChoiceDTO) => {
            return { ...questionChoice, IsCorrect: false };
          }
        );

      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire?.InspectionQuestion,
              InspectionQuestionChoices: editedList,
              FreeTextAnswer: "",
              NotApplicableAnswer: true,
            },
            SaveAnswerBeforeClickPrevious: true,
          },
        };
      });
    }
    //setIsDirty(true);
  };

  const checkSystemFunctionRight = (
    functionId: EnumSystemFunctions,
    functionTypeId: EnumSystemFunctionTypes
  ): boolean => {
    return CheckFunctionRights(
      authState.FunctionRights,
      EnumPageType.Questionnaire,
      functionId,
      functionTypeId
    );
  };

  const OnTextBoxChange = (name: string, value: any, isFreeText?: boolean) => {
    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...values.Questionnaire,
          InspectionQuestion: {
            ...values.Questionnaire?.InspectionQuestion,
            [name]: value ?? "",
            NotApplicableAnswer: isFreeText
              ? false
              : values.Questionnaire?.InspectionQuestion?.NotApplicableAnswer,
          },
          SaveAnswerBeforeClickPrevious: true,
        },
      };
    });
  };

  //Page onload
  useEffect(() => {
    // heightCal();
    if (FetchedRef.current) return;
    FetchedRef.current = true;
    //dispatch(clearPageState());
    if (parameter?.SiteInspectionID > 0 && parameter?.StandardID > 0) {
      dispatch(
        getSiteInspectionQuestiontData({
          StandardID: parameter?.StandardID,
          SiteInspectionID: parameter?.SiteInspectionID,
          IsSiteInspectionViewButtonClick:
            parameter?.IsSiteInspectionViewButtonClick,
          InspectionStandardId: parameter?.InspectionStandardId,
          QuestionnaireStarted: parameter?.QuestionnaireStarted,
          ReferenceID: parameter?.ReferenceID,
          InspectionStandardName: parameter?.InspectionStandardName,
        } as QuestionnaireDTO)
      );
    } else {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: initialState.Questionnaire,
          PageMode: initialState.PageMode,
        };
      });
    }
  }, []);

  useEffect(() => {
    setNumberList(questionnaireState?.Questionnaire?.InspectionQuestionList);
  }, [questionnaireState.Questionnaire?.InspectionQuestionList]);

  useEffect(() => {
    if (numberList && numberList.length > 0) {
      heightCal();
    }
  }, [
    numberList,
    questionnairePagePageState.Questionnaire?.CurrentQuestionNumber,
    questionnairePagePageState.Questionnaire?.InspectionQuestion?.IsApproved,
    questionnairePagePageState.Questionnaire?.InspectionQuestion
      ?.RejectedReason,
    slider?.current?.offsetHeight,
    legend?.current?.offsetHeight,
    header?.current?.offsetHeight,
    footer?.current?.offsetHeight,
  ]);

  // every time when reducer state change set values to page state
  useEffect(() => {
    if (!FetchedRef.current) return;
    if (
      questionnairePagePageState?.Questionnaire?.InspectionQuestion?.Id !==
      questionnaireState?.Questionnaire?.InspectionQuestion?.Id
    ) {
      // setIsDirty(false);
      setTimeout(function () {
        let scrollto = questionnaireState.Questionnaire?.CurrentQuestionNumber;
        let diff = fieldRef.current?.length - scrollto;
        if (
          questionnairePagePageState?.Questionnaire?.CurrentQuestionNumber >
          questionnaireState.Questionnaire?.CurrentQuestionNumber
        ) {
          window.scrollTo(0, 0);
          if (questionnaireState.Questionnaire?.CurrentQuestionNumber <= 5) {
            fieldRef.current[
              questionnaireState.Questionnaire?.CurrentQuestionNumber -
                questionnaireState.Questionnaire?.CurrentQuestionNumber
            ]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else {
            fieldRef.current[scrollto - 6]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        } else {
          window.scrollTo(0, 0);
          if (diff >= 5) {
            fieldRef.current[scrollto + 4]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else if (diff > 2 && diff <= 5) {
            fieldRef.current[scrollto + 1]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else if (diff > 0 && diff <= 2) {
            fieldRef.current[scrollto]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          } else {
            fieldRef.current[scrollto - 1]?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }
      }, 100);
    }

    const { PageMode, Questionnaire } = questionnaireState;
    const { NavigateURl, NavigateObject } =
      PageMode?.RedirctOption ?? ({} as RedirectObject);

    // page state inspection question property
    let {
      Id: pageStateInspectionQuestionId,
      InspectionQuestionChoices: pageStateInspectionQuestionChoices,
      NotApplicableAnswer: pageStateNotApplicableAnswer,
      Comment: pageStateComment,
      ProductImpactAnswer: pageStateProductImpactAnswer,
      FreeTextAnswer: pageStateFreeTextAnswer,
    } = questionnairePagePageState.Questionnaire?.InspectionQuestion;

    // reducer state inspection question property
    const {
      Id: stateInspectionQuestionId,
      InspectionQuestionChoices: stateInspectionQuestionChoices,
      NotApplicableAnswer: stateNotApplicableAnswer,
      Comment: stateComment,
      ProductImpactAnswer: stateProductImpactAnswer,
      FreeTextAnswer: stateFreeTextAnswer,
    } = Questionnaire?.InspectionQuestion;

    let issEditInspectionQuestionAnswer =
      questionnairePagePageState.IsEditInspectionQuestionAnswer ?? false;
    let showPreviousAnswerAndApprovalHistory =
      questionnairePagePageState.ShowPreviousAnswerAndApprovalHistory ?? false;
    let showSources = questionnairePagePageState.ShowSources ?? false;
    // update page state inspection question property when question change
    if (pageStateInspectionQuestionId !== stateInspectionQuestionId) {
      pageStateInspectionQuestionChoices = stateInspectionQuestionChoices ?? [];
      pageStateNotApplicableAnswer = stateNotApplicableAnswer ?? false;
      pageStateComment = stateComment ?? "";
      pageStateProductImpactAnswer = stateProductImpactAnswer ?? false;
      pageStateFreeTextAnswer = stateFreeTextAnswer ?? "";
      issEditInspectionQuestionAnswer = false;
      showPreviousAnswerAndApprovalHistory = false;
      showSources = false;
    }

    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...questionnaireState.Questionnaire,
          InspectionQuestion: {
            ...questionnaireState.Questionnaire?.InspectionQuestion,
            Comment: pageStateComment,
            InspectionQuestionChoices: pageStateInspectionQuestionChoices,
            ProductImpactAnswer: pageStateProductImpactAnswer,
            NotApplicableAnswer: pageStateNotApplicableAnswer,
            FreeTextAnswer: pageStateFreeTextAnswer,
          },
          SaveAnswerBeforeClickPrevious:
            pageStateInspectionQuestionId !== stateInspectionQuestionId
              ? false
              : Questionnaire?.SaveAnswerBeforeClickPrevious,
        },
        PageMode: questionnaireState.PageMode,
        ShowPreviousAnswerAndApprovalHistory:
          showPreviousAnswerAndApprovalHistory,
        IsEditInspectionQuestionAnswer: issEditInspectionQuestionAnswer,
        ShowSources: showSources,
      };
    });

    // set navigate URL
    if (NavigateURl !== "") {
      navigate(NavigateURl, NavigateObject);
      dispatch(clearPageModeRedirectMode());
      FetchedRef.current = false;
      dispatch(clearPageState());
    }

    // set answer count
    setCountList((prevState) => ({
      ...prevState,
      All: Questionnaire?.AllQuestionCount,
      AnsweredWithGaps: Questionnaire?.GapCount,
      AnsweredWithoutGaps: Questionnaire?.AnsweredCount,
      Skipped: Questionnaire?.SkippedCount,
      Pending: Questionnaire?.PendingCount,
      AnsweredAsNA: Questionnaire?.AnsweredAsNA,
      Answered: Questionnaire?.PendingApproveAnsweredCount ?? 0,
    }));
  }, [questionnaireState]);

  useEffect(() => {}, [
    questionnairePagePageState.Questionnaire?.InspectionQuestion
      ?.InspectionQuestionChoices,
  ]);

  const getSelectedImageFiles = (fileList: Array<UploadFileDTO>) => {
    // select files to be removed
    let removefileIdList: Array<number> =
      questionnairePagePageState.Questionnaire?.RemovedFileIdList != null
        ? [...questionnairePagePageState.Questionnaire?.RemovedFileIdList]
        : [];

    questionnairePagePageState.Questionnaire?.UploadedFileList.forEach(
      (item, index: number) => {
        let found = fileList.filter((obj) => {
          return obj.Id === item.Id;
        });

        if (found?.length === 0) {
          removefileIdList.push(item.Id);
        } else {
          // dispatch(updateUploadFilesList(fileList));
        }
      }
    );

    fileList = fileList.map((file) => {
      return {
        ...file,
        InspectionQuestionId:
          questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id,
      };
    });

    dispatch(
      changeUploadFileListData({
        UploadedFileList: fileList,
        RemovedFileIdList: removefileIdList,
        SaveAnswerBeforeClickPrevious: true,
      } as QuestionnaireDTO)
    );

    //setIsDirty(true);
  };

  const uploadFilesToBackend = () => {
    let toBeUploaded: Array<UploadFileDTO> = [] as Array<UploadFileDTO>;
    let inpogressUploaded: UploadFileDTO = {} as UploadFileDTO;

    inpogressUploaded =
      questionnairePagePageState.Questionnaire?.UploadedFileList?.find(
        (file) => file.UploadStatus === EnumDataTransferStatus.InProgress
      );

    if (!inpogressUploaded) {
      toBeUploaded =
        questionnairePagePageState.Questionnaire?.UploadedFileList?.filter(
          (file) => file.UploadStatus === EnumDataTransferStatus.NotStarted
        );
      if (toBeUploaded?.length > 0) {
        dispatch(inspectionFileUpload());
      }
    }
  };

  useEffect(() => {
    uploadFilesToBackend();
  }, [questionnairePagePageState.Questionnaire?.UploadedFileList]);

  const onClickSaveBtn = (saveStatus?: EnumInspectionQuestionStatus): void => {
    //setIsDirty(false);

    const { IsSiteInspectionViewButtonClick, ReadOnlyMode, RejectedNow } =
      questionnairePagePageState?.Questionnaire ?? ({} as QuestionnaireDTO);

    if (!IsSiteInspectionViewButtonClick) {
      if (!isInspectionQuestionAnswered() && !ReadOnlyMode && !RejectedNow) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(
                saveInspectionQuestionAnswer({
                  ...questionnairePagePageState?.Questionnaire,
                  InspectionQuestion: {
                    ...questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion,
                    InspectionQuestionStatusId:
                      EnumInspectionQuestionStatus.Skipped,
                  },
                  SaveAnswerBeforeClickPrevious: true,
                })
              );
            },
          } as PopupProps)
        );
      } else if (saveStatus === EnumInspectionQuestionStatus.Skipped) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(
                saveInspectionQuestionAnswer({
                  ...questionnairePagePageState?.Questionnaire,
                  InspectionQuestion: {
                    ...questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion,
                    InspectionQuestionStatusId:
                      EnumInspectionQuestionStatus.Skipped,
                    NotApplicableAnswer: null,
                    InspectionQuestionChoices: [
                      ...questionnairePagePageState?.Questionnaire
                        ?.InspectionQuestion?.InspectionQuestionChoices,
                    ].map((item) => {
                      return {
                        ...item,
                        IsCorrect: null,
                      };
                    }),
                  },
                  SaveAnswerBeforeClickPrevious: true,
                })
              );
            },
          } as PopupProps)
        );
      } else {
        dispatch(
          saveInspectionQuestionAnswer(
            questionnairePagePageState?.Questionnaire
          )
        );
      }
    } else {
      dispatch(
        saveInspectionQuestionAnswer(questionnairePagePageState?.Questionnaire)
      );
    }
  };

  const onClickPausedBtn = (
    saveStatus?: EnumInspectionStandardStatus,
    isFromPauseBtn?: Boolean
  ): void => {
    //setIsDirty(false);
    let SaveClickPrevious = questionnairePagePageState.Questionnaire
      .ReadOnlyMode
      ? false
      : questionnairePagePageState.Questionnaire?.SaveAnswerBeforeClickPrevious; //SaveAnswerCheck();

    setAnswerCountList(true);

    if (
      isFromPauseBtn &&
      !questionnairePagePageState.Questionnaire
        .IsSiteInspectionViewButtonClick &&
      questionnairePagePageState.Questionnaire?.InspectionStandardStatusId !==
        EnumInspectionStandardStatus.Completed
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Are you sure you want to pause?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setQuestionnairePageState((values) => {
              return {
                ...values,
                Questionnaire: {
                  ...values.Questionnaire,
                  InspectionStandardStatus: saveStatus,
                  FinalQuestionAnswered: SaveClickPrevious,
                  IsReferenceButtonClick: false,
                },
              };
            });
            dispatch(
              saveInspectionStandardStatus({
                ...questionnairePagePageState.Questionnaire,
                InspectionStandardStatus: saveStatus,
                FinalQuestionAnswered: SaveClickPrevious,
                IsReferenceButtonClick: false,
              })
            );
          },
          NoBtnClick() {
            reSetAnswerCountListToOriginal();
          },
        } as PopupProps)
      );
    } else if (
      questionnairePagePageState.Questionnaire?.IsSiteInspectionViewButtonClick
    ) {
      navigate("/siteInspectionStart", {
        state: {
          InspectionId:
            questionnairePagePageState.Questionnaire.SiteInspectionID,
        },
      });
    } else {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionStandardStatus: saveStatus,
            FinalQuestionAnswered: SaveClickPrevious,
            IsReferenceButtonClick: true,
          },
        };
      });
      dispatch(
        saveInspectionStandardStatus({
          ...questionnairePagePageState.Questionnaire,
          InspectionStandardStatus: saveStatus,
          FinalQuestionAnswered: SaveClickPrevious,
          IsReferenceButtonClick: true,
        })
      );
    }
  };

  const onClickCompleteBtn = (
    saveStatus?: EnumInspectionStandardStatus
  ): void => {
    //setIsDirty(false);
    validateAndComplete(saveStatus, true);
  };

  const setAnswerCountList = (isPaused: boolean): CountListType => {
    // current answer count
    let {
      AnsweredCount,
      GapCount,
      SkippedCount,
      AnsweredAsNA,
      PendingCount,
      PendingApproveAnsweredCount,
      InspectionQuestion,
      IsEnableReviewByGlobalSME,
      RejectedNow,
    } = questionnairePagePageState.Questionnaire ?? ({} as QuestionnaireDTO);

    // current answer
    let {
      InspectionQuestionStatusId,
      IsAnswered,
      IsApproved,
      NotApplicableAnswer,
      InspectionQuestionChoices,
      FreeTextAnswer,
      QuestionTypeId,
      SequenceNumber,
    } = InspectionQuestion ?? ({} as InspectionQuestionDTO);

    // previous answer
    const {
      InspectionQuestionStatusId: previousAnswer,
      NotApplicableAnswer: isPreviousAnswerNotApplicable,
    } =
      questionnaireState.Questionnaire?.InspectionQuestion ??
      ({} as InspectionQuestionDTO);

    // check new answer status
    InspectionQuestionStatusId =
      RejectedNow || isPaused
        ? InspectionQuestionStatusId
        : EnumInspectionQuestionStatus.Skipped;

    if (NotApplicableAnswer ?? false) {
      InspectionQuestionStatusId = EnumInspectionQuestionStatus.Answered;
    } else if (
      QuestionTypeId == EnumQuestionTypesType.FreeText &&
      FreeTextAnswer
    ) {
      InspectionQuestionStatusId = EnumInspectionQuestionStatus.Answered;
    } else {
      const newQuestionAnswerChoices = InspectionQuestionChoices?.filter(
        (filter) => filter.IsCorrect ?? false
      );

      if (
        (QuestionTypeId == EnumQuestionTypesType.RatingStar &&
          newQuestionAnswerChoices.some((some) => !some.IsGap)) ||
        (newQuestionAnswerChoices.length > 0 &&
          newQuestionAnswerChoices.filter((filter) => !filter.IsGap)?.length ===
            newQuestionAnswerChoices?.length)
      ) {
        InspectionQuestionStatusId = EnumInspectionQuestionStatus.Answered;
      } else if (newQuestionAnswerChoices.some((some) => some.IsGap)) {
        InspectionQuestionStatusId = EnumInspectionQuestionStatus.Gap;
      }
    }

    // set Answered without Gaps Count
    AnsweredCount =
      InspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
      !NotApplicableAnswer &&
      !(
        previousAnswer === EnumInspectionQuestionStatus.Answered &&
        !isPreviousAnswerNotApplicable
      ) &&
      !IsEnableReviewByGlobalSME
        ? AnsweredCount + 1
        : AnsweredCount;

    if (
      !(
        InspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
        !NotApplicableAnswer
      ) &&
      previousAnswer === EnumInspectionQuestionStatus.Answered &&
      !isPreviousAnswerNotApplicable &&
      AnsweredCount > 0
    ) {
      AnsweredCount = AnsweredCount - 1;
      IsAnswered = false;
    }

    // set Answered with Gaps Count
    GapCount =
      InspectionQuestionStatusId === EnumInspectionQuestionStatus.Gap &&
      !NotApplicableAnswer &&
      !(
        previousAnswer === EnumInspectionQuestionStatus.Gap &&
        !isPreviousAnswerNotApplicable
      ) &&
      !IsEnableReviewByGlobalSME
        ? GapCount + 1
        : GapCount;

    if (
      InspectionQuestionStatusId !== EnumInspectionQuestionStatus.Gap &&
      previousAnswer === EnumInspectionQuestionStatus.Gap &&
      GapCount > 0
    ) {
      GapCount = GapCount - 1;
      IsAnswered = false;
    }

    // set Answered as N/A Count
    AnsweredAsNA =
      InspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
      NotApplicableAnswer &&
      !(
        previousAnswer === EnumInspectionQuestionStatus.Answered &&
        isPreviousAnswerNotApplicable
      ) &&
      !IsEnableReviewByGlobalSME
        ? AnsweredAsNA + 1
        : AnsweredAsNA;

    if (
      !(
        InspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered &&
        NotApplicableAnswer
      ) &&
      previousAnswer === EnumInspectionQuestionStatus.Answered &&
      isPreviousAnswerNotApplicable &&
      AnsweredAsNA > 0
    ) {
      AnsweredAsNA = AnsweredAsNA - 1;
      IsAnswered = false;
    }

    // set Skipped Count
    SkippedCount =
      InspectionQuestionStatusId === EnumInspectionQuestionStatus.Skipped &&
      !NotApplicableAnswer
        ? SkippedCount + 1
        : SkippedCount;

    if (
      InspectionQuestionStatusId === EnumInspectionQuestionStatus.Skipped &&
      SkippedCount > 0 &&
      previousAnswer === EnumInspectionQuestionStatus.Skipped
    ) {
      SkippedCount = SkippedCount - 1;
      IsAnswered = false;
    }

    // set Pending Count
    if (
      previousAnswer === EnumInspectionQuestionStatus.Pending &&
      InspectionQuestionStatusId !== EnumInspectionQuestionStatus.Pending &&
      PendingCount > 0
    ) {
      PendingCount = PendingCount - 1;
      IsAnswered = false;
    }

    // set Pending Approve Answered Count
    if (
      IsEnableReviewByGlobalSME &&
      (InspectionQuestionStatusId === EnumInspectionQuestionStatus.Answered ||
        InspectionQuestionStatusId === EnumInspectionQuestionStatus.Gap) &&
      !IsAnswered
    ) {
      PendingApproveAnsweredCount = PendingApproveAnsweredCount + 1;
      IsAnswered = true;
      IsApproved = false;
    }

    // set number list
    const numberlist = [...numberList].map((item) => {
      if (item.SequenceNumber === SequenceNumber) {
        return {
          ...item,
          InspectionQuestionStatusId: InspectionQuestionStatusId,
          NotApplicableAnswer: NotApplicableAnswer,
          IsAnswered: IsAnswered,
          IsApproved: IsApproved,
        };
      }
      return item;
    });
    setNumberList(numberlist);

    // set count list
    setCountList((prevState) => ({
      ...prevState,
      AnsweredWithoutGaps: AnsweredCount,
      AnsweredWithGaps: GapCount,
      Skipped: SkippedCount,
      Pending: PendingCount,
      AnsweredAsNA: AnsweredAsNA,
      Answered: PendingApproveAnsweredCount,
    }));

    return {
      AnsweredWithoutGaps: AnsweredCount,
      AnsweredWithGaps: GapCount,
      Skipped: SkippedCount,
      Pending: PendingCount,
      AnsweredAsNA: AnsweredAsNA,
      InspectionStandardQuestionList: numberlist,
    } as CountListType;
  };

  const reSetAnswerCountListToOriginal = () => {
    setCountList((prevState) => ({
      ...prevState,
      Pending: questionnaireState.Questionnaire?.PendingCount,
      AnsweredWithoutGaps: questionnaireState.Questionnaire?.AnsweredCount,
      AnsweredWithGaps: questionnaireState.Questionnaire?.GapCount,
      Skipped: questionnaireState.Questionnaire?.SkippedCount,
      AnsweredAsNA: questionnaireState.Questionnaire?.AnsweredAsNA,
      Answered:
        questionnaireState.Questionnaire?.PendingApproveAnsweredCount ?? 0,
    }));

    const numberlist = [...numberList].map((item) => {
      if (
        item.SequenceNumber ===
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          ?.SequenceNumber
      ) {
        return {
          ...item,
          InspectionQuestionStatusId:
            questionnaireState?.Questionnaire?.InspectionQuestion
              ?.InspectionQuestionStatusId,
          NotApplicableAnswer:
            questionnaireState?.Questionnaire?.InspectionQuestion
              ?.NotApplicableAnswer,
        };
      }
      return item;
    });
    setNumberList(numberlist);
  };

  const validateAndComplete = (
    saveStatus?: EnumInspectionStandardStatus,
    FinalQuestionAnswered?: boolean
  ) => {
    const answerCount = setAnswerCountList(false);

    const { InspectionStandardId, SiteInspectionId } =
      questionnairePagePageState.Questionnaire?.InspectionQuestion ??
      ({} as InspectionQuestionDTO);

    dispatch(
      viewInspectionSummarySliceReducerActions.viewInspectionSummaryDetails({
        InspectionSummaryType: EnumInspectionSummaryType.InspectionStandard,
        SerachInspectionStandard: {
          Id: InspectionStandardId,
          InspectionId: SiteInspectionId,
          Inspection: {
            InspectionQuestions: answerCount.InspectionStandardQuestionList,
            AnsweredWithoutGapCount: answerCount?.AnsweredWithoutGaps,
            AnsweredWithGapCount: answerCount?.AnsweredWithGaps,
            AnsweredWithSkipCount: answerCount?.Skipped,
            AnsweredAsNACount: answerCount?.AnsweredAsNA,
            PendingQuestionCount: answerCount?.Pending,
          } as InspectionDTO,
        } as InspectionStandardDTO,
        onCompleteBtnClick(inspectionSummary: InspectionDTO) {
          setQuestionnairePageState((values) => {
            return {
              ...values,
              Questionnaire: {
                ...values.Questionnaire,
                InspectionQuestion: {
                  ...values.Questionnaire?.InspectionQuestion,
                  InspectionQuestionStatusId:
                    values.Questionnaire?.InspectionQuestion
                      .InspectionQuestionStatusId ===
                    EnumInspectionQuestionStatus.Pending
                      ? EnumInspectionQuestionStatus.Skipped
                      : values.Questionnaire?.InspectionQuestion
                          .InspectionQuestionStatusId,
                },
                InspectionStandardStatus: saveStatus,
                FinalQuestionAnswered: FinalQuestionAnswered,
              },
            };
          });
          dispatch(
            saveInspectionStandardStatus({
              ...questionnairePagePageState.Questionnaire,
              InspectionQuestion: {
                ...questionnairePagePageState.Questionnaire?.InspectionQuestion,
                InspectionQuestionStatusId:
                  questionnairePagePageState.Questionnaire?.InspectionQuestion
                    .InspectionQuestionStatusId ===
                  EnumInspectionQuestionStatus.Pending
                    ? EnumInspectionQuestionStatus.Skipped
                    : questionnairePagePageState.Questionnaire
                        .InspectionQuestion?.InspectionQuestionStatusId,
              },
              InspectionStandardStatus: saveStatus,
              FinalQuestionAnswered: FinalQuestionAnswered,
            })
          );
        },
        onCloseBtnClick() {
          reSetAnswerCountListToOriginal();
        },
      } as ViewInspectionSummaryProps)
    );
  };

  const navigationQuestion = (sequence?: number): void => {
    //ignore navigate qustion when click current selection

    const {
      CurrentQuestionNumber,
      ReadOnlyMode,
      SaveAnswerBeforeClickPrevious,
      IsSiteInspectionViewButtonClick,
      RejectedNow,
    } = questionnairePagePageState.Questionnaire ?? ({} as QuestionnaireDTO);

    if (CurrentQuestionNumber === sequence) {
      return;
    }

    // setIsDirty(false);
    if (CurrentQuestionNumber > sequence) {
      setQuestionTransition(backwardTransition);
    } else {
      setQuestionTransition(forwardTransition);
    }
    let SaveClickPrevious = ReadOnlyMode
      ? false
      : SaveAnswerBeforeClickPrevious;

    if (
      !IsSiteInspectionViewButtonClick &&
      !isInspectionQuestionAnswered() &&
      !ReadOnlyMode &&
      !RejectedNow
    ) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: "Are you sure you want to skip?",
          HeaderText: "Confirmation",
          PopupType: EnumPopupType.YesNoConfirmation,
          YesBtnClick() {
            setQuestionnairePageState((values) => {
              return {
                ...values,
                Questionnaire: {
                  ...values.Questionnaire,
                  PendingCount: 0,
                  AnsweredCount: 0,
                  AllQuestionCount: 0,
                  GapCount: 0,
                  SkippedCount: 0,
                  AnsweredAsNA: 0,
                  InspectionQuestion: {
                    ...values.Questionnaire?.InspectionQuestion,
                    //SequenceNumber: sequence,
                    Comment:
                      questionnairePagePageState.Questionnaire
                        .InspectionQuestion?.Comment,
                    InspectionQuestionStatusId:
                      EnumInspectionQuestionStatus.Skipped,
                    //NotApplicableAnswer: null,
                  },
                  InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
                  SaveAnswerBeforeClickPrevious: true,
                  IsFromQuestionnairePge: false,
                },
              };
            });
            dispatch(
              getSiteInspectionQuestiontData({
                ...questionnairePagePageState.Questionnaire,

                PendingCount: 0,
                AllQuestionCount: 0,
                AnsweredCount: 0,
                GapCount: 0,
                SkippedCount: 0,
                AnsweredAsNA: 0,
                InspectionQuestion: {
                  ...questionnairePagePageState.Questionnaire
                    .InspectionQuestion,
                  SequenceNumber: sequence,
                  Comment:
                    questionnairePagePageState.Questionnaire?.InspectionQuestion
                      .Comment,
                  InspectionQuestionStatusId:
                    EnumInspectionQuestionStatus.Skipped,
                  //NotApplicableAnswer: null,
                },
                InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
                SaveAnswerBeforeClickPrevious: true,
                IsFromQuestionnairePge: false,
              })
            );
          },
        } as PopupProps)
      );
    } else {
      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            PendingCount: 0,
            AnsweredCount: 0,
            AllQuestionCount: 0,
            GapCount: 0,
            SkippedCount: 0,
            AnsweredAsNA: 0,
            InspectionQuestion: {
              ...values.Questionnaire?.InspectionQuestion,

              Comment:
                questionnairePagePageState.Questionnaire?.InspectionQuestion
                  .Comment,
            },
            InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
            SaveAnswerBeforeClickPrevious: SaveClickPrevious,
            IsFromQuestionnairePge: false,
          },
        };
      });

      dispatch(
        getSiteInspectionQuestiontData({
          ...questionnairePagePageState.Questionnaire,

          PendingCount: 0,
          AllQuestionCount: 0,
          AnsweredCount: 0,
          GapCount: 0,
          SkippedCount: 0,
          AnsweredAsNA: 0,
          InspectionQuestion: {
            ...questionnairePagePageState.Questionnaire?.InspectionQuestion,
            SequenceNumber: sequence,
            Comment:
              questionnairePagePageState.Questionnaire?.InspectionQuestion
                .Comment,
          },
          InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
          SaveAnswerBeforeClickPrevious: SaveClickPrevious,
          IsFromQuestionnairePge: false,
        })
      );
    }
  };

  const getPreviousInspectionQuestion = (
    Currentsequence?: number,
    SaveQuestion?: boolean,
    questionStatus?: EnumInspectionQuestionStatus
  ) => {
    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...values.Questionnaire,
          PendingCount: 0,
          AnsweredCount: 0,
          AllQuestionCount: 0,
          GapCount: 0,
          SkippedCount: 0,
          InspectionQuestion: {
            ...values.Questionnaire?.InspectionQuestion,
            //SequenceNumber: Currentsequence - 1,
            Comment:
              questionnairePagePageState.Questionnaire?.InspectionQuestion
                .Comment,
            InspectionQuestionStatusId: questionStatus,
          },
          InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
          SaveAnswerBeforeClickPrevious: SaveQuestion,
          IsFromQuestionnairePge: false,
        },
      };
    });
    dispatch(
      getSiteInspectionQuestiontData({
        ...questionnairePagePageState.Questionnaire,
        PendingCount: 0,
        AllQuestionCount: 0,
        AnsweredCount: 0,
        GapCount: 0,
        SkippedCount: 0,
        InspectionQuestion: {
          ...questionnairePagePageState.Questionnaire?.InspectionQuestion,
          SequenceNumber: Currentsequence - 1,
          Comment:
            questionnairePagePageState.Questionnaire?.InspectionQuestion
              ?.Comment,
          InspectionQuestionStatusId: questionStatus,
        },
        InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
        SaveAnswerBeforeClickPrevious: SaveQuestion,
        IsFromQuestionnairePge: false,
      })
    );
  };

  const onClickBackBtn = (
    Currentsequence?: number,
    saveStatus?: EnumInspectionQuestionStatus
  ): void => {
    setQuestionTransition(backwardTransition);
    // setIsDirty(false);

    const {
      ReadOnlyMode,
      SaveAnswerBeforeClickPrevious,
      IsSiteInspectionViewButtonClick,
      InspectionQuestion,
      RejectedNow,
    } = questionnairePagePageState.Questionnaire ?? ({} as QuestionnaireDTO);

    const { InspectionQuestionStatusId } =
      InspectionQuestion ?? ({} as InspectionQuestionDTO);

    let SaveClickPrevious = ReadOnlyMode
      ? false
      : SaveAnswerBeforeClickPrevious;

    if (!IsSiteInspectionViewButtonClick) {
      if (!isInspectionQuestionAnswered() && !ReadOnlyMode && !RejectedNow) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              getPreviousInspectionQuestion(
                Currentsequence,
                true,
                EnumInspectionQuestionStatus.Skipped
              );
            },
          } as PopupProps)
        );
      } else {
        getPreviousInspectionQuestion(
          Currentsequence,
          SaveClickPrevious,
          InspectionQuestionStatusId
        );
      }
    } else {
      getPreviousInspectionQuestion(
        Currentsequence,
        false,
        InspectionQuestionStatusId
      );
    }
  };

  const getError = (error: FileUploaderErrorType) => {
    /* TODO document why this arrow function is empty */
  };

  const fieldRef = useRef([]);

  // fieldRef.current = [];
  const addToRefs: (el) => void = (el) => {
    if (el && !fieldRef.current.includes(el)) {
      fieldRef.current.push(el);
    }
  };

  const getIndicator = (
    inspectionQuestion: InspectionQuestionDTO,
    questionBoxStyle: string
  ) => {
    if (
      questionnairePagePageState.Questionnaire?.IsEnableReviewByGlobalSME &&
      ((inspectionQuestion.InspectionQuestionStatusId ===
        EnumInspectionQuestionStatus.Answered ||
        inspectionQuestion.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Gap ||
        inspectionQuestion.NotApplicableAnswer) ??
        false) &&
      (inspectionQuestion.IsAnswered ?? false) &&
      !(inspectionQuestion.IsApproved ?? false)
    ) {
      return "dark-blue-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestion.InspectionQuestionStatusId ===
        EnumInspectionQuestionStatus.Answered &&
      !(inspectionQuestion.NotApplicableAnswer ?? false)
    ) {
      return "green-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestion.InspectionQuestionStatusId ===
        EnumInspectionQuestionStatus.Answered &&
      (inspectionQuestion.NotApplicableAnswer ?? false)
    ) {
      return "na-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestion.InspectionQuestionStatusId ===
      EnumInspectionQuestionStatus.Gap
    ) {
      return "red-indicator" + questionBoxStyle;
    } else if (
      inspectionQuestion.InspectionQuestionStatusId ===
      EnumInspectionQuestionStatus.Skipped
    ) {
      return "orange-indicator" + questionBoxStyle;
    } else {
      return "light-blue-indicator" + questionBoxStyle;
    }
  };

  const onClickClearAnswerBtn = (
    saveStatus?: EnumInspectionQuestionStatus
  ): void => {
    let editedList: Array<InspectionQuestionChoiceDTO> =
      questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices.map(
        (questionChoice: InspectionQuestionChoiceDTO, index: number) => {
          let updateQuestionChoice: InspectionQuestionChoiceDTO = Object.assign(
            {} as InspectionQuestionChoiceDTO,
            questionChoice
          );
          updateQuestionChoice.IsCorrect = null;
          return updateQuestionChoice;
        }
      );

    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...values.Questionnaire,
          InspectionQuestion: {
            ...values.Questionnaire?.InspectionQuestion,
            InspectionQuestionChoices: editedList,
            FreeTextAnswer: "",
            NotApplicableAnswer: null,
            InspectionQuestionStatusId: EnumInspectionQuestionStatus.Pending,
          },
          SaveAnswerBeforeClickPrevious: true,
          PendingCount: values.Questionnaire?.PendingCount + 1,
        },
      };
    });

    dispatch(
      setSiteInspectionStandardStatus({
        ...questionnaireState.Questionnaire,
        InspectionQuestion: {
          ...questionnaireState.Questionnaire?.InspectionQuestion,
          InspectionQuestionChoices: editedList,
          FreeTextAnswer: "",
          NotApplicableAnswer: null,
          InspectionQuestionStatusId: EnumInspectionQuestionStatus.Pending,
        },
        SaveAnswerBeforeClickPrevious: true,
        PendingCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId !==
          EnumInspectionQuestionStatus.Pending
            ? questionnaireState.Questionnaire?.PendingCount + 1
            : questionnaireState.Questionnaire?.PendingCount,
        SkippedCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Skipped
            ? questionnaireState.Questionnaire?.SkippedCount - 1
            : questionnaireState.Questionnaire?.SkippedCount,
        AnsweredCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId ===
            EnumInspectionQuestionStatus.Answered &&
          questionnaireState.Questionnaire?.InspectionQuestion
            .NotApplicableAnswer === null
            ? questionnaireState.Questionnaire?.AnsweredCount - 1
            : questionnaireState.Questionnaire?.AnsweredCount,
        GapCount:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId === EnumInspectionQuestionStatus.Gap
            ? questionnaireState.Questionnaire?.GapCount - 1
            : questionnaireState.Questionnaire?.GapCount,
        AnsweredAsNA:
          questionnaireState.Questionnaire?.InspectionQuestion
            ?.InspectionQuestionStatusId ===
            EnumInspectionQuestionStatus.Answered &&
          questionnaireState.Questionnaire?.InspectionQuestion
            .NotApplicableAnswer
            ? questionnaireState.Questionnaire?.AnsweredAsNA - 1
            : questionnaireState.Questionnaire?.AnsweredAsNA,
      })
    );

    const numberlist = [...numberList].map((item) => {
      if (
        item.SequenceNumber ===
        questionnairePagePageState.Questionnaire?.InspectionQuestion
          ?.SequenceNumber
      ) {
        return {
          ...item,
          InspectionQuestionStatusId: saveStatus,
          NotApplicableAnswer: null,
        };
      }
      return item;
    });
    setNumberList(numberlist);
  };

  const onClickEditBtn = () => {
    dispatch(setPageMode(EnumPageMode.EditMode));
    dispatch(setPageReadOnlyMode(false));

    setQuestionnairePageState((values) => {
      return { ...values, IsEditInspectionQuestionAnswer: true };
    });
  };

  const onClickQuestionAnswerApproveBtn = () => {
    dispatch(
      updateQuestionAnswerApprovalStatus({
        Id: questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id,
        AnswerApprovalStatus: EnumApprovalStatus.Approved,
        SelectedReferenceID:
          questionnairePagePageState?.Questionnaire?.ReferenceID ?? 0,
      } as InspectionQuestionDTO)
    );
  };
  // Use a reducer to force re-render
  const forceUpdate = React.useReducer((x) => x + 1, 0)[1];

  const onClickQuestionAnswerRejectBtn = () => {
    let isValid = validator.allValid();
    if (!isValid) {
      validator.showMessages();
      forceUpdate();
    } else {
      validator.hideMessages();

      setQuestionnairePageState((values) => {
        return {
          ...values,
          Questionnaire: {
            ...values.Questionnaire,
            InspectionQuestion: {
              ...values.Questionnaire?.InspectionQuestion,
              NotApplicableAnswer: null,
              InspectionQuestionChoices: [
                ...values.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.map(
                  (item) => {
                    return { ...item, IsCorrect: null };
                  }
                ),
              ],
              FreeTextAnswer: "",
            },
          },
        };
      });

      dispatch(
        updateQuestionAnswerApprovalStatus({
          Id: questionnairePagePageState.Questionnaire?.InspectionQuestion?.Id,
          AnswerApprovalStatus: EnumApprovalStatus.Reject,
          RejectedReason: rejectReason,
          SelectedReferenceID:
            questionnairePagePageState?.Questionnaire?.ReferenceID ?? 0,
        } as InspectionQuestionDTO)
      );

      handleRejectPopupClose();
    }
  };

  const onChangeRejectReason = (name: string, value: string) => {
    setRejectReason(value ?? "");
  };

  const onClickGoToFirstBtn = () => {
    const {
      ReadOnlyMode,
      SaveAnswerBeforeClickPrevious,
      InspectionQuestionList,
      IsSiteInspectionViewButtonClick,
      RejectedNow,
      CurrentQuestionNumber,
    } = questionnairePagePageState.Questionnaire ?? ({} as QuestionnaireDTO);

    const lastQuestionNumber =
      InspectionQuestionList[InspectionQuestionList?.length - 1]
        .SequenceNumber ?? 0;

    let sequenceNumber =
      InspectionQuestionList?.find(
        (find) =>
          find.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Pending
      )?.SequenceNumber ?? numberList[0].SequenceNumber;

    let SaveClickPrevious = ReadOnlyMode
      ? false
      : SaveAnswerBeforeClickPrevious;

    if (lastQuestionNumber === CurrentQuestionNumber) {
      // last question is a pending
      if (sequenceNumber === lastQuestionNumber) {
        sequenceNumber = numberList[0].SequenceNumber;
      }

      // check skip
      if (
        !IsSiteInspectionViewButtonClick &&
        !isInspectionQuestionAnswered() &&
        !ReadOnlyMode &&
        !RejectedNow
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Are you sure you want to skip?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              getSelectdQuestionDetails(sequenceNumber, true);
            },
          } as PopupProps)
        );
      } else {
        getSelectdQuestionDetails(sequenceNumber, SaveClickPrevious);
      }
    } else {
      getSelectdQuestionDetails(sequenceNumber, SaveClickPrevious);
    }
  };

  const getSelectdQuestionDetails = (
    sequenceNumber: number,
    SaveClickPrevious: boolean
  ) => {
    dispatch(
      getSiteInspectionQuestiontData({
        ...questionnairePagePageState.Questionnaire,

        PendingCount: 0,
        AllQuestionCount: 0,
        AnsweredCount: 0,
        GapCount: 0,
        SkippedCount: 0,
        AnsweredAsNA: 0,
        InspectionQuestion: {
          ...questionnairePagePageState.Questionnaire?.InspectionQuestion,
          SequenceNumber: sequenceNumber,
          Comment:
            questionnairePagePageState.Questionnaire?.InspectionQuestion
              .Comment,
        },
        InspectionQuestionList: [] as Array<InspectionQuestionDTO>,
        SaveAnswerBeforeClickPrevious: SaveClickPrevious,
        IsFromQuestionnairePge: false,
      })
    );
  };

  const isDisableCompleteBtn = (): boolean => {
    const {
      IsInspectionStandardException,
      IsEnableReviewByGlobalSME,
      InspectionQuestion,
      PendingApproveAnsweredCount,
    } = questionnairePagePageState?.Questionnaire ?? ({} as QuestionnaireDTO);

    // return true
    if (
      IsInspectionStandardException ||
      (questionnairePagePageState.IsEditInspectionQuestionAnswer ?? false)
    )
      return true;

    // return false when IsEnableReviewByGlobalSME flg not enable
    if (!IsEnableReviewByGlobalSME) return false;

    // check edit btn is click
    if (questionnairePagePageState.IsEditInspectionQuestionAnswer ?? false)
      return true;

    // previous state
    const {
      InspectionQuestionChoices: prevInspectionQuestionChoices,
      NotApplicableAnswer: prevNotApplicableAnswer,
      FreeTextAnswer: prevFreeTextAnswer,
      IsAnswered,
      IsApproved,
    } = questionnaireState.Questionnaire?.InspectionQuestion ??
    ({} as InspectionQuestionDTO);

    // new state
    const {
      InspectionQuestionChoices: newInspectionQuestionChoices,
      NotApplicableAnswer: newNotApplicableAnswer,
      FreeTextAnswer: newFreeTextAnswer,
    } = InspectionQuestion ?? ({} as InspectionQuestionDTO);

    const previousQuestionAnswers =
      prevInspectionQuestionChoices?.filter(
        (find) => find.IsCorrect ?? false
      ) ?? ([] as Array<InspectionQuestionChoiceDTO>);

    const newQuestionAnswers =
      newInspectionQuestionChoices?.filter((find) => find.IsCorrect ?? false) ??
      ([] as Array<InspectionQuestionChoiceDTO>);

    return (
      (IsAnswered && !IsApproved) ||
      PendingApproveAnsweredCount !== 0 ||
      !isObjectEqualTo(previousQuestionAnswers, newQuestionAnswers) ||
      (prevNotApplicableAnswer ?? false) !==
        (newNotApplicableAnswer ?? false) ||
      (prevFreeTextAnswer ?? "") !== (newFreeTextAnswer ?? "")
    );
  };

  const onCheckBoxChangeCallBack = (
    choiceData: InspectionQuestionChoiceDTO,
    isChecked: boolean
  ) => {
    const editedChoicesList =
      questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.map(
        (questionChoice: InspectionQuestionChoiceDTO) => {
          return {
            ...questionChoice,
            IsCorrect:
              questionChoice.Id === choiceData.Id
                ? isChecked
                : questionChoice.IsCorrect,
          };
        }
      );

    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...values.Questionnaire,
          InspectionQuestion: {
            ...values.Questionnaire?.InspectionQuestion,
            InspectionQuestionChoices: editedChoicesList,
            NotApplicableAnswer: null,
          },
          SaveAnswerBeforeClickPrevious: true,
        },
      };
    });
  };

  const setShowPreviousAnswerAndApprovalHistory = () => {
    setQuestionnairePageState((values) => {
      return {
        ...values,
        ShowPreviousAnswerAndApprovalHistory:
          !values.ShowPreviousAnswerAndApprovalHistory,
      };
    });
  };

  const setShowSources = () => {
    setQuestionnairePageState((values) => {
      return {
        ...values,
        ShowSources: !values.ShowSources,
      };
    });
  };

  const isShowAnswerChoises = (
    questionTypes: Array<EnumQuestionTypesType>
  ): boolean => {
    const inspectionQuestionType =
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.QuestionTypeId;
    return questionTypes?.includes(inspectionQuestionType);
  };

  const isShowPreviousAnswerChoises = (
    questionTypes: Array<EnumQuestionTypesType>
  ): boolean => {
    const inspectionQuestionType =
      questionnairePagePageState.Questionnaire?.InspectionQuestion
        ?.QuestionTypeId;

    return (
      questionTypes?.includes(inspectionQuestionType) &&
      !questionnairePagePageState?.Questionnaire?.InspectionQuestion
        ?.PreviousAnswerAndApprovalHistory?.PreviousNotApplicableAnswer
    );
  };

  const getHPRRatingBarValue = (
    inspectionQuestionChoices: Array<any>
  ): number => {
    return inspectionQuestionChoices?.filter(
      (filter) => filter.IsCorrect ?? false
    )?.length;
  };

  const onRatingSelectionChangeCallBack = (
    name: string,
    selectedRating: number
  ) => {
    const editedChoicesList =
      questionnairePagePageState.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.map(
        (questionChoice: InspectionQuestionChoiceDTO, index: number) => {
          return {
            ...questionChoice,
            IsCorrect: index + 1 <= selectedRating,
          };
        }
      );

    setQuestionnairePageState((values) => {
      return {
        ...values,
        Questionnaire: {
          ...values.Questionnaire,
          InspectionQuestion: {
            ...values.Questionnaire?.InspectionQuestion,
            InspectionQuestionChoices: editedChoicesList,
            NotApplicableAnswer: null,
          },
          SaveAnswerBeforeClickPrevious: true,
        },
      };
    });
  };

  const isInspectionQuestionAnswered = (): boolean => {
    const { InspectionQuestion } =
      questionnairePagePageState.Questionnaire ?? ({} as QuestionnaireDTO);

    const {
      NotApplicable,
      NotApplicableAnswer,
      InspectionQuestionChoices,
      FreeTextAnswer,
      QuestionTypeId,
    } = InspectionQuestion ?? ({} as InspectionQuestionDTO);

    return (
      (NotApplicable && NotApplicableAnswer) ||
      (QuestionTypeId === EnumQuestionTypesType.FreeText &&
        FreeTextAnswer !== "" &&
        FreeTextAnswer !== null &&
        FreeTextAnswer !== undefined) ||
      InspectionQuestionChoices?.some((choice) => choice.IsCorrect ?? false)
    );
  };

  const isShowClearAnswerButton = (): boolean => {
    if (questionnairePagePageState?.PageMode.IsDisable) {
      return false;
    }

    return (
      checkSystemFunctionRight(
        EnumSystemFunctions.QuestionnairePage_ClearAnswer,
        EnumSystemFunctionTypes.ButtonAccess
      ) && isInspectionQuestionAnswered()
    );
  };

  const isShowApproveAndRejectButton = (): boolean => {
    const {
      IsSiteInspectionViewButtonClick,
      //ReferenceID,
      IsEnableReviewByGlobalSME,
      IsInspectionQuestionApprover,
      InspectionQuestion,
    } = questionnairePagePageState?.Questionnaire ?? ({} as QuestionnaireDTO);

    const { IsAnswered, IsApproved } =
      InspectionQuestion ?? ({} as InspectionQuestionDTO);

    return (
      !IsSiteInspectionViewButtonClick &&
      //ReferenceID === 0 &&
      IsEnableReviewByGlobalSME &&
      IsInspectionQuestionApprover &&
      IsAnswered &&
      !IsApproved
    );
  };

  const isShowCompleteButton = (): boolean => {
    const { IsPauseBtn, IsCompleteBtn } =
      questionnairePagePageState?.PageMode ?? ({} as PageModeType);

    const {
      IsEnableReviewByGlobalSME,
      InspectionStandardStatusId,
      IsSiteInspectionViewButtonClick,
      ReferenceID,
    } = questionnairePagePageState?.Questionnaire ?? ({} as QuestionnaireDTO);

    return (
      ((IsEnableReviewByGlobalSME &&
        InspectionStandardStatusId !==
          EnumInspectionStandardStatus.Completed) ||
        (IsPauseBtn && IsCompleteBtn)) &&
      !IsSiteInspectionViewButtonClick &&
      ReferenceID === 0 &&
      checkSystemFunctionRight(
        EnumSystemFunctions.QuestionnairePage_CompleteStandard,
        EnumSystemFunctionTypes.ButtonAccess
      )
    );
  };

  const isShowEditButton = (): boolean => {
    const { PageMode } =
      questionnairePagePageState?.PageMode ?? ({} as PageModeType);

    const {
      IsEnableReviewByGlobalSME,
      InspectionQuestion,
      IsSiteInspectionViewButtonClick,
    } = questionnairePagePageState?.Questionnaire ?? ({} as QuestionnaireDTO);

    const { IsAnswered, IsApproved } =
      InspectionQuestion ?? ({} as InspectionQuestionDTO);

    return (
      IsEnableReviewByGlobalSME &&
      checkSystemFunctionRight(
        EnumSystemFunctions.QuestionnairePage_OverwriteApprovedAnswers,
        EnumSystemFunctionTypes.ButtonAccess
      ) &&
      IsAnswered &&
      IsApproved &&
      PageMode === EnumPageMode.ViewMode &&
      !IsSiteInspectionViewButtonClick
    );
  };

  const isShowGoToFirstButton = (): boolean => {
    const { InspectionQuestionList } =
      questionnairePagePageState?.Questionnaire ?? ({} as QuestionnaireDTO);

    return (
      !isShowNextButton() &&
      InspectionQuestionList &&
      InspectionQuestionList?.length > 0
    );
  };

  const isShowNextButton = (): boolean => {
    const { InspectionQuestionList, CurrentQuestionNumber } =
      questionnairePagePageState?.Questionnaire ?? ({} as QuestionnaireDTO);

    return (
      InspectionQuestionList?.length > 1 &&
      InspectionQuestionList[InspectionQuestionList?.length - 1]
        ?.SequenceNumber !== CurrentQuestionNumber
    );
  };

  const isShowPreviousButton = (): boolean => {
    const { InspectionQuestionList, CurrentQuestionNumber } =
      questionnairePagePageState?.Questionnaire ?? ({} as QuestionnaireDTO);

    return InspectionQuestionList?.length > 1 && CurrentQuestionNumber > 1;
  };

  const getHPRRatingBarTooltils = (
    inspectionQuestionChoises:
      | Array<InspectionQuestionChoiceDTO>
      | Array<InspectionQuestionApprovalHistoryDetailDTO>
  ): Array<HPRRatingBarLabelType> => {
    return inspectionQuestionChoises?.map(
      (
        choise:
          | InspectionQuestionChoiceDTO
          | InspectionQuestionApprovalHistoryDetailDTO,
        index: number
      ) => {
        return {
          Value: index + 1,
          Label: choise.ChoiceText?.DefaultText,
          Tooltip: choise.ChoiceText?.DefaultText,
        } as HPRRatingBarLabelType;
      }
    );
  };

  const getGoToFirstPendingLabel = (): string => {
    let label = "Go To First";

    const pendingQuestionNumber =
      questionnairePagePageState?.Questionnaire?.InspectionQuestionList?.find(
        (find) =>
          find.InspectionQuestionStatusId ===
          EnumInspectionQuestionStatus.Pending
      )?.SequenceNumber;

    if (
      pendingQuestionNumber &&
      pendingQuestionNumber > 0 &&
      pendingQuestionNumber !==
        questionnairePagePageState.Questionnaire?.CurrentQuestionNumber
    ) {
      label = "Go To First Pending";
    }

    return label;
  };

  const getPreviousAnswerAndApprovalHistoryLabel = (): string => {
    let label = "Previous Answer and Approval History";

    if (questionnairePagePageState.ShowPreviousAnswerAndApprovalHistory) {
      label = "Show less";
    } else if (
      questionnairePagePageState?.Questionnaire?.InspectionQuestion
        ?.PreviousAnswerAndApprovalHistory?.ApprovalStatus ===
        EnumApprovalStatus.Approved &&
      !questionnairePagePageState?.Questionnaire?.InspectionQuestion
        ?.IsOverrideAnswer
    ) {
      label = "Approval History";
    }

    return label;
  };

  const getQuestionNumberClass = (): string => {
    let qNoClass = "";

    const qNoLenght =
      questionnairePagePageState?.Questionnaire?.InspectionQuestion?.SequenceNumber?.toString()
        ?.length;

    switch (qNoLenght) {
      case 1:
        qNoClass = "one-digit";
        break;
      case 2:
        qNoClass = "two-digit";
        break;
      case 3:
        qNoClass = "three-digit";
        break;
      default:
        break;
    }

    return qNoClass;
  };

  return (
    <>
      {questionnairePagePageState?.Questionnaire?.InspectionQuestion
        .QuestionId ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="custom-question-nav-wrap" ref={slider}>
                  <div className="">
                    {isScrollable && !isStart && (
                      <div
                        className="q-nav button"
                        onClick={() => {
                          handleScroll(0);
                        }}
                        style={{ marginLeft: "-14px" }}
                      >
                        <ArrowLeft2 className="prev" />
                        <span className="mr-10 prev">
                          Previous <br />
                          QN(s)
                        </span>
                      </div>
                    )}
                  </div>

                  <div
                    className={`question-container ${
                      isScrollable ? "question-container" : ""
                    }`}
                    ref={questionContainerRef}
                  >
                    {numberList &&
                      numberList.map((list, index) => {
                        let questionBoxStyle: string =
                          list.SequenceNumber ===
                          questionnairePagePageState.Questionnaire
                            .CurrentQuestionNumber
                            ? " current-item-blue-border"
                            : "";

                        return (
                          <div
                            key={index}
                            className={"question-item-wrapper"}
                            onClick={() =>
                              navigationQuestion(list.SequenceNumber)
                            }
                            ref={addToRefs}
                          >
                            <span
                              className={getIndicator(list, questionBoxStyle)}
                            >
                              {list.SequenceNumber}
                              <span
                                className="attachment-indicator"
                                style={{
                                  display: list.IsAttachmentAvailable
                                    ? "flex"
                                    : "none",
                                }}
                              >
                                {/* <AttachFileIcon></AttachFileIcon> */}
                                <img src={attachIco3} height={20} />
                              </span>
                            </span>
                          </div>
                        );
                      })}
                  </div>
                  <div className="m-l-3">
                    {isScrollable && !isEnd && (
                      // <Tooltip title="Next Question">
                      <div
                        className="q-nav"
                        onClick={() => handleScroll(1)}
                        style={{ marginRight: "-14px" }}
                      >
                        <span className="ml-10">
                          Next <br />
                          QN(s)
                        </span>
                        <div className="next">
                          <ArrowRight2 />
                        </div>
                      </div>
                      // </Tooltip>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            {/*Counts bar start*/}

            <Grid container>
              <div className="legend-wrapper" ref={legend}>
                <ul>
                  <li>
                    <a>
                      <span className="color-code-legend green-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.AnsweredWithoutGaps})
                        </span>
                        No Gaps
                      </label>
                    </a>
                  </li>

                  <li>
                    <a>
                      <span className="color-code-legend red-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.AnsweredWithGaps})
                        </span>
                        Gaps
                      </label>
                    </a>
                  </li>

                  <li>
                    <a>
                      <span className="color-code-legend na-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.AnsweredAsNA})
                        </span>
                        N/A
                      </label>
                    </a>
                  </li>

                  <li>
                    <a>
                      <span className="color-code-legend orange-indicator"></span>
                      <label className="legend-description">
                        <span className="q-quantity">
                          ({countList.Skipped})
                        </span>
                        Skipped
                      </label>
                    </a>
                  </li>
                  <li>
                    {questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion.InspectionStandard
                      ?.InspectionStandardStatusId !==
                    EnumInspectionStandardStatus.Completed ? (
                      <a>
                        <span className="color-code-legend blue-indicator"></span>
                        <label className="legend-description">Current</label>
                      </a>
                    ) : (
                      <></>
                    )}
                  </li>
                  <li>
                    {questionnairePagePageState?.Questionnaire
                      ?.InspectionQuestion.InspectionStandard
                      ?.InspectionStandardStatusId !==
                    EnumInspectionStandardStatus.Completed ? (
                      <a>
                        <span className="color-code-legend light-blue-indicator"></span>
                        <label className="legend-description">
                          <span className="q-quantity">
                            ({countList.Pending})
                          </span>
                          Pending
                        </label>
                      </a>
                    ) : (
                      <></>
                    )}
                  </li>
                  {questionnairePagePageState.Questionnaire
                    ?.IsEnableReviewByGlobalSME && (
                    <li>
                      <a>
                        <span className="color-code-legend dark-blue-indicator"></span>
                        <label className="legend-description">
                          <span className="q-quantity">
                            ({countList.Answered})
                          </span>
                          Answered
                        </label>
                      </a>
                    </li>
                  )}
                </ul>
              </div>

              <div className="flex-right valign-middle site-ins-progress-test">
                {/* {questionnairePagePageState?.PageMode.IsShowProgress ? ( */}
                {questionnaireState?.Questionnaire
                  ?.InspectionStandardStatusId !==
                  EnumInspectionStandardStatus.Completed &&
                questionnairePagePageState?.Questionnaire?.ReferenceID === 0 ? (
                  <span className="question-num-wrapper align-right margin-right-15">
                    Progress:{" "}
                    <span className="cur-question-numv">
                      {countList.AnsweredWithoutGaps +
                        countList.AnsweredWithGaps +
                        countList.AnsweredAsNA}
                    </span>
                    <span className="total-question-num">/{countList.All}</span>
                  </span>
                ) : (
                  <></>
                )}

                {questionnairePagePageState?.Questionnaire?.InspectionQuestion
                  ?.InspectionStandard?.InspectionStandardStatusId ===
                EnumInspectionStandardStatus.Completed ? (
                  <label className="audit-qty-lbl red-text align-right">
                    <span></span>
                    {questionnairePagePageState.Questionnaire?.SkippedCount +
                      questionnairePagePageState.Questionnaire?.GapCount +
                      " Total Gaps"}
                  </label>
                ) : (
                  <></>
                )}
                {questionnaireState?.Questionnaire
                  ?.InspectionStandardStatusId !==
                  EnumInspectionStandardStatus.Completed &&
                questionnairePagePageState?.Questionnaire?.ReferenceID > 0 ? (
                  <span className="question-num-wrapper align-right margin-right-15">
                    Selection Progress:{" "}
                    <span className="cur-question-numv">
                      {countList.AnsweredWithoutGaps +
                        countList.AnsweredWithGaps +
                        countList.AnsweredAsNA}
                    </span>
                    <span className="total-question-num">/{countList.All}</span>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </Grid>

            {/*Counts bar end*/}

            <Grid container>
              <Grid item xs={12}>
                <div className="item-wrapper questionaire-wrap mb-0">
                  <div className="item-header" ref={header}>
                    <Grid container>
                      <Grid item xl={8} lg={8} md={7} sm={12} xs={12}>
                        <h1 className="fx v-align">
                          <a
                            className="cursor-pointer audit-back v-align mr-10"
                            style={{ display: "inline-flex" }}
                            onClick={() =>
                              onClickPausedBtn(
                                EnumInspectionStandardStatus.Paused,
                                true
                              )
                            }
                            title={"Back To Site Inspection"}
                          >
                            <ArrowBackIosNewOutlinedIcon className="" />
                            <span className="body-bold secondary-color flex__item--vcenter">
                              Back
                            </span>
                          </a>
                          <Tooltip
                            title={`${questionnairePagePageState.Questionnaire?.InspectionName} : ${questionnairePagePageState.Questionnaire.InspectionStandardName}`}
                          >
                            <span className="limit-1">
                              {`${questionnairePagePageState.Questionnaire?.InspectionName} : ${questionnairePagePageState.Questionnaire.InspectionStandardName}`}
                            </span>
                          </Tooltip>
                        </h1>
                      </Grid>
                      <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                        {/* Reference selection button */}
                        {questionnairePagePageState?.Questionnaire
                          ?.ReferenceID > 0 && (
                          <Button
                            className="primary-btn-small align-right" // change 'primary' -> 'primary-btn-small'
                            variant="contained"
                            disableElevation
                            onClick={() =>
                              onClickPausedBtn(
                                EnumInspectionStandardStatus.Paused,
                                false
                              )
                            }
                          >
                            Reference selection
                          </Button>
                        )}

                        {/* Complete button */}
                        {isShowCompleteButton() && (
                          <Button
                            startIcon={<ShieldTick variant="Outline" />} // add icon
                            className={
                              isDisableCompleteBtn()
                                ? "primary-btn-small  align-right disable-btn" // change 'primary' -> 'primary-btn-small'
                                : "primary-btn-small  align-right" // change 'primary' -> 'primary-btn-small'
                            }
                            variant="contained"
                            disableElevation
                            onClick={() =>
                              onClickCompleteBtn(
                                EnumInspectionStandardStatus.Completed
                              )
                            }
                            disabled={isDisableCompleteBtn()}
                          >
                            Complete
                          </Button>
                        )}

                        {/* Approve & Reject button */}
                        {isShowApproveAndRejectButton() && (
                          <>
                            {checkSystemFunctionRight(
                              EnumSystemFunctions.QuestionnairePage_GlobalSMEReject,
                              EnumSystemFunctionTypes.ButtonAccess
                            ) && (
                              <Button
                                className="primary-btn-small align-right"
                                disableElevation
                                size="small"
                                variant="outlined"
                                startIcon={
                                  <Slash variant="Outline" size={20} />
                                }
                                onClick={handleRejectPopupOpen}
                              >
                                Reject
                              </Button>
                            )}

                            {checkSystemFunctionRight(
                              EnumSystemFunctions.QuestionnairePage_GlobalSMEApprove,
                              EnumSystemFunctionTypes.ButtonAccess
                            ) && (
                              <Button
                                className="primary-btn-small align-right"
                                disableElevation
                                size="small"
                                variant="outlined"
                                startIcon={
                                  <TickCircle variant="Outline" size={20} />
                                }
                                onClick={onClickQuestionAnswerApproveBtn}
                              >
                                Approve
                              </Button>
                            )}
                          </>
                        )}

                        {/* Edit button */}
                        {isShowEditButton() && (
                          <Button
                            className="primary-btn-small align-right"
                            variant="outlined"
                            disableElevation
                            onClick={onClickEditBtn}
                            startIcon={<Edit variant="Outline" />}
                            size="small"
                          >
                            Edit
                          </Button>
                        )}

                        {/* Summary button */}
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion.InspectionStandard
                          ?.InspectionStandardStatusId ===
                          EnumInspectionStandardStatus.Completed &&
                          questionnairePagePageState?.Questionnaire
                            ?.ReferenceID === 0 && (
                            <Button
                              startIcon={<Note variant="Outline" />} // add icon
                              className="primary-btn-small  align-right" // change 'primary' -> 'primary-btn-small'
                              variant="contained"
                              disableElevation
                              onClick={() =>
                                navigate("/questionnaireSummary", {
                                  state: {
                                    StandardId:
                                      questionnairePagePageState.Questionnaire
                                        .StandardID,
                                    SiteInspectionId:
                                      questionnairePagePageState.Questionnaire
                                        .SiteInspectionID,
                                    InspectionStandardId:
                                      questionnairePagePageState.Questionnaire
                                        .InspectionStandardId,
                                    ReAssess: false,
                                  } as QuestionnaireSummaryPageParametersType,
                                })
                              }
                            >
                              Summary
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  </div>
                  {/*Start of question*/}
                  <motion.div
                    key={
                      questionnairePagePageState?.Questionnaire
                        ?.InspectionQuestion?.QuestionId
                    }
                    className="item-content question-conent"
                    variants={questionTransition}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    style={{ height: qsectionheight, overflowY: "auto" }}
                  >
                    {/*Start of question*/}
                    <div className="questionair-outer-wrapper">
                      <Grid container className="pl-15 pr-15">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <label className="question-label">
                            <span className="question-num">
                              {questionnairePagePageState
                                ? questionnairePagePageState?.Questionnaire
                                    ?.InspectionQuestion?.SequenceNumber
                                : ""}
                              {questionnairePagePageState &&
                              questionnairePagePageState.Questionnaire
                                .InspectionQuestion?.SequenceNumber
                                ? "."
                                : ""}
                            </span>
                            <pre className="question-text mb-0">
                              {
                                questionnairePagePageState?.Questionnaire
                                  ?.QuestionText
                              }
                            </pre>
                          </label>
                        </Grid>
                      </Grid>

                      <Grid container className="mt-5 pl-15 pr-15">
                        <Grid item xs={12}>
                          <div
                            className={`width-auto question-answer-new flex-wrap_wrap radio-checkbox-align ${getQuestionNumberClass()}`}
                          >
                            {isShowAnswerChoises([
                              EnumQuestionTypesType.YesNo,
                              EnumQuestionTypesType.MultipleAnswersSingleChoice,
                            ]) && (
                              <RadioGroup
                                name="QuestionAnswer"
                                className="flex--container width100-p"
                                value={
                                  questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.find(
                                    (find) => find.IsCorrect ?? false
                                  )?.Id?.toString() ?? ""
                                }
                                onChange={(event) =>
                                  handleQuestionRadioChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                              >
                                {questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.map(
                                  (choiceData, index) => {
                                    return (
                                      <span
                                        className="sub-question-wrapper block two-col"
                                        key={`singleQuestionAnswer_${index}`}
                                      >
                                        <HPRRadioButton
                                          key={index}
                                          Name={
                                            "QuestionAnswer_" + choiceData.Id
                                          }
                                          Disabled={
                                            questionnairePagePageState?.PageMode
                                              ?.IsDisable
                                          }
                                          Value={choiceData.Id?.toString()}
                                          Label={
                                            choiceData.ChoiceText.DefaultText
                                          }
                                          ClassName={"default-label valign-top"}
                                        ></HPRRadioButton>
                                      </span>
                                    );
                                  }
                                )}
                              </RadioGroup>
                            )}

                            {isShowAnswerChoises([
                              EnumQuestionTypesType.MultiplAnswersMultipleChoice,
                            ]) && (
                              <div className="flex--container flex-wrap_wrap width100-p mb-10">
                                {questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionChoices?.map(
                                  (choiceData, index) => {
                                    return (
                                      <span
                                        className="sub-question-wrapper block two-col"
                                        key={`multipleQuestionAnswer_${index}`}
                                      >
                                        <HPRCheckBox
                                          key={index}
                                          Name={
                                            "MultipleQuestionAnswer_" + index
                                          }
                                          Disabled={
                                            questionnairePagePageState?.PageMode
                                              ?.IsDisable
                                          }
                                          checked={
                                            choiceData.IsCorrect ?? false
                                          }
                                          Label={
                                            choiceData.ChoiceText.DefaultText
                                          }
                                          ClassName={"default-label valign-top"}
                                          onCheckBoxChange={(name, checked) => {
                                            onCheckBoxChangeCallBack(
                                              choiceData,
                                              checked
                                            );
                                          }}
                                        ></HPRCheckBox>
                                      </span>
                                    );
                                  }
                                )}
                              </div>
                            )}

                            {isShowAnswerChoises([
                              EnumQuestionTypesType.FreeText,
                            ]) && (
                              <div className="audit-sub-section">
                                <div className="form-group site-inspec-textarea mb-0">
                                  <HPRTextBoxAutoResize
                                    Id={"freeTextAnswer"}
                                    Label={""}
                                    Name={"FreeTextAnswer"}
                                    Value={
                                      questionnairePagePageState?.Questionnaire
                                        ?.InspectionQuestion?.FreeTextAnswer ??
                                      ""
                                    }
                                    Type={EnumTextBoxType.Text}
                                    ClassName={"site-inspec-textarea"}
                                    InputProps={{ maxLength: 2000 }}
                                    ShowTextCounter
                                    TextLength={2000}
                                    MultiLine
                                    onTextBoxChange={(
                                      name: string,
                                      value: string
                                    ) => {
                                      OnTextBoxChange(name, value, true);
                                    }}
                                    Disabled={
                                      questionnairePagePageState?.PageMode
                                        ?.IsDisable
                                    }
                                  />
                                </div>
                              </div>
                            )}

                            {isShowAnswerChoises([
                              EnumQuestionTypesType.RatingStar,
                            ]) && (
                              <div className="flex--container width100-p justify-middle mb-10">
                                <HPRRatingBar
                                  Name={"RatingBar"}
                                  ClassName={"rating-bar"}
                                  IconSize={50}
                                  Value={getHPRRatingBarValue(
                                    questionnairePagePageState.Questionnaire
                                      ?.InspectionQuestion
                                      ?.InspectionQuestionChoices
                                  )}
                                  Precision={1}
                                  Max={
                                    questionnairePagePageState.Questionnaire
                                      ?.InspectionQuestion
                                      ?.InspectionQuestionChoices?.length ?? 1
                                  }
                                  onRatingSelectionChange={
                                    onRatingSelectionChangeCallBack
                                  }
                                  Disabled={
                                    questionnairePagePageState?.PageMode
                                      ?.IsDisable
                                  }
                                  Tooltips={getHPRRatingBarTooltils(
                                    questionnairePagePageState.Questionnaire
                                      ?.InspectionQuestion
                                      ?.InspectionQuestionChoices
                                  )}
                                ></HPRRatingBar>
                              </div>
                            )}

                            {questionnairePagePageState?.Questionnaire
                              ?.InspectionQuestion?.NotApplicable && (
                              <RadioGroup
                                name="notApplicableQuestionAnswer"
                                className="width100-p dash-top-border"
                                value={
                                  questionnairePagePageState?.Questionnaire
                                    ?.InspectionQuestion?.NotApplicableAnswer ??
                                  false
                                    ? "-1"
                                    : ""
                                }
                                onChange={(event) =>
                                  handleQuestionRadioChange(
                                    event.target.name,
                                    event.target.value
                                  )
                                }
                              >
                                <HPRRadioButton
                                  Name={"QuestionAnswer"}
                                  Value={"-1"}
                                  Label={"Not Applicable"}
                                  ClassName={
                                    "default-label applicable valign-top"
                                  }
                                  Disabled={
                                    questionnairePagePageState?.PageMode
                                      ?.IsDisable
                                  }
                                ></HPRRadioButton>
                              </RadioGroup>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        className="pl-15 pr-15 fx valign-middle mb-5"
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          {isShowClearAnswerButton() && (
                            <div className="clear-btn">
                              <Button
                                variant="outlined"
                                disableElevation
                                className="danger-btn-line-small ml-0"
                                size="small"
                                startIcon={
                                  <Bag
                                    className="Icon-button-delete-line"
                                    variant="Outline"
                                  />
                                }
                                onClick={() => {
                                  onClickClearAnswerBtn(
                                    EnumInspectionQuestionStatus.Pending
                                  );
                                }}
                              >
                                Clear Answer(s)
                              </Button>
                            </div>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          className="fx justify-right"
                        >
                          {questionnairePagePageState.Questionnaire
                            ?.IsEnableReviewByGlobalSME &&
                            questionnairePagePageState.Questionnaire
                              ?.InspectionQuestion
                              ?.PreviousAnswerAndApprovalHistory && (
                              <div
                                className="link d-flex"
                                onClick={
                                  setShowPreviousAnswerAndApprovalHistory
                                }
                              >
                                {!questionnairePagePageState.ShowPreviousAnswerAndApprovalHistory && (
                                  <EmptyWallet
                                    variant="Outline"
                                    className="ico-primary"
                                    size="20"
                                    color="#0288d1"
                                  ></EmptyWallet>
                                )}

                                <a className=" pl-10 link-style">
                                  {getPreviousAnswerAndApprovalHistoryLabel()}
                                </a>
                              </div>
                            )}
                        </Grid>
                      </Grid>
                      {/* <div className="flex--container space-between valign-middle pl-15 pr-15">
                         
                          
                        </div> */}

                      {questionnairePagePageState.Questionnaire
                        ?.IsEnableReviewByGlobalSME &&
                        questionnairePagePageState.ShowPreviousAnswerAndApprovalHistory && (
                          <div className="previous-answer-approval-history gray-background pl-15 pr-15 pt-10 pb-10 mb-15 mt-10">
                            {questionnairePagePageState?.Questionnaire
                              ?.InspectionQuestion
                              ?.PreviousAnswerAndApprovalHistory &&
                              (questionnairePagePageState?.Questionnaire
                                ?.InspectionQuestion?.IsOverrideAnswer ||
                                questionnairePagePageState?.Questionnaire
                                  ?.InspectionQuestion
                                  ?.PreviousAnswerAndApprovalHistory
                                  ?.ApprovalStatus ===
                                  EnumApprovalStatus.Reject) && (
                                <>
                                  <div className="bold">Previous Answer</div>

                                  <div className="previous-answer-wrapper mb-5">
                                    <div
                                      className={`width-auto question-answer-new flex-wrap_wrap radio-checkbox-align ${getQuestionNumberClass()}`}
                                    >
                                      {isShowPreviousAnswerChoises([
                                        EnumQuestionTypesType.YesNo,
                                        EnumQuestionTypesType.MultipleAnswersSingleChoice,
                                      ]) && (
                                        <RadioGroup
                                          name="prevQuestionAnswer"
                                          className="width100-p"
                                          value={
                                            questionnairePagePageState?.Questionnaire?.InspectionQuestion?.PreviousAnswerAndApprovalHistory?.InspectionQuestionApprovalHistoryDetails?.find(
                                              (find) => find.IsCorrect ?? false
                                            )?.Id?.toString() ?? ""
                                          }
                                        >
                                          {questionnairePagePageState?.Questionnaire?.InspectionQuestion?.PreviousAnswerAndApprovalHistory?.InspectionQuestionApprovalHistoryDetails?.map(
                                            (prevchoiceData, index) => {
                                              return (
                                                <span
                                                  className="sub-question-wrapper block two-col"
                                                  key={index}
                                                >
                                                  <HPRRadioButton
                                                    key={index}
                                                    Name={
                                                      "QuestionAnswer_" + index
                                                    }
                                                    Disabled
                                                    Value={prevchoiceData.Id?.toString()}
                                                    Label={
                                                      prevchoiceData.ChoiceText
                                                        .DefaultText
                                                    }
                                                    ClassName={
                                                      "default-label valign-top"
                                                    }
                                                  ></HPRRadioButton>
                                                </span>
                                              );
                                            }
                                          )}
                                        </RadioGroup>
                                      )}

                                      {isShowPreviousAnswerChoises([
                                        EnumQuestionTypesType.MultiplAnswersMultipleChoice,
                                      ]) && (
                                        <div className="flex--container flex-wrap_wrap width100-p">
                                          {questionnairePagePageState?.Questionnaire?.InspectionQuestion?.PreviousAnswerAndApprovalHistory?.InspectionQuestionApprovalHistoryDetails?.map(
                                            (prevchoiceData, index) => {
                                              return (
                                                <span
                                                  className="sub-question-wrapper block two-col checkbox-filter"
                                                  key={index}
                                                >
                                                  <HPRCheckBox
                                                    key={index}
                                                    Name={
                                                      "MultipleQuestionAnswer_" +
                                                      index
                                                    }
                                                    Disabled
                                                    checked={
                                                      prevchoiceData.IsCorrect ??
                                                      false
                                                    }
                                                    Label={
                                                      prevchoiceData.ChoiceText
                                                        .DefaultText
                                                    }
                                                    ClassName={
                                                      "default-label valign-top"
                                                    }
                                                  ></HPRCheckBox>
                                                </span>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}

                                      {isShowPreviousAnswerChoises([
                                        EnumQuestionTypesType.FreeText,
                                      ]) && (
                                        <div className="audit-sub-section">
                                          <pre className="semi-bold disabled-text">
                                            {questionnairePagePageState
                                              ?.Questionnaire
                                              ?.InspectionQuestion
                                              ?.PreviousAnswerAndApprovalHistory
                                              ?.PreviousFreeTextAnswer ?? ""}
                                          </pre>
                                        </div>
                                      )}

                                      {isShowPreviousAnswerChoises([
                                        EnumQuestionTypesType.RatingStar,
                                      ]) && (
                                        <div>
                                          <HPRRatingBar
                                            Name={"prevRatingBar"}
                                            ClassName={"rating-bar"}
                                            Value={getHPRRatingBarValue(
                                              questionnairePagePageState
                                                .Questionnaire
                                                ?.InspectionQuestion
                                                ?.PreviousAnswerAndApprovalHistory
                                                ?.InspectionQuestionApprovalHistoryDetails
                                            )}
                                            Precision={1}
                                            Max={
                                              questionnairePagePageState
                                                .Questionnaire
                                                ?.InspectionQuestion
                                                ?.PreviousAnswerAndApprovalHistory
                                                ?.InspectionQuestionApprovalHistoryDetails
                                                ?.length ?? 1
                                            }
                                            Disabled
                                            Tooltips={getHPRRatingBarTooltils(
                                              questionnairePagePageState
                                                .Questionnaire
                                                ?.InspectionQuestion
                                                ?.PreviousAnswerAndApprovalHistory
                                                ?.InspectionQuestionApprovalHistoryDetails
                                            )}
                                          ></HPRRatingBar>
                                        </div>
                                      )}

                                      {questionnairePagePageState?.Questionnaire
                                        ?.InspectionQuestion
                                        ?.PreviousAnswerAndApprovalHistory
                                        ?.PreviousNotApplicableAnswer && (
                                        <RadioGroup
                                          name="prevNotApplicableQuestionAnswer"
                                          className="width100-p"
                                          value={
                                            questionnairePagePageState
                                              ?.Questionnaire
                                              ?.InspectionQuestion
                                              ?.PreviousAnswerAndApprovalHistory
                                              ?.PreviousNotApplicableAnswer
                                              ? "-1"
                                              : ""
                                          }
                                        >
                                          <HPRRadioButton
                                            Name={"QuestionAnswer"}
                                            Value={"-1"}
                                            Label={"Not Applicable"}
                                            ClassName={
                                              "default-label applicable valign-top"
                                            }
                                            Disabled
                                          ></HPRRadioButton>
                                        </RadioGroup>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                            {questionnairePagePageState?.Questionnaire
                              ?.InspectionQuestion
                              ?.PreviousAnswerAndApprovalHistory
                              ?.ApprovalStatus ===
                              EnumApprovalStatus.Approved && (
                              <div className="previous-answer-wrapper">
                                <div className="width-auto question-answer-new flex-wrap_wrap mb-5">
                                  <span className="bold mr-5">
                                    Approved by and Date:
                                  </span>
                                  <span className="semi-bold mr-5">
                                    {
                                      questionnairePagePageState?.Questionnaire
                                        ?.InspectionQuestion
                                        ?.PreviousAnswerAndApprovalHistory
                                        ?.ModifiedByNavigation?.DisplayName
                                    }
                                  </span>
                                  <span className="border-left pl-5">
                                    {formatDateTimeToString(
                                      questionnairePagePageState?.Questionnaire
                                        ?.InspectionQuestion
                                        ?.PreviousAnswerAndApprovalHistory
                                        ?.ActionDate,
                                      "DD-MMM-YYYY HH:mm:ss"
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}

                            {questionnairePagePageState?.Questionnaire
                              ?.InspectionQuestion
                              ?.PreviousAnswerAndApprovalHistory &&
                              questionnairePagePageState?.Questionnaire
                                ?.InspectionQuestion
                                ?.PreviousAnswerAndApprovalHistory
                                ?.ApprovalStatus ===
                                EnumApprovalStatus.Reject && (
                                <div className="approval-history">
                                  <div className="reject-reason mb-5">
                                    <span className="bold mr-5">
                                      Reason for Rejection:
                                    </span>
                                    <span className="semi-bold">
                                      {
                                        questionnairePagePageState
                                          ?.Questionnaire?.InspectionQuestion
                                          ?.PreviousAnswerAndApprovalHistory
                                          ?.Reason
                                      }
                                    </span>
                                  </div>

                                  <div className="reject-by">
                                    <span className="bold mr-5">
                                      Rejected by and Date:
                                    </span>
                                    <span className="semi-bold mr-5">
                                      {
                                        questionnairePagePageState
                                          ?.Questionnaire?.InspectionQuestion
                                          ?.PreviousAnswerAndApprovalHistory
                                          ?.ModifiedByNavigation?.DisplayName
                                      }
                                    </span>
                                    <span className="border-left pl-5">
                                      {formatDateTimeToString(
                                        questionnairePagePageState
                                          ?.Questionnaire?.InspectionQuestion
                                          ?.PreviousAnswerAndApprovalHistory
                                          ?.ActionDate,
                                        "DD-MMM-YYYY HH:mm:ss"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}

                      {questionnairePagePageState?.Questionnaire
                        ?.InspectionQuestion?.AdditionalComments ? (
                        <Grid container className="pl-15 pr-15">
                          <Grid item xs={12}>
                            <div className="audit-sub-section mt-10">
                              <div className="form-group site-inspec-textarea mb-0">
                                <HPRTextBoxAutoResize
                                  Id={"questionComment"}
                                  Label={"Comment"}
                                  Name={"Comment"}
                                  Value={
                                    questionnairePagePageState?.Questionnaire
                                      ?.InspectionQuestion?.Comment
                                      ? questionnairePagePageState
                                          ?.Questionnaire?.InspectionQuestion
                                          ?.Comment
                                      : ""
                                  }
                                  Type={EnumTextBoxType.Text}
                                  ClassName={"site-inspec-textarea"}
                                  InputProps={{ maxLength: 1000 }}
                                  ShowTextCounter
                                  TextLength={1000}
                                  MultiLine
                                  onTextBoxChange={OnTextBoxChange}
                                  Disabled={
                                    questionnairePagePageState?.PageMode
                                      ?.IsDisable
                                  }
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                      <Grid
                        container
                        spacing={2}
                        className="pl-15 pr-15 upload-img-doc"
                      >
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.ImageUpload !== 3 ? (
                          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Grid container>
                              <Grid item xs={12}>
                                <div className="item-desc-wrap img-up">
                                  <div className={"selected-item-wrapper"}>
                                    <HPRFileUploader
                                      Label={"Upload Image(s)"}
                                      FileType={[EnumFileType.Image]}
                                      Name={"Image"}
                                      GetselectedFiles={getSelectedImageFiles}
                                      GetError={getError}
                                      PreviewImage={true}
                                      UploadedFileList={
                                        questionnairePagePageState.Questionnaire
                                          ?.UploadedFileList
                                      }
                                      Multiple
                                      Disabled={
                                        questionnairePagePageState?.PageMode
                                          ?.IsDisable
                                      }
                                      ShowComment
                                    />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.ImageUpload === 3 &&
                        questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.DocumentUpload !== 3 ? (
                          <Grid item xl={6} lg={6} md={6} sm={6} xs={6} />
                        ) : null}
                        {questionnairePagePageState?.Questionnaire
                          ?.InspectionQuestion?.DocumentUpload !== 3 ? (
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            className="pt-0 mt-16"
                          >
                            <div className={"fx-right fx upload-button doc-up"}>
                              <div
                                className={"selected-item-wrapper text-right"}
                              >
                                <HPRFileUploader
                                  Label={"Upload Document(s)"}
                                  FileType={[
                                    EnumFileType.Doc,
                                    EnumFileType.Email,
                                  ]}
                                  Name={"doc"}
                                  GetselectedFiles={getSelectedImageFiles}
                                  GetError={getError}
                                  UploadedFileList={
                                    questionnairePagePageState.Questionnaire
                                      .UploadedFileList
                                  }
                                  Multiple
                                  Disabled={
                                    questionnairePagePageState?.PageMode
                                      ?.IsDisable
                                  }
                                  ShowComment
                                />
                              </div>
                            </div>
                          </Grid>
                        ) : null}
                      </Grid>

                      {questionnairePagePageState?.Questionnaire
                        ?.InspectionQuestion?.InspectionQuestionParameters
                        .length > 0 && (
                        <Grid container className="pl-15 pr-15">
                          {/* <div className="bold">Source(s)</div> */}

                          <div className="audit-sub-section">
                            <div className="link fx valign-middle">
                              <a
                                className="link-style semi-bold"
                                onClick={setShowSources}
                              >
                                {"Show Source(s)"}
                              </a>
                              <span
                                className="fx ico-primary pl-10"
                                onClick={setShowSources}
                              >
                                {questionnairePagePageState.ShowSources ? (
                                  <ArrowCircleUp size="20" color="#0288d1" />
                                ) : (
                                  <ArrowCircleDown size="20" color="#0288d1" />
                                )}
                              </span>
                            </div>
                            {questionnairePagePageState.ShowSources && (
                              <>
                                <Grid item xs={12}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                      <div className="grid-item-header">
                                        Reference
                                      </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <div className="grid-item-header">
                                        System
                                      </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <div className="grid-item-header">
                                        Sub-system
                                      </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <div className="grid-item-header">
                                        Subject
                                      </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <div className="grid-item-header">
                                        Section
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                {questionnairePagePageState?.Questionnaire?.InspectionQuestion?.InspectionQuestionParameters?.map(
                                  (questionparams, index: number) => {
                                    return (
                                      <Grid item xs={12} key={index}>
                                        <Grid
                                          container
                                          spacing={1}
                                          sx={{ mb: 1 }}
                                        >
                                          <Grid item xs={2}>
                                            <div className="grid-item-wrapper q-page-ref">
                                              <a
                                                href={
                                                  questionparams
                                                    .StandardReference.Url
                                                }
                                                target="_blank"
                                                className="link"
                                              >
                                                {questionparams
                                                  .StandardReference.Name
                                                  ? questionparams
                                                      .StandardReference.Name
                                                  : "N/A"}
                                              </a>
                                            </div>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <div className="grid-item-wrapper">
                                              {questionparams.StandardSystem
                                                .Name
                                                ? questionparams.StandardSystem
                                                    .Name
                                                : "N/A"}
                                            </div>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <div className="grid-item-wrapper">
                                              {questionparams.StandardSubSystem
                                                .Name
                                                ? questionparams
                                                    .StandardSubSystem.Name
                                                : "N/A"}
                                            </div>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <div className="grid-item-wrapper">
                                              {questionparams.StandardSubject
                                                .Name
                                                ? questionparams.StandardSubject
                                                    .Name
                                                : "N/A"}
                                            </div>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <div className="grid-item-wrapper">
                                              {questionparams.StandardSection
                                                .Name
                                                ? questionparams.StandardSection
                                                    .Name
                                                : "N/A"}
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </div>
                        </Grid>
                      )}
                    </div>
                  </motion.div>

                  {/* End of question */}
                  <div className="audit-item-footer" ref={footer}>
                    <Grid
                      container
                      className={`${
                        questionnairePagePageState.Questionnaire
                          ?.IsEnableReviewByGlobalSME
                          ? "fx-middle"
                          : "fx-between"
                      }`}
                    >
                      <Grid item xl={6} lg={6} md={6} sm={4} xs={5}>
                        {isShowPreviousButton() && (
                          <Button
                            className="primary small-btn align-left"
                            variant="contained"
                            disableElevation
                            onClick={() =>
                              onClickBackBtn(
                                questionnairePagePageState.Questionnaire
                                  .CurrentQuestionNumber,
                                EnumInspectionQuestionStatus.Pending
                              )
                            }
                          >
                            Previous
                          </Button>
                        )}
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={8}
                        xs={7}
                        className="right-sec"
                      >
                        {isShowNextButton() && (
                          <Button
                            className="primary-btn-small small-btn" // change 'primary' -> 'primary-btn-small'
                            variant="contained"
                            disableElevation
                            onClick={() => {
                              setQuestionTransition(forwardTransition);
                              onClickSaveBtn(
                                EnumInspectionQuestionStatus.Pending
                              );
                            }}
                          >
                            Next
                          </Button>
                        )}

                        {isShowGoToFirstButton() && (
                          <Button
                            className="primary-btn-small small-btn" // change 'primary' -> 'primary-btn-small'
                            variant="contained"
                            disableElevation
                            onClick={onClickGoToFirstBtn}
                          >
                            {getGoToFirstPendingLabel()}
                          </Button>
                        )}

                        {!questionnairePagePageState?.PageMode.IsDisable &&
                          !isInspectionQuestionAnswered() && (
                            <Button
                              className="primary-btn-small small-btn" // change 'primary' -> 'primary-btn-small'
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                setQuestionTransition(forwardTransition);
                                onClickSaveBtn(
                                  EnumInspectionQuestionStatus.Skipped
                                );
                              }}
                            >
                              Skip
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <ViewInspectionQuestionHistory />
      <Grid item xs={12} lg={12} md={12} xl={12}>
        <Modal
          className="modal-outter modal-md"
          open={openRejectPopup}
          onClose={handleRejectPopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="modal-header">
              <div className="bold">Reason for Rejection</div>
            </div>
            <div className="modal-content p-b-5">
              <Grid container className="">
                <Grid item xs={12} className="">
                  <div className="form-group w-100 mb-0 textinput-popup mt-10">
                    <HPRTextBox
                      Id="reasonForRejection"
                      Name="reasonForRejection"
                      Label={"Reason for Rejection"}
                      Required={true}
                      TextLength={100}
                      InputProps={{ maxLength: 100 }}
                      ComponentProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => {
                                onChangeRejectReason("reasonForRejection", "");
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      ShowTextCounter={true}
                      Value={rejectReason ?? ""}
                      Type={EnumTextBoxType.Text}
                      Disabled={false}
                      onTextBoxChange={onChangeRejectReason}
                      Validator={validator}
                      Rules={"required"}
                      IsEnableValidator={true}
                      ShowValidMsg={true}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="modal-button-bar">
              <Button
                className="secondary-btn-small"
                variant="outlined"
                size="small"
                startIcon={<CloseCircle variant="Outline" />}
                disableElevation
                onClick={handleRejectPopupClose}
              >
                Discard
              </Button>
              <Button
                className="primary-btn-small"
                variant="contained"
                size="small"
                startIcon={<ShieldTick variant="Outline" />}
                disableElevation
                onClick={onClickQuestionAnswerRejectBtn}
              >
                Confirm
              </Button>
            </div>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};

export default QuestionnairePage;
