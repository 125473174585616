import SimpleReactValidator from "simple-react-validator";
import { EnumPageMode } from "../../../common/enums/common.enums";
import {
  DefaultTextDTO,
  DropDownDTO,
  QuestionCategoryChoiceDTO,
  QuestionCategoryDTO,
  StatusDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import { createSlice } from "@reduxjs/toolkit";
import { MultipleChoicePageType } from "./MultipleChoicePage.types";

const initialState = {
  PageMode: {
    PageMode: EnumPageMode.Default,
    IsDisable: false,
    IsShowSaveBtn: false,
    IsShowCloseBtn: false,
    IsShowUpdateBtn: false,
    IsShowCancelBtn: false,
    IsShowDraftBtn: false,
    IsShowEditBtn: false,
    IsShowPublishedBtn: false,
    IsShowDeactivateBtn: false,
  } as PageModeType,
  MultipleChoice: {
    Id: 0,
    Name: "",
    QuestionTypeId: 0,
    StatusNavigation: { Status1: "Draft" } as StatusDTO,
    Version: 0.1,
    QuestionTypes: [] as DropDownDTO[],
    QuestionCategoryChoices: [] as QuestionCategoryChoiceDTO[],
  } as QuestionCategoryDTO,
  Validator: new SimpleReactValidator(),
  ChoiceValidator: new SimpleReactValidator({
    messages: {
      default: "This field is required",
    },
  }),
  ShowErrorMsg: false,
  DataRecieved: false,
} as MultipleChoicePageType;

export const multipleChoiceSlice = createSlice({
  name: "multipleChoice",
  initialState: initialState,
  reducers: {
    setMultipleChoice: (
      state: MultipleChoicePageType,
      action: {
        payload: QuestionCategoryDTO;
        type: string;
      }
    ) => {
      return {
        ...state,
        IsDisable: true,
        MultipleChoice: action.payload,
      };
    },
    changePageMode: (
      state: MultipleChoicePageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (action.payload === EnumPageMode.ViewMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowSaveBtn: false,
            IsShowCloseBtn: true,
            IsShowUpdateBtn: false,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowEditBtn: true,
            IsShowPublishedBtn: false,
            IsShowDeactivateBtn: false,
          },
        };
      } else if (action.payload === EnumPageMode.AddMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowSaveBtn: false,
            IsShowCloseBtn: false,
            IsShowUpdateBtn: false,
            IsShowCancelBtn: true,
            IsShowDraftBtn: true,
            IsShowEditBtn: false,
            IsShowPublishedBtn: true,
            IsShowDeactivateBtn: false,
          },
          MultipleChoice: {
            ...initialState.MultipleChoice,
            QuestionTypes: state.MultipleChoice.QuestionTypes,
            QuestionCategoryChoices: [
              {
                Id: 0,
                DefaultText: { DefaultText1: "" } as DefaultTextDTO,
                DisplayOrder: 1,
              } as QuestionCategoryChoiceDTO,
            ],
          },
        };
      } else if (action.payload === EnumPageMode.EditMode) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowSaveBtn: false,
            IsShowCloseBtn: false,
            IsShowUpdateBtn: false,
            IsShowCancelBtn: true,
            IsShowDraftBtn: true,
            IsShowEditBtn: false,
            IsShowPublishedBtn: true,
            IsShowDeactivateBtn: true,
          },
        };
      }
    },

    resetMultipleChoice: (state: MultipleChoicePageType) => {
      return {
        ...state,
        IsDisable: true,
        MultipleChoice: initialState.MultipleChoice,
      };
    },

    setDataRecieved: (
      state: MultipleChoicePageType,
      action: {
        payload: boolean;
        type: string;
      }
    ) => {
      return {
        ...state,
        DataRecieved: action.payload,
      };
    },
  },
});

export const multipleChoiceReducer = multipleChoiceSlice.reducer;

export const multipleChoiceReducerActions = multipleChoiceSlice.actions;
