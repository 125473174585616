// material
import { Button, Grid, IconButton } from "@mui/material";
import {
  CardSend,
  CloseCircle,
  ArrowLeft2,
  Edit,
  ClipboardText,
  GlobalEdit,
  DirectboxReceive,
  CardSlash,
} from "iconsax-react";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

// project import
import HPRTextBox from "../../../components/common/HPRTextBox/HPRTextBox";
import { EnumTextBoxType } from "../../../components/common/HPRTextBox/HPRTextBox.enum";
import {
  StandardPageParametersType,
  StandardPageType,
} from "./StandardPage.types";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  checkStandardBeforeDeactivate,
  getStandardById,
  saveStandard,
} from "./StandardPage.action";
import {
  changePageMode,
  reSetStandard,
  setStandard,
} from "./StandardPage.reducer";
import {
  DropDownDTO,
  EmployeeDTO,
  StatusSummaryCountDTO,
  StandardDTO,
  StandardSmeDTO,
  StatusDTO,
} from "../../../common/types/common.dto.types";
import { PageModeType } from "../../../common/types/common.page.type";
import {
  EnumPageMode,
  EnumSMEType,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { useNavigate } from "react-router-dom";
import { PageRegistrationProperties } from "../../../core/reducers/pageRegistrationReducer.types";
import usePageRegistration from "../../../core/hooks/usePageRegistration";
import HPRDropDownMultipleSelect from "../../../components/common/HPRDropDownMultipleSelect/HPRDropDownMultipleSelect";
import HPRDropDownSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownSingleSelect";
import { StandardQuestionsPageParametersType } from "../StandardQuestionsPage/StandardsQuestionsPage.types";

// Popups
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { openPopup } from "../../../components/Popups/Popup.reducer";

// styles
import "./standard-page.scss";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import SimpleReactValidator from "simple-react-validator";
import { StandardsPageParametersType } from "../StandardsPage/StandardsPage.types";
import { UsePromptTypes } from "../../../core/hooks/use-prompt/use-prompt.types";
import { usePrompt } from "../../../core/hooks/use-prompt/use-prompt";
import HPRDropDownMsGraphSingleSelect from "../../../components/common/HPRDropDownSingleSelect/HPRDropDownMsGraphSingleSelecty";
import HPRDropDownMsGraphMultipleSelect from "../../../components/common/HPRDropDownMultipleSelect/HPRDropDownMsGraphMultipleSelect";

/* ==============================|| VIEWS - PAGES - STANDRARD ||==============================  */
const StandardPage = (props: PageRegistrationProperties) => {
  // registering page properties
  usePageRegistration(props);

  // initial page state object
  const initialState = {
    PageMode: {
      PageMode: EnumPageMode.Default,
      IsShowSaveBtn: false,
      IsDisable: false,
      IsShowCloseBtn: false,
      IsShowUpdateBtn: false,
      IsShowCancelBtn: false,
      IsShowDraftBtn: false,
      IsShowEditBtn: false,
      IsShowPublishedBtn: false,
      IsShowDeactivateBtn: false,
    } as PageModeType,
    Standard: {
      Name: "",
      PrimarySME: {
        Sme: { Id: 0, DisplayName: "", Uuid: "" } as EmployeeDTO,
        Smeid: 0,
      } as StandardSmeDTO,
      SecondarySME: [] as Array<StandardSmeDTO>,
      StatusNavigation: { Status1: "Draft" } as StatusDTO,
      QuestionsSummary: {
        All: 0,
        Archive: 0,
        Deactivated: 0,
        Draft: 0,
        Published: 0,
      } as StatusSummaryCountDTO,
      Version: 0.1,
      StandardSmes: [] as Array<StandardSmeDTO>,
      Status: EnumStatus.Draft,
      StandardSmeDropDownList: [] as Array<DropDownDTO>,
    } as StandardDTO,
    IsDisable: false,
    //SecondarySMEselectOptions: [] as Array<DropDownDTO>,
    SecondarySMEValues: [] as Array<string>,
    ConfirmationPopupOpen: false,
    ErrorPopupOpen: false,
    HeaderText: "",
    Validator: new SimpleReactValidator({
      messages: {
        default: "This field is required",
      },
    }),
    ShowErrorMsg: false,
  } as StandardPageType;

  // create state for StandardPageType with initialState values
  const [standardPageState, setstandardPageState] = useState(initialState);

  // use for page navigation
  const navigate = useNavigate();

  // pass parameters from outside to page
  const location = useLocation();
  const parameter: StandardPageParametersType = location.state;

  // reducer manage fucntions
  const standardState = useAppSelector((state) => state.standardReducer);

  const dispatch = useAppDispatch();

  // check when one time call to get standard data from backend
  const dataFetchedRef = useRef(false);

  // load page with parameter and with out
  useEffect(() => {
    if (parameter?.StandardId > 0) {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      dispatch(getStandardById(parameter.StandardId));
    } else {
      setstandardPageState((values) => {
        return {
          ...values,
          Standard: initialState.Standard,
        };
      });
      dispatch(changePageMode(EnumPageMode.AddMode));
    }
  }, []);

  // every time when reducer state change set values to page state
  useEffect(() => {
    setstandardPageState((values) => {
      return {
        ...values,
        Standard: {
          ...standardState.Standard,
          StandardSmeDropDownList:
            values.Standard.StandardSmeDropDownList?.length === 0
              ? standardState.Standard?.StandardSmeDropDownList
              : values.Standard?.StandardSmeDropDownList,
        },
        SecondarySMEValues:
          standardState.Standard.SecondarySME != null &&
          standardState.Standard.SecondarySME.length > 0
            ? standardState.Standard.SecondarySME.map((x) =>
                x.Sme != null ? x.Sme.Uuid : x.Smeid.toString()
              )
            : standardPageState.SecondarySMEValues,
        PageMode: standardState.PageMode,
      };
    });
  }, [standardState]);

  useEffect(() => {
    if (!dataFetchedRef.current) return;
    setstandardPageState((values) => {
      return {
        ...values,
        Standard: {
          ...values.Standard,
          StandardSmeDropDownList:
            standardState?.Standard?.StandardSmeDropDownList,
        },
      };
    });
  }, [standardState.Standard?.StandardSmeDropDownList]);

  useEffect(() => {
    standardPagevalidation();
  }, [standardPageState]);

  const IsPageValid = (): boolean => {
    let isValid = standardPageState.Validator.allValid();
    if (!isValid) {
      setstandardPageState((values) => {
        return {
          ...values,
          ShowErrorMsg: !standardPageState.ShowErrorMsg,
        };
      });

      // show validation message
      standardPageState.Validator.showMessages();

      return isValid;
    } else {
      // hide validation message
      standardPageState.Validator.hideMessages();
    }

    return isValid;
  };

  // page unload validations
  const [isDirty, setIsDirty] = useState(false);
  const prompt = {
    Message: "Do you want to discard the changes?",
    When: isDirty,
    YesBtnClick() {
      setstandardPageState((values) => {
        return {
          ...values,
          Standard: standardState.Standard,
          SecondarySMEValues:
            standardState.Standard.SecondarySME.length > 0
              ? standardState.Standard.SecondarySME.map((x) =>
                  x.Sme != null ? x.Sme.Uuid : x.Smeid.toString()
                )
              : standardPageState.SecondarySMEValues,
        };
      });
    },
  } as UsePromptTypes;
  usePrompt(prompt);
  //-----Button functions-----//

  const PublishButtonClick = () => {
    SaveButtonClick(EnumStatus.Published);
  };
  const DraftButtonClick = () => {
    //SaveButtonClick(EnumStatus.Draft);
    if (standardPageState.PageMode.PageMode === EnumPageMode.AddMode) {
      SaveButtonClick(EnumStatus.Draft);
    } else {
      let standard: StandardDTO = Object.assign({}, standardPageState.Standard);
      standard.Status = EnumStatus.Draft;
      standard.StandardSmes = Object.assign([], standard.SecondarySME);
      if (
        standard.PrimarySME.Sme.Uuid &&
        standard.PrimarySME.Sme.Uuid !== "0"
      ) {
        standard.StandardSmes.push(standard.PrimarySME);
      }

      if (IsPageValid()) {
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to save the standard as a draft?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              dispatch(checkStandardBeforeDeactivate(standard));
            },
          })
        );
      }
    }
  };
  const SaveButtonClick = (saveStatus?: EnumStatus) => {
    // if (standardPageState.Standard.Name === "") {
    //   dispatch(
    //     openPopup({
    //       Open: true,
    //       BodyText: "Standard Name Missing",
    //       HeaderText: "Warning",
    //       PopupType: EnumPopupType.WarningPopup,
    //     } as PopupProps)
    //   );
    // } else if (standardPageState.Standard.PrimarySME.Sme.Id === 0) {
    //   dispatch(
    //     openPopup({
    //       Open: true,
    //       BodyText: "Primary SME Missing",
    //       HeaderText: "Warning",
    //       PopupType: EnumPopupType.WarningPopup,
    //     } as PopupProps)
    //   );
    // } else if (standardPageState.Standard.SecondarySME.length === 0) {
    //   dispatch(
    //     openPopup({
    //       Open: true,
    //       BodyText: "Secondary SME Missing",
    //       HeaderText: "Warning",
    //       PopupType: EnumPopupType.WarningPopup,
    //     } as PopupProps)
    //   );
    // } else {
    if (IsPageValid()) {
      let standard: StandardDTO = Object.assign({}, standardPageState.Standard);
      standard.StandardSmes = Object.assign([], standard.SecondarySME);
      if (
        standard.PrimarySME.Sme.Uuid &&
        standard.PrimarySME.Sme.Uuid !== "0"
      ) {
        standard.StandardSmes.push(standard.PrimarySME);
      }

      if (saveStatus === EnumStatus.Draft) {
        standard.Status = EnumStatus.Draft;
      } else if (saveStatus === EnumStatus.Published) {
        standard.Status = EnumStatus.Published;
      }
      const sameSecondarySmes = standard.SecondarySME?.filter((obj) => {
        return !standardState.Standard.SecondarySME?.some((obj1) => {
          return obj1.Sme.Uuid === obj.Sme.Uuid;
        });
      });
      if (
        standardPageState.PageMode.PageMode === EnumPageMode.EditMode &&
        standardState.Standard.Status === EnumStatus.Published &&
        standard.Status === EnumStatus.Published &&
        (standard.Name.trim() !== standardState.Standard.Name ||
          standardState.Standard.PrimarySME.Sme.Uuid !==
            standard.PrimarySME.Sme.Uuid ||
          sameSecondarySmes.length > 0 ||
          standardState.Standard.SecondarySME.length >
            standard.SecondarySME.length)
      ) {
        dispatch(
          openPopup({
            Open: true,
            BodyText:
              "Please note that your changes will impact relevant records. Are you sure you want to apply your changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setIsDirty(false);
              dispatch(saveStandard(standard));
            },
          } as PopupProps)
        );
      } else {
        setIsDirty(false);
        dispatch(saveStandard(standard));
      }
    }
  };
  const EditButtonClick = () => {
    dispatch(changePageMode(EnumPageMode.EditMode));
  };
  const CloseButtonClick = () => {
    dispatch(reSetStandard());
    navigate("/standards", {
      state: {
        SearchStatusId: parameter?.SearchStatusId,
      } as StandardsPageParametersType,
    });
  };
  const DeactivateButtonClick = () => {
    let standard: StandardDTO = Object.assign({}, standardPageState.Standard);
    standard.Status = EnumStatus.Deactivated;
    standard.StandardSmes = Object.assign([], standard.SecondarySME);
    if (standard.PrimarySME.Sme.Uuid && standard.PrimarySME.Sme.Uuid !== "0") {
      standard.StandardSmes.push(standard.PrimarySME);
    }
    dispatch(
      openPopup({
        Open: true,
        BodyText: "Do you want to deactivate the standard?",
        HeaderText: "Confirmation",
        PopupType: EnumPopupType.YesNoConfirmation,
        YesBtnClick() {
          dispatch(checkStandardBeforeDeactivate(standard));
        },
      })
    );
  };

  const CancelButtonClick = () => {
    const tempisDirty = isDirty;
    if (standardPageState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        standardPageState.Standard.Name === "" &&
        (!standardPageState.Standard.PrimarySME.Sme.Uuid ||
          standardPageState.Standard.PrimarySME.Sme.Uuid === "0") &&
        standardPageState.Standard.SecondarySME?.length === 0
      ) {
        navigate("/standards", {
          state: {
            SearchStatusId: parameter?.SearchStatusId,
          } as StandardsPageParametersType,
        });
      } else {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setstandardPageState((values) => {
                return {
                  ...values,
                  Standard: standardState.Standard,
                  SecondarySMEValues:
                    standardState.Standard.SecondarySME.length > 0
                      ? standardState.Standard.SecondarySME.map((x) =>
                          x.Sme != null ? x.Sme.Uuid : x.Smeid.toString()
                        )
                      : standardPageState.SecondarySMEValues,
                };
              });
              navigate("/standards", {
                state: {
                  SearchStatusId: parameter?.SearchStatusId,
                } as StandardsPageParametersType,
              });
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      }
    } else {
      const isSameUser = standardPageState.Standard.SecondarySME?.filter(
        (obj) => {
          return !standardState.Standard.SecondarySME?.some((obj1) => {
            return obj1.Sme.Uuid === obj.Sme.Uuid;
          });
        }
      );

      // *****Conditions checked***** //
      // Check name changed
      // check primary sme changed
      // check secondary sme list changed
      // check secondary sme list if empty by remove by user
      if (
        standardState.Standard.Name !== standardPageState.Standard.Name ||
        standardState.Standard.PrimarySME.Sme.Uuid !==
          standardPageState.Standard.PrimarySME.Sme.Uuid ||
        isSameUser.length > 0 ||
        standardState.Standard.SecondarySME.length >
          standardPageState.Standard.SecondarySME.length
      ) {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setstandardPageState((values) => {
                return {
                  ...values,
                  Standard: standardState.Standard,
                  SecondarySMEValues:
                    standardState.Standard.SecondarySME.length > 0
                      ? standardState.Standard.SecondarySME.map((x) =>
                          x.Sme != null ? x.Sme.Uuid : x.Smeid.toString()
                        )
                      : [],
                };
              });
              dispatch(changePageMode(EnumPageMode.ViewMode));
              standardPageState.Validator.hideMessages();
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      } else {
        dispatch(changePageMode(EnumPageMode.ViewMode));
        standardPageState.Validator.hideMessages();
      }
    }
  };
  const BackBtnClick = () => {
    const tempisDirty = isDirty;

    if (standardPageState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        standardPageState.Standard.Name === "" &&
        (!standardPageState.Standard.PrimarySME.Sme.Uuid ||
          standardPageState.Standard.PrimarySME.Sme.Uuid === "0") &&
        standardPageState.Standard.SecondarySME?.length === 0
      ) {
        navigate("/standards", {
          state: {
            SearchStatusId: parameter?.SearchStatusId,
          } as StandardsPageParametersType,
        });
      } else {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setstandardPageState((values) => {
                return {
                  ...values,
                  Standard: standardState.Standard,
                  SecondarySMEValues:
                    standardState.Standard.SecondarySME.length > 0
                      ? standardState.Standard.SecondarySME.map((x) =>
                          x.Sme != null ? x.Sme.Uuid : x.Smeid.toString()
                        )
                      : standardPageState.SecondarySMEValues,
                };
              });
              navigate("/standards", {
                state: {
                  SearchStatusId: parameter?.SearchStatusId,
                } as StandardsPageParametersType,
              });
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      }
    } else {
      const isSameUser = standardPageState.Standard.SecondarySME?.filter(
        (obj) => {
          return !standardState.Standard.SecondarySME?.some((obj1) => {
            return obj1.Sme.Uuid === obj.Sme.Uuid;
          });
        }
      );

      // *****Conditions checked***** //
      // Check name changed
      // check primary sme changed
      // check secondary sme list changed
      // check secondary sme list if empty by remove by user
      if (
        standardState.Standard.Name !== standardPageState.Standard.Name ||
        standardState.Standard.PrimarySME.Sme.Uuid !==
          standardPageState.Standard.PrimarySME.Sme.Uuid ||
        isSameUser.length > 0 ||
        standardState.Standard.SecondarySME.length >
          standardPageState.Standard.SecondarySME.length
      ) {
        setIsDirty(false);
        dispatch(
          openPopup({
            Open: true,
            BodyText: "Do you want to discard the changes?",
            HeaderText: "Confirmation",
            PopupType: EnumPopupType.YesNoConfirmation,
            YesBtnClick() {
              setstandardPageState((values) => {
                return {
                  ...values,
                  Standard: standardState.Standard,
                  SecondarySMEValues:
                    standardState.Standard.SecondarySME.length > 0
                      ? standardState.Standard.SecondarySME.map((x) =>
                          x.Sme != null ? x.Sme.Uuid : x.Smeid.toString()
                        )
                      : [],
                };
              });
              dispatch(changePageMode(EnumPageMode.ViewMode));
              navigate("/standards", {
                state: {
                  SearchStatusId: parameter?.SearchStatusId,
                } as StandardsPageParametersType,
              });
            },
            NoBtnClick() {
              setIsDirty(tempisDirty);
            },
          } as PopupProps)
        );
      } else {
        navigate("/standards", {
          state: {
            SearchStatusId: parameter?.SearchStatusId,
          } as StandardsPageParametersType,
        });
      }
    }
  };
  //-----End Button functions-----//

  const changeStandardPageStateStandard = (name: string, value: any) => {
    setstandardPageState((values) => {
      if (name === "PrimarySME") {
        return {
          ...values,
          Standard: {
            ...values.Standard,
            PrimarySME: {
              ...values.Standard.PrimarySME,
              //Id: value,
              Sme: {
                ...values.Standard.PrimarySME.Sme,
                Uuid: value,
              },
            },
          },
        };
      } else if (name === "SecondarySME") {
        return {
          ...values,
          Standard: {
            ...values.Standard,
            SecondarySME: standardPageState.SecondarySMEValues.map((sme) => {
              return { Sme: { Uuid: sme } } as StandardSmeDTO;
            }),
          },
        };
      } else {
        return {
          ...values,
          Standard: {
            ...values.Standard,
            [name]: value,
          },
        };
      }
    });
  };

  const standardPagevalidation = () => {
    if (standardPageState.PageMode.PageMode === EnumPageMode.AddMode) {
      if (
        standardPageState.Standard.Name === "" &&
        (!standardPageState.Standard.PrimarySME.Sme.Uuid ||
          standardPageState.Standard.PrimarySME.Sme.Uuid === "0") &&
        standardPageState.Standard.SecondarySME?.length === 0
      ) {
        setIsDirty(false);
      } else {
        setIsDirty(true);
      }
    } else {
      const isSameUser = standardPageState.Standard.SecondarySME.filter(
        (obj) => {
          return !standardState.Standard.SecondarySME.some((obj1) => {
            return obj1.Sme.Uuid === obj.Sme.Uuid;
          });
        }
      );

      // *****Conditions checked***** //
      // Check name changed
      // check primary sme changed
      // check secondary sme list changed
      // check secondary sme list if empty by remove by user
      if (
        standardState.Standard.Name !== standardPageState.Standard.Name ||
        standardState.Standard.PrimarySME.Sme.Uuid !==
          standardPageState.Standard.PrimarySME.Sme.Uuid ||
        isSameUser.length > 0 ||
        (standardState.Standard.SecondarySME.length > 0 &&
          standardPageState.Standard.SecondarySME.length === 0)
      ) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  };

  // Secondary SME Drop down change event
  const onChangeSecondaryDropdown = (
    selectOptions: Array<DropDownDTO>,
    selectedValues: Array<string>
  ) => {
    // if (
    //   standardPageState.Standard.PrimarySME.Smeid > 0 &&
    //   selectedValues.find((obj) => {
    //     return obj == standardPageState.Standard.PrimarySME.Smeid.toString();
    //   })
    // ) {
    //   dispatch(
    //     openPopup({
    //       Open: true,
    //       BodyText:
    //         "Selected SME already selected as Primary SME, please select another SME",
    //       HeaderText: "Warning",
    //       PopupType: EnumPopupType.WarningPopup,
    //     } as PopupProps)
    //   );
    // } else {
    // Push the object
    let secList: Array<StandardSmeDTO> = selectedValues.map((sme) => {
      return {
        //Smeid: parseInt(sme),
        Sme: { Uuid: sme?.toString() },
        SmetypeId: EnumSMEType.Secondary_SME,
      } as StandardSmeDTO;
    });
    const newArray = standardPageState.Standard.SecondarySME.slice(); // Create a copy
    newArray.push(secList[0]);
    setstandardPageState((value) => {
      if (standardPageState.Standard.SecondarySME.length > 0) {
        return {
          ...value,
          Standard: {
            ...value.Standard,
            SecondarySME: secList,
            StandardSmeDropDownList: selectOptions,
          },
          SecondarySMEValues: selectedValues,
        };
      } else {
        return {
          ...value,
          Standard: {
            ...value.Standard,
            SecondarySME: newArray,
            StandardSmeDropDownList: selectOptions,
          },
          SecondarySMEValues: selectedValues,
        };
      }
    });
    // }
  };

  // Primary SME Drop down change event
  const onChangePrimaryDropdown = (
    selectOption: Array<DropDownDTO>,
    selectedValue: string
  ) => {
    // if (
    //   standardPageState.Standard.SecondarySME.find((obj) => {
    //     return obj.Smeid == parseInt(selectedValue);
    //   })
    // ) {
    //   dispatch(
    //     openPopup({
    //       Open: true,
    //       BodyText:
    //         "Selected SME already selected as Secondary SME, please select another SME",
    //       HeaderText: "Warning",
    //       PopupType: EnumPopupType.WarningPopup,
    //     } as PopupProps)
    //   );
    // } else {
    setstandardPageState((value) => {
      return {
        ...value,

        Standard: {
          ...value.Standard,
          PrimarySME: {
            ...value.Standard.PrimarySME,
            SmetypeId: EnumSMEType.Primary_SME,
            //Smeid: selectedValue ? parseInt(selectedValue) : 0,
            Sme: {
              ...value.Standard.PrimarySME.Sme,
              //Id: selectedValue ? parseInt(selectedValue) : 0,
              Uuid: selectedValue?.toString(),
            },
          },
          StandardSmeDropDownList: selectOption,
        },
      };
    });
    // changeStandardPageStateStandard("PrimarySME", selectedValue);
    // }
  };

  return (
    <>
      {/* Main Backgroud */}
      {/* <Grid container columns={12} className="global-header-wrap"> */}
      {/* <Grid item lg={6} md={6} sm={12} xs={12} className="">
          <h1>Standard</h1>
        </Grid> */}
      {/* <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="vlign-middle status-version-wrap"
        >
          <div className="global-version-top mb-5">
            <span>Version:</span>
            {standardPageState.Standard != null
              ? standardPageState.Standard.Version.toFixed(1) +
                (standardPageState.Standard.Status === EnumStatus.Published ||
                standardPageState.Standard.Status === EnumStatus.Deactivated
                  ? ""
                  : "")
              : "0.1"}
          </div>
          <div className="standart-status-change mb-5 d-flex">
            <div className="standards-status">
              <span>Status:</span>
              <span
                className={
                  standardPageState.Standard.Status === EnumStatus.Published
                    ? "status-color green"
                    : standardPageState.Standard.Status === EnumStatus.Draft &&
                      standardPageState.PageMode.PageMode !==
                        EnumPageMode.AddMode
                    ? "status-color yellow"
                    : standardPageState.Standard.Status ===
                      EnumStatus.Deactivated
                    ? "status-color gray"
                    : "status-color yellow"
                }
              >
                {standardPageState.Standard.StatusNavigation?.Id ===
                EnumStatus.Deactivated
                  ? "Deactivated"
                  : standardPageState.Standard.StatusNavigation?.Status1}
              </span>
            </div>
          </div>
        </Grid> */}
      {/* </Grid> */}
      <div className="content-section-card top-main-padding">
        <Grid container columns={12} className="global-subhead-btn-wrap">
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="main-elements-background"
          >
            <Grid
              container
              columns={12}
              className="global-subhead-btn-wrap top-main-border-inner"
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="global-subhead-wrap"
              >
                {/* <IconButton
                  aria-label="back"
                  className="sub-head-back-btn"
                  onClick={() => {
                    BackBtnClick();
                  }}
                  title="Back to Standards"
                >
                  <ArrowLeft2 size="24" variant="Outline" />
                </IconButton> */}
                <h2 className="pr-10">
                  <Button
                    aria-label="back"
                    className="sub-head-back-btn"
                    onClick={() => {
                      BackBtnClick();
                    }}
                    title="Back to home page"
                  >
                    {/* <ArrowLeft2 size="24" variant="Outline" /> */}
                    <ArrowBackIosNewOutlinedIcon className="" />
                    <span className="body-bold secondary-color">Back</span>
                  </Button>
                </h2>
                {/* <h2>Sub Header</h2> */}

                <h1>Standard</h1>

                <div className="vl"></div>
                <Grid
                  item
                  // lg={6}
                  // md={6}
                  // sm={12}
                  // xs={12}
                  className="vlign-middle status-version-wrap"
                >
                  <div className="standart-status-change d-flex">
                    {" "}
                    {/* removeed mb-5 class */}
                    <div className="standards-status">
                      <span>Status:</span>
                      <span
                        className={
                          standardPageState.Standard.Status ===
                          EnumStatus.Published
                            ? "status-color green"
                            : standardPageState.Standard.Status ===
                                EnumStatus.Draft &&
                              standardPageState.PageMode.PageMode !==
                                EnumPageMode.AddMode
                            ? "status-color yellow"
                            : standardPageState.Standard.Status ===
                              EnumStatus.Deactivated
                            ? "status-color gray"
                            : "status-color yellow"
                        }
                      >
                        {standardPageState.Standard.StatusNavigation?.Id ===
                        EnumStatus.Deactivated
                          ? "Deactivated"
                          : standardPageState.Standard.StatusNavigation
                              ?.Status1}
                      </span>
                    </div>
                  </div>
                  <div className="global-version-top">
                    {" "}
                    {/* removeed mb-5 class */}
                    <span>Version:</span>
                    {standardPageState.Standard != null
                      ? standardPageState.Standard.Version.toFixed(1) +
                        (standardPageState.Standard.Status ===
                          EnumStatus.Published ||
                        standardPageState.Standard.Status ===
                          EnumStatus.Deactivated
                          ? ""
                          : "")
                      : "0.1"}
                  </div>
                </Grid>
              </Grid>

              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="sub-header-btn-wrap"
              >
                {standardState.PageMode.IsShowCancelBtn && (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={CancelButtonClick}
                    className="secondary-btn-small"
                    size="small"
                    startIcon={<CloseCircle variant="Outline" />}
                  >
                    Cancel
                  </Button>
                )}
                {standardState.PageMode.IsShowCloseBtn && (
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={CloseButtonClick}
                    startIcon={<CloseCircle variant="Outline" />}
                    className="secondary-btn-small"
                    size="small"
                  >
                    Close
                  </Button>
                )}
                {standardState.PageMode.IsShowDeactivateBtn &&
                  standardState.Standard.Status !== EnumStatus.Deactivated &&
                  standardState.PageMode.PageMode === EnumPageMode.EditMode && (
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={DeactivateButtonClick}
                      startIcon={<CardSlash variant="Outline" />}
                      className="primary-btn-small"
                      size="small"
                    >
                      Deactivate
                    </Button>
                  )}

                {standardState.PageMode.IsShowEditBtn &&
                  standardState.Standard.Status !== EnumStatus.Deactivated && (
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={EditButtonClick}
                      startIcon={<Edit variant="Outline" />}
                      className="primary-btn-small"
                      size="small"
                    >
                      Edit
                    </Button>
                  )}

                {standardState.PageMode.IsShowDraftBtn &&
                  standardState.Standard.Status !== EnumStatus.Published && (
                    <Button
                      variant="contained"
                      disableElevation
                      className="primary-btn-small"
                      size="small"
                      startIcon={<ClipboardText variant="Outline" />}
                      onClick={DraftButtonClick}
                    >
                      Draft
                    </Button>
                  )}
                {standardState.PageMode.IsShowPublishedBtn && (
                  <Button
                    variant="contained"
                    disableElevation
                    className="primary-btn-small"
                    size="small"
                    startIcon={<CardSend variant="Outline" />}
                    onClick={() => PublishButtonClick()}
                  >
                    Publish
                  </Button>
                )}
                {standardState.PageMode.IsShowUpdateBtn && (
                  <Button
                    variant="contained"
                    disableElevation
                    className="primary-btn-small"
                    size="small"
                    startIcon={<GlobalEdit variant="Outline" />}
                    onClick={() => SaveButtonClick()}
                  >
                    Update
                  </Button>
                )}
                {standardState.PageMode.IsShowSaveBtn && (
                  <Button
                    variant="contained"
                    disableElevation
                    className="primary-btn-small"
                    size="small"
                    startIcon={<DirectboxReceive variant="Outline" />}
                    onClick={() => SaveButtonClick(EnumStatus.Published)}
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container className="global-subhead-wrap">
              {/* Button bar */}
              <Grid container>
                <Grid item xl={3} lg={3} md={2} sm={12} xs={12}></Grid>
                <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                  <div className="form-group">
                    <HPRTextBox
                      ClassName="no-hint"
                      Id=""
                      Label="Name"
                      Name="Name"
                      Value={standardPageState.Standard.Name}
                      Type={EnumTextBoxType.Text}
                      Disabled={standardState.PageMode.IsDisable}
                      onTextBoxChange={changeStandardPageStateStandard}
                      Validator={standardPageState.Validator}
                      Rules={"required"}
                      IsEnableValidator={true}
                      Required
                      InputProps={{ maxLength: 250 }}
                    ></HPRTextBox>
                  </div>
                  <div className="form-group">
                    <HPRDropDownMsGraphSingleSelect
                      Id=""
                      Label="Global Primary SME"
                      Name="PrimarySME"
                      SelectedValue={
                        standardPageState.Standard.PrimarySME != null
                          ? standardPageState.Standard.PrimarySME.Sme.Uuid
                          : "0"
                      }
                      Items={
                        standardPageState?.Standard?.StandardSmeDropDownList
                      }
                      Url={
                        (standardPageState?.Standard?.StandardSmeDropDownList &&
                          standardPageState?.Standard?.StandardSmeDropDownList
                            ?.length > 0) ||
                        !parameter.StandardId
                          ? "Standard/GetPrimarySMEListForDropDown"
                          : ""
                      }
                      Disabled={standardState.PageMode.IsDisable}
                      onDropDownChange={onChangePrimaryDropdown}
                      Validator={standardPageState.Validator}
                      Rules={"required"}
                      IsEnableValidator={true}
                      Required
                      IsSearchable
                    ></HPRDropDownMsGraphSingleSelect>
                  </div>
                  <div className="form-group">
                    <HPRDropDownMsGraphMultipleSelect
                      Id=""
                      Label="Global Secondary SME(s)"
                      Name="SecondarySME"
                      Select={standardPageState.SecondarySMEValues}
                      Items={
                        standardPageState?.Standard?.StandardSmeDropDownList
                      }
                      Url={
                        (standardPageState?.Standard?.StandardSmeDropDownList &&
                          standardPageState?.Standard?.StandardSmeDropDownList
                            ?.length > 0) ||
                        !parameter.StandardId
                          ? "Standard/GetPrimarySMEListForDropDown"
                          : ""
                      }
                      Disabled={standardState.PageMode.IsDisable}
                      onMultipleSelectDropDownChange={onChangeSecondaryDropdown}
                      Validator={standardPageState.Validator}
                      Rules={"required"}
                      IsEnableValidator={true}
                      Required
                      IsSearchable
                    ></HPRDropDownMsGraphMultipleSelect>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xl={3} lg={3} md={2} sm={12} xs={12}></Grid>
                <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                  <div className="main-section">
                    <label className="section-header">
                      Question(s) Summary
                    </label>
                    <div
                      className={`${
                        standardState.Standard.Id > 0
                          ? "question-summary-inner"
                          : ""
                      }`}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <div
                            className="standardstatus"
                            onClick={() => {
                              if (standardState.Standard.Id > 0) {
                                navigate("/standardsQuestions", {
                                  state: {
                                    StandardId: standardState.Standard.Id,
                                    EnumStatus: EnumStatus.Published,
                                  } as StandardQuestionsPageParametersType,
                                });
                              }
                            }}
                          >
                            <label>
                              <u className="link">
                                Published :{" "}
                                {`${
                                  standardPageState.Standard
                                    .QuestionsSummary !== null
                                    ? standardPageState.Standard
                                        .QuestionsSummary.Published
                                    : 0
                                } Question(s)`}{" "}
                              </u>
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <div
                            className="standardstatus"
                            onClick={() => {
                              if (standardState.Standard.Id > 0) {
                                navigate("/standardsQuestions", {
                                  state: {
                                    StandardId: standardState.Standard.Id,
                                    EnumStatus: EnumStatus.Draft,
                                  } as StandardQuestionsPageParametersType,
                                });
                              }
                            }}
                          >
                            <label>
                              <u className="link">
                                Draft :{" "}
                                {`${
                                  standardPageState.Standard
                                    .QuestionsSummary !== null
                                    ? standardPageState.Standard
                                        .QuestionsSummary.Draft
                                    : 0
                                } Question(s)`}{" "}
                              </u>
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <div
                            className="standardstatus"
                            onClick={() => {
                              if (standardState.Standard.Id > 0) {
                                navigate("/standardsQuestions", {
                                  state: {
                                    StandardId: standardState.Standard.Id,
                                    EnumStatus: EnumStatus.Deactivated,
                                  } as StandardQuestionsPageParametersType,
                                });
                              }
                            }}
                          >
                            <label>
                              <u className="link">
                                Deactivated :{" "}
                                {`${
                                  standardPageState.Standard
                                    .QuestionsSummary !== null
                                    ? standardPageState.Standard
                                        .QuestionsSummary.Deactivated
                                    : 0
                                } Question(s)`}
                              </u>
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default StandardPage;
