// assets
import StandardIco from "../../../icons/standard.svg";

// project imports
import { all, takeEvery, put, call } from "@redux-saga/core/effects";
import {
  SEARCHBYSTATUS,
  SEARCHQUESTION,
  STANDARDDETAILSWITHQUESTIONLIST,
} from "./StandardsQuestionsPage.action";
import {
  setByStatusQuestion,
  setFilteredStatus,
  setPagination,
  setSearchQuestion,
  setStandardDetailsWithQuestionList,
} from "./StandardsQuestionsPage.reducer";
import {
  getStandardDetailsWithQuestionList,
  getSearchQuestion,
  getQuestionByStatus,
  getStandardSourcesMenuDetails,
} from "./StandardsQuestionsPage.api";
import { StandardQuestionsType } from "./StandardsQuestionsPage.types";
import { AnyAction } from "redux";
import {
  HPRListPaginationType,
  PaginationListItemType,
} from "../../../components/common/HPRListPagination/HPRListPagination.types";
import { EnumListShowRowCountList } from "../../../common/enums/common.enums";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import {
  SearchParameterDTO,
  StatusSummaryCountDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { callAllApiRequest } from "../../../core/api/apiClient.service";

// ==============================|| VIEWS - PAGES - Login - SAGA ||============================== //

const getStandardQuestionListWatcher = function* (): any {
  // constant
  const icons = { StandardIco };
  yield takeEvery(
    STANDARDDETAILSWITHQUESTIONLIST,
    function* (action: AnyAction): any {
      const standardId: number = action.payload["StandardId"];
      const listShowCount: number =
        EnumListShowRowCountList.ListShowRowCountPerPage_2;
      const pageNo: number = action.payload["pageNo"];
      const SearchStatus: number = action.payload["SearchStatus"];
      const SortBy: number = action.payload["SortBy"];
      const SortType: number = action.payload["SortType"];

      //clear state
      yield put(
        setPagination({
          ItemList: [] as Array<PaginationListItemType>,
          ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
          TotalRecordsCount: 0,
          StatusSummaryCount: {} as StatusSummaryCountDTO,
        } as HPRListPaginationType)
      );

      // set page loading on
      yield put(setLoading(true));

      try {
        const getQuestionListApi: Promise<ActionResponseType> =
          getStandardDetailsWithQuestionList(
            standardId,
            listShowCount,
            pageNo,
            SearchStatus,
            SortBy,
            SortType
          );
        const getStandardSourcesMenuDetailsApi: Promise<ActionResponseType> =
          getStandardSourcesMenuDetails(standardId);

        const result = yield call(
          async () =>
            await callAllApiRequest([
              getQuestionListApi,
              getStandardSourcesMenuDetailsApi,
            ])
        );

        if (result[0]?.Data == null && !result[0]?.Data) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "No standard question data to show",
              HeaderText: "Success",
              PopupType: EnumPopupType.SuccessPopup,
            } as PopupProps)
          );
        } else {
          yield put(
            setPagination({
              ItemList: result[0].Data.QuestionListData?.map((question) => {
                return {
                  Icon: icons.StandardIco,
                  Id: question.Id,
                  Status: question.Status,
                  StatusID: question.StatusId,
                  //Code: question.Code,
                  Version: question.Version,
                  Text: question.RequirementText,
                  QuestionParameters: question.QuestionParameters,
                  ModifiedDate: question.LastModifiedDate,
                  ModifiedBy: question.LastModifiedBy,
                  SequenceNumber: question.SequenceNumber,
                } as PaginationListItemType;
              }),
              ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
              TotalRecordsCount: result[0].Data.QuestionListCount,
              StatusSummaryCount: result[0].Data.StatusSummaryCount,
            } as HPRListPaginationType)
          );

          if (result[1]?.Data != null && result[1]?.Data) {
            yield put(
              setStandardDetailsWithQuestionList({
                ...result[0]?.Data,
                ...result[1]?.Data,
              } as StandardQuestionsType)
            );
          }
        }

        // const result: StandardQuestionsType = yield call(() =>
        //   getStandardDetailsWithQuestionList(
        //     standardId,
        //     listShowCount,
        //     pageNo,
        //     SearchStatus,
        //     SortBy,
        //     SortType
        //   )
        // );

        // if (result == null) {
        //   yield put(
        //     openPopup({
        //       Open: true,
        //       BodyText: "No standard question data to show",
        //       HeaderText: "Success",
        //       PopupType: EnumPopupType.SuccessPopup,
        //     } as PopupProps)
        //   );
        // } else {
        //   yield put(
        //     setPagination({
        //       ItemList: result.QuestionListData.map((question) => {
        //         return {
        //           Icon: icons.StandardIco,
        //           Id: question.Id,
        //           Status: question.Status,
        //           StatusID: question.StatusId,
        //           Code: question.Code,
        //           Version: question.Version,
        //           Text: question.RequirementText,
        //           QuestionParameters: question.QuestionParameters,
        //           ModifiedDate: question.LastModifiedDate,
        //           ModifiedBy: question.LastModifiedBy,
        //           SequenceNumber: question.SequenceNumber,
        //         } as PaginationListItemType;
        //       }),
        //       ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
        //       TotalRecordsCount: result.QuestionListCount,
        //       StatusSummaryCount: result.StatusSummaryCount,
        //     } as HPRListPaginationType)
        //   );

        //   yield put(setStandardDetailsWithQuestionList(result));
        // }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Error",
            PopupType: EnumPopupType.ErrorPopup,
          } as PopupProps)
        );
      }

      // set page loading off
      yield put(setLoading(false));
    }
  );
};

const getSearchQuestionWatcher = function* (): any {
  const icons = { StandardIco };
  yield takeEvery(SEARCHQUESTION, function* (action: AnyAction): any {
    const questionSearchParameter: SearchParameterDTO = action.payload;

    // set page loading on
    yield put(setLoading(true));

    const result: StandardQuestionsType = yield call(() =>
      getSearchQuestion(questionSearchParameter)
    );
    if (result == null) {
    } else {
      yield put(
        setPagination({
          ItemList: result.QuestionListData.map((question) => {
            return {
              Icon: icons.StandardIco,
              Id: question.Id,
              Status: question.Status,
              StatusID: question.StatusId,
              //Code: question.Code,
              Version: question.Version,
              Text: question.RequirementText,
              QuestionParameters: question.QuestionParameters,
              ModifiedDate: question.LastModifiedDate,
              ModifiedBy: question.LastModifiedBy,
              SequenceNumber: question.SequenceNumber,
            } as PaginationListItemType;
          }),
          ItemShowCount: questionSearchParameter.ListPageSize,
          TotalRecordsCount: result.QuestionListCount,
          StatusSummaryCount: result.StatusSummaryCount,
        } as HPRListPaginationType)
      );

      yield put(setSearchQuestion(result));
    }
    // set page loading off
    yield put(setLoading(false));
  });
};

const getByStatusQuestionWatcher = function* (): any {
  const icons = { StandardIco };
  yield takeEvery(SEARCHBYSTATUS, function* (action: AnyAction): any {
    const status: number = action.payload["status"];
    const standardID: number = action.payload["standardID"];

    // set page loading on
    yield put(setLoading(true));

    const result: StandardQuestionsType = yield call(() =>
      getQuestionByStatus(status, standardID)
    );
    if (result == null) {
    } else {
      yield put(
        setPagination({
          ItemList: result.QuestionListData.map((question) => {
            return {
              Icon: icons.StandardIco,
              Id: question.Id,
              Status: question.Status,
              StatusID: question.StatusId,
              //Code: question.Code,
              Version: question.Version,
              Text: question.RequirementText,
              QuestionParameters: question.QuestionParameters,
              ModifiedDate: question.LastModifiedDate,
              ModifiedBy: question.LastModifiedBy,
              SequenceNumber: question.SequenceNumber,
            } as PaginationListItemType;
          }),
          ItemShowCount: EnumListShowRowCountList.ListShowRowCountPerPage_2,
          TotalRecordsCount: result.QuestionListCount,
          StatusSummaryCount: result.StatusSummaryCount,
        } as HPRListPaginationType)
      );

      yield put(setFilteredStatus(status));

      yield put(setByStatusQuestion(result));
    }
    // set page loading off
    yield put(setLoading(false));
  });
};

const standardQuestionSaga = function* () {
  yield all([
    getStandardQuestionListWatcher(),
    getSearchQuestionWatcher(),
    getByStatusQuestionWatcher(),
  ]);
};

export default standardQuestionSaga;
