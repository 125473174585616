import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  FileUploaderErrorType,
  FileUploaderState,
  HPRFileUploaderProps,
} from "./HPRFileUploader.types";
import { EnumFileUploaderError } from "./HPRFileUploader.enum";
import { UploadFileDTO } from "../../../common/types/common.dto.types";
import { EnumTextBoxType } from "../HPRTextBox/HPRTextBox.enum";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { openPopup, updatePopupProps } from "../../Popups/Popup.reducer";
import { EnumPopupType } from "../../Popups/Popup.enum";
import { PopupProps } from "../../Popups/PopupProps.types";
import { Bag, Edit, InfoCircle } from "iconsax-react";
import word from "../../../icons/word.png";
import pdf from "../../../icons/pdf.png";
import email from "../../../icons/email.png";
// import excel from "../../../icons/reports.svg";
import excel from "../../../icons/excel.png";
import { GetFileFromBackend, fileRename } from "./HPRFileUploader.api";
import {
  EnumDataTransferStatus,
  EnumFileType,
} from "../../../common/enums/common.enums";
import {
  updateDownloadFileStatus,
  updateFileName,
} from "../../../views/pages/QuestionnairePage/QuestionnairePage.reducer";

import { ActionResponseType } from "../../../common/types/common.types";
import { setLoading } from "../../../views/layouts/main-layout/MainLayout.reducer";
import {
  MainLayoutType,
  UploadFileSetting,
} from "../../../views/layouts/main-layout/MainLayout.types";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import HPRTextBoxAutoResize from "../HPRTextBox/HPRTextBoxAutoResize";
// ==============================|| COMPONENTS - COMMON - HPRFILEUPLOADER ||============================== //

const HPRFileUploader = (props: HPRFileUploaderProps) => {
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    undefined
  );
  const [openImageSlider, setOpenImageSlider] = useState<boolean>(false);
  const mainLayoutState: MainLayoutType = useAppSelector(
    (state) => state.mainLayoutReducer
  );

  const uploadFileSettings: Array<UploadFileSetting> =
    mainLayoutState.AppConfiguration.UploadFileSettings.filter((x) =>
      props.FileType.includes(x.FileType)
    );

  const uploadFileSettingsForImages: Array<UploadFileSetting> =
    mainLayoutState.AppConfiguration.UploadFileSettings;

  const initialState = {
    UploadedFileList: [] as Array<UploadFileDTO>,
  } as FileUploaderState;
  //set initial file list
  if (props.UploadedFileList !== undefined) {
    initialState.UploadedFileList = props.UploadedFileList;
  }

  // reducer manage fucntions
  const dispatch = useAppDispatch();

  const getMaxAllowedSize = (fileType: EnumFileType): string => {
    let requiredFileConfig = uploadFileSettings.find(
      (x) => x.FileType === fileType
    );
    let allowedSize: number = requiredFileConfig?.AllowedMaxFileSize;

    let size = "";
    if (allowedSize < 1024) {
      size = allowedSize + "B";
    } else if (allowedSize < 1024 * 1024) {
      size = +(allowedSize / 1024).toFixed(2) + "KB";
    } else {
      size = +(allowedSize / (1024 * 1024)).toFixed(2) + "MB";
    }

    return size;
  };

  const getFileType = (file: File): EnumFileType => {
    let fileType = EnumFileType.Default;
    for (let x = 0; x < uploadFileSettings?.length; x++) {
      let requiredTypeArray =
        uploadFileSettings[x]?.AllowedFileTypes?.split(",");

      if (requiredTypeArray != null && requiredTypeArray.length > 0) {
        for (let y = 0; y < requiredTypeArray.length; y++) {
          if (
            requiredTypeArray[y].includes(
              file.name.split(".").pop().split("/").pop().toLowerCase()
            ) ||
            (file.name != null && file.name.endsWith(requiredTypeArray[y]))
          ) {
            fileType = uploadFileSettings[x].FileType;

            return fileType;
          }
        }
      }
    }

    return fileType;
  };

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    let fileType = EnumFileType.Default;
    let uploadedFileList: Array<UploadFileDTO> = props.UploadedFileList
      ? [...props.UploadedFileList]
      : ([] as Array<UploadFileDTO>);
    let errorMessages: Array<string> = [] as Array<string>;

    const getMaxAllowedSize = (): string => {
      let requiredFileConfig = uploadFileSettings.find(
        (x) => x.FileType === fileType
      );
      let allowedSize: number = requiredFileConfig?.AllowedMaxFileSize;

      let size = "";
      if (allowedSize < 1024) {
        size = allowedSize + "B";
      } else if (allowedSize < 1024 * 1024) {
        size = +(allowedSize / 1024).toFixed(2) + "KB";
      } else {
        size = +(allowedSize / (1024 * 1024)).toFixed(2) + "MB";
      }

      return size;
    };

    for (const _file of Array.from(event.target.files)) {
      let file: File = _file;
      const filename: string = file.name;
      let size = "";

      // ==============================|| START FILE VALIDATION ||============================== //

      if (props.NotallowMultipleFile && props.UploadedFileList?.length > 1) {
        returnSelectedFileError(EnumFileUploaderError.NotallowMultiple);
        event.target.value = null;
        return;
      }

      if (file.size < 1024) {
        size = file.size + " B";
      } else if (file.size < 1024 * 1024) {
        size = +(file.size / 1024).toFixed(2) + " KB";
      } else {
        size = +(file.size / (1024 * 1024)).toFixed(2) + " MB";
      }

      // check file type
      fileType = getFileType(file);
      if (fileType === EnumFileType.Default) {
        returnSelectedFileError(EnumFileUploaderError.FileTypeNotMatch);
        event.target.value = null;
        let bodyText = "Uploaded file not supported";

        dispatch(
          openPopup({
            Open: true,
            BodyText: bodyText,
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
          } as PopupProps)
        );
        return;
      }

      let requiredFileConfig = uploadFileSettings?.find(
        (x) => x.FileType === fileType
      );

      // check file size 10 MB
      if (file.size >= +requiredFileConfig?.AllowedMaxFileSize) {
        errorMessages.push(file.name);
      } else {
        // ==============================|| END FILE VALIDATION ||============================== //

        uploadedFileList = props.Multiple
          ? ([
              ...uploadedFileList,
              {
                Id: 0,
                File: file,
                FileName: filename,
                FilePath: URL.createObjectURL(file),
                FileType: fileType,
                FileSize: size,
                FileLength: file.size,
                UploadStatus: EnumDataTransferStatus.NotStarted,
                DownloadStatus: EnumDataTransferStatus.Default,
              } as UploadFileDTO,
            ] as Array<UploadFileDTO>)
          : ([
              {
                Id: 0,
                File: file,
                FileName: filename,
                FilePath: URL.createObjectURL(file),
                FileType: fileType,
                FileSize: size,
                FileLength: file.size,
                UploadStatus: EnumDataTransferStatus.NotStarted,
                DownloadStatus: EnumDataTransferStatus.Default,
              } as UploadFileDTO,
            ] as Array<UploadFileDTO>);
      }
    }

    // if any errors on file related to size shows here
    if (errorMessages && errorMessages.length > 0) {
      dispatch(
        openPopup({
          Open: true,
          BodyText: `
          The following file(s) are too large to upload. Maximum file size is ${getMaxAllowedSize()} per attachment.<br/>
         <ul class="upload-file-list">
          ${errorMessages
            .map((fileName: string, index: number) => {
              return `<li key={${index}}>${fileName}</li>`;
            })
            .join("")}</ul>`,
          HeaderText: "Warning",
          PopupType: EnumPopupType.WarningPopup,
        } as PopupProps)
      );
    }

    //clear event after upload
    event.target.value = null;
    if (props.GetselectedFiles) {
      props.GetselectedFiles(uploadedFileList);
    }
  };

  const removeUploadedFile = (removefile: number) => {
    let uploadedFileList: Array<UploadFileDTO> = [...props.UploadedFileList];

    // remove file from prop list
    uploadedFileList = uploadedFileList.filter(
      (item, index) => item.Id !== removefile
    );

    if (props.GetselectedFiles) {
      props.GetselectedFiles(uploadedFileList);
    }
  };

  //return selected file error
  const returnSelectedFileError = (
    errorType: EnumFileUploaderError,
    error?: string
  ) => {
    if (props.GetError) {
      props.GetError({
        ErrorMessage: error,
        ErrorType: errorType,
      } as FileUploaderErrorType);
    }
  };

  const getStatusCss = (file: UploadFileDTO): string => {
    if (
      file.UploadStatus === EnumDataTransferStatus.InProgress ||
      file.DownloadStatus === EnumDataTransferStatus.InProgress
    ) {
      return "load";
    } else if (file.Id > 0 && EnumDataTransferStatus.Finished) {
      return "done";
    }
    return "";
  };

  const setFileType = (): string => {
    let allowedTypeArr = [];
    let allowedTypes = "";
    for (let x = 0; x < uploadFileSettings?.length; x++) {
      allowedTypeArr.push(uploadFileSettings[x].AllowedFileTypes);
    }
    allowedTypes = allowedTypeArr.join() ?? null;
    return allowedTypes ? allowedTypes : "*";
  };

  const OnAddCommentChange = (
    fileIndex: number,
    fileType: number,
    value: string
  ): void => {
    let filteredFileIndex = -1;
    const uploadedFileList: Array<UploadFileDTO> = [
      ...props.UploadedFileList?.map((fileData) => {
        // find correct index according to selected file type
        if (props.FileType.includes(fileData.FileType)) {
          filteredFileIndex += 1;
        }

        if (filteredFileIndex === fileIndex && fileData.FileType === fileType) {
          return { ...fileData, Comment: value };
        }
        return { ...fileData };
      }),
    ];

    if (props.GetselectedFiles) {
      props.GetselectedFiles([...uploadedFileList] as Array<UploadFileDTO>);
    }
  };

  const DownloadFile = (uploadFileDTO: UploadFileDTO): void => {
    dispatch(
      updateDownloadFileStatus({
        ...uploadFileDTO,
        DownloadStatus: EnumDataTransferStatus.InProgress,
      })
    );
    GetFileFromBackend(uploadFileDTO, () => {
      dispatch(
        updateDownloadFileStatus({
          ...uploadFileDTO,
          DownloadStatus: EnumDataTransferStatus.Finished,
        })
      );
    });
  };

  const handleRename = async (
    file: UploadFileDTO,
    value: string,
    closePopup: () => void
  ) => {
    // Regex to allow alphanumeric characters, spaces, underscores, dots, and hyphens
    const allowedCharactersRegex = /^[a-zA-Z0-9 _.)(-]+$/;

    if (value.trim().length > 0) {
      if (allowedCharactersRegex.test(value)) {
        dispatch(setLoading(true));
        const r: ActionResponseType = await fileRename(file.Id, value);
        if (r.IsSuccess) {
          dispatch(updateFileName({ Id: file.Id, Name: r.Data as string }));
          closePopup();
        } else {
          dispatch(
            updatePopupProps({
              ErrorMessage: r.ErrorMessage,
            })
          );
        }
        dispatch(setLoading(false));
      } else {
        dispatch(
          updatePopupProps({
            ErrorMessage:
              "Invalid filename! Allowed characters are: letters, numbers, spaces, underscores, dots, and hyphens",
          })
        );
      }
    } else {
      dispatch(
        updatePopupProps({
          ErrorMessage: "Please enter a file name",
        })
      );
    }
  };

  const getFileTypeFromFileName = (fileName: string): EnumFileType => {
    let fileType = EnumFileType.Default;
    for (let x = 0; x < uploadFileSettingsForImages?.length; x++) {
      let requiredTypeArray =
        uploadFileSettingsForImages[x]?.AllowedFileTypes?.split(",");

      if (requiredTypeArray != null && requiredTypeArray.length > 0) {
        for (let y = 0; y < requiredTypeArray.length; y++) {
          if (fileName.toLocaleLowerCase().includes(requiredTypeArray[y])) {
            fileType = uploadFileSettingsForImages[x].FileType;

            return fileType;
          }
        }
      }
    }

    return fileType;
  };

  const getMaxAllowedFileSizeLabel = (): string => {
    const maxFileSizeLabel = props.FileType?.map((fileType) => {
      let fileSizeLabel = "0MB";
      if (fileType === EnumFileType.Image) {
        fileSizeLabel = getMaxAllowedSize(EnumFileType.Image);
      } else if (fileType === EnumFileType.Doc) {
        fileSizeLabel = `Document(s) ${getMaxAllowedSize(EnumFileType.Doc)}`;
      } else if (fileType === EnumFileType.Email) {
        fileSizeLabel = `Email(s) ${getMaxAllowedSize(EnumFileType.Email)}`;
      } else if (fileType === EnumFileType.Excel) {
        fileSizeLabel = `Excel(s) ${getMaxAllowedSize(EnumFileType.Excel)}`;
      } else {
        fileSizeLabel = getMaxAllowedSize(EnumFileType.Default);
      }
      return fileSizeLabel;
    })?.join(", ");

    return `Max ${maxFileSizeLabel ?? "0MB"} per attachment`;
  };

  return (
    <>
      <div className="file-btn-wrapper">
        <div className="flex--container valign-middle">
          <a className="header-lbl icon-a flex__item--vcenter description-tooltip first">
            <span className="icon-span flex__item--vcenter">
              <Tooltip title={getMaxAllowedFileSizeLabel()}>
                <InfoCircle
                  className="icon-btn mr-5"
                  color={"#0288d1"}
                  variant="Bold"
                  size={20}
                />
              </Tooltip>
            </span>
          </a>
          <Button
            className={
              props.Disabled
                ? "disabled primary large-btn flex__item--vcenter"
                : "primary large-btn flex__item--vcenter"
            }
            variant="contained"
            component="label"
          >
            {props.Label}
            <input
              hidden
              multiple={props.Multiple}
              type="file"
              accept={setFileType()}
              onChange={(e) => onChangeFileUpload(e)}
            />
          </Button>

          <a className="header-lbl icon-a flex__item--vcenter description-tooltip second">
            <span className="icon-span flex__item--vcenter">
              <Tooltip title={getMaxAllowedFileSizeLabel()}>
                <InfoCircle
                  className="icon-btn ml-5"
                  color={"#0288d1"}
                  variant="Bold"
                  size={20}
                />
              </Tooltip>
            </span>
          </a>
        </div>

        {/* <p className="file-uploader">{getMaxAllowedFileSizeLabel()}</p> */}
      </div>
      {props.UploadedFileList?.length > 0 &&
        props.UploadedFileList?.filter((file) =>
          props.FileType.includes(file.FileType)
        ).map((file: UploadFileDTO, index: number) => {
          return (
            <div key={`fileId-${index}`} className="flex--container">
              <div className="flex__item">
                <div
                  className="flex--container img-upload-wrapper fileupload-responsive"
                  style={{ position: "relative" }}
                >
                  {props.PreviewImage ? (
                    <div
                      className="img-wrap flex__item--vcenter pr-10"
                      onClick={() => {
                        setSelectedImage(
                          file.FilePreview != null
                            ? file.FilePreview
                            : file.FilePath
                        );
                        setOpenImageSlider(true);
                      }}
                    >
                      <img
                        className=""
                        alt=""
                        src={
                          file.FilePreview != null
                            ? file.FilePreview
                            : file.FilePath
                        }
                        key={file.Id}
                      />
                    </div>
                  ) : (
                    <div className="img-wrap flex__item--vcenter pr-10">
                      {getFileTypeFromFileName(file.FileName) ===
                        EnumFileType.Doc && file.FileName.includes(".pdf") ? (
                        <img src={pdf} className="file-pdf" />
                      ) : (getFileTypeFromFileName(file.FileName) ===
                          EnumFileType.Doc &&
                          file.FileName.includes(".xls")) ||
                        (getFileTypeFromFileName(file.FileName) ===
                          EnumFileType.Doc &&
                          file.FileName.includes(".xlsx")) ? (
                        <img src={excel} className="file-excel" />
                      ) : getFileTypeFromFileName(file.FileName) ===
                        EnumFileType.Doc ? (
                        <img src={word} className="file-pdf" />
                      ) : getFileTypeFromFileName(file.FileName) ===
                        EnumFileType.Email ? (
                        <img src={email} className="file-email" />
                      ) : getFileTypeFromFileName(file.FileName) ===
                        EnumFileType.Excel ? (
                        <img src={excel} className="file-excel" />
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  <div className="flex__item flex-wrap text-left">
                    <div
                      className="link"
                      onClick={() => DownloadFile(file)}
                    >
                      <Tooltip title={file.FileName}>
                        <span className="limit-text limit-1">
                          {file.FileName}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="body3">{file.FileSize}</div>
                  </div>
                  <div>
                    {/* Don't remove this comment it's need */}
                    {(file.Id === 0 ||
                      file.UploadStatus ||
                      file.DownloadStatus) && (
                      <div
                        className={`btn-circle-download ${getStatusCss(file)}`}
                      >
                        <svg
                          id="arrow"
                          width="14px"
                          height="20px"
                          viewBox="17 14 14 20"
                          transform={
                            file.UploadStatus ===
                              EnumDataTransferStatus.NotStarted ||
                            file.UploadStatus ===
                              EnumDataTransferStatus.InProgress
                              ? "rotate(180)"
                              : "rotate(0)"
                          }
                        >
                          <path d="M24,15 L24,32"></path>
                          <polyline points="30 27 24 33 18 27"></polyline>
                        </svg>
                        <svg
                          id="check"
                          width="21px"
                          height="15px"
                          viewBox="13 17 21 15"
                        >
                          <polyline points="32.5 18.5 20 31 14.5 25.5"></polyline>
                        </svg>
                        <svg
                          id="border"
                          width="48px"
                          height="40px"
                          viewBox="0 0 40 40"
                        >
                          <path d="M24,1 L24,1 L24,1 C36.7025492,1 47,11.2974508 47,24 L47,24 L47,24 C47,36.7025492 36.7025492,47 24,47 L24,47 L24,47 C11.2974508,47 1,36.7025492 1,24 L1,24 L1,24 C1,11.2974508 11.2974508,1 24,1 L24,1 Z"></path>
                        </svg>
                      </div>
                    )}

                    <Button
                      className={
                        props.Disabled ||
                        file.UploadStatus ===
                          EnumDataTransferStatus.NotStarted ||
                        file.UploadStatus === EnumDataTransferStatus.InProgress
                          ? "disabled flex__item--vcenter"
                          : "flex__item--vcenter"
                      }
                      title="Rename"
                      onClick={() => {
                        dispatch(
                          openPopup({
                            Open: true,
                            HeaderText: "Rename file",
                            BodyText: "Type in the new file name here",
                            InputText:
                              file.FileName.substring(
                                0,
                                file.FileName.lastIndexOf(".")
                              ) || file.FileName,
                            PopupType: EnumPopupType.TextInputPopup,
                            InputTextCount: 250,
                            ActionBtnClick: (value, closePopup) => {
                              handleRename(file, value, closePopup);
                            },
                          } as PopupProps)
                        );
                      }}
                    >
                      {/* Rename */}
                      <Edit
                        variant="Outline"
                        className="ico-primary"
                        size="20"
                        color="#68c321"
                      />
                    </Button>
                    <Button
                      className={
                        props.Disabled ||
                        file.UploadStatus ===
                          EnumDataTransferStatus.NotStarted ||
                        file.UploadStatus === EnumDataTransferStatus.InProgress
                          ? "disabled flex__item--vcenter"
                          : "flex__item--vcenter"
                      }
                      title="Remove"
                      onClick={() => {
                        dispatch(
                          openPopup({
                            Open: true,
                            BodyText:
                              "Are you sure you want to delete this file?",
                            HeaderText: "Confirmation",
                            PopupType: EnumPopupType.YesNoConfirmation,
                            YesBtnClick: () => {
                              removeUploadedFile(file.Id);
                            },
                          } as PopupProps)
                        );
                      }}
                    >
                      {/* Remove */}
                      <Bag
                        variant="Outline"
                        className="ico-primary"
                        size="20"
                        color="#ef5350"
                      />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex__item">
                <div className="pl-10">
                  {props.ShowComment && (
                    <HPRTextBoxAutoResize
                      Id=""
                      Label={"Add Comment"}
                      TextLength={100}
                      Name={`Comment_${index}_${file.FileType}`}
                      Type={EnumTextBoxType.Text}
                      Value={file.Comment != null ? file.Comment : ""}
                      // ClassName={props.Disabled ? "disabled" : ""}
                      onTextBoxChange={(name: string, value: string) =>
                        OnAddCommentChange(index, file.FileType, value)
                      }
                      ShowTextCounter
                      MultiLine={true}
                      Disabled={
                        props.Disabled ||
                        file.UploadStatus ==
                          EnumDataTransferStatus.NotStarted ||
                        file.UploadStatus == EnumDataTransferStatus.InProgress
                      }
                      InputProps={{ maxLength: 100 }}
                    ></HPRTextBoxAutoResize>
                  )}
                </div>
              </div>
              {selectedImage && (
                <Grid item xs={12} lg={12} md={12} xl={4}>
                  <FormGroup>
                    <FormControl>
                      <Modal
                        onClose={() => {
                          setOpenImageSlider(false);
                          setSelectedImage(undefined);
                        }}
                        open={openImageSlider}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            maxHeight: "90%",
                            bgcolor: "background.paper",
                            border: "2px solid #000",
                            boxShadow: 24,
                            p: 4,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton
                            aria-label="close"
                            onClick={() => {
                              setOpenImageSlider(false);
                              setSelectedImage(undefined);
                            }}
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <TransformWrapper>
                            <TransformComponent
                              wrapperStyle={{
                                width: "100%",
                                height: "100%",
                                // width: "auto",
                                // height: "auto",
                              }}
                            >
                              <img
                                src={selectedImage}
                                alt=""
                                style={{
                                  // maxWidth: "100%",
                                  // maxHeight: "80%",
                                  maxWidth: "80vw",
                                  maxHeight: "80vh",
                                  minHeight: "40vh",
                                }}
                              />
                            </TransformComponent>
                          </TransformWrapper>
                        </Box>
                      </Modal>
                    </FormControl>
                  </FormGroup>
                </Grid>
              )}
            </div>
          );
        })}
    </>
  );
};

export default HPRFileUploader;
