import { InspectionQuestionDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";

// ==============================|| VIEWS - COMPONENTS - VIEW INSPECTION QUESTION - API ||============================== //

// client object for axios calls to backend
const client = new ApiClient();

export const getViewInspectionQuestionDetails = async (
  inspectionQuestion: InspectionQuestionDTO
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Questionnaire/GetInspectionQuestionsDetailsForRemediationTracker`;
    let questionnaireResponse = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, inspectionQuestion)
      .then((response: ActionResponseType) => {
        questionnaireResponse = response;
      })
      .catch((error) => {
        questionnaireResponse = {} as ActionResponseType;
      });
    return questionnaireResponse;
  } catch (error) {
    throw error;
  }
};
