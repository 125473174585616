import { takeEvery, put, call, select, fork } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import {
  EnumApprovalStatus,
  EnumDataTransferStatus,
  EnumInspectionStandardStatus,
  EnumPageMode,
} from "../../../common/enums/common.enums";
import {
  InspectionQuestionDTO,
  QuestionnaireDTO,
  UploadFileDTO,
} from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { EnumPopupType } from "../../../components/Popups/Popup.enum";
import { openPopup } from "../../../components/Popups/Popup.reducer";
import { PopupProps } from "../../../components/Popups/PopupProps.types";
import {
  APPROVEORREJECTINSPECTIONQUESTIONANSWER,
  GETINSPECTIONQUESTION,
  INSPECTIONFILEUPLOAD,
  SAVEINSPECTIONQUESTIONANSWERS,
  SAVEINSPECTIONSTANDARDSTATUS,
} from "./QuestionnairePage.action";
import {
  updateQuestionAnswerApprovalStatus,
  getSiteInspectionQuestiontData,
  inspectionFileUpload,
  saveInspectionQuestionAnswer,
  saveInspectionStandardStatus,
} from "./QuestionnairePage.api";
import {
  setPageMode,
  setPageModeRedirectMode,
  setSiteInspectionQuestionnaire,
  updateQuestionnaireSaveRunningStatus,
  updateUploadFilesList,
  updateUploadFileStatus,
} from "./QuestionnairePage.reducer";
import { QuestionnaireDataType } from "./QuestionnairePage.types";
import { QuestionnaireSummaryPageParametersType } from "../QuestionnaireSummaryPage/QuestionnaireSummaryPage.types";
import { RedirectObject } from "../../../common/types/common.page.type";
import { SiteInspectionStartPageParametersType } from "../SiteInspectionStartPage/SiteInspectionStartPage.types";
import { setLoading } from "../../layouts/main-layout/MainLayout.reducer";
import { RootState } from "../../../core/store";
import { QuestionnaireStartPageParametersTypes } from "../QuestionnaireStartPage/QuestionnaireStartPage.types";

const getSiteInspectionQuestionWatcher = function* (): any {
  yield takeEvery(GETINSPECTIONQUESTION, function* (action: AnyAction): any {
    // set page loading on
    yield put(setLoading(true));
    // get data from backend

    const SearchParam: QuestionnaireDTO = action.payload;

    const state: QuestionnaireDataType = yield select(
      (s: RootState) => s.siteInspectionQuestionnaireReducer
    );

    let toBeUploaded: Array<UploadFileDTO> =
      state.Questionnaire.UploadedFileList.filter(
        (file) =>
          file.UploadStatus === EnumDataTransferStatus.NotStarted ||
          file.UploadStatus === EnumDataTransferStatus.InProgress
      );
    if (toBeUploaded.length > 0) {
      yield put(
        updateQuestionnaireSaveRunningStatus({
          QuestionnaireSaveActionName: GETINSPECTIONQUESTION,
          IsQuestionnaireSaveRunning: true,
          QuestionnaireSaveCopy: SearchParam,
        } as QuestionnaireDataType)
      );
    } else {
      try {
        const result: ActionResponseType = yield call(() =>
          getSiteInspectionQuestiontData(SearchParam)
        );
        if (!result.IsSuccess) {
          yield put(setLoading(false));
          yield put(
            openPopup({
              Open: true,
              BodyText: "No inspection question data to show",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          if (result === null) {
            yield put(setLoading(false));
            yield put(
              openPopup({
                Open: true,
                BodyText: "No inspection question data to show",
                HeaderText: "Warning",
                PopupType: EnumPopupType.SuccessPopup,
                // okBtnClick:  ()=>navigate("/standards", {}),
              } as PopupProps)
            );
          } else {
            // yield put(clearPageState());
            const inspectionQuestionnaireDTO: QuestionnaireDTO = result.Data;

            if (
              inspectionQuestionnaireDTO.IsSiteInspectionViewButtonClick ||
              inspectionQuestionnaireDTO.InspectionQuestion.InspectionStandard
                ?.InspectionStandardStatusId ===
                EnumInspectionStandardStatus.Completed
            ) {
              yield put(setPageMode(EnumPageMode.ViewMode));
              inspectionQuestionnaireDTO.ReadOnlyMode = true;

              if (
                inspectionQuestionnaireDTO.IsFromQuestionnairePge &&
                inspectionQuestionnaireDTO.ReferenceID === 0
              ) {
                yield put(
                  openPopup({
                    Open: true,
                    BodyText:
                      "Inspection already been completed by another user.",
                    HeaderText: "Warning",
                    PopupType: EnumPopupType.WarningPopup,
                    // okBtnClick: () => navigate("/standards", {}),
                  } as PopupProps)
                );
              } else if (inspectionQuestionnaireDTO.ReferenceID > 0) {
                let redirectObject = {
                  NavigateURl: "/questionnaireStart",
                  NavigateObject: {
                    state: {
                      StandardId: inspectionQuestionnaireDTO.StandardID,
                      InspectionId: inspectionQuestionnaireDTO.SiteInspectionID,
                      InspectionStandardId:
                        inspectionQuestionnaireDTO.InspectionStandardId,
                      Title: inspectionQuestionnaireDTO.InspectionStandardName,
                      IsContinueButtonVisible: true,
                    } as QuestionnaireStartPageParametersTypes,
                  },
                } as RedirectObject;

                yield put(setPageModeRedirectMode(redirectObject));
              }
            } else if (inspectionQuestionnaireDTO.IsEnableReviewByGlobalSME) {
              if (
                inspectionQuestionnaireDTO.ReadOnlyMode ||
                (inspectionQuestionnaireDTO.InspectionQuestion?.IsAnswered ??
                  false)
              ) {
                yield put(setPageMode(EnumPageMode.ViewMode));
                inspectionQuestionnaireDTO.ReadOnlyMode = true;
              } else {
                yield put(setPageMode(EnumPageMode.AddMode));
                inspectionQuestionnaireDTO.ReadOnlyMode = false;
              }
            } else {
              yield put(setPageMode(EnumPageMode.AddMode));
              inspectionQuestionnaireDTO.ReadOnlyMode = false;
            }

            yield put(
              setSiteInspectionQuestionnaire({
                ...inspectionQuestionnaireDTO,
                RejectedNow: false,
              })
            );
            yield put(setLoading(false));
          }
        }
      } catch (error) {
        yield put(setLoading(false));
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when getting data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            // okBtnClick: () => navigate("/standards", {}),
          } as PopupProps)
        );
      }
    }

    // set page loading on
    // yield put(setLoading(false));
  });
};

const saveSiteInspectionQuestionWatcher = function* (): any {
  yield takeEvery(
    SAVEINSPECTIONQUESTIONANSWERS,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));
      const InspectionQuestionAnswer: QuestionnaireDTO = action.payload;
      const state: QuestionnaireDataType = yield select(
        (s: RootState) => s.siteInspectionQuestionnaireReducer
      );
      let toBeUploaded: Array<UploadFileDTO> =
        state.Questionnaire.UploadedFileList.filter(
          (file) =>
            file.UploadStatus === EnumDataTransferStatus.NotStarted ||
            file.UploadStatus === EnumDataTransferStatus.InProgress
        );

      if (toBeUploaded.length > 0) {
        yield put(
          updateQuestionnaireSaveRunningStatus({
            QuestionnaireSaveActionName: SAVEINSPECTIONQUESTIONANSWERS,
            IsQuestionnaireSaveRunning: true,
            QuestionnaireSaveCopy: InspectionQuestionAnswer,
          } as QuestionnaireDataType)
        );
      } else {
        let toBeUploaded: Array<UploadFileDTO> =
          state.Questionnaire.UploadedFileList.filter(
            (file) =>
              file.UploadStatus === EnumDataTransferStatus.NotStarted ||
              file.UploadStatus === EnumDataTransferStatus.InProgress
          );
        try {
          // save Subject data from backend

          const result: ActionResponseType = yield call(() =>
            saveInspectionQuestionAnswer(InspectionQuestionAnswer)
          );

          if (!result.IsSuccess) {
            yield put(
              openPopup({
                Open: true,
                BodyText: result.ErrorMessage
                  ? result.ErrorMessage
                  : "An error occurred when saving data",
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          } else {
            const inspectionQuestionnaireDTO: QuestionnaireDTO = result.Data;

            yield put(
              setSiteInspectionQuestionnaire({
                ...inspectionQuestionnaireDTO,
                RejectedNow: false,
              })
            );

            if (inspectionQuestionnaireDTO.IsEnableReviewByGlobalSME) {
              if (
                inspectionQuestionnaireDTO.ReadOnlyMode ||
                inspectionQuestionnaireDTO.IsSiteInspectionViewButtonClick
              ) {
                yield put(setPageMode(EnumPageMode.ViewMode));
              } else {
                yield put(setPageMode(EnumPageMode.EditMode));
              }
            }
          }
        } catch (error) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when saving data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
              // okBtnClick: () => navigate("/standards", {}),
            } as PopupProps)
          );
        }

        // set page loading
        yield put(setLoading(false));
      }
    }
  );
};

const saveSiteInspectionStandardStatusWatcher = function* (): any {
  yield takeEvery(
    SAVEINSPECTIONSTANDARDSTATUS,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));
      const InspectionStandardStatusParam: QuestionnaireDTO = action.payload;

      const state: QuestionnaireDataType = yield select(
        (s: RootState) => s.siteInspectionQuestionnaireReducer
      );

      let toBeUploaded: Array<UploadFileDTO> =
        state.Questionnaire.UploadedFileList.filter(
          (file) =>
            file.UploadStatus === EnumDataTransferStatus.NotStarted ||
            file.UploadStatus === EnumDataTransferStatus.InProgress
        );
      if (toBeUploaded.length > 0) {
        yield put(
          updateQuestionnaireSaveRunningStatus({
            QuestionnaireSaveActionName: SAVEINSPECTIONSTANDARDSTATUS,
            IsQuestionnaireSaveRunning: true,
            QuestionnaireSaveCopy: InspectionStandardStatusParam,
          } as QuestionnaireDataType)
        );
      } else {
        // use for page navigation
        try {
          // save Subject data from backend

          const result: ActionResponseType = yield call(() =>
            saveInspectionStandardStatus(InspectionStandardStatusParam)
          );

          if (!result.IsSuccess) {
            yield put(
              openPopup({
                Open: true,
                BodyText: result.ErrorMessage
                  ? result.ErrorMessage
                  : "An error occurred when saving data",
                HeaderText: "Warning",
                PopupType: EnumPopupType.WarningPopup,
              } as PopupProps)
            );
          } else {
            //yield put(setLoading(false));

            const inspectionQuestionnaireDTO: QuestionnaireDTO = result.Data;
            if (
              inspectionQuestionnaireDTO.IsFromQuestionnairePge &&
              inspectionQuestionnaireDTO.ReferenceID === 0
            ) {
              yield put(setPageMode(EnumPageMode.ViewMode));
              inspectionQuestionnaireDTO.ReadOnlyMode = true;
              yield put(
                openPopup({
                  Open: true,
                  BodyText:
                    "Inspection already been completed by another user.",
                  HeaderText: "Warning",
                  PopupType: EnumPopupType.WarningPopup,
                  // okBtnClick: () => navigate("/standards", {}),
                } as PopupProps)
              );

              yield put(
                setSiteInspectionQuestionnaire({
                  ...inspectionQuestionnaireDTO,
                  RejectedNow: false,
                })
              );
            } else {
              if (
                InspectionStandardStatusParam.InspectionStandardStatus ===
                  EnumInspectionStandardStatus.Paused &&
                !inspectionQuestionnaireDTO.IsReferenceButtonClick
              ) {
                let redirectObject = {
                  NavigateURl: "/siteInspectionStart",
                  NavigateObject: {
                    state: {
                      InspectionId:
                        InspectionStandardStatusParam.SiteInspectionID,
                    } as SiteInspectionStartPageParametersType,
                  },
                } as RedirectObject;

                yield put(setPageModeRedirectMode(redirectObject));
              } else if (
                InspectionStandardStatusParam.InspectionStandardStatus ===
                  EnumInspectionStandardStatus.Completed &&
                !inspectionQuestionnaireDTO.IsReferenceButtonClick
              ) {
                let redirectObject = {
                  NavigateURl: "/questionnaireSummary",
                  NavigateObject: {
                    state: {
                      StandardId: InspectionStandardStatusParam.StandardID,
                      SiteInspectionId:
                        InspectionStandardStatusParam.SiteInspectionID,
                      ReAssess: false,
                      InspectionStandardId:
                        InspectionStandardStatusParam.InspectionStandardId,
                    } as QuestionnaireSummaryPageParametersType,
                  },
                } as RedirectObject;

                yield put(setPageModeRedirectMode(redirectObject));
              } else if (
                inspectionQuestionnaireDTO.ReferenceID > 0 &&
                inspectionQuestionnaireDTO.IsReferenceButtonClick
              ) {
                let redirectObject = {
                  NavigateURl: "/questionnaireStart",
                  NavigateObject: {
                    state: {
                      StandardId: InspectionStandardStatusParam.StandardID,
                      InspectionId:
                        InspectionStandardStatusParam.SiteInspectionID,
                      InspectionStandardId:
                        InspectionStandardStatusParam.InspectionStandardId,
                      Title:
                        InspectionStandardStatusParam.InspectionStandardName,
                      IsContinueButtonVisible: true,
                    } as QuestionnaireStartPageParametersTypes,
                  },
                } as RedirectObject;

                yield put(setPageModeRedirectMode(redirectObject));
              }
            }
          }
        } catch (error) {
          yield put(
            openPopup({
              Open: true,
              BodyText: "An error occurred when saving data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        }

        // set page loading
        yield put(setLoading(false));
      }
    }
  );
};

const inspectionFileUploadWatcher = function* (): any {
  const fileuploading = yield takeEvery(
    INSPECTIONFILEUPLOAD,
    function* (): any {
      // get questionnaire page state from reducer

      try {
        let toBeUploaded: Array<UploadFileDTO> = [] as Array<UploadFileDTO>;
        let inpogressUploaded: UploadFileDTO = {} as UploadFileDTO;

        do {
          const state: QuestionnaireDataType = yield select(
            (s: RootState) => s.siteInspectionQuestionnaireReducer
          );

          // check file is in uploading progress
          inpogressUploaded = state.Questionnaire.UploadedFileList.find(
            (file) => file.UploadStatus === EnumDataTransferStatus.InProgress
          );
          if (!inpogressUploaded) {
            toBeUploaded = state.Questionnaire.UploadedFileList.filter(
              (file) => file.UploadStatus === EnumDataTransferStatus.NotStarted
            );
            if (toBeUploaded && toBeUploaded.length > 0) {
              // create temp upload file list
              let tempUploadFileList: Array<UploadFileDTO> = [
                ...state.Questionnaire.UploadedFileList,
              ] as Array<UploadFileDTO>;

              // select next file index to be uploading
              let nextToBeUploadedFileIndex: number =
                tempUploadFileList.findIndex(
                  (file) =>
                    file.UploadStatus === EnumDataTransferStatus.NotStarted
                );

              // update next file to be uploading status to inprogress
              tempUploadFileList[nextToBeUploadedFileIndex] = {
                ...tempUploadFileList[nextToBeUploadedFileIndex],
                UploadStatus: EnumDataTransferStatus.InProgress,
              };

              // update ui status of next file to be uploading
              yield put(updateUploadFilesList(tempUploadFileList));

              const result = yield call(
                async () =>
                  await inspectionFileUpload(
                    tempUploadFileList[nextToBeUploadedFileIndex]
                  )
              );

              if (!result.IsSuccess) {
              } else {
                yield put(
                  updateUploadFileStatus({
                    ...result.Data,
                    UploadStatus: EnumDataTransferStatus.Finished,
                  })
                );
              }
            }
          }
        } while (toBeUploaded.length > 0);
        const state: QuestionnaireDataType = yield select(
          (s: RootState) => s.siteInspectionQuestionnaireReducer
        );

        if (state.IsQuestionnaireSaveRunning) {
          yield put({
            type: state.QuestionnaireSaveActionName,
            payload: {
              ...state.QuestionnaireSaveCopy,
              UploadedFileList: state.Questionnaire.UploadedFileList,
            },
          });

          yield put(
            updateQuestionnaireSaveRunningStatus({
              QuestionnaireSaveActionName: "",
              IsQuestionnaireSaveRunning: false,
              QuestionnaireSaveCopy: {} as QuestionnaireDTO,
            } as QuestionnaireDataType)
          );
        }
      } catch (error) {}
    }
  );

  // yield takeLatest(QUESTIONNAIREPAGESTOPFILEUPLOAD, function* () {
  //   yield cancel(fileuploading);
  // });
};

const updateQuestionAnswerApprovalStatusWatcher = function* (): any {
  yield takeEvery(
    APPROVEORREJECTINSPECTIONQUESTIONANSWER,
    function* (action: AnyAction): any {
      // set page loading
      yield put(setLoading(true));

      const questionAnswerApproveOrReject: InspectionQuestionDTO =
        action.payload;

      try {
        // save Subject data from backend

        const result: ActionResponseType = yield call(() =>
          updateQuestionAnswerApprovalStatus(questionAnswerApproveOrReject)
        );

        if (!result.IsSuccess) {
          yield put(
            openPopup({
              Open: true,
              BodyText: result.ErrorMessage
                ? result.ErrorMessage
                : "An error occurred when saving data",
              HeaderText: "Warning",
              PopupType: EnumPopupType.WarningPopup,
            } as PopupProps)
          );
        } else {
          const inspectionQuestionnaireDTO: QuestionnaireDTO = {
            ...result.Data,
            ReadOnlyMode:
              questionAnswerApproveOrReject.AnswerApprovalStatus ===
              EnumApprovalStatus.Reject
                ? false
                : result.Data?.ReadOnlyMode,
            RejectedNow:
              questionAnswerApproveOrReject.AnswerApprovalStatus ===
              EnumApprovalStatus.Reject,
          };

          yield put(setSiteInspectionQuestionnaire(inspectionQuestionnaireDTO));

          if (
            questionAnswerApproveOrReject.AnswerApprovalStatus ===
            EnumApprovalStatus.Reject
          ) {
            yield put(setPageMode(EnumPageMode.EditMode));
          } else {
            yield put(setPageMode(EnumPageMode.ViewMode));
          }
        }
      } catch (error) {
        yield put(
          openPopup({
            Open: true,
            BodyText: "An error occurred when saving data",
            HeaderText: "Warning",
            PopupType: EnumPopupType.WarningPopup,
            // okBtnClick: () => navigate("/standards", {}),
          } as PopupProps)
        );
      }

      // set page loading
      yield put(setLoading(false));
    }
  );
};

const SiteInspectionQuestionnairePageSaga = function* () {
  // yield all([
  //   getSiteInspectionQuestionWatcher(),
  //   saveSiteInspectionQuestionWatcher(),
  //   saveSiteInspectionStandardStatusWatcher(),
  //   inspectionFileUploadWatcher(),
  // ]);
  // yield all([
  //   fork(getSiteInspectionQuestionWatcher),
  //   fork(saveSiteInspectionQuestionWatcher),
  //   fork(saveSiteInspectionStandardStatusWatcher),
  //   fork(inspectionFileUploadWatcher),
  // ]);
  yield fork(getSiteInspectionQuestionWatcher);
  yield fork(saveSiteInspectionQuestionWatcher);
  yield fork(saveSiteInspectionStandardStatusWatcher);
  yield fork(inspectionFileUploadWatcher);
  yield fork(updateQuestionAnswerApprovalStatusWatcher);
};

export default SiteInspectionQuestionnairePageSaga;
