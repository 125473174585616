// project imports
import { StandardDTO } from "../../../common/types/common.dto.types";
import { ActionResponseType } from "../../../common/types/common.types";
import { ApiClient } from "../../../core/api/apiClient.service";
import { AxiosError } from "axios";
// client object for axios calls to backend
const client = new ApiClient();

/* ==============================|| VIEWS - PAGES - STANDRARD - API ||==============================  */

// Get standard data from backend
export const getStandardById = async (
  id: number
): Promise<ActionResponseType> => {
  try {
    const URL: string = `Standard/GetStandardById/${id}`;
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .get<ActionResponseType>(URL)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

// Save/Update standard
export const saveStandard = async (
  standard: StandardDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "";
    if (standard.Id > 0) {
      URL = "Standard/UpdateStandard";
    } else {
      URL = "Standard/SaveStandard";
    }
    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, standard)
      .then((response) => {
        result = response;
      })
      .catch((error: AxiosError) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

// Check standard status before deactivate
// check standard used for questions
// check standard used for inspections
export const checkBeforeDeactivateStandard = async (
  standard: StandardDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "Standard/CheckBeforeDeactivateStandard";

    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, standard)
      .then((response) => {
        result = response;
      })
      .catch((error: AxiosError) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

// Deactivate standard
export const deactivateStandardApi = async (
  standard: StandardDTO
): Promise<ActionResponseType> => {
  try {
    let URL: string = "Standard/DeactivateStandard";

    let result: ActionResponseType = {} as ActionResponseType;
    await client
      .post<ActionResponseType>(URL, standard)
      .then((response) => {
        result = response;
      })
      .catch((error: AxiosError) => {
        return error;
      });
    return result;
  } catch (error) {
    throw error;
  }
};

//Get SME dropdown list for standard
export const getStandardSMEDropdownList =
  async (): Promise<ActionResponseType> => {
    try {
      const URL: string = `Standard/GetStandardSMEDropdownList`;
      let result: ActionResponseType = {} as ActionResponseType;
      await client
        .get<ActionResponseType>(URL)
        .then((response) => {
          result = response;
        })
        .catch((error) => {
          return error;
        });
      return result;
    } catch (error) {
      throw error;
    }
  };
