import { QuestionCategoryDTO } from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - MULTIPLE CHOICES - ACTION ||============================== //

export const GETMULTIPLECHOICESLIST = "GETMULTIPLECHOICESLIST";

export const getMultipleChoiceList = (searchParameter: QuestionCategoryDTO) => {
  return {
    type: GETMULTIPLECHOICESLIST,
    payload: {
      searchParameter: searchParameter,
    },
  };
};
