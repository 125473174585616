import { useState, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from ".";
import jwt_decode from "jwt-decode";
import { authReducerActions } from "../reducers/authReducer";
import { AuthType } from "../reducers/auth.types";
import { ApiClient } from "../api/apiClient.service";
import {
  ActionResponseType,
  AppOptions,
} from "../../common/types/common.types";
import { EnumLogoutReason } from "../../views/pages/LogoutPage/LogoutPage.types";
import { useLocation } from "react-router-dom";
import { LoginRequestErrorType } from "../../views/pages/LoginPage/LoginPage.types";
import { loginReducerActions } from "../../views/pages/LoginPage/LoginPage.reducer";
import { openPopup } from "../../components/Popups/Popup.reducer";
import { PopupProps } from "../../components/Popups/PopupProps.types";
import { EnumPopupType } from "../../components/Popups/Popup.enum";
import loginSagaActions from "../../views/pages/LoginPage/LoginPage.action";
import { GetAppOptions } from "../../common/functions/common.functions";
import { EnumAuthenticationModes } from "../../common/enums/common.enums";

export interface AuthProperties {
  GeneralId: string;
  DisplayName: string;
  ExpiryMinutes: string;
  ExpiryWarningMunutes: string;
  NewTokenFrequency: string;
  SystemFunctions: Array<string>;
  ProfilePhoto: string;
  UserScope: string;
  SystemScreensIds: Array<string>;
  IsFirstTimeLogging: string;
}

const client = new ApiClient();
const appOptions: AppOptions = GetAppOptions();

const getAccessToken = () => {
  try {
    const store = JSON.parse(
      localStorage.getItem("hpr_token_store") || "null"
    ) as AuthType | null;
    return store.AccessToken as string | null;
  } catch {
    return null;
  }
};

const reAcquireToken = async (cb: (data?: AuthType) => void): Promise<void> => {
  const accessToken = getAccessToken();

  return client
    .post("/Auth/ReAcquireTokens", { AccessToken: accessToken ?? "" })
    .then((data: ActionResponseType) => {
      if (
        data?.Data?.AccessToken &&
        data?.Data?.GeneralToken &&
        data?.Data?.Stamp
      ) {
        const tokenStore = JSON.parse(
          localStorage.getItem("hpr_token_store") || "null"
        ) as AuthType | null;

        if (tokenStore != null) {
          tokenStore.AccessToken = data?.Data?.AccessToken;
          tokenStore.GeneralToken = data?.Data?.GeneralToken;
          tokenStore.Stamp = data?.Data?.Stamp;
          tokenStore.Authenticated = data?.Data?.Authenticated;
          localStorage.setItem("hpr_token_store", JSON.stringify(tokenStore));
        } else {
          var query = new URLSearchParams();
          query.append(
            EnumLogoutReason.General.toString(),
            "Sorry, an unexpected error occured. Please login again."
          );
          localStorage.setItem("autoLoggedOut", "true");
          window.location.href = "/logout?" + query.toString();

          localStorage.removeItem("hpr_token_store");
          localStorage.removeItem("firstTimeLog");
        }

        var d = {
          AccessToken: data?.Data?.AccessToken,
          GeneralToken: data?.Data?.GeneralToken,
          Stamp: data?.Data?.Stamp,
          Authenticated: data?.Data?.Authenticated,
        } as AuthType;

        if (cb) cb(d);
      } else {
        var query = new URLSearchParams();
        query.append(EnumLogoutReason.General.toString(), data?.ErrorMessage);
        localStorage.setItem("autoLoggedOut", "true");
        window.location.href = "/logout?" + query.toString();

        localStorage.removeItem("hpr_token_store");
        localStorage.removeItem("firstTimeLog");
        if (cb) cb();
      }
    })
    .catch((e) => {
      localStorage.setItem("autoLoggedOut", "true");
      window.location.href = "/logout";
      if (cb) cb();
      // debugger;
      localStorage.removeItem("hpr_token_store");
      localStorage.removeItem("firstTimeLog");
    });
};

const verifyToken = async (
  accessToken: string,
  cb: (ok: boolean) => void
): Promise<void> => {
  return client
    .post("/Auth/Verify", { AccessToken: accessToken })
    .then((data: boolean) => {
      if (data) {
        if (cb) cb(true);
      } else {
        if (cb) cb(false);
      }
    })
    .catch((e) => {
      if (cb) cb(false);
    });
};

const useAuth = () => {
  const authState = useAppSelector((r) => r.authReducer);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const displayLogoutPopup = (sessionExpired: boolean) => {
    dispatch(
      openPopup({
        Open: true,
        BodyText: `Unable to establish a connection to the server${
          sessionExpired ? " and user session also expired" : ""
        }. You will be logged off automatically.`,
        HeaderText: "Warning",
        PopupType: EnumPopupType.WarningPopup,
        OkBtnClick() {
          logOut();
        },
      } as PopupProps)
    );
  };

  const [auth, setAuth] = useState<AuthProperties | null>(() => {
    try {
      const tokenStore = JSON.parse(
        localStorage.getItem("hpr_token_store") || "null"
      ) as AuthType | null;

      if (
        tokenStore &&
        tokenStore.AccessToken &&
        tokenStore.GeneralToken &&
        tokenStore.Stamp
      ) {
        const decoded = jwt_decode(tokenStore.GeneralToken) as AuthProperties;

        return decoded;
      }
    } catch {}

    return null;
  });

  useEffect(() => {
    if (auth) {
      const tokenStore = JSON.parse(
        localStorage.getItem("hpr_token_store") || "null"
      ) as AuthType | null;
      if (tokenStore) {
        dispatch(authReducerActions.setAuth(tokenStore));
      }
    } else {
      dispatch(authReducerActions.removeAuth());
    }
  }, [auth]);

  // useEffect(() => {
  //   if (authState?.AccessToken && authState?.GeneralToken && authState?.Stamp) {
  //     setLoading(true);
  //     reAcquireToken((data) => {
  //       if (data) {
  //         dispatch(authReducerActions.setAuth(data));
  //       } else {
  //         // console.log("token reacquire failed");
  //       }
  //       setLoading(false);
  //     });
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    if (authState.AccessToken && authState.GeneralToken && authState.Stamp) {
      let latest = authState;
      try {
        const tokenStore = JSON.parse(
          localStorage.getItem("hpr_token_store") || "null"
        ) as AuthType | null;

        if (tokenStore.Stamp > authState.Stamp) {
          latest = tokenStore;
        }
      } catch {}

      try {
        localStorage.setItem("hpr_token_store", JSON.stringify(latest));

        if (latest?.GeneralToken) {
          const decoded = jwt_decode(latest.GeneralToken) as AuthProperties;
          setAuth(decoded);
        } else {
          // console.log("token decode failed");
        }
      } catch {
        //    debugger;
        localStorage.removeItem("hpr_token_store");
        localStorage.removeItem("firstTimeLog");
        setAuth(null);
      }
    } else {
      //    debugger;
      localStorage.removeItem("hpr_token_store");
      localStorage.removeItem("firstTimeLog");
      setAuth(null);
    }
  }, [authState?.AccessToken, authState?.GeneralToken, authState?.Stamp]);

  const logOut = useCallback((error?: LoginRequestErrorType) => {
    const appOptions: AppOptions = GetAppOptions();

    if (
      appOptions.AuthMode ===
      EnumAuthenticationModes.MicrosoftAzureActiveDirectoryAuthentication
    ) {
      dispatch(loginSagaActions.logout());
    }
    dispatch(authReducerActions.removeAuth());
    if (error) {
      if (
        appOptions.AuthMode ===
        EnumAuthenticationModes.MicrosoftAzureActiveDirectoryAuthentication
      ) {
        dispatch(loginReducerActions.setError(error.Error));
      } else if (
        appOptions.AuthMode === EnumAuthenticationModes.BasicAuthentication
      ) {
        dispatch(loginReducerActions.setErrors([error]));
      }
    }
    localStorage.removeItem("remediationTrackerTaskSearchParameter");
    localStorage.removeItem("remediationTrackerTaskColumnOptions");
    localStorage.removeItem("selectedRole");
    localStorage.removeItem("siteInspectionSearchParameter");
    localStorage.removeItem("MultipleChoicesSearchParameter");
    setAuth(null);
  }, []);

  const reAcquire = useCallback(
    (cb?: () => void) => {
      if (authState.AccessToken && authState.GeneralToken && authState.Stamp) {
        setLoading(true);
        reAcquireToken((data) => {
          if (data) {
            if (data.Authenticated) {
              dispatch(authReducerActions.setAuth(data));
            } else {
              // console.log("token re-acquisition failed");
              displayLogoutPopup(true);
            }
          } else {
            // console.log("token re-acquisition failed");
            displayLogoutPopup(false);
          }

          setLoading(false);
          if (cb) {
            cb();
          }
        });
      }
    },
    [authState]
  );
  const verify = useCallback((accessToken: string, cb: () => void) => {
    verifyToken(accessToken, (ok) => {
      if (!ok) {
        //    debugger;
        localStorage.removeItem("hpr_token_store");
        dispatch(authReducerActions.removeAuth());
        setAuth(null);
      }
      if (cb) cb();
    });
  }, []);
  return [auth, loading, logOut, reAcquire, verify] as const;
};

export default useAuth;
