import { FC, useEffect, useRef, useState } from "react";
import {
  HPRMaterialReactTableColumnFilteredSummaryType,
  HPRMaterialReactTableProps,
} from "./HPRMaterialReactTable.type";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MaterialReactTable,
} from "material-react-table";
import {
  ColumnFiltertypeDTO,
  SortDropDownDTO,
} from "../../../common/types/common.dto.types";
import { EnumSortTypes } from "../../../common/enums/common.enums";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HPRMaterialReactTableToolbarComponent from "./HPRMaterialReactTableToolbarComponent";

// ==============================|| HPR - MATERIAL_REACT_TABLE ||============================== //

const HPRMaterialReactTable: FC<HPRMaterialReactTableProps> = (props) => {
  const dataFetchedRef = useRef(false);
  const columnFilterDataFetchedRef = useRef(false);
  const toolBarRef = useRef(null);

  const [pagination, setPagination] = useState({
    pageIndex: props?.PaginationProps?.SelectedPage
      ? props.PaginationProps?.SelectedPage - 1
      : 0,
    pageSize: props.PaginationProps?.PageSize,
  });

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [sorting, setSorting] = useState<MRT_SortingState>(
    props.InitialSortByState
      ? [
          {
            id: `${props.InitialSortByState?.Label}-${props.InitialSortByState?.Value}`,
            desc:
              props.InitialSortByState?.SortType === EnumSortTypes.Descending,
          },
        ]
      : []
  );

  // ------------------- handle state Change <start> ------------------ //

  // set pagination
  useEffect(() => {
    setPagination({
      pageIndex: props.PaginationProps?.SelectedPage - 1,
      pageSize: props.PaginationProps?.PageSize,
    });
  }, [props.PaginationProps?.PageSize, props.PaginationProps?.SelectedPage]);

  // pagination
  useEffect(() => {
    // skip on load page
    dataFetchedRef.current = true;
    if (pagination.pageSize === props.PaginationProps?.PageSize) {
      if (pagination.pageIndex + 1 !== props.PaginationProps?.SelectedPage) {
        dataFetchedRef.current = false;
      }
    } else {
      dataFetchedRef.current = false;
    }

    if (dataFetchedRef.current) return;

    onPaginationChange();
  }, [pagination]);

  // column Filters
  useEffect(() => {
    // skip page load
    if (!columnFilterDataFetchedRef.current && columnFilters?.length > 0) {
      columnFilterDataFetchedRef.current = true;
    }

    if (
      !columnFilterDataFetchedRef.current ||
      JSON.stringify(columnFilters) ===
        JSON.stringify(
          props.ColumnFilteredSummary?.map(
            (filter: HPRMaterialReactTableColumnFilteredSummaryType) => {
              return { id: filter.Field, value: filter.Value };
            }
          )
        )
    )
      return;

    onchangeColumnFilters();
  }, [columnFilters]);

  // when change filter column wise
  useEffect(() => {
    if (!props.ColumnFilteredSummary) {
      setColumnFilters([]);
    } else {
      const filters = props.ColumnFilteredSummary?.map(
        (filter: HPRMaterialReactTableColumnFilteredSummaryType) => {
          return { id: filter.Field, value: filter.Value };
        }
      );

      setColumnFilters(filters);
    }
  }, [props.ColumnFilteredSummary]);

  // sorting
  useEffect(() => {
    onchangeSorting();
  }, [sorting]);

  // when change filter column wise
  useEffect(() => {
    if (!props.FilterColumnWise) {
      setColumnFilters([]);
    }
  }, [props.FilterColumnWise]);

  // ------------------- handle state Change <End> ------------------ //

  // --------------------- Call Back Function <Start>---------------------------- //
  const isInitialSortState = useRef(true);
  const onchangeSorting = () => {
    if (
      props.CallbackFunctionProps?.onChangeSortByCallBack &&
      sorting &&
      sorting?.length > 0
    ) {
      if (!isInitialSortState.current || !props.InitialSortByState) {
        const sort = sorting[0];

        const sortBy = {
          SortType: sort.desc
            ? EnumSortTypes.Descending
            : EnumSortTypes.Ascending,
          Value: sort?.id?.split("-")[1],
          Label: sort.id?.split("-")[0],
        } as SortDropDownDTO;

        props.CallbackFunctionProps?.onChangeSortByCallBack(sortBy);
      } else {
        isInitialSortState.current = false;
      }
    } else {
      const sortBy = props.DefaultSort
        ? props.DefaultSort
        : ({
            SortType: EnumSortTypes.Ascending,
            Value: "1",
            Label: "Name",
          } as SortDropDownDTO);

      setSorting(
        props.DefaultSort
          ? [
              {
                id: `${props.DefaultSort?.Label}-${props.DefaultSort?.Value}`,
                desc: props.DefaultSort?.SortType === EnumSortTypes.Descending,
              },
            ]
          : []
      );

      props.CallbackFunctionProps?.onChangeSortByCallBack(sortBy);
    }
  };

  const onchangeColumnFilters = () => {
    if (props.CallbackFunctionProps?.onColumnFiltersCallBack) {
      const columnsFilterList = columnFilters?.map((column) => {
        return {
          Column: column.id,
          Value: column.value,
        } as ColumnFiltertypeDTO;
      });

      props.CallbackFunctionProps?.onColumnFiltersCallBack(columnsFilterList);
    }
  };

  const onPaginationChange = () => {
    if (props?.PaginationProps?.onPaginationChangeCallBack) {
      props?.PaginationProps.onPaginationChangeCallBack(
        pagination.pageIndex + 1,
        pagination.pageSize
      );
    }
  };

  const onRowClicked = (row: any) => {
    if (
      props.EnableRowClick &&
      props.CallbackFunctionProps?.onRowClickedCallBack
    ) {
      props.CallbackFunctionProps?.onRowClickedCallBack(row.original);
    }
  };
  // --------------------- Call Back Function <End>---------------------------- //

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable
        enableStickyHeader
        enableStickyFooter
        columns={props.Columns}
        data={props.Data}
        defaultColumn={{ maxSize: 900, minSize: 50, size: 150 }}
        rowCount={props?.PaginationProps?.TotalRecordsCount}
        sortDescFirst={false}
        manualPagination={true}
        paginationDisplayMode="pages"
        muiPaginationProps={{
          rowsPerPageOptions: [10, 15, 25, 50, 100],
        }}
        // muiTopToolbarProps={{ sx: { padding: 10 } }}
        onPaginationChange={setPagination}
        enableColumnFilters={props.EnableColumnFilters ?? false}
        manualFiltering={true}
        columnFilterDisplayMode="popover"
        onColumnFiltersChange={setColumnFilters}
        enableSorting={props.EnableSorting ?? false}
        manualSorting={true}
        onSortingChange={setSorting}
        muiBottomToolbarProps={{
          className: "pag-wrapper",
        }}
        state={{
          pagination: pagination,
          // columnOrder: [
          //   //   ...["mrt-row-expand", "mrt-row-select"],
          //   ...listColumnsState.map((item) => item.ColumnId),
          // ],
          sorting: sorting,
          columnFilters: columnFilters,
          showLoadingOverlay: props.ShowDataLoading,
          isLoading: props.ShowDataLoading,
        }}
        enableColumnActions={false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => onRowClicked(row),
          sx: {
            cursor: props.EnableRowClick ? "pointer" : "",
          },
        })}
        renderToolbarInternalActions={({ table }) => (
          <HPRMaterialReactTableToolbarComponent
            Table={table}
            ToolBarProps={props.ToolBarProps}
            ToolBarRef={toolBarRef}
          />
        )}
        muiTableContainerProps={{
          className: "hpr-material-table-inner",
        }}
        localization={{
          filterFuzzy: "",
          rowsPerPage: "Per page",
        }}
      ></MaterialReactTable>
    </LocalizationProvider>
  );
};

export default HPRMaterialReactTable;
