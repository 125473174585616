import { createSlice } from "@reduxjs/toolkit";
// project imports
import {
  EnumInspectionSetListType,
  EnumPageMode,
  EnumStatus,
} from "../../../common/enums/common.enums";
import { InspectionSetPageType } from "./InspectionSetPage.types";
import { PageModeType } from "../../../common/types/common.page.type";
import SimpleReactValidator from "simple-react-validator";
import {
  DropDownDTO,
  InspectionSetListTypeDTO,
  InspectionSetSelectDTO,
} from "../../../common/types/common.dto.types";

// ==============================|| VIEWS - PAGES - INSPECTION SET PAGE - REDUCER ||============================== //

// set initial state values for InspectionSetPageType
const initialState = {
  IsLoading: false,
  PageMode: {
    IsDisable: false,
    IsShowSaveBtn: false,
    IsShowCloseBtn: false,
    IsShowUpdateBtn: false,
    IsShowCancelBtn: false,
    IsShowDraftBtn: false,
    IsShowEditBtn: false,
    IsShowPublishedBtn: false,
    PageMode: EnumPageMode.Default,
  } as PageModeType,
  InspectionSet: {
    InspectionName: "",
    InspectionId: 0,
    Status: "",
    StatusId: EnumStatus.Draft,
    SelectedAssessmentTypeId: 0,
    DisableType: false,
    InspectionSetListAvailable: [] as Array<InspectionSetSelectDTO>,
    InspectionSetListSelected: [] as Array<InspectionSetSelectDTO>,
    AssessmentTypeList: [] as Array<DropDownDTO>,
  } as InspectionSetListTypeDTO,
  SearchInspectionSetListAvailable: {
    InspectionId: 0,
    Name: "",
    InspectionSetListType: EnumInspectionSetListType.Initial,
    StandardId: 0,
    StandardSystemId: null,
    StandardSubSystemId: null,
    StandardSubectId: null,
    StandardReferenceId: null,
    StandardSectionId: null,
    IsSelected: true,
  } as InspectionSetSelectDTO,
  SearchInspectionSetListSelected: {
    InspectionId: 0,
    Name: "",
    InspectionSetListType: EnumInspectionSetListType.Initial,
    StandardId: 0,
    StandardSystemId: null,
    StandardSubSystemId: null,
    StandardSubectId: null,
    StandardReferenceId: null,
    StandardSectionId: null,
    IsSelected: false,
  } as InspectionSetSelectDTO,
  Validator: new SimpleReactValidator({
    messages: {
      default: "This field is required",
    },
  }),
  CheckAllAvailable: false,
  CheckAllSelected: false,
  ClickInspectionSetItem: {} as InspectionSetSelectDTO,
} as InspectionSetPageType;

export const inspectionSetSlice = createSlice({
  name: "inspectionset",
  initialState: initialState,
  reducers: {
    setInitialData: (
      state: InspectionSetPageType,
      action: { payload: InspectionSetPageType; type: string }
    ) => {
      return {
        ...state,
        InspectionSet: action.payload?.InspectionSet,
        SearchInspectionSetListAvailable:
          action.payload?.SearchInspectionSetListAvailable,
        SearchInspectionSetListSelected:
          action.payload?.SearchInspectionSetListSelected,
        PageMode: action.payload?.PageMode,
      };
    },
    setInitialAvailableDataFromBackEnd: (
      state: InspectionSetPageType,
      action: { payload: InspectionSetPageType; type: string }
    ) => {
      return {
        ...state,
        InspectionSet: {
          ...state.InspectionSet,
          InspectionId: action.payload.InspectionSet.InspectionId,
          InspectionName: action.payload.InspectionSet.InspectionName,
          StatusId: action.payload.InspectionSet.StatusId,
          Status: action.payload.InspectionSet.Status,
          Version: action.payload.InspectionSet.Version,
          SelectedAssessmentTypeId:
            action.payload.InspectionSet.SelectedAssessmentTypeId,
          DisableType: action.payload.InspectionSet.DisableType,
          AssessmentTypeList: action.payload.InspectionSet.AssessmentTypeList,
          InspectionSetListAvailable:
            action.payload.InspectionSet?.InspectionSetListAvailable?.map(
              (item, index) => {
                return {
                  ...item,
                  SequenceNumber: index,
                  IsSelectedDisabled: item.IsSelected,
                };
              }
            ),
          InspectionSetListSelected:
            action.payload.InspectionSet?.InspectionSetListSelected?.map(
              (item, index) => {
                return {
                  ...item,
                  SequenceNumber: index,
                };
              }
            ),
        },
        OriginalInspectionSet: {
          ...state.InspectionSet,
          InspectionId: action.payload.InspectionSet.InspectionId,
          InspectionName: action.payload.InspectionSet.InspectionName,
          StatusId: action.payload.InspectionSet.StatusId,
          Status: action.payload.InspectionSet.Status,
          Version: action.payload.InspectionSet.Version,
          SelectedAssessmentTypeId:
            action.payload.InspectionSet.SelectedAssessmentTypeId,
          DisableType: action.payload.InspectionSet.DisableType,
          InspectionSetListAvailable:
            action.payload.InspectionSet?.InspectionSetListAvailable?.map(
              (item, index) => {
                return {
                  ...item,
                  SequenceNumber: index,
                  IsSelectedDisabled: item.IsSelected,
                };
              }
            ),
          InspectionSetListSelected:
            action.payload.InspectionSet?.InspectionSetListSelected?.map(
              (item, index) => {
                return {
                  ...item,
                  SequenceNumber: index,
                };
              }
            ),
        },
        SearchInspectionSetListAvailable:
          action.payload?.SearchInspectionSetListAvailable,
        SearchInspectionSetListSelected:
          action.payload?.SearchInspectionSetListAvailable,
      };
    },
    setAvailableDataFromBackEnd: (
      state: InspectionSetPageType,
      action: { payload: InspectionSetPageType; type: string }
    ) => {
      //set Available set
      let inspectionSetListAvailable = [
        ...action.payload.InspectionSet.InspectionSetListAvailable.filter(
          (filter) => {
            return !state.InspectionSet?.InspectionSetListAvailable?.some(
              (element) => {
                return (
                  element.StandardId === filter.StandardId &&
                  element.StandardReferenceId === filter.StandardReferenceId &&
                  element.StandardSystemId === filter.StandardSystemId &&
                  element.StandardSubSystemId === filter.StandardSubSystemId &&
                  element.StandardSubectId === filter.StandardSubectId &&
                  element.StandardSectionId === filter.StandardSectionId
                );
              }
            );
          }
        ),
      ];

      //set Selected set
      let InspectionSetListSelected: Array<InspectionSetSelectDTO> = [];
      let isOriginalData = false;
      if (
        action.payload.InspectionSet.InspectionSetListSelected &&
        action.payload.InspectionSet.InspectionSetListSelected.length > 0 &&
        action.payload.SearchInspectionSetListAvailable.IsSelected
      ) {
        const newSelectedList = [
          ...action.payload.InspectionSet.InspectionSetListSelected.filter(
            (filter) => {
              return !state.InspectionSet?.InspectionSetListSelected?.some(
                (element) => {
                  return (
                    element.StandardId === filter.StandardId &&
                    element.StandardReferenceId ===
                      filter.StandardReferenceId &&
                    element.StandardSystemId === filter.StandardSystemId &&
                    element.StandardSubSystemId ===
                      filter.StandardSubSystemId &&
                    element.StandardSubectId === filter.StandardSubectId &&
                    element.StandardSectionId === filter.StandardSectionId
                  );
                }
              );
            }
          ),
        ]?.map((item) => {
          return {
            ...item,
            IsSelected: action.payload?.ClickInspectionSetItem?.IsSelected, // false,
          };
        });

        //add this because compaire with data is change
        isOriginalData = true;
        InspectionSetListSelected = [
          ...state.InspectionSet.InspectionSetListSelected,
          ...newSelectedList,
        ];
      } else {
        const isItemInSelectedList =
          state.InspectionSet.InspectionSetListSelected.some(
            (filter) =>
              filter.Id === action.payload.ClickInspectionSetItem?.Id &&
              filter.StandardId ===
                action.payload.ClickInspectionSetItem?.StandardId &&
              filter.InspectionSetListType ===
                action.payload.ClickInspectionSetItem?.InspectionSetListType &&
              filter.StandardReferenceId ===
                action.payload.ClickInspectionSetItem?.StandardReferenceId &&
              filter.StandardSystemId ===
                action.payload.ClickInspectionSetItem?.StandardSystemId &&
              filter.StandardSubSystemId ===
                action.payload.ClickInspectionSetItem?.StandardSubSystemId &&
              filter.StandardSubectId ===
                action.payload.ClickInspectionSetItem?.StandardSubectId &&
              filter.StandardSectionId ===
                action.payload.ClickInspectionSetItem?.StandardSectionId
          );

        if (isItemInSelectedList) {
          const newSelectedList = [
            ...action.payload.InspectionSet.InspectionSetListAvailable.filter(
              (filter) => {
                return !state.InspectionSet?.InspectionSetListSelected?.some(
                  (element) => {
                    return (
                      element.StandardId === filter.StandardId &&
                      element.StandardReferenceId ===
                        filter.StandardReferenceId &&
                      element.StandardSystemId === filter.StandardSystemId &&
                      element.StandardSubSystemId ===
                        filter.StandardSubSystemId &&
                      element.StandardSubectId === filter.StandardSubectId &&
                      element.StandardSectionId === filter.StandardSectionId
                    );
                  }
                );
              }
            ),
          ]?.map((item, index, arrQuestionIdList) => {
            const questionIdList =
              item.InspectionSetListType !== EnumInspectionSetListType.Standard
                ? item.QuestionIdList?.filter((filterQuestionId) => {
                    return !arrQuestionIdList[
                      index - 1
                    ]?.QuestionIdList?.includes(filterQuestionId);
                  })
                : item.QuestionIdList;

            return {
              ...item,
              IsSelected: action.payload?.ClickInspectionSetItem?.IsSelected, //false,
              QuestionIdList: questionIdList,
              QuestionCount: questionIdList.length,
            };
          });

          InspectionSetListSelected = [
            ...state.InspectionSet.InspectionSetListSelected,
            ...newSelectedList.filter((filter) => filter.QuestionCount !== 0),
          ];
        } else {
          InspectionSetListSelected = [
            ...state.InspectionSet.InspectionSetListSelected,
          ];
        }
      }

      inspectionSetListAvailable = [...inspectionSetListAvailable].map(
        (item) => {
          const questionExsit = InspectionSetListSelected.some(
            (filter) =>
              filter.Id === item?.Id &&
              filter.StandardId === item.StandardId &&
              filter.StandardReferenceId === item.StandardReferenceId &&
              filter.StandardSystemId === item.StandardSystemId &&
              filter.StandardSubSystemId === item.StandardSubSystemId &&
              filter.StandardSubectId === item.StandardSubectId &&
              filter.StandardSectionId === item.StandardSectionId &&
              filter.InspectionSetListType === item.InspectionSetListType
          );

          const noOfselectedQuestion = item.QuestionIdList?.filter(
            (filterQuestionId) =>
              InspectionSetListSelected?.some(
                (some) =>
                  some.QuestionIdList?.includes(filterQuestionId) &&
                  item.Id !== some.Id &&
                  item.Name !== some.Name &&
                  item.StandardId === some.StandardId &&
                  some.InspectionSetListType !==
                    EnumInspectionSetListType.Standard
              )
          )?.length;

          const questionCount =
            item.QuestionIdList?.length > 0
              ? item.QuestionIdList?.length - noOfselectedQuestion
              : item.QuestionCount;

          return {
            ...item,
            IsSelected: questionExsit
              ? questionExsit
              : action.payload.SearchInspectionSetListAvailable.IsSelected,
            IsSelectedDisabled: questionExsit,
            IsHide: questionCount <= 0 && !questionExsit,
            QuestionCount:
              questionCount && questionCount > 0
                ? questionCount
                : item.QuestionCount,
          };
        }
      );

      const filteredSelectedItemExsit = InspectionSetListSelected?.some(
        (filter) => {
          return (
            action.payload.SearchInspectionSetListSelected?.StandardId ===
              filter.StandardId &&
            action.payload.SearchInspectionSetListSelected
              ?.StandardReferenceId === filter.StandardReferenceId &&
            action.payload.SearchInspectionSetListSelected?.StandardSystemId ===
              filter.StandardSystemId &&
            action.payload.SearchInspectionSetListSelected
              ?.StandardSubSystemId === filter.StandardSubSystemId &&
            action.payload.SearchInspectionSetListSelected?.StandardSubectId ===
              filter.StandardSubectId &&
            action.payload.SearchInspectionSetListSelected
              ?.StandardSectionId === filter.StandardSectionId //&&
            // filter?.Name?.toUpperCase().includes(
            //   action.payload.SearchInspectionSetListSelected?.Name?.toUpperCase()
            // )
          );
        }
      );

      return {
        ...state,
        InspectionSet: {
          ...state.InspectionSet,
          InspectionSetListAvailable: [
            ...state.InspectionSet.InspectionSetListAvailable,
            ...inspectionSetListAvailable,
          ]?.map((item, index) => {
            return {
              ...item,
              SequenceNumber: index,
            };
          }),
          InspectionSetListSelected: InspectionSetListSelected?.map(
            (item, index) => {
              return {
                ...item,
                QuestionCount: item.QuestionCount,
                SequenceNumber: index,
              };
            }
          ),
        },
        OriginalInspectionSet: {
          ...state.OriginalInspectionSet,
          InspectionSetListAvailable: [
            ...state.InspectionSet.InspectionSetListAvailable,
            ...inspectionSetListAvailable,
          ]?.map((item, index) => {
            return {
              ...item,
              SequenceNumber: index,
            };
          }),
          InspectionSetListSelected: isOriginalData
            ? InspectionSetListSelected?.map((item, index) => {
                return {
                  ...item,
                  QuestionCount: item.QuestionCount,
                  SequenceNumber: index,
                };
              })
            : [...state.OriginalInspectionSet.InspectionSetListSelected],
        },
        SearchInspectionSetListAvailable: {
          ...action.payload.SearchInspectionSetListAvailable,
          Name: "",
        },
        SearchInspectionSetListSelected: filteredSelectedItemExsit
          ? {
              ...action.payload.SearchInspectionSetListSelected,
              Name: "",
            }
          : state.SearchInspectionSetListSelected,
        ClickInspectionSetItem: action.payload.ClickInspectionSetItem,
      };
    },
    setSelectedData: (
      state: InspectionSetPageType,
      action: { payload: InspectionSetPageType; type: string }
    ) => {
      return {
        ...state,
        InspectionSet: {
          ...state.InspectionSet,
          InspectionSetListAvailable:
            action.payload.InspectionSet.InspectionSetListAvailable,
          InspectionSetListSelected:
            action.payload.InspectionSet?.InspectionSetListSelected?.map(
              (item, index) => {
                return {
                  ...item,
                  SequenceNumber: index,
                };
              }
            ),
        },
        SearchInspectionSetListAvailable:
          action.payload?.SearchInspectionSetListAvailable,
        SearchInspectionSetListSelected:
          action.payload?.SearchInspectionSetListSelected,
      };
    },
    setInspectionSet: (
      state: InspectionSetPageType,
      action: { payload: InspectionSetListTypeDTO; type: string }
    ) => {
      return {
        ...state,
        InspectionSet: {
          ...state.InspectionSet,
          Id: action.payload?.InspectionId,
          InspectionId: action.payload?.InspectionId,
          InspectionName: action.payload?.InspectionName,
          Version: action.payload?.Version,
          Status: action.payload?.Status,
          StatusId: action.payload?.StatusId,
          SelectedAssessmentTypeId: action.payload.SelectedAssessmentTypeId,
          DisableType: action.payload.DisableType,
          InspectionSetListAvailable:
            action.payload?.InspectionSetListAvailable,
          InspectionSetListSelected: action.payload?.InspectionSetListSelected,
        },
        OriginalInspectionSet: {
          ...state.OriginalInspectionSet,
          Id: action.payload?.InspectionId,
          InspectionId: action.payload?.InspectionId,
          InspectionName: action.payload?.InspectionName,
          Version: action.payload?.Version,
          Status: action.payload?.Status,
          StatusId: action.payload?.StatusId,
          SelectedAssessmentTypeId: action.payload.SelectedAssessmentTypeId,
          DisableType: action.payload.DisableType,
          InspectionSetListAvailable:
            action.payload?.InspectionSetListAvailable,
          InspectionSetListSelected: action.payload?.InspectionSetListSelected,
        },
        SearchInspectionSetListAvailable: {
          ...state.SearchInspectionSetListAvailable,
          Id: action.payload?.InspectionId,
          InspectionId: action.payload?.InspectionId,
        },
        SearchInspectionSetListSelected: {
          ...state.SearchInspectionSetListSelected,
          Id: action.payload?.InspectionId,
          InspectionId: action.payload?.InspectionId,
        },
      };
    },
    setInspectionSetName: (
      state: InspectionSetPageType,
      action: { payload: string; type: string }
    ) => {
      return {
        ...state,
        InspectionSet: {
          ...state.InspectionSet,
          InspectionName: action.payload,
        },
      };
    },
    setSearchInspectionSetListAvailable: (
      state: InspectionSetPageType,
      action: { payload: InspectionSetPageType; type: string }
    ) => {
      return {
        ...state,
        SearchInspectionSetListAvailable:
          action.payload?.SearchInspectionSetListAvailable,
        SearchInspectionSetListSelected:
          action.payload?.SearchInspectionSetListSelected,
      };
    },
    changePageMode: (
      state: InspectionSetPageType,
      action: { payload: EnumPageMode; type: string }
    ) => {
      if (EnumPageMode.ViewMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.ViewMode,
            IsDisable: true,
            IsShowCloseBtn: true,
            IsShowEditBtn: true,
            IsShowDeactivateBtn: false,
            IsShowCancelBtn: false,
            IsShowDraftBtn: false,
            IsShowPublishedBtn: false,
            IsShowUpdateBtn: false,
            IsShowSaveBtn: false,
          },
        };
      } else if (EnumPageMode.EditMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.EditMode,
            IsDisable: false,
            IsShowCancelBtn: true,
            IsShowDeactivateBtn: true,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
          },
        };
      } else if (EnumPageMode.AddMode === action.payload) {
        return {
          ...state,
          PageMode: {
            ...state.PageMode,
            PageMode: EnumPageMode.AddMode,
            IsDisable: false,
            IsShowCancelBtn: true,
            IsShowDraftBtn: true,
            IsShowPublishedBtn: true,
            IsShowSaveBtn: false,
            IsShowUpdateBtn: false,
            IsShowCloseBtn: false,
            IsShowEditBtn: false,
            IsShowDeactivateBtn: false,
          },
        };
      }
    },
  },
});

export const {
  setInitialData,
  changePageMode,
  setInitialAvailableDataFromBackEnd,
  setAvailableDataFromBackEnd,
  setSelectedData,
  setSearchInspectionSetListAvailable,
  setInspectionSet,
  setInspectionSetName,
} = inspectionSetSlice.actions;

export const inspectionSetReducer = inspectionSetSlice.reducer;
